import styled from "@emotion/styled/macro";
import { GroupWrapper, Input, Label } from "./InputModule";
import { forwardRef } from "react";

const Select = styled(Input)`
  background-color: #fff;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus-visible {
    outline-color: var(--primary-blue);
  }
`;

const SelectModule = forwardRef(function SelectModule(
  { identifier, name, onChange, value, required, options, label, size, style },
  ref
) {
  return (
    <GroupWrapper medium={size === "medium"} icon={true}>
      <Select
        as="select"
        id={identifier}
        name={identifier}
        onChange={onChange}
        value={value}
        required={required}
        ref={ref}
        style={style}
      >
        {options}
      </Select>
      <Label htmlFor={identifier}>{label}</Label>
    </GroupWrapper>
  );
});

export default SelectModule;
