import styled from "@emotion/styled";
import { components } from "react-select";
import SelectFilter from "../ui/SelectFilter";
import criteria from "../../../../../../../../../../shared/resources/_criteria.json";
import tvCriteria from "../../../../../../../../../../shared/resources/_tv_criteria.json";
import podcastCriteria from "../../../../../../../../../../shared/resources/_podcast_criteria.json";

const CRITERIA_TOGGLE_OPTIONS = [
  { type: "criteriaToggle", value: "yes", label: "Yes" },
  { type: "criteriaToggle", value: "no", label: "No" },
  { type: "criteriaToggle", value: "na", label: "NA" },
];

const criteriaSelectStyles = {
  container: (styles) => ({
    ...styles,
    flexGrow: 1,
    "@media screen and  (max-width: 600px)": {
      minWidth: "225px",
    },
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#eaeaea",
    border: "none",
    borderRadius: "none",
    // width: "756px",
    marginRight: "17px",
    marginLeft: 0,
    "@media screen and (max-width: 600px)": {
      // width: "225px",
      marginLeft: "0px",
      marginRight: "10px",
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: (styles) => ({
    ...styles,
  }),
};
const criteriaToggleSelectStyles = {
  container: (styles) => ({
    ...styles,
    minWidth: "75px",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#eaeaea",
    border: "none",
    borderRadius: "none",
    // width: "110px",
    "@media screen and (max-width: 600px)": { width: "70px", margin: 0 },
  }),
  indicatorSeparator: (styles) => ({ display: "none" }),
};

const CriteriaFilterContainerStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: 12px;

  .spacer {
    width: 30px;
  }
  @media screen and (max-width: 600px) {
    min-width: 90%;
  }
`;

const DropdownIndicatorStyles = styled.div`
  color: #3355dd;
  width: 0;
  height: 0;
  border-width: 0 4px 7px 4px;
  border-color: transparent transparent #3355dd transparent;
  border-style: solid;
  transform: rotate(180deg);
  margin-right: 10px;
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorStyles />
    </components.DropdownIndicator>
  );
};

export default function CriteriaFilter({ type, value, index, onChange }) {
  let criteriaVals = criteria;
  if (type === "TV") {
    criteriaVals = tvCriteria;
  } else if (type === "PODCAST") {
    criteriaVals = podcastCriteria;
  }

  const CRITERIA_OPTIONS = Object.keys(criteriaVals)
    .filter((key, index) => key !== "rating" && key !== "subrating")
    .map((key, index) => {
      return {
        type: "criteria",
        label: criteriaVals[key].title,
        value: key,
        id: index,
      };
    });

  return (
    <CriteriaFilterContainerStyles>
      <SelectFilter
        styles={criteriaSelectStyles}
        customComponent={{ DropdownIndicator }}
        options={CRITERIA_OPTIONS}
        onChange={onChange}
        value={CRITERIA_OPTIONS.filter(function (option) {
          return option.value === value.value;
        })}
        id={`criteria-${index}`}
        name={`criteria-${index}`}
      />
      <SelectFilter
        styles={criteriaToggleSelectStyles}
        customComponent={{ DropdownIndicator }}
        options={CRITERIA_TOGGLE_OPTIONS}
        onChange={onChange}
        value={CRITERIA_TOGGLE_OPTIONS.filter(function (option) {
          return option.value === value.toggleValue;
        })}
        id={`criteria-toggle-${index}`}
        name={`criteria-toggle-${index}`}
        isSearchable={false}
        placeholder={null}
      />
    </CriteriaFilterContainerStyles>
  );
}
