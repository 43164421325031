import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ContentService from "../../../../service/ContentService";
import WebsiteRequests from "./WebsiteRequests";
import MythRequests from "./MythRequests";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import AuthService from "../../../../service/AuthService";

export const Text = styled.p`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: left;
`;

const ModuleWrapper = styled.div`
  div.completed-message {
    display: flex;
    flex-direction: column;
  }

  p:has(+ textarea) {
    margin-bottom: 5px;
  }

  p.error {
    color: #ff4444;
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: column;
  }
`;

export const Textarea = styled.textarea`
  height: 100px;
  width: 100%;
  max-width: 460px;
  margin-bottom: 30px;
  border: ${({ error }) =>
    error ? "1px solid var(--primary-red)" : "1px solid var(--primary-black)"};
  border-radius: 2px;

  @media screen and (max-width: 600px) {
    max-width: 290px;
  }

  &:focus-visible {
    outline-color: var(--primary-blue);
  }
  &:user-invalid {
    border: 1px solid red;
    outline-color: red;
  }
`;

export const Header4 = styled.h4`
  margin-bottom: 5px;
`;

export default function RequestModule({ onDismiss, context }) {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const principal = useSelector((state) => state.authentication?.principal);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PartnerView",
        view: `Request - ${context}`,
        user: principal?.username,
        partner: principal?.attributes?.partner,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [context, principal]);

  const handleSubmit = (e, value, type) => {
    e.preventDefault();
    setIsLoading(true);
    let requestValue = {};
    if (value.length === 0) {
      setSubmitted(false);
    } else {
      if (type === "website") {
        requestValue = value;
      } else {
        for (const [key, val] of value) {
          requestValue[key] = val;
        }
      }

      ContentService.getInstance()
        .sendRequest(requestValue)
        .then((res) => {
          if (res.status === 200) {
            setSubmitted(true);
          } else {
            setError(res.message);
          }
        })
        .finally((e) => {
          setIsLoading(false);
        });

      setSubmitted(true);
    }
  };

  return (
    <ModuleWrapper submitted={submitted}>
      {context === "website" &&
      AuthService.getInstance().hasLabelAccess(principal) ? (
        <WebsiteRequests
          onSubmit={handleSubmit}
          submitted={submitted}
          onDismiss={onDismiss}
          isLoading={isLoading}
        />
      ) : (
        ""
      )}

      {context === "myth" &&
      AuthService.getInstance().hasMythAccess(principal) ? (
        <MythRequests
          onSubmit={handleSubmit}
          submitted={submitted}
          onDismiss={onDismiss}
        />
      ) : (
        ""
      )}
    </ModuleWrapper>
  );
}
