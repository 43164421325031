import { useReducer, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AccountService from "../../service/AccountService";
import { UPDATE_USER } from "../../_actions";
import AppReduxStroe from "../../AppReduxStore";
import ErrorMessageview from "../../common/ErrorMessageView";
import LoadingIndicator from "../../common/LoadingIndicator";
import { AccountCancelButton, Button } from "../FormElements/Button";
import {
  AccountDetailsHeader,
  AccountSectionWrapper,
  ButtonsWrapper,
} from "./AccountStyles";
import InputModule from "../FormElements/InputModule";

const UpdateAccountName = ({ firstName, lastName, onDismiss }) => {
  const [formFields, setFormFields] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "" || firstName,
      lastName: "" || lastName,
    }
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [html5error, setHtml5error] = useState({});
  const [validated, setValidated] = useState(false);

  const intl = useIntl();

  const handleInputChange = (event) => {
    event.target.setCustomValidity(""); // have to reset custom validity else field never returns to valid
    const target = event.target;
    const inputName = target.name;
    let inputValue = target.value;
    if (inputName === "optIn") {
      inputValue = target.checked;
    }

    setHtml5error((prevState) => ({
      ...prevState,
      [inputName]: undefined,
    }));

    setFormFields({ [inputName]: inputValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);
    setError(false);
    setHtml5error({});

    if (validate(event)) {
      AccountService.getInstance()
        .updateName({
          firstName: formFields.firstName,
          lastName: formFields.lastName,
        })
        .then((response) => {
          onDismiss(event, "nameModal");
          AppReduxStroe.dispatch({
            type: UPDATE_USER,
            user: response,
          });
        })
        .catch((error) => {
          setHtml5error((prevState) => ({
            ...prevState,
            // firstName: formFields.firstName,
          }));
        })
        .finally((response) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const checkIsValid = (e) => {
    if (e.target.checkValidity() === false) {
      e.target.parentNode.classList.add("was-validated");
    }
  };

  const validate = (event) => {
    setValidated(true);

    return event.target.checkValidity();
  };

  return (
    <>
      <AccountSectionWrapper edit>
        <AccountDetailsHeader>
          <FormattedMessage id="template.name" defaultMessage="Name" />
        </AccountDetailsHeader>
        <ButtonsWrapper>
          <AccountCancelButton
            onClick={onDismiss}
            form="update-account-name-form"
          >
            <FormattedMessage id="template.cancel" defaultMessage="Cancel" />
          </AccountCancelButton>
          <Button form="update-account-name-form" type="submit">
            {loading ? (
              <LoadingIndicator button />
            ) : (
              <FormattedMessage id="template.save" defaultMessage="Save" />
            )}
          </Button>
        </ButtonsWrapper>
      </AccountSectionWrapper>
      {error ? (
        <div className="checkout__error">
          <ErrorMessageview error={error} />
        </div>
      ) : (
        ""
      )}
      <form
        onSubmit={handleSubmit}
        disabled={loading}
        aria-busy={loading}
        noValidate
        name="update-account-name-form"
        id="update-account-name-form"
      />
      <InputModule
        type="text"
        identifier="firstName"
        placeholder={intl.formatMessage({
          id: "template.first_name",
          defaultMessage: "First Name",
        })}
        autoComplete="firstName"
        value={formFields.firstName}
        onChange={handleInputChange}
        onBlur={checkIsValid}
        required={true}
        maxLength={35}
        onInvalid={() => {
          let firstName = {
            id: "template.firstname_blank",
            defaultMessage: "Please enter a first name",
            name: "firstName",
            html: true,
          };

          setHtml5error((prevState) => ({
            ...prevState,
            firstName,
          }));
        }}
        error={html5error.firstName}
        form="update-account-name-form"
      />
      <InputModule
        type="text"
        identifier="lastName"
        placeholder={intl.formatMessage({
          id: "template.last_name",
          defaultMessage: "Last Name",
        })}
        autoComplete="lastName"
        value={formFields.lastName}
        onChange={handleInputChange}
        onBlur={checkIsValid}
        required={true}
        maxLength={35}
        onInvalid={() => {
          let lastName = {
            id: "template.lastname_blank",
            defaultMessage: "Please enter a last name",
            name: "lastName",
            html: true,
          };

          setHtml5error((prevState) => ({
            ...prevState,
            lastName,
          }));
        }}
        error={html5error.lastName}
        form="update-account-name-form"
      />
    </>
  );
};

export default UpdateAccountName;
