import styled from "@emotion/styled";
import { components } from "react-select";
import SelectFilter from "../ui/SelectFilter";

// HIGH CREDIBILITY, GENERALLY CREDIBLE, CREDIBLE WITH EXCEPTIONS, PROCEED WITH CAUTION, PROCEED WITH MAXIMUM CAUTION)
// plus Platform and Satire

const DropdownIndicatorStyles = styled.div`
  color: #3355dd;
  width: 0;
  height: 0;
  border-width: 0 4px 7px 4px;
  border-color: transparent transparent #3355dd transparent;
  border-style: solid;
  transform: rotate(180deg);
  margin-right: 10px;
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorStyles />
    </components.DropdownIndicator>
  );
};

const criteriaSelectStyles = {
  container: (styles) => ({
    ...styles,
    flexGrow: 1,
    "@media screen and  (max-width: 600px)": {
      minWidth: "225px",
    },
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#eaeaea",
    border: "none",
    borderRadius: "none",
    // width: "756px",
    marginRight: "17px",
    marginLeft: 0,
    "@media screen and (max-width: 600px)": {
      // width: "225px",
      marginLeft: "0px",
      marginRight: "10px",
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: (styles) => ({
    ...styles,
  }),
};

function RemoveButton({ onClick }) {
  return <RemoveFilterButton onClick={onClick} />;
}

const RemoveFilterButton = styled.button`
  height: 15px;
  width: 15px;
  cursor: pointer;
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Emod/search/filter/clear%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/close-small' fill='%233355DD' transform='translate(-1.000000, -1.000000)'%3E%3Cpath d='M17.0666667,6.93333333 L17.0663333,14.9333333 L25.0666667,14.9333333 L25.0666667,17.0666667 L17.0663333,17.0663333 L17.0666667,25.0666667 L14.9333333,25.0666667 L14.9333333,17.0663333 L6.93333333,17.0666667 L6.93333333,14.9333333 L14.9333333,14.9333333 L14.9333333,6.93333333 L17.0666667,6.93333333 Z' id='close' transform='translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") -1px -1px
    no-repeat;
  background-size: 20px;
  margin: -2px 10px 0 -22px;
  padding: 0;
  inset: 0;
  border: 0;
`;

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <RemoveButton {...props} />
    </components.MultiValueRemove>
  );
};

const multiSelectFilterStyles = {
  container: (styles) => ({
    ...styles,
    flexGrow: 1,
    "@media screen and  (max-width: 600px)": {
      minWidth: "225px",
    },
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#eaeaea",
    border: "none",
    borderRadius: "none",
    // width: "756px",
    marginRight: "17px",
    marginLeft: 0,
    "@media screen and (max-width: 600px)": {
      // width: "225px",
      marginLeft: "0px",
      marginRight: "10px",
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#eaeaea",
    marginRight: "4px",
    alignItems: "center",
    ":hover .select__multi-value__remove": {
      display: "flex",
    },
  }),
  multiValueLabel: (styles, state) => ({
    height: state.selectProps.isMobile ? "auto" : "30px",
    border: "1px solid #3355DD",
    borderRadius: "30px",
    color: "#3355DD",
    fontSize: state.selectProps.isMobile ? "12px" : "14px",
    lineHeight: "28px",
    textAlign: "center",
    backgroundColor: "#fff",
    padding: "0 25px 0 10px",
    "@media screen and (max-width: 600px)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),
  multiValueRemove: () => ({
    marginLeft: "-3px",
    color: "#3355DD",
    display: "none",
    alignItems: "center",
    "@media screen and (max-width: 600px)": { marginTop: "7px" },
  }),
  menu: (styles) => ({
    ...styles,
    "@media screen and (max-width: 600px)": {
      fontSize: "12px",
      width: "350px",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? "rgba(0,0,0,0.04)" : "auto",
    ":hover": {
      backgroundColor: "rgba(0,0,0,0.04)",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    flexGrow: 1,
    "@media screen and (max-width: 600px)": { width: "240px" },
  }),
};

export default function RatingsFilter({ filter, onChange, RATINGS }) {
  return (
    <SelectFilter
      styles={multiSelectFilterStyles}
      customComponent={{
        DropdownIndicator: DropdownIndicator,
        IndicatorContainer: () => null,
        MultiValueRemove: MultiValueRemove,
        ClearIndicator: () => null,
      }}
      options={RATINGS}
      onChange={onChange}
      value={filter?.value}
      id={filter?.slug}
      name={filter?.slug}
      searchable={false}
      multi
    />
  );
}
