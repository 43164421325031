import { combineReducers } from "redux";

import {
  APP_CONFIG,
  DELETE_SEARCH_TERM,
  DELETE_SELECTED_LABEL,
  GET_PRINCIPAL,
  LOGOUT,
  RESET_ALL_FILTERS,
  RESET_SELECTED_FILTER,
  RESET_ALL_RATINGS_FILTERS,
  RESET_SELECTED_RATINGS_FILTER,
  RESET_ALL_SORT_FILTERS,
  SET_CRITERIA_FILTERS,
  SET_LABEL_DOMAIN,
  SET_SCORE_FILTERS,
  SET_SCORE_TYPE,
  SET_SEARCH_CONTEXT,
  SET_SEARCH_PAGE,
  SET_SEARCH_TERM,
  SET_SELECTED_FILTERS,
  SET_SELECTED_RATINGS_FILTERS,
  SET_SELECTED_LABEL,
  SET_SUBSCRIBED,
  UPDATE_USER,
  SET_SUSPENDED,
} from "../_actions";

import AuthService from "../service/AuthService";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { gtmDataLayer } from "../common/Helper";

function authentication(state = { authenticated: false }, action) {
  const authService = AuthService.getInstance();
  switch (action.type) {
    case LOGOUT:
      authService.logOut(true);
      return {
        ...state,
        principal: null,
        authenticated: null,
        user: null,
      };
    case GET_PRINCIPAL:
      return Object.assign({}, state, {
        principal: action.principal,
        authenticated: action.authenticated,
      });
    case UPDATE_USER:
      return Object.assign({}, state, {
        user: action.user,
      });
    case APP_CONFIG:
      return Object.assign({}, state, {
        appConfig: action.appConfig,
      });
    default:
      return state;
  }

  // return Object.assign({}, state, {
  //   principal: authService.principal,
  //   authenticated: authService.authenticated(),
  // });
}

function searchTerms(state = { term: null, page: 0 }, action) {
  switch (action.type) {
    case DELETE_SEARCH_TERM:
      return {
        ...state,
        term: action.payload,
        page: 0,
      };

    case SET_SEARCH_TERM:
      return Object.assign({}, state, {
        term: action.payload,
        page: 0,
      });

    case SET_LABEL_DOMAIN:
      return Object.assign({}, state, {
        domain: action.payload,
        page: 0,
      });
    case SET_SEARCH_CONTEXT:
      return Object.assign({}, state, {
        context: action.payload,
        page: 0,
      });
    case SET_SEARCH_PAGE:
      return Object.assign({}, state, {
        page: action.payload,
      });
    default:
      return state;
  }
}

const selectedFilters = {
  topics: {
    name: "Topics",
    slug: "topics",
    value: [],
    multiline: true,
  },
  flags: {
    name: "Flags",
    slug: "flags",
    value: [],
    multiline: true,
  },
  medium: {
    name: "Medium",
    slug: "medium",
    value: [],
    multiline: true,
  },
  coverage: {
    name: "Coverage",
    slug: "coverage",
    value: [],
    multiline: true,
  },
  orientation: {
    name: "Political Orientation",
    slug: "orientation",
    value: [],
    multiline: true,
  },
  location: {
    name: "Market Area",
    slug: "location",
    value: [],
    multiline: true,
  },
  locale: {
    name: "Locale",
    slug: "locale",
    value: [],
    multiline: true,
  },
  ratings: {
    name: "Rating Levels",
    slug: "ratings",
    value: [],
    multiline: false,
  },
  score: {
    name: "Score",
    slug: "score",
    value: { low: "", high: "" },
    defaultRange: { low: 0, high: 100 },
    scoreType: "range",
    multiline: false,
  },
  owner: {
    name: "Owner",
    slug: "owner",
    value: "",
    multiline: false,
    single: true,
  },
  criteria: [
    {
      name: "Criteria",
      slug: "criteria",
      value: null,
      toggleValue: null,
      complete: false,
    },
  ],
  categories: {
    name: "Categories",
    slug: "categories",
    value: [],
    multiline: false,
  },
  subject_tags: {
    name: "Subject",
    slug: "subject_tags",
    value: [],
    multiline: true,
  },
  publisher_type: {
    name: "Publisher Type",
    slug: "publisher_type",
    value: [],
    multiline: false,
  },
  tv_network: {
    name: "TV Network",
    slug: "tv_network",
    key: "network",
    value: "",
    multiline: false,
    single: true,
  },
  opinion: {
    name: "Strongly Opinionated",
    slug: "opinion",
    key: "opinion",
    value: "",
    multiline: false,
    single: true,
  },
  harm_risk: {
    name: "Risk of Harm",
    slug: "harm_risk",
    key: "harm_risk",
    value: [],
    multiline: true,
  },
  myth_location: {
    name: "Myth Location",
    slug: "myth_location",
    key: "location",
    value: [],
    multiline: true,
  },
  spread_location: {
    name: "Spread Location",
    slug: "spread_location",
    key: "spread_location",
    value: [],
    multiline: true,
  },
  show_offline: {
    name: "Include Offline Sites",
    slug: "show_offline",
    key: "show_offline",
    value: true,
    multiline: false,
  },
  communities_served: {
    name: "Communities Served",
    slug: "communities_served",
    key: "communities_served",
    value: [],
    multiline: true,
  },
  bg_segments: {
    name: "BrandGuard Segments",
    slug: "bg_segments",
    key: "bg_segments",
    value: [],
    multiline: true,
  },
  first_appearance_date: {
    name: "First Appeared",
    slug: "first_appearance_date",
    value: { start: "", end: "" },
    type: "date_range",
    multiline: false,
  },
  sortBy: {
    name: "Sort By",
    slug: "sortBy",
    value: "",
    multiline: false,
    single: true,
  },
  sortOrder: {
    name: "Sort Order",
    slug: "sortOrder",
    value: "",
    multiline: false,
    single: true,
  },
  manipulated_media: {
    name: "Manipulated Media",
    slug: "manipulated_media",
    value: [],
    multiline: true,
  },
};

const ratingFilters = {
  rating_type: {
    name: "Rating Type",
    slug: "rating_type",
    key: "rating_type",
    value: [],
    multiline: false,
  },
  update_type: {
    name: "Update Type",
    slug: "update_type",
    key: "update_type",
    value: [],
    multiline: false,
  },
  start_date: {
    name: "Start Date",
    slug: "start_date",
    key: "start_date",
    value: "",
    multiline: false,
    single: true,
  },
  end_date: {
    name: "End Date",
    slug: "end_date",
    key: "end_date",
    value: "",
    multiline: false,
    single: true,
  },
};

function filterRatings(
  state = {
    selectedFilters: ratingFilters,
  },
  action
) {
  switch (action.type) {
    case SET_SELECTED_RATINGS_FILTERS: {
      const { name, value } = action.payload;
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [name]: { ...state.selectedFilters[name], value },
        },
      };
    }
    case RESET_SELECTED_RATINGS_FILTER:
      const currentFilters = { ...state.selectedFilters };
      const { filter } = action.payload;
      if (filter === "start_date" || filter === "end_date") {
        currentFilters[filter].value = "";
      } else {
        currentFilters[filter].value = [];
      }
      return { selectedFilters: currentFilters };

    case RESET_ALL_RATINGS_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ...ratingFilters,
        },
      };

    default:
      return state;
  }
}

function filter(
  state = {
    selectedFilters,
  },
  action
) {
  const principal = action?.payload?.principal;

  switch (action.type) {
    case SET_SELECTED_FILTERS: {
      const { name, value, scoreContext } = action.payload;

      let gtmEvent = "DashboardAdvancedFilter";
      if (name === "sortBy" || name === "sortOrder") {
        gtmEvent = "DashboardAdvancedSort";
      }

      try {
        gtmDataLayer(
          gtmEvent,
          principal,
          action.type,
          scoreContext?.value,
          name,
          JSON.stringify(value)
        );
      } catch (e) {}

      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [name]: { ...state.selectedFilters[name], value },
        },
      };
    }
    case SET_SCORE_TYPE: {
      const { scoreType, scoreContext } = action.payload;
      try {
        gtmDataLayer(
          "DashboardAdvancedFilter",
          principal,
          scoreContext?.value,
          scoreContext?.value,
          scoreType,
          state.selectedFilters.score
        );
      } catch (e) {}

      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          score: {
            ...state.selectedFilters.score,
            scoreType,
          },
        },
      };
    }

    case SET_SCORE_FILTERS: {
      const { scoreValueType, value, scoreContext } = action.payload;
      const newValue = value;
      const currentScore = { ...state.selectedFilters.score };
      const currentScoreValue = currentScore.value;
      const { scoreType } = currentScore;

      try {
        gtmDataLayer(
          "DashboardAdvancedFilter",
          principal,
          scoreContext?.value,
          scoreContext?.value,
          scoreValueType,
          value
        );
      } catch (e) {}

      if (
        scoreType === "range" &&
        (currentScoreValue.low || (scoreValueType === "low" && newValue)) &&
        currentScoreValue.high === ""
      ) {
        currentScoreValue.high = scoreContext.value === "WEB" ? 100 : 10;
      }

      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          score: {
            ...currentScore,
            value: {
              ...currentScoreValue,
              [scoreValueType]: newValue,
            },
          },
        },
      };
    }

    case SET_CRITERIA_FILTERS:
      const { scoreContext } = action.payload;
      const criterias = action.payload.filters.criteria;

      criterias.map((criteria) => {
        criteria.complete = !!(criteria.value && criteria.toggleValue);
        return criteria;
      });

      const count = criterias.reduce(
        (acc, cur) => (cur.complete ? ++acc : acc),
        0
      );

      if (count === criterias.length) {
        criterias.push({
          name: "Criteria",
          slug: "criteria",
          value: null,
          toggleValue: null,
          complete: false,
        });
      }

      criterias.map((criterion) => {
        if (criterion.value && criterion.toggleValue) {
          gtmDataLayer(
            "DashboardAdvancedFilter",
            principal,
            scoreContext.value,
            scoreContext.value,
            criterion.slug,
            `${criterion.value} - ${criterion.toggleValue}`
          );
        }
      });

      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          criteria: criterias,
        },
      };

    case RESET_SELECTED_FILTER:
      const currentFilters = { ...state.selectedFilters };
      const { filter, index } = action.payload;

      if (filter === "show_offline") {
        currentFilters[filter].value = true;
      } else if (filter === "score") {
        currentFilters[filter].value = { low: "", high: "" };
        currentFilters[filter].defaultRange = { low: 0, high: 100 };
      } else if (filter === "criteria") {
        if (currentFilters.criteria.length > 1) {
          if (index || index === 0) {
            currentFilters.criteria.splice(index, 1);

            // selectedFilters.criteria.filter((el, idx) => {
            //   return idx !== index;
            // });
          } else {
            currentFilters.criteria = [
              {
                name: "Criteria",
                slug: "criteria",
                value: null,
                toggleValue: null,
                complete: false,
              },
            ];
          }
        }
      } else if (currentFilters[filter].single) {
        currentFilters[filter].value = "";
      } else if (filter === "show_offline") {
        currentFilters[filter].value = false;
      } else if (filter === "first_appearance_date") {
        currentFilters[filter].value = { start: "", end: "" };
      } else {
        currentFilters[filter].value = [];
      }
      return { selectedFilters: currentFilters };

    case RESET_ALL_FILTERS: // but keep sortBy and sortOrder
      return {
        ...state,
        selectedFilters: {
          ...selectedFilters,
          score: {
            ...selectedFilters.score,
            value: { low: "", high: "" },
          },
          sortBy: {
            ...selectedFilters.sortBy,
            value: "",
          },
          sortOrder: {
            ...selectedFilters.sortOrder,
            value: "",
          },
        },
      };

    case RESET_ALL_SORT_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ...selectedFilters,
          sortBy: {
            ...selectedFilters.sortBy,
            value: "",
          },
          sortOrder: {
            ...selectedFilters.sortOrder,
            value: "",
          },
        },
      };

    default:
      return state;
  }
}

function selectedLabel(state = { selectedLabel: null }, action) {
  switch (action.type) {
    case SET_SELECTED_LABEL:
      return {
        ...state,
        selectedLabel: action.payload,
      };

    case DELETE_SELECTED_LABEL:
      return {
        ...state,
        selectedLabel: action.payload,
      };
    default:
      return state;
  }
}

function isSubscribed(state = { subscribed: false, suspended: false }, action) {
  switch (action.type) {
    case SET_SUBSCRIBED:
      return {
        ...state,
        subscribed: action.payload,
      };
    case SET_SUSPENDED:
      return {
        ...state,
        suspended: action.payload,
      };
    default:
      return state;
  }
}

const App = combineReducers({
  authentication,
  searchTerms,
  selectedLabel,
  isSubscribed,

  searchFilter: filter,
  ratingFilter: filterRatings,
});

export default App;
