import { FilterContainer } from "./ui/FilterContainer";
import React from "react";
import ScoreFilter from "./AdvancedSearchFilters/ScoreFilter";
import CriteriaFilterContainer from "./AdvancedSearchFilters/CriteriaFilterContainer";
import RadioButtonFilter from "./ui/RadioButtonFilter";
import CheckboxFilter from "./ui/CheckboxFilter";
import MultiSelectFilter from "./ui/MultiSelectFilter";
import metadata from "../../../../../../../../../shared/resources/_podcast_metadata.json";
import OfflineToggle from "./ui/OfflineToggle";

const RATINGS_VALUES = [
  { key: "A", value: "A", label: "LOWEST RISK" },
  { key: "B", value: "B", label: "LOW RISK" },
  { key: "M", value: "M", label: "SOME RISK" },
  { key: "F", value: "F", label: "HIGH RISK" },
];

const YESNO = [
  { key: "Yes", value: "Yes", label: "Yes" },
  { key: "No", value: "No", label: "No" },
];

const ORIENTATION = [];

metadata.orientation.values.map((orientation) =>
  ORIENTATION.push({
    type: "orientation",
    value: orientation,
    label: orientation,
  })
);

const TOPICS = [];

metadata.topic.values.map((topic) =>
  TOPICS.push({ value: topic, label: topic })
);

const FLAGS = [];
const FLAG_OBJECTS = {};

metadata.flags.values.forEach((flag) => {
  FLAG_OBJECTS[flag.key] = flag.value;
  FLAGS.push({ type: "flags", value: flag.key, label: flag.value });
});

export const getFlagValue = (flag, stub) => {
  try {
    const result = FLAGS.find((flagVal) => {
      return flagVal.value.toLowerCase().trim() === flag.toLowerCase().trim();
    });
    return result ? result.label : flag;
  } catch (e) {}
  return flag;
};

const PodcastAdvancedSearch = ({
  principal,
  selectedFilters,
  openFilters,
  handleSelectChange,
  handleUI,
  handleSelectAndRadioChange,
  handleCriteriaChange,
  handleToggleChange,
  clear,
}) => (
  <>
    <FilterContainer filter={selectedFilters?.ratings} handleClear={clear}>
      <CheckboxFilter
        filter={selectedFilters?.ratings}
        options={RATINGS_VALUES}
        onChange={handleSelectAndRadioChange}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.score}
      handleClear={clear}
      toggleContainer={handleUI}
      isContainerOpen={openFilters.has("score")}
    >
      <ScoreFilter
        onChange={handleSelectAndRadioChange}
        filter={selectedFilters?.score}
      />
    </FilterContainer>

    <CriteriaFilterContainer
      type={"PODCAST"}
      filters={selectedFilters?.criteria}
      removeCriteriaFilter={clear}
      handleCriteriaChange={handleCriteriaChange}
      toggleContainer={handleUI}
      openFilters={openFilters}
    />

    <FilterContainer
      filter={selectedFilters?.topics}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={TOPICS}
        onChange={handleSelectChange}
        filter={selectedFilters?.topics}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.flags}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={FLAGS}
        isClearable={false}
        filter={selectedFilters?.flags}
        onChange={handleSelectChange}
      />
    </FilterContainer>

    <FilterContainer filter={selectedFilters?.opinion} handleClear={clear}>
      <RadioButtonFilter
        filter={selectedFilters?.opinion}
        options={YESNO}
        onChange={handleSelectAndRadioChange}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.orientation}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        filter={selectedFilters?.orientation}
        options={ORIENTATION}
        onChange={handleSelectChange}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.show_offline}
      handleClear={clear}
      smallerContext
      toggleContainer={handleUI}
      context="podcast"
    >
      <OfflineToggle
        filter={selectedFilters?.show_offline}
        onChange={handleToggleChange}
      />
    </FilterContainer>
  </>
);

export default PodcastAdvancedSearch;
