import React from "react";
import styled from "@emotion/styled";

const PrintLogoWrapper = styled.div`
  display: none;
  @media print {
    display: block;
    padding: 10px 0 40px;
  }
`;

const Logo = styled.img`
  display: none;
  @media print {
    display: block;
    height: 40px;
  }
`;

function PrintLogo() {
  return (
    <PrintLogoWrapper>
      <Logo src="/images/ng-logo-full.svg" />
    </PrintLogoWrapper>
  );
}

export default PrintLogo;
