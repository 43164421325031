import Select from "react-select";

export default function SelectFilter(props) {
  return (
    <Select
      isMulti={props.multi}
      components={props.customComponent}
      name={props.name}
      options={props.options}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={props.styles}
      value={props.value}
      onChange={props.onChange}
      id={props.id}
      isSearchable={props.isSearchable}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
    />
  );
}
