import React from "react";
import styled from "@emotion/styled";
import webCriteria from "../../../../../../../../shared/resources/_criteria.json";
import tvCriteria from "../../../../../../../../shared/resources/_tv_criteria.json";
import podcastCriteria from "../../../../../../../../shared/resources/_podcast_criteria.json";

import Checkmark from "../Checkmark";
import RightArrow from "../common/svg/RightArrow";

import { FormattedMessage } from "react-intl";

const CriteriaStyles = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  display: flex;
  margin-bottom: 10px;
  margin-top: -2px;
  align-items: center;
  min-width: 400px;
`;

const CriteriaView = ({ newCriteria, oldCriteria, network }) => {
  let criteria = webCriteria;
  switch (network) {
    case "TV":
      criteria = tvCriteria;
      break;
    case "PODCAST":
      criteria = podcastCriteria;
      break;
  }

  let changedCriteria = [];
  if (oldCriteria && newCriteria) {
    Object.keys(oldCriteria).forEach((key) => {
      if (newCriteria[key]?.body !== oldCriteria[key]?.body) {
        changedCriteria.push(newCriteria[key]);
      }
    });
  }

  return changedCriteria.map((item, index) => {
    return (
      <CriteriaStyles key={`criteria_${index}`}>
        <Checkmark value={oldCriteria[item.title].body} margin />
        <RightArrow height="40px" width="40px" />
        <Checkmark value={newCriteria[item.title].body} />
        <div>
          <FormattedMessage
            id={`criteria.${index}`}
            defaultMessage={criteria[item.title].title}
          />
        </div>
      </CriteriaStyles>
    );
  });
};

export default CriteriaView;
