import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function useScrollOnParams(ref, param, match, offSet = 0) {
  let { [param]: localParam } = useParams();

  // hack for change of myth to false narrative
  if (localParam === "falseNarrativeSettings") {
    localParam = "mythSettings";
  }

  useEffect(() => {
    if (ref.current) {
      if (localParam === match) {
        setTimeout(() => {
          window.scrollTo({
            top: ref.current.offsetTop - offSet,
            behavior: "smooth",
          });
        }, 0);
      }
    }
  }, [ref, match, localParam, offSet]);

  return null;
}
