import styled from "@emotion/styled/macro";
import { useState } from "react";

const Wrapper = styled.button`
  position: relative;
  border-radius: 2px;
  cursor: pointer;
  background-color: var(--primary-gray);
  width: ${(props) => (props.size === "small" ? "80px" : "100px")};
  height: ${(props) => (props.size === "small" ? "30px" : "40px")};
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  z-index: 1;

  &:focus {
    box-shadow: 0 0 3px 1px var(--primary-blue);
  }
`;

const TextBlock = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => (props.isOn ? "40%" : "0%")};

  width: 60%;
  height: 40px;
  background-color: transparent;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: ${(props) => (props.size === "small" ? "30px" : "40px")};
  transition: all 0.2s ease-in-out;
`;

const ColorBlock = styled.div`
  position: absolute;
  top: 0;

  left: ${(props) => (props.isOn ? "0" : "60%")};
  right: ${(props) => (props.isOn ? "revert" : "0")};
  border-radius: 2px;
  width: 40%;
  height: ${(props) => (props.size === "small" ? "30px" : "40px")};
  background-color: ${(props) =>
    props.isOn ? "var(--primary-blue)" : "var(--primary-black)"};
  flex: 1 1 40%;
  transition: all 0.1s ease-in-out;
`;

export default function SettingToggle({ callback, isOn, size }) {
  const handleClick = () => {
    callback();
  };

  return (
    <Wrapper
      onClick={handleClick}
      type="button"
      value={isOn}
      name="toggle-button"
      data-toggle={isOn}
      size={size}
    >
      <TextBlock isOn={isOn} size={size}>
        {isOn ? "On" : "Off"}
      </TextBlock>
      <ColorBlock isOn={isOn} size={size} />
    </Wrapper>
  );
}
