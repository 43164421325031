import { useCallback, useEffect, useRef, useState } from "react";

import useCheckIfDomainExists from "./useCheckIfDomainExists";
import { Button, ButtonBlack } from "../../../FormElements/Button";
import { GroupWrapper } from "../../../FormElements/InputModule";
import { Textarea, Text } from "./Request";
import SelectModule from "../../../FormElements/SelectModule";
import LoadingIndicator from "../../../../common/LoadingIndicator";

import locales from "../../../../common/locales/locales.json";
import { AccountModalHeader } from "../../../Account/AccountStyles";
import { RadioButton, RadioModule } from "../../../Subscription/SignupStyles";
import {
  ErrorMessage,
  Form,
  Input,
  Label,
  Note,
  RadioWrapper,
  Wrapper,
  WrapperBotttom,
  H4,
} from "./RequestStyles";

const WebsiteRequests = ({ onSubmit, submitted, onDismiss, isLoading }) => {
  const [value, setValue] = useState({
    website: "",
    "extra-information": "",
    urgent: false,
    "urgent-reason": "",
    locale: "en_US",
  });

  const [site, setSite] = useState(null);
  const localeOptions = [...locales];
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const { isInitialLoading, errorMsg, canRequest, setReadyToCheck } =
    useCheckIfDomainExists(site, selectRef);

  function handleCheckSubmit(e) {
    e.preventDefault();

    if (value.website !== "") {
      setReadyToCheck(true);
      setSite(value.website);
    }
  }

  const validate = async (e) => {
    return e.target.checkValidity();
  };

  const submit = async (e) => {
    e.preventDefault();

    if (value["extra-information"] === "") {
      return false;
    }

    if (value.urgent && value["urgent-reason"] === "") {
      return false;
    }

    if (validate(e)) {
      onSubmit(e, value, "website");
    }
  };

  const handleChange = useCallback((e) => {
    setValue((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }, []);

  return (
    <>
      {submitted && !isLoading ? (
        <>
          <AccountModalHeader>Website Rating Request</AccountModalHeader>
          <Text>
            Thank you for requesting a website rating. Our team will review your
            request.{" "}
          </Text>
          <Text>
            Please note that we can only rate websites in the countries and
            languages we cover, and that websites that do not have much news
            content may not be possible to rate against our journalistic
            criteria. Please also note that we receive many rating requests and
            may not be able to get to every request immediately.{" "}
          </Text>
          <Text>
            If the request is urgent or time-sensitive, we recommend following
            up with your NewsGuard client support representative directly or
            contacting us at{" "}
            <a href="mailto:clients@newsguardtech.com">
              clients@newsguardtech.com
            </a>
            .{" "}
          </Text>
          <Button onClick={onDismiss}>Close</Button>
        </>
      ) : (
        <>
          <Wrapper>
            <Form
              id="website-request"
              noValidate
              onSubmit={handleCheckSubmit}
              canRequest={canRequest}
            >
              <AccountModalHeader>Website Rating Request</AccountModalHeader>
              <Text>
                If you would like to suggest a website to be rated by
                NewsGuard's team, please submit a request here.
              </Text>

              <div>
                <ErrorMessage className="form__error">{errorMsg}</ErrorMessage>
              </div>
              <Label htmlFor="website">Website URL</Label>
              <Input
                ref={inputRef}
                id="website"
                name="website"
                medium={true}
                identifier={"website"}
                label={"Website"}
                type={"text"}
                onChange={handleChange}
                value={value.website}
                required
                pattern="^[a-zA-Z0-9][a-zA-Z0-9\-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$"
              />
              <Button type="submit">
                {isInitialLoading ? <LoadingIndicator button /> : "Continue"}
              </Button>
            </Form>
          </Wrapper>
          <WrapperBotttom disabled={!canRequest}>
            {canRequest && (
              <AccountModalHeader>
                Rating Request for: {site}
              </AccountModalHeader>
            )}
            <Form
              onSubmit={submit}
              id="website-info"
              canRequest={!canRequest}
              noValidate
            >
              <Label htmlFor="locale">
                <H4>{canRequest ? site : "Website"} Locale</H4>
                <Text>
                  To the best of your ability, please identify the country and
                  language in which {site} operates.
                </Text>
              </Label>

              <SelectModule
                disabled={!canRequest}
                identifier="locale"
                name="locale"
                onChange={handleChange}
                value={value.localeValue}
                required
                options={localeOptions.map((locale) => (
                  <option key={locale.value} value={locale.value}>
                    {locale.label}
                  </option>
                ))}
                size={"medium"}
                ref={selectRef}
                id="locale"
                style={{ marginBottom: "10px" }}
              />
              <Note>
                <strong>Note:</strong> NewsGuard currently operates in the
                countries and languages listed in the dropdown menu below.
                Please contact{" "}
                <a href="mailto:clients@newsguardtech.com">
                  clients@newsguardtech.com
                </a>{" "}
                for additional information or assistance.
              </Note>
              <GroupWrapper medium={true}>
                <Label htmlFor="extra-information">
                  <H4>
                    *Why are you interested in{" "}
                    {canRequest ? site : "the website"}
                    ’s rating?
                  </H4>
                  <Text style={{ marginBottom: "10px", fontWeight: 400 }}>
                    Let us know if there’s any information about{" "}
                    {canRequest ? site : "the site"}, how you came across it,
                    specific content you’re concerned about, or any other
                    context our team should have.{" "}
                  </Text>
                </Label>
                <Textarea
                  name="extra-information"
                  onChange={handleChange}
                  required
                  disabled={!canRequest}
                  value={value["extra-information"]}
                ></Textarea>
              </GroupWrapper>
              <p>Is this request urgent?</p>
              <RadioWrapper>
                <RadioModule>
                  <RadioButton
                    type="radio"
                    id="urgent"
                    value="true"
                    checked={value.urgent}
                    onChange={() => setValue({ ...value, urgent: true })}
                    name="urgent"
                  />
                  <label htmlFor="urgent" className="payment-types__label">
                    Yes
                  </label>
                </RadioModule>
                <RadioModule>
                  <RadioButton
                    type="radio"
                    id="not-urgent"
                    value="false"
                    checked={!value.urgent}
                    onChange={() => setValue({ ...value, urgent: false })}
                    name="not-urgent"
                  />
                  <label htmlFor="not-urgent" className="payment-types__label">
                    No
                  </label>
                </RadioModule>
              </RadioWrapper>
              {value.urgent && (
                <>
                  <Label htmlFor="urgent-reason">
                    Please explain why this is urgent
                  </Label>
                  <Input
                    id="urgent-reason"
                    name="urgent-reason"
                    identifier={"urgent-reason"}
                    type={"text"}
                    onChange={handleChange}
                    required={value.urgent}
                    value={value["urgent-reason"]}
                  />
                </>
              )}
              <ButtonBlack type="submit">
                {isLoading ? (
                  <LoadingIndicator button />
                ) : (
                  "Request Website Rating"
                )}
              </ButtonBlack>
            </Form>
          </WrapperBotttom>
        </>
      )}
    </>
  );
};

export default WebsiteRequests;
