import CheckboxTree from "./CheckboxTree";
import ReportMetadata from "../../../../../../../../shared/resources/_report_metadata";

// ReportMetadata.category.values
const CategoryFilterFillupDefault = (value, selected, nodes) => {
  const newSelected = [...selected];
  nodes.map((node) => {
    if (node.value === value) {
      if (newSelected.indexOf(value) < 0) {
        newSelected.push(value);
      }
      if (node.children && node.children.length > 0) {
        node.children.map((child) => {
          if (newSelected.indexOf(child.value) < 0) {
            newSelected.push(child.value);
          }
        });
        const other = `${value}[other]`;
        if (newSelected.indexOf(other) < 0) {
          newSelected.push(other);
        }
      }
    } else {
      node.children.map((child) => {
        if (child.value === value) {
          if (newSelected.indexOf(child.value) < 0) {
            newSelected.push(child.value);
          }
        }
      });
    }
  });
  return newSelected;
};

export const CategoryFilterSetup = (value, selected) => {
  return CategoryFilterFillupDefault(
    value,
    selected,
    ReportMetadata.category.values
  );
};

const ReportCategoryChooser = ({ reportSettings, handleChange }) => {
  const selected = reportSettings?.reportCategories || reportSettings || [];

  return (
    <CheckboxTree
      name="ReportCategory"
      nodes={ReportMetadata.category.values}
      selected={selected}
      setSelected={(val) => {
        const event = {
          target: {
            name: "ReportCategory",
            value: val,
          },
        };

        handleChange(event);
      }}
      hasOther={false}
    />
  );
};

export default ReportCategoryChooser;
