import { FormattedMessage } from "react-intl";

import {
  AccountModalHeader,
  ButtonsWrapper,
  DateWrapper,
  TextWrapper,
} from "../Account/AccountStyles";
import { ButtonBlack, ButtonRed } from "../FormElements/Button";
import { getRenewDate } from "../Account/Membership";
import LoadingIndicator from "../../common/LoadingIndicator";

const CancelSubscription = ({
  subscriptions,
  onCancel,
  close,
  isExpiring,
  loading,
}) => {
  const subscription = subscriptions?.length ? subscriptions[0] : null;
  if (!subscription && !isExpiring) {
    return null;
  }

  return (
    <>
      <AccountModalHeader>
        <FormattedMessage
          id="template.cancel_subscription"
          defaultMessage="Cancel Subscription"
        />
      </AccountModalHeader>

      {subscription.platform === "APPLE" ? (
        <>
          <TextWrapper>
            <FormattedMessage
              id="template.cancel_apple_subscription"
              defaultMessage="You created this NewsGuard subscription using Apple's App Store. Please cancel the subscription using your Mac, iPhone or iPad. Detailed instructions can be found by <applelink>clicking here</applelink>."
              values={{
                applelink: (...chunks) => (
                  <a
                    href="https://support.apple.com/en-us/HT202039"
                    className="link link-external"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </TextWrapper>
          <ButtonBlack onClick={close}>
            <FormattedMessage id="template.close" defaultMessage="Close" />
          </ButtonBlack>
        </>
      ) : subscription.autoRenewal ? (
        isExpiring ? (
          <>
            <TextWrapper>
              <FormattedMessage
                id="template.cancel_subscription_expiring"
                values={{
                  expiration_date: getRenewDate(subscription.expirationDate),
                }}
                defaultMessage="Your subscription is set to expire at the end of its term, {expiration_date}"
              />
            </TextWrapper>

            <ButtonBlack
              className="btn btn__small btn__small--cancel"
              onClick={close}
            >
              <FormattedMessage id="template.close" defaultMessage="Close" />
            </ButtonBlack>
          </>
        ) : (
          <>
            <TextWrapper>
              <p>
                <FormattedMessage
                  id="template.cancellationConfirmation"
                  defaultMessage="Cancel Subscription at the end of its term,"
                />
              </p>{" "}
              <DateWrapper>
                {getRenewDate(subscription.expirationDate)}
              </DateWrapper>
            </TextWrapper>
            <ButtonsWrapper>
              <ButtonBlack onClick={close}>
                <FormattedMessage
                  id="template.keep"
                  defaultMessage="Keep Plan"
                />
              </ButtonBlack>
              <ButtonRed
                onClick={(e) => onCancel(e, subscription)}
                style={{ width: "256px" }}
              >
                {loading ? (
                  <LoadingIndicator button />
                ) : (
                  <FormattedMessage
                    id="template.cancel_subscription"
                    defaultMessage="Cancel Subscription"
                  />
                )}
              </ButtonRed>
            </ButtonsWrapper>
          </>
        )
      ) : (
        <>
          <TextWrapper>
            <FormattedMessage
              id="template.cancel_subscription_expiring"
              values={{
                expiration_date: getRenewDate(subscription.expirationDate),
              }}
              defaultMessage="Your subscription is set to expire at the end of its term, {expiration_date}"
            />
          </TextWrapper>

          <ButtonBlack
            className="btn btn__small btn__small--cancel"
            onClick={close}
          >
            <FormattedMessage id="template.close" defaultMessage="Close" />
          </ButtonBlack>
        </>
      )}
    </>
  );
};

export default CancelSubscription;
