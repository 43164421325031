import { FilterContainer } from "./ui/FilterContainer";
import LocaleFilter from "./AdvancedSearchFilters/LocaleFilter";
import MythCategoriesFilter from "./AdvancedSearchFilters/MythCategoriesFilter";
import ContentService from "../../../../service/ContentService";
import MultiSelectFilter from "./ui/MultiSelectFilter";
import metadata from "../../../../../../../../../shared/resources/_myth_metadata.json";
import countries from "../../../../../../../../../shared/resources/_country.json";
import React from "react";

const loadOptions = (inputValue) => {
  return ContentService.getInstance()
    .getDistinctiveReportMetadataValue("subject_tags", inputValue)
    .then((response) => {
      return response.map((v) => {
        return {
          label: v,
          value: v,
        };
      });
    });
};

const countryOptions = countries.map((c) => {
  return {
    type: "country",
    value: c.id,
    label: c.label,
  };
});

export default ({
  principal,
  selectedFilters,
  openFilters,
  handleSelectChange,
  handleUI,
  handleSelectAndRadioChange,
  clear,
}) => (
  <div className="filters-wrapper">
    <FilterContainer
      filter={selectedFilters.subject_tags}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        async={true}
        loadOptions={loadOptions}
        onChange={handleSelectChange}
        filter={selectedFilters.subject_tags}
        principal={principal}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.locale}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <LocaleFilter
        onChange={handleSelectChange}
        principal={principal}
        filter={selectedFilters?.locale}
        exclude={["en_ALL"]}
      />
    </FilterContainer>

    <FilterContainer
      name="Categories"
      handleClear={clear}
      smallerContext
      align={"flex-start"}
      toggleContainer={handleUI}
      isContainerOpen={openFilters.has("categories")}
      filter={selectedFilters.categories}
    >
      <MythCategoriesFilter
        onChange={handleSelectAndRadioChange}
        value={selectedFilters?.categories?.value}
      />
    </FilterContainer>
  </div>
);
