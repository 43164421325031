import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import { FormattedMessage } from "react-intl";
import AccountService from "../../service/AccountService";
import LoadingIndicator from "../../common/LoadingIndicator";
import {
  AccountDetails,
  AccountDetailsHeader,
  AccountSection,
  AccountSectionHeader,
  AccountSectionWrapper,
  TextInitialCap,
} from "./AccountStyles";
import { AccountButton, ButtonBlack } from "../FormElements/Button";
import ModalModule from "../Partners/common/ModalModules";
import { useNavigate } from "react-router";

const PaymentMethod = ({ elementRef }) => {
  const [loading, setLoading] = useState(true);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const open = () => setPaymentModalOpen(true);
  const close = () => setPaymentModalOpen(false);

  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    getData();
  }, []);

  const renderModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setPaymentModalOpen(true);
  };

  const onDismiss = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    getData();

    setPaymentModalOpen(false);
  };

  const onSubmit = () => {
    return havePastDueSubscription();
  };

  const havePastDueSubscription = () => {
    AccountService.getInstance()
      .havePastDueSubscription()
      .then((response) => {
        if (response.message && "true" === response.message) {
          // payment must have failed
          // need a new payment info

          setError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = () => {
    setLoading(true);
    havePastDueSubscription();

    AccountService.getInstance()
      .getPaymentMethod()
      .then((paymentMethod) => {
        setPaymentMethod(paymentMethod);
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          navigate("/signout");
          //AppReduxStore.dispatch({ type: LOGOUT });
        }

        setPaymentMethod(null);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  return (
    <div ref={elementRef} id="payment-information">
      <AccountSectionHeader>
        {" "}
        <FormattedMessage
          id="template.payment_method"
          defaultMessage="Payment Method"
        />
      </AccountSectionHeader>
      {error ? (
        <div className="checkout__error">
          <FormattedMessage
            id="message.update_payment"
            defaultMessage="Please update your <paymentlink>Payment Method</paymentlink>"
            values={{
              paymentlink: (...chunks) => (
                <a href="/account/paymentmethod">{chunks}</a>
              ),
            }}
          />
        </div>
      ) : (
        ""
      )}
      <AccountSection>
        {loading ? (
          <LoadingIndicator />
        ) : paymentMethod && paymentMethod.paymentMethod ? (
          <>
            <AccountSectionWrapper noHeader>
              <AccountButton onClick={open}>
                <FormattedMessage
                  id="template.updatePayment"
                  defaultMessage="Update Payment"
                />
              </AccountButton>
            </AccountSectionWrapper>
            {paymentMethod.type === "sepa_debit" ? (
              <AccountDetails>
                <FormattedMessage
                  id="template.current_sepa"
                  defaultMessage="Your current payment method is SEPA"
                />
              </AccountDetails>
            ) : paymentMethod.type === "card" ? (
              <AccountDetails>
                <FormattedMessage
                  id="template.currentPaymentMethodInformation"
                  defaultMessage="You’re paying with {paymentType} ending in {lastFour}, expires {date}."
                  values={{
                    paymentType: (
                      <TextInitialCap>
                        {paymentMethod.paymentMethod.brand}
                      </TextInitialCap>
                    ),
                    lastFour: paymentMethod.paymentMethod.last4,
                    date:
                      paymentMethod.paymentMethod.expMonth +
                      "/" +
                      paymentMethod.paymentMethod.expYear,
                  }}
                />
              </AccountDetails>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <AccountSectionWrapper>
              <AccountDetailsHeader>
                <FormattedMessage
                  id="template.current_card_not_in"
                  defaultMessage="You do not have any payment method on file."
                />
              </AccountDetailsHeader>
              <ButtonBlack onClick={open}>
                <FormattedMessage id="template.add" defaultMessage="add" />
              </ButtonBlack>
            </AccountSectionWrapper>
            <AccountDetails />
          </>
        )}
      </AccountSection>

      {error ? (
        <div className="checkout__error">
          <FormattedMessage
            id="message.update_payment"
            defaultMessage="Please update your <paymentlink>Payment Method</paymentlink>"
            values={{ paymentlink: (...chunks) => <span>{chunks}</span> }}
          />
        </div>
      ) : (
        ""
      )}
      <ModalModule
        showDialog={paymentModalOpen}
        close={close}
        account
        onDismiss={close}
      >
        <UpdatePaymentMethod
          onDismiss={onDismiss}
          submit={onSubmit}
          user={user}
          paymentMethodType={
            paymentMethod && paymentMethod.paymentMethod && paymentMethod.type
          }
        />
      </ModalModule>
    </div>
  );
};

// Add this function:

export default PaymentMethod;
