import { appConfig } from "../_actions";
import AppReduxStore from "../AppReduxStore";

const extensionCheck = () => {
  window.addEventListener(
    "receive_app_config",
    (event) => {
      // received app config
      // this confirms that you have an extension
      // trigger redux or context
      AppReduxStore.dispatch(appConfig(event.detail));
    },
    false
  );

  // deprecated - from older version
  window.addEventListener("message", (event) => {
    if (event.origin === document.location.origin) {
      const message = event.data;
      if (message.action === "receive_app_config") {
        AppReduxStore.dispatch(appConfig(message.data));
      }
    }
  });

  window.self.dispatchEvent(
    new CustomEvent("get_newsguard_extension", { detail: {} })
  );
};

export default extensionCheck;
