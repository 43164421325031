import { useState } from "react";
import styled from "@emotion/styled/macro";
import ContentService from "../../../service/ContentService";
import { ButtonWhite, LinkButton } from "../../FormElements/Button";
import { FilterToggleButtonsContainer } from "../Search/Filters/ui/FiltersButtons";
import AuthService from "../../../service/AuthService";
import { useSelector } from "react-redux";

const SiteModuleStyling = styled.div`
  padding: 20px 20px 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 42px;
  margin-top: ${(props) => (props.search ? "50px" : 0)};
  z-index: ${(props) => (props.isOpen ? 21 : "auto")};
  width: 100%;

  @media screen and (max-width: 600px) {
    padding: 20px 10px 5px;
    margin-top: ${(props) => (props.search ? "-30px" : 0)};
    min-width: 310px;
  }

  ${(props) =>
    props.search
      ? `
  
  @media screen and (max-width: 600px) {
    // border-radius: 0;
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // height: 100%;
    // width: 100%;
    margin-top: 0;
  }
  
  `
      : null}

  & .module-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    color: #000000;
    font-family: Sora;
    margin-bottom: 13px;

    & .following-count {
      /* margin-left: auto; */
    }

    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
  .module-header-text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    /* line-height: 21px; */
    margin-right: auto;
  }

  .close-module {
    cursor: pointer;
  }

  h3 {
    text-transform: uppercase;
  }

  .draghandle-container {
    text-align: center;
  }

  .draghandle-icon {
    background: url("/images/drag.svg") 0 4px no-repeat;
    display: inline-block;
    height: 24px;
    width: 11px;
    margin-right: 30px;
  }

  .heading-divider {
    height: 4px;
    color: #000;
    margin: 0;
    border: 0;
    background-color: #000;
    margin-bottom: 10px;
  }

  ul {
    font-size: 18px;
    line-height: 22px;
    list-style: none;
    padding: 0 10px;
  }

  > li {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 20px;
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    margin: ${({ noContent }) => (noContent ? "25px 0 24px" : "25px 0 44px")};

    text-transform: uppercase;
    padding: 0 10px;
    & h4 {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }
    @media screen and (max-width: 600px) {
      margin: 15px 0 10px;
    }
  }
`;

const ToggleButtonStyle = styled.div`
  width: 36px;
  cursor: pointer;
  height: 15px;

  position: relative;
  top: ${(props) => (props.open ? "13px" : "-8px")};

  transform: ${(props) => (props.open ? "rotate(180deg)" : "none")};
`;

const ButtonContainer = styled.div`
  @media screen and (max-width: 400px) {
    display: none;
  }
`;

export default function SiteModule(props) {
  const {
    handle,
    handleToggle,
    isOpen,
    id,
    context,
    search,
    drag,
    name,
    count,
    noContent,
    listHeaders,
    children,
    handleClose,
    type,
    showAlertButton = true,
    handleFiltersToggle,
    isFiltersOpen,
    numberOfFiltersSelected,
  } = props;

  return (
    <>
      <SiteModuleStyling
        search={search}
        context={context}
        noContent={noContent}
        isOpen={isOpen}
      >
        <div className="module-header">
          {drag ? (
            <div className="draghandle-container" {...handle}>
              <span className="draghandle-icon"></span>
            </div>
          ) : null}

          <h3 className="module-header-text">
            {name
              ? name
              : id === "report"
              ? "Recent Reports"
              : id === "developing_narrative"
              ? "Developing Narratives"
              : "Recent False Narratives"}
            {(count || count === 0) && (
              <span className="following-count"> ({count})</span>
            )}
          </h3>
          {id === "update" && (
            <>
              <FilterToggleButtonsContainer
                numberOfFilters={numberOfFiltersSelected}
                onClick={(e) => handleFiltersToggle(e, id)}
                type="basic"
                isFiltersOpen={isFiltersOpen}
              />
            </>
          )}

          {search ? (
            <div className="close-module" onClick={handleClose}>
              Close
            </div>
          ) : (
            <>
              {showAlertButton && (
                <ButtonContainer>
                  <LinkButton
                    to={`/partner/settings/preferences/${type || ""}`}
                    icon="/images/alerts.svg"
                  >
                    Alerts
                  </LinkButton>
                </ButtonContainer>
              )}

              <ToggleButtonStyle
                onClick={(e) => handleToggle(e, id)}
                open={isOpen}
              >
                <svg
                  viewBox="0 0 60 60"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g id="ii/system/arrow-down" fill="#000000">
                      <polygon
                        id="Rectangle"
                        transform="translate(29.750000, 25.750000) rotate(45.000000) translate(-29.750000, -25.750000) "
                        points="41.5 14 41.5 37.5 18 37.5 18 34.5 38.499 34.5 38.5 14"
                      ></polygon>
                    </g>
                  </g>
                </svg>
              </ToggleButtonStyle>
            </>
          )}
        </div>
        {isOpen ? (
          <>
            <hr className="heading-divider" />
            {listHeaders ? (
              <div className="list-header">
                {listHeaders.map((header) => (
                  <h4 key={header}>{header}</h4>
                ))}
              </div>
            ) : null}
            {children}
          </>
        ) : null}
      </SiteModuleStyling>
    </>
  );
}
