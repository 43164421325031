import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import LoadingIndicator from "../../common/LoadingIndicator";
import ContentService from "../../service/ContentService";
import AuthService from "../../service/AuthService";
import Unauthorized from "./Unauthorized/Unauthorized";
import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

const ModuleHeader = styled.h2`
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 10px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;
const Button = styled.button`
  color: ${(p) =>
    p.disabled ? "var(--primary-gray-disabled)" : "var(--primary-white)"};
  background-color: ${(p) =>
    p.disabled ? "var(--primary-gray)" : "var(--primary-blue)"};
  border-radius: 2px;
  border-color: transparent;
  cursor: ${(p) => (p.disabled ? "revert" : "pointer")};
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0;
`;

const ModuleText = styled.p`
  margin-bottom: 10px;
  line-height: 20px;
`;

const ErrorText = styled.p`
  padding-top: 10px;
  color: red;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Checkbox = styled.input`
  accent-color: #3355dd;
  height: 26px;
  width: 26px;
  border-radius: 2px;
  margin-right: 8px;
`;

const DownloadButton = ({
  buttonAction,
  buttonLabel,
  disabled,
  closeModal,
}) => {
  let [isLoading, setIsLoading] = useState(false);

  const onClick = (e) => {
    setIsLoading(true);
    buttonAction(e).finally((res) => {
      setIsLoading(false);
      closeModal?.();
    });
  };

  return (
    <ButtonsWrapper>
      <Button onClick={onClick} disabled={disabled || isLoading}>
        {isLoading ? <LoadingIndicator button /> : buttonLabel}
      </Button>
    </ButtonsWrapper>
  );
};

export default function ExportModule({
  exportContext,
  filteredDownload,
  closeModal,
}) {
  const [error, setError] = useState(null);
  const [checked, setIsChecked] = useState(false);

  const principal = useSelector((state) => state.authentication?.principal);
  const authService = AuthService.getInstance();

  const location = useLocation();
  const isExportFiltersContext = location.pathname.includes("search");

  function canExportData(context, access) {
    if (access) {
      if (isExportFiltersContext && exportContext === context) {
        return true;
      } else if (!isExportFiltersContext) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PartnerView",
        user: principal?.username,
        partner: principal?.attributes?.partner,
        view: "Download View",
        content_type: "",
        content_title: "",
        content_locale: "",
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const download = (type = "WEBSITE") => {
    if (checked) {
      setError(false);
      let action = ContentService.getInstance().downloadLabel;

      if (isExportFiltersContext) {
        action = filteredDownload;
      } else if (type === "MYTH") {
        action = ContentService.getInstance().downloadMyth;
      } else if (type === "TV") {
        action = ContentService.getInstance().downloadTVLabel;
      } else if (type === "PODCAST") {
        action = ContentService.getInstance().downloadPodcastLabel;
      }

      return action().catch((error) => {
        setError(true);
      });
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!checked);
    setError(false);
  };

  return authService.hasCSVExportAccess(principal) &&
    (authService.hasLabelAccess(principal) ||
      authService.hasTVAccess(principal) ||
      authService.hasPodcastAccess(principal) ||
      authService.hasMythAccess(principal)) ? (
    <Wrapper>
      <ModuleHeader>Data Export Terms of Service</ModuleHeader>
      <ModuleText>
        You have requested to download NewsGuard’s proprietary data. By
        clicking, “I Agree” below, you certify and agree that you will use this
        data only for purposes authorized under a licensing agreement between
        your organization and NewsGuard and only while such agreement remains in
        effect. Furthermore, you agree not to share the file with any person or
        party outside of your organization without NewsGuard’s express written
        consent.
      </ModuleText>
      <Label>
        <Checkbox
          type="checkbox"
          checked={checked}
          onClick={handleCheckboxChange}
          onChange={() => {}}
        />
        <span>Yes, I agree to the above terms.</span>
      </Label>
      {error && (
        <ErrorText>
          There was an error with your file download. Please try again.
        </ErrorText>
      )}
      {canExportData("WEB", authService.hasLabelAccess(principal)) ? (
        <DownloadButton
          buttonAction={() => download("WEBSITES")}
          disabled={!checked}
          buttonLabel={"Export Website Data"}
          closeModal={closeModal}
        />
      ) : (
        ""
      )}
      {canExportData("TV", authService.hasTVAccess(principal)) ? (
        <DownloadButton
          buttonAction={() => download("TV")}
          disabled={!checked}
          buttonLabel={"Export TV Data"}
          closeModal={closeModal}
        />
      ) : (
        ""
      )}
      {canExportData("PODCAST", authService.hasPodcastAccess(principal)) ? (
        <DownloadButton
          buttonAction={() => download("PODCAST")}
          disabled={!checked}
          buttonLabel={"Export Podcast Data"}
          closeModal={closeModal}
        />
      ) : (
        ""
      )}
      {canExportData("MYTH", authService.hasMythAccess(principal)) ? (
        <DownloadButton
          buttonAction={() => download("MYTH")}
          disabled={!checked}
          buttonLabel={"Export False Narrative Data"}
          closeModal={closeModal}
        />
      ) : (
        ""
      )}
    </Wrapper>
  ) : (
    <Unauthorized />
  );
}
