import { useQuery } from "@tanstack/react-query";
import ContentService from "../../../service/ContentService";

export function useFollowing(pageIndex, pageSize) {
  const { data, error, isPreviousData } = useQuery({
    queryKey: ["following", pageIndex, pageSize],
    queryFn: () => getData(pageIndex, pageSize),
    keepPreviousData: true,
  });

  return {
    followings: data?.content || [],
    totalPages: data?.totalPages,
    totalElements: data?.totalElements,
    error,
    isPreviousData,
  };
}

async function getData(pageIndex = 1, pageSize = 10) {
  const sortField = "createdDate";
  const sortOrder = "DESC";

  return await ContentService.getInstance().listFollowsPage(
    pageIndex,
    pageSize,
    sortField,
    sortOrder
  );
}
