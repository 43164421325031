import React from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

const TOSStyles = styled.p`
  margin-top: 40px;
  font-size: 14px !important;
  line-height: 20px;

  span,
  a {
    font-size: 14px;
    line-height: 20px;
  }
  a {
    text-decoration: none;
    color: rgba(51, 85, 221, 1);
  }
  @media print {
    display: none;
  }
`;

const TOSAndPrivacyPolicy = (props) => {
  return (
    <TOSStyles>
      <FormattedMessage
        id="template.tos_privacy_links"
        defaultMessage="
                            <tos>https://www.newsguardtech.com/terms-of-service/</tos>
                            and
                            <privacy>https://www.newsguardtech.com/privacy-policy/</privacy>"
        values={{
          tos: (...chunks) => (
            <a
              className="link link--external"
              target="_blank"
              href={chunks}
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="template.tos"
                defaultMessage="Terms of Service"
              />
            </a>
          ),
          privacy: (...chunks) => (
            <a
              className="link link--external"
              target="_blank"
              href={chunks}
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="template.privacy_policy"
                defaultMessage="Privacy Policy"
              />
            </a>
          ),
        }}
      />
    </TOSStyles>
  );
};

export default TOSAndPrivacyPolicy;
