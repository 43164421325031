import { useMemo } from "react";
import { Link } from "react-router-dom";

import {
  dateFormatter,
  formatLabelHistoryNote,
  hasCriteria,
  scoreFormatter,
} from "../Helpers/misc";
import CriteriaView from "./CriteriaView";

function HistoryColumns(props) {
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
        Cell: (data) => {
          const onCurrentLabel =
            data.cell.row.original.labelId === props.current.id;
          if (onCurrentLabel || data.cell.row.original.newRank === "TK") {
            return dateFormatter(data.cell.value);
          }
          return (
            <Link
              className="link"
              to={{
                pathname: `/partner/label/${data.cell.row.original.labelId}`,
                state: {
                  selectedTab: "label",
                },
              }}
            >
              {dateFormatter(data.cell.value)}
            </Link>
          );
        },
        width: 175,
      },
      {
        Header: "Score",
        accessor: "oldScore",
        width: 60,
        Cell: (data) => {
          return (
            <span className="score score--old">
              {hasCriteria(data.cell.row.original.oldRank)
                ? data.cell.value
                : "---"}
            </span>
          );
        },
      },
      {
        Header: " ",
        accessor: "newScore",
        width: 100,
        Cell: (data) => {
          return hasCriteria(data.cell.row.original.newRank)
            ? scoreFormatter(
                data.cell.row.original.oldRank,
                data.cell.row.original.oldScore,
                data.cell.row.original.newRank,
                data.cell.row.original.newScore
              )
            : "---";
        },
      },
      {
        Header: "Note",
        accessor: "note",
        disableSortBy: true,
        Cell: (data) => {
          if (
            data.cell.row.original.criteriaChanged &&
            data.cell.row.original.newCriteria &&
            data.cell.row.original.oldCriteria
          ) {
            return (
              <>
                {formatLabelHistoryNote(data.cell.row.original, props.current)}
                <CriteriaView
                  network={props.current.network}
                  newCriteria={data.cell.row.original.newCriteria}
                  oldCriteria={data.cell.row.original.oldCriteria}
                />
              </>
            );
          }
          return formatLabelHistoryNote(data.cell.row.original, props.current);
        },
        width: 550,
        minWidth: 550,
      },
    ],
    [props]
  );
  return columns;
}

export default HistoryColumns;
