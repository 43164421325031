import CriteriaFilter from "./CriteriaFilter";
import { FilterContainer } from "../ui/FilterContainer";

export default function CriteriaFilterContainer({
  type,
  filters,
  removeCriteriaFilter,
  handleCriteriaChange,
  toggleContainer,
  openFilters,
}) {
  return filters?.map((filter, index) => {
    const isComplete = filter.complete;

    return (
      <FilterContainer
        filter={filter}
        isComplete={isComplete}
        key={index}
        index={index}
        handleClear={() => removeCriteriaFilter("criteria", index)}
        isContainerOpen={openFilters.has(`criteria-${index}`)}
        toggleContainer={toggleContainer}
      >
        <CriteriaFilter
          value={filter}
          index={index}
          onChange={handleCriteriaChange}
          isComplete={isComplete}
          type={type}
        />
      </FilterContainer>
    );
  });
}
