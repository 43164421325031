import { useEffect, useState } from "react";
import { useBlockLayout, useTable } from "react-table";
import styled from "@emotion/styled/macro";

import AccountService from "../../service/AccountService";
import {
  additionalCurrencySuffix,
  getLanguage,
} from "../../common/_localeMessages";
import { FormattedMessage, FormattedNumber } from "react-intl";
import LoadingIndicator from "../../common/LoadingIndicator";
import { LinkButtonAccount } from "../Subscription/SignupStyles";
import ErrorMessage from "../FormElements/ErrorMessage";
import { useNavigate } from "react-router";

const PaymentHistory = (props) => {
  const [paymentHistory, setPaymentHistory] = useState({ data: [] });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const loadExternalUrl = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 80px auto 65px auto;
  `;

  const Title = styled.h2`
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  `;

  const TR = styled.tr`
    border-bottom: 1px solid #e5e5e5;
    align-items: center;
    padding: 10px 0;
    width: 100% !important;
    &:hover {
      font-weight: 600;
    }

    & .date {
      width: 60vw !important;
      max-width: 460px !important;
      min-width: 160px !important;

      // fix this hack
      @media screen and (max-width: 750px) {
        width: 50% !important;
      }
      @media screen and (max-width: 600px) {
        width: 35% !important;
      }
      @media screen and (max-width: 500px) {
        width: 30% !important;
      }
    }

    & .amount {
      width: 110px !important;
      white-space: nowrap;
    }
    & .status {
      width: 155px !important;
    }
    & .invoice {
      width: 60px !important;
    }
  `;

  const PaymentStatus = styled.div`
    display: inline;

    padding: 6px 18px;
    text-transform: uppercase;
    background-color: ${(props) =>
      props.status === "paid"
        ? "rgba(0, 204, 136, 0.4);"
        : "rgba(255, 68, 68, 0.4);"};
    border-radius: 2px;
  `;

  function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
        },
        useBlockLayout
      );

    // Render the UI for your table
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <TR {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </TR>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TR {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.className,
                        style: cell.column.style,
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </TR>
            );
          })}
        </tbody>
      </table>
    );
  }

  const columns = [
    {
      dataField: "paymentAt",
      accessor: "paymentAt",
      text: "date",
      Cell: (data) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };

        return new Date(
          data.cell.row.values.paymentAt * 1000
        ).toLocaleDateString(getLanguage(), options);
      },

      className: "date",
    },
    {
      dataField: "amountPaid",
      accessor: "amountPaid",

      Cell: (data) => {
        const row = data.cell.row;

        const cellData = row.values;
        const currency = row.original.currency;

        const numberFormatOptions = {
          style: "currency",
          currency: currency,
        };
        return (
          <>
            <FormattedNumber
              {...numberFormatOptions}
              currency={currency}
              value={cellData.amountPaid / 100}
            />
            {additionalCurrencySuffix(currency, getLanguage())}
          </>
        );
      },
      className: "amount",
    },
    {
      dataField: "status",
      accessor: "status",
      Cell: (data) => {
        const row = data.cell.row;
        const cellData = row.values;
        return (
          <PaymentStatus status={cellData.status}>
            {cellData.status}
          </PaymentStatus>
        );
      },
      className: "status",
    },
    {
      dataField: "invoicePdf",
      accessor: "invoicePdf",

      Cell: (data) => {
        const cellData = data.cell.row.values;
        return (
          <LinkButtonAccount
            to={cellData.invoicePdf}
            onClick={(e) => loadExternalUrl(e, cellData.invoicePdf)}
          >
            PDF
          </LinkButtonAccount>
        );
      },
      className: "invoice",
    },
  ];

  useEffect(() => {
    AccountService.getInstance()
      .getPaymentHistory()
      .then((response) => {
        setPaymentHistory({ data: response.data });
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          navigate("/signout");
          //AppReduxStore.dispatch({ type: LOGOUT });
        }
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getMore = () => {
    const { paymentHistory } = this.state;

    const lastElement = paymentHistory.data[paymentHistory.data.length - 1];
    AccountService.getInstance()
      .getPaymentHistory(lastElement.id)
      .then((paymentHistoryResults) => {
        paymentHistoryResults.data.forEach((item) => {
          paymentHistory.data.push(item);
        });
        paymentHistory.hasMore = paymentHistoryResults.hasMore;
        this.setState({ paymentHistory: paymentHistory });
      })
      .catch((error) => {
        if (error.status === 401 || error.status === 403) {
          navigate("/signout");
          //AppReduxStore.dispatch({ type: LOGOUT });
        }
        setError(true);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <Title>
        <FormattedMessage
          id="template.payment_history"
          defaultMessage="Billing History"
        />
      </Title>
      {error ? (
        <ErrorMessage
          error={{
            response: {
              code: "message.payment_history_error",
              message:
                "There was an error loading the payment history. Please reload this page.",
            },
          }}
        />
      ) : (
        ""
      )}

      {loading ? <LoadingIndicator /> : ""}

      {paymentHistory?.hasMore ? <span onClick={() => getMore()}></span> : ""}
      <Table columns={columns} data={paymentHistory.data} />
    </Wrapper>
  );
};

export default PaymentHistory;
