import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ContentService from "../../../../service/ContentService";

async function checkIfExist(website) {
  return await ContentService.getInstance()
    .checkDomain(website)
    .then((res) => res);
}

function useCheckIfDomainExists(website, ref) {
  const [readyToCheck, setReadyToCheck] = useState(false);
  const [canRequest, setCanRequest] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { isInitialLoading } = useQuery({
    queryKey: ["webrequest", website],
    queryFn: () => checkIfExist(website),
    enabled: readyToCheck,
    onError: (e) => {
      try {
        if (e.status === 409) {
          setErrorMsg(
            `The website you are requesting has already been rated by NewsGuard.
            View the ${website} label in the dashboard to see the website's rating.`
          );
          if (e.response.value) {
            // to do:something with labelLink below
            const labelLink = `/partner/label/${e.response.value}`;
          }
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    },
    onSuccess: (data) => {
      setCanRequest(true);
      ref.current.focus();
    },

    onSettled: () => {
      setReadyToCheck(false);
    },
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return {
    isInitialLoading,
    canRequest,
    errorMsg,
    setReadyToCheck,
  };
}

export default useCheckIfDomainExists;
