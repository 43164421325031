import React, { useEffect, useState } from "react";
import {
  CardElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import styled from "@emotion/styled/macro";

import AccountService from "../../service/AccountService";

import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import LoadingIndicator from "../../common/LoadingIndicator";
import ErrorMessageView, { ErrorLink } from "../../common/ErrorMessageView";
import InputModule, {
  ErrorMessage,
  GroupWrapper,
} from "../FormElements/InputModule";
import { Button, ButtonBlack } from "../FormElements/Button";
import {
  AccountMessage,
  AccountMessageSpecial,
  AccountMessageWrapper,
  RadioButton,
  RadioModule,
  RadioWrapper,
  Title,
  Wrapper,
} from "./SignupStyles";
import Checkbox from "../FormElements/Checkbox";
import { ButtonsWrapper } from "../Account/AccountStyles";
import {
  additionalCurrencySuffix,
  getLanguage,
} from "../../common/_localeMessages";
import PersonalUseElement from "./PersonalUseElement";

// SEPA TEST NUMBER: DE89370400440532013000

const createOptions = (country = "DE", fontSize, padding) => {
  return {
    supportedCountries: ["SEPA"],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: country,
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "'Open Sans', sans-serif",
        "::placeholder": {
          color: "#red",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

const StyledCardElement = styled(CardElement)`
  display: block;
  margin: 0 0 30px 0;
  // max-width: 500px;
  padding: 10px 14px;

  border: ${(props) => (props.error ? "1px solid #ff4444" : "1px solid #000")};

  border-radius: 2px;
  background: white;
  font-family: "Sora", sans-serif;
`;

const SEPAWrapper = styled.div`
  & #sepaDetails {
    display: block;
    margin: 0 0 10px 0;
    // max-width: 500px;
    padding: 10px 14px;

    border: ${(props) =>
      props.error ? "1px solid #ff4444" : "1px solid #000"};

    border-radius: 2px;
    background: white;
    font-family: "Sora", sans-serif;
  }
`;

const MandateWrapper = styled.div`
  font-size: var(--12px);
  margin-bottom: 20px;
`;

const CheckoutForm = (props) => {
  const elements = useElements();
  const stripe = useStripe();
  const intl = useIntl();

  const [email, setEmail] = useState(props.email);
  const [name, setName] = useState(props.name);

  const [paymentMethodType, setPaymentMethodType] = useState(
    props.paymentMethodType || "card"
  );
  const [paymentMethod, setPaymentMethod] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [nameErrorMessage, setNameErrorMessage] = useState(null);
  const [personalUseAccepted, setPersonalUseAccepted] = useState(false);
  const [personalUseAcceptedError, setPersonalUseAcceptedError] =
    useState(false);
  const [complete, setComplete] = useState(false);
  const [SEPAComplete, setSEPAComplete] = useState(false);

  const accountService = AccountService.getInstance();

  const { update, isTrial, onDismiss, plan, coupon } = props;
  const [price, setPrice] = useState(4.95);
  const [currency, setCurrency] = useState("usd");
  const [numberFormatOptions, setNumberFormatOptions] = useState({
    style: "currency",
    currency: "usd",
  });

  const [longErrorMessage, setLongErrorMessage] = useState(null);

  useEffect(() => {
    if (plan?.amount && coupon?.amountOff) {
      setPrice((plan.amount - (coupon.amountOff ? coupon.amountOff : 0)) / 100);
    } else {
      setPrice((plan?.amount || 495) / 100);
    }
    const currency = plan?.currency || "usd";
    setCurrency(currency);
    setNumberFormatOptions({
      style: "currency",
      currency: currency,
    });
  }, [plan, coupon]);

  useEffect(() => {
    if (props.onPaymentMethodError && error) {
      props.onPaymentMethodError();
    }
  }, [error]);

  useEffect(() => {
    if (props.updatePaymentMethodType) {
      props.updatePaymentMethodType(paymentMethodType);
    }
  }, [paymentMethodType]);

  const submit = (e) => {
    e.preventDefault();

    if (name === "") {
      setNameError({
        id: "template.name_invalid_message",
        defaultMessage: "Please enter your name",
        name: "name",
        html: true,
      });

      return false;
    }

    if (!update) {
      if (email === "") {
        setEmailError({
          id: "template.email_invalid_message",
          defaultMessage: "Please enter a correct email address",
          name: "email",
          html: true,
        });

        return false;
      }
    }
    if (!personalUseAccepted) {
      setPersonalUseAcceptedError({
        id: "template.personal_use_unchecked",
        defaultMessage:
          "Please check the box confirming that you will not use NewsGuard for prohibited purposes or <licensing>click here</licensing> to learn more about licensing.",
        name: "name",
        html: true,
        values: {
          licensing: (...chunks) => (
            <a
              href="https://www.newsguardtech.com/feedback/give/"
              target="_blank"
              rel="noopener noreferrer"
              className="error"
            >
              {chunks}
            </a>
          ),
        },
      });

      return false;
    }

    if (!isLoading) {
      setIsLoading(true);
      let paymentMethodData = {};
      if (paymentMethodType === "sepa_debit") {
        paymentMethodData = {
          type: paymentMethodType,
          sepa_debit: elements.getElement(IbanElement),
          billing_details: {
            name: name,
            email: email,
          },
        };
      } else {
        paymentMethodData = {
          type: paymentMethodType,
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
          },
        };
      }

      return stripe
        .createPaymentMethod(paymentMethodData)
        .then((payload) => {
          setPaymentMethod(payload.paymentMethod);
          return processPayment(payload);
        })
        .catch((error) => {
          console.log(error);
          setError(true);
          setErrorMessage(error?.message ? error.message : error);
        })
        .finally((res) => setIsLoading(false));
    }
  };

  const processPayment = (payload) => {
    /*
     return Promise.reject({
     code: "incomplete_zip",
     type: "validation_error",
     message: "Your postal code is incomplete."});
     */

    if (payload.error) {
      if (payload.error.code === "email_invalid") {
        setEmailError(true);
        setEmailErrorMessage(payload.error.message);
      } else {
        setError(true);
        setErrorMessage(payload.error.message);
      }

      /**
       code: "incomplete_zip"
       type: "validation_error"
       message: "Your postal code is incomplete."
       **/
      setPaymentMethod({});
      return Promise.reject(payload.error);
    } else {
      // setPaymentMethod(payload.paymentMethod);

      return accountService
        .addPayment(payload.paymentMethod)
        .then((response) => {
          if (props.submit) {
            return props.submit(response);
          }
        })
        .catch((error) => {
          setError(true);
          setLongErrorMessage(true);
          setErrorMessage(
            <FormattedMessage
              id="message.ERROR"
              defaultMessage="Something went wrong.  Please try again. If you continue to experience difficulties, please contact <mail_link>support@newsguardtech.com</mail_link> for help."
              values={{
                mail_link: (...chunks) => (
                  <ErrorLink href="mailto:support@newsguardtech.com">
                    {chunks}
                  </ErrorLink>
                ),
              }}
            />
          );
        });
    }
  };

  const handleCardInput = (e) => {
    setError(false);
    if (e.complete) {
      if (e.elementType === "iban") {
        setSEPAComplete(true);
      } else {
        setComplete(true);
      }
    } else {
      setComplete(false);
      setSEPAComplete(false);
    }
  };

  return (
    <>
      {isLoading && !update ? <LoadingIndicator className="large" /> : ""}

      <Wrapper update={update}>
        {update ? (
          <Title update>
            <FormattedMessage
              id="template.updatePaymentDetails"
              defaultMessage="Update Payment Details"
            />
          </Title>
        ) : (
          ""
        )}

        {currency === "eur" ? (
          <GroupWrapper>
            <RadioWrapper>
              <RadioModule>
                <RadioButton
                  type="radio"
                  id="payment-type-cc"
                  value="card"
                  checked={paymentMethodType === "card"}
                  onChange={() => setPaymentMethodType("card")}
                />
                <label
                  htmlFor="payment-type-cc"
                  className="payment-types__label"
                >
                  <FormattedMessage
                    id="template.payment_type_cc"
                    defaultMessage=" Credit Card"
                  />
                </label>
              </RadioModule>
              <RadioModule>
                <RadioButton
                  type="radio"
                  id="payment-type-sepa"
                  value="sepa_debit"
                  checked={paymentMethodType === "sepa_debit"}
                  onChange={() => setPaymentMethodType("sepa_debit")}
                />
                <label
                  htmlFor="payment-type-sepa"
                  className="payment-types__label"
                >
                  <FormattedMessage
                    id="template.payment_type_sepa"
                    defaultMessage="SEPA Direct Debit"
                  />
                </label>
              </RadioModule>
            </RadioWrapper>
          </GroupWrapper>
        ) : (
          ""
        )}

        {paymentMethodType === "sepa_debit" ? (
          <>
            <div>
              <InputModule
                type="text"
                identifier="name"
                placeholder={intl.formatMessage({
                  id: "template.name",
                  defaultMessage: "Name",
                })}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  setNameError(null);
                }}
                error={nameError}
                required
                update={update}
              />
              {nameError ? (
                <div className="checkout__error checkout__error--modal">
                  {nameErrorMessage ? (
                    nameErrorMessage
                  ) : (
                    <ErrorMessageView error={nameError} />
                  )}
                </div>
              ) : (
                ""
              )}
              <SEPAWrapper>
                <IbanElement
                  options={createOptions()}
                  id="sepaDetails"
                  onChange={(e) => handleCardInput(e)}
                />
              </SEPAWrapper>
              {error ? (
                <div className="checkout__error checkout__error--modal">
                  {errorMessage ? (
                    errorMessage
                  ) : (
                    <ErrorMessageView error={error} />
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <MandateWrapper>
              <FormattedMessage
                id="template.sepa_mandate"
                defaultMessage="By providing your payment information and confirming this payment, you authorise (A) NewsGuard Technologies, Inc. and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur."
              />
            </MandateWrapper>
          </>
        ) : (
          <>
            <InputModule
              type="text"
              identifier="name"
              placeholder={intl.formatMessage({
                id: "template.name",
                defaultMessage: "Name",
              })}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setNameError(null);
              }}
              required
              error={nameError}
              update={update}
            />

            {error && (
              <GroupWrapper>
                {errorMessage ? (
                  <ErrorMessage long={longErrorMessage}>
                    {errorMessage}
                  </ErrorMessage>
                ) : (
                  <ErrorMessage>
                    <ErrorMessageView error={error} />
                  </ErrorMessage>
                )}
              </GroupWrapper>
            )}

            <StyledCardElement
              onChange={(e) => handleCardInput(e)}
              error={error}
            />
          </>
        )}

        {!update ? (
          <>
            <AccountMessageWrapper>
              <AccountMessageSpecial>
                {plan.trial ? (
                  <FormattedMessage
                    id="template.sign_up_trial_footer"
                    key={price}
                    values={{
                      strong: (...chunks) => <strong>{chunks}</strong>,
                      price: (
                        <>
                          <FormattedNumber
                            {...numberFormatOptions}
                            value={price}
                            key={price}
                          />
                          {additionalCurrencySuffix(currency, getLanguage())}
                        </>
                      ),
                    }}
                    defaultMessage="<strong>For unlimited access to NewsGuard, sign up today for a 2-week trial membership.</strong> You may cancel at any time. After your two weeks, you’ll have access to a discounted membership at just {price}/month."
                  />
                ) : (
                  <FormattedMessage
                    id="template.sign_up_discount_footer"
                    key={price}
                    values={{
                      strong: (...chunks) => <strong>{chunks}</strong>,
                      price: (
                        <>
                          <FormattedNumber
                            {...numberFormatOptions}
                            value={price}
                            key={price}
                          />
                          {additionalCurrencySuffix(currency, getLanguage())}
                        </>
                      ),
                    }}
                    defaultMessage="For unlimited access to NewsGuard, <strong>start your discounted membership today for just {price}/month.</strong>"
                  />
                )}
                {"*"}
                {/* {plan.trial ? (
                 <FormattedMessage
                 id="template.continue_sign_up_free_desc"
                 values={{
                 strong: (...chunks) => <strong>{chunks}</strong>,
                 price: (
                 <>
                 <FormattedNumber
                 {...numberFormatOptions}
                 value={price}
                 key={price}
                 currency={currency}
                 />
                 {additionalCurrencySuffix(
                 currency,
                 getLanguage()
                 )}
                 </>
                 ),
                 }}
                 defaultMessage="By signing up for a NewsGuard account and providing a payment
                 method, you will automatically be enrolled in a <strong>free 2 week trial</strong>. Following this free trial period you will be billed the
                 <strong>introductory rate</strong> of <strong>{price} per month</strong>. Your subscription will automatically renew every month.
                 You will be charged {price} on each monthly renewal until you cancel your subscription in the account settings.
                 If you cancel, previous charges will not be refunded, but you may continue to use the service until the end of the term you paid for."
                 />
                 ) : (
                 <FormattedMessage
                 id="template.continue_sign_up_discounted_desc"
                 values={{
                 strong: (...chunks) => <strong>{chunks}</strong>,
                 price: (
                 <>
                 <FormattedNumber
                 {...numberFormatOptions}
                 value={price}
                 currency={currency}
                 />
                 {additionalCurrencySuffix(
                 currency,
                 getLanguage()
                 )}
                 </>
                 ),
                 }}
                 defaultMessage="By signing up for a NewsGuard account and providing a payment method,
                 you will automatically be enrolled in membership at the <strong>discounted rate of {price} per monthly</strong>.
                 Your subscription will automatically renew every month. You will be charged {price} on each monthly renewal until you cancel your subscription in your account settings.
                 If you cancel, previous charges will not be refunded, but you may continue to use the service until the end of the term you paid for."
                 />
                 )} */}
              </AccountMessageSpecial>
            </AccountMessageWrapper>
          </>
        ) : null}
        <PersonalUseElement
          personalUseAccepted={personalUseAccepted}
          setPersonalUseAccepted={setPersonalUseAccepted}
          personalUseAcceptedError={personalUseAcceptedError}
          setPersonalUseAcceptedError={setPersonalUseAcceptedError}
        />
        <MandateWrapper>
          <FormattedMessage
            id="template.creditcard_mandate"
            defaultMessage="I authorize NewsGuard Technologies, Inc. to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you."
          />
        </MandateWrapper>
        <ButtonsWrapper>
          {update && (
            <ButtonBlack onClick={onDismiss}>
              <FormattedMessage id="template.cancel" defaultMessage="Cancel" />
            </ButtonBlack>
          )}

          <Button
            onClick={submit}
            disabled={
              error ||
              nameError ||
              !name ||
              // (!update && !personalUseAccepted) ||
              (paymentMethodType === "card" && !complete) ||
              (paymentMethodType === "sepa_debit" && !SEPAComplete)
            }
          >
            {isLoading ? (
              <LoadingIndicator button />
            ) : isTrial ? (
              <FormattedMessage
                id="template.start_free_trial"
                defaultMessage="Start My Free Trial"
              />
            ) : update ? (
              <FormattedMessage id="template.update" defaultMessage="Update" />
            ) : (
              <FormattedMessage
                id="template.start_my_subscription"
                defaultMessage="Start My Subscription"
              />
            )}
          </Button>
        </ButtonsWrapper>
        {!update && (
          <AccountMessageWrapper>
            <AccountMessage>
              <FormattedMessage
                id="template.credit_card_disclaimer"
                defaultMessage="By clicking the {offer_text} button above, you are agreeing to our <tos>https://www.newsguardtech.com/terms-of-service/</tos>."
                values={{
                  offer_text: isTrial ? (
                    <FormattedMessage
                      id="template.start_free_trial"
                      defaultMessage="Start My Free Trial"
                    />
                  ) : (
                    <FormattedMessage
                      id="template.start_my_subscription"
                      defaultMessage="Start My Subscription"
                    />
                  ),
                  tos: (...chunks) => (
                    <a
                      className="link link--external"
                      target="_blank"
                      href={chunks}
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage
                        id="template.tos"
                        defaultMessage="Terms of Service"
                      />
                    </a>
                  ),
                }}
              />
            </AccountMessage>
          </AccountMessageWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default CheckoutForm;
