import { useQuery } from "@tanstack/react-query";
import ContentService from "../../../service/ContentService";

export function useRecentContents(pageIndex, pageSize, id) {
  const { data, error, isPreviousData } = useQuery({
    queryKey: ["recentContents", pageIndex, pageSize, id],
    queryFn: () => getData(pageIndex, pageSize, id),
    keepPreviousData: true,
  });

  return {
    recentContents: data?.content || [],
    totalElements: data?.totalElements,
    totalPages: data?.totalPages,
    error,
    isPreviousData,
  };
}

async function getData(pageIndex = 1, pageSize = 10, id) {
  return await ContentService.getInstance().getRecentContentsPage(
    pageIndex,
    pageSize,
    null,
    null,
    null,
    id
  );
}
