import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.scale
      ? `
    @media screen and (max-width: 600px) {  
    transform: scale(${props.scale});
    }
  `
      : null}
`;

const spin2 = keyframes`

    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    } 
  `;

const Spinner = styled.div`
  display: block;
  height: 2rem;
  width: 2rem;
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 4px var(--primary-blue, #3355dd) solid;
  border-radius: 50%;

  animation: ${spin2} 1s ease infinite;

  border-top: 4px var(--primary-white, #fff) solid;
`;

export default function LoadingIndicator(props) {
  return (
    <Wrapper {...props}>
      <Spinner />
    </Wrapper>
  );
}
