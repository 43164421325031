import styled from "@emotion/styled";

const SearchHistoryWrapper = styled.div`
  width: ${(props) => (props.isHome ? "1160px" : "100%")};
  padding: 20px 80px 40px;

  position: ${(props) => (props.isHome ? "absolute" : "relative")};
  z-index: ${(props) => (props.isHome ? 10 : 13)};

  ${(props) =>
    props.isHome
      ? `box-shadow: 0 8px 10px -10px rgba(0, 0, 0, 0.12);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  border-top: none;
  background-color: #fff;
  top: 66px;
  left: -1px;
  width: calc(100% + 2px);
  max-width: 1160px
;`
      : null}

  @media only screen and (max-width: 600px) {
    padding: 20px 40px 40px;

    ${(props) =>
      props.isHome
        ? `
  top: 49px;
  left: 0;
  width: calc(100% );
  max-width: 1160px
;`
        : null}
  }

  div {
    text-align: left;
    color: #3355dd;
    font-family: Sora;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    margin-top: 15px;
  }
`;

const SearchList = styled.ul`
  margin-top: 15px;
  padding: 0;
  list-style: none;
  text-align: left;
  color: #000;
  font-family: Sora;
  font-size: 16px;
  line-height: 24px;
  max-height: 412px;
  overflow-y: scroll;

  li {
    margin-bottom: 16px;
    cursor: pointer;
    background-image: url("/images/history.svg");
    background-repeat: no-repeat;
    background-position: 0 51%;
    background-size: 20px;
    padding-left: 25px;
  }
`;

export default function SearchHistory({ history, onClick, clear, isHome }) {
  return (
    <SearchHistoryWrapper isHome={isHome}>
      <div onClick={clear}>Clear search history</div>
      <SearchList>
        {history.map((item, index) => (
          <li key={`item-${index}`} onClick={(e) => onClick(e, item)}>
            {item}
          </li>
        ))}
      </SearchList>
    </SearchHistoryWrapper>
  );
}
