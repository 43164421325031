import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";

import SubscriptionFlowMenu from "./SubscriptionFlowMenu";

const Header = styled.header`
  position: fixed;
  top: 0;
  text-align: center;

  margin: 0 auto;
  width: 100%;
  z-index: 21;
`;

const NavStyling = styled.nav`
  text-align: center;
  margin: 0 auto;
  height: 90px;
  background-color: #fff;
  z-index: 1;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
  padding: 31px 20px 31px 35px;
  display: flex;

  justify-content: space-between;
  align-items: center;

  & .nav-items {
    list-style: none;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      align-items: flex-start;
    }
  }

  & .nav-item {
    padding: 0 10px;
    cursor: pointer;

    button {
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      border: none;
    }

    a {
      text-decoration: none;
      color: #000;
    }
  }
  .partner-logo img {
    width: 185px;
  }
  @media screen and (max-width: 600px) {
    padding-left: 20px;
    height: 60px;

    & .partner-logo {
      z-index: 20;
      min-width: 132px;
      & img {
        width: 82px;
      }
    }
  }
`;

const Side = styled.div`
  flex: 1;
`;

const SignUpHeader = () => {
  return (
    <>
      <Header>
        <NavStyling>
          <Side>
            <div className="partner-logo">
              <Link to="/">
                <img
                  src="/images/NewsGuardLogo_SMALL-Black.svg"
                  alt="NewsGuard Logo"
                  className="partner-logo"
                />
              </Link>
            </div>
          </Side>
          <SubscriptionFlowMenu step={1} />
          <Side />
        </NavStyling>
      </Header>
    </>
  );
};

export default SignUpHeader;
