import NetworkService from "./NetworkService";
import AuthService from "./AuthService";
import AppReduxStore from "../AppReduxStore";
import { UPDATE_USER } from "../_actions";

const AccountService = (() => {
  let instance;

  function createInstance() {
    return new AccountServiceInstance();
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default AccountService;

class AccountServiceInstance {
  networkService = {};
  authService = {};
  resourceServiceRoot = "";

  constructor() {
    this.networkService = NetworkService.getInstance();
    this.authService = AuthService.getInstance();
    this.resourceServiceRoot = "";
  }

  isITunesTrialEligible = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/subscription/itunes/is_trial_eligible`;
    return this.networkService.post(userEndpoint, {}).then((result) => {
      //console.log(result);
      if (result) {
        console.log("it is true");
      } else {
        console.log("it is false");
      }
      return result;
    });
  };

  updateConfig(config) {
    //console.log(config);
    if (config) {
      this.resourceServiceRoot = config.resourceServiceRoot;
    }
    //console.log(this.resourceServiceRoot);
  }

  retrieveUserInfoFromServer = (passToApp = false) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    return this.authService
      .retrieveUserInfoFromServer()
      .then((principal) => {
        //console.log(principal);
        if (
          !AuthService.getInstance().isUnregisteredVoucherUser(principal) ||
          AuthService.getInstance().isPasswordChangePrivilege(principal)
        ) {
          return this.getUser()
            .then((user) => {
              //console.log(user);
              AppReduxStore.dispatch({
                type: UPDATE_USER,
                user: user,
              });
              this.authService.triggerUserSignIn(passToApp);
              return Promise.resolve(user);
            })
            .catch((error) => {
              console.log(error);
              AppReduxStore.dispatch({
                type: UPDATE_USER,
                user: {},
              });
              return Promise.reject(error);
            });
        }
        return Promise.resolve(principal);
      })
      .catch((error) => {
        if (
          error.code === 401 ||
          error.status === 401 ||
          error.code === 403 ||
          error.status === 403
        ) {
          this.authService.logOut();
        }
        return Promise.reject(error);
      });
  };

  getUser = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user`;
    return this.networkService.get(userEndpoint);
  };

  getCustomerCurrency = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/currency`;
    return this.networkService.get(userEndpoint);
  };

  updateName = (nameRequest) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/name`;
    return this.networkService.patch(userEndpoint, nameRequest);
  };

  updateCustomerCountry = (country) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/country`;
    return this.networkService.patch(userEndpoint, { country: country });
  };

  getCustomerBillingCountry = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/billingcountry`;
    return this.networkService.get(userEndpoint);
  };

  addPayment = (paymentMethod) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const paymentEndpoint = `${this.resourceServiceRoot}/payments`;
    return this.networkService.post(paymentEndpoint, {
      paymentMethod: paymentMethod.id,
    });
  };

  updateNewsLetter = (doesSubscribe = false) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/newsletter`;
    return this.networkService.patch(userEndpoint, {
      newsletter: doesSubscribe,
    });
  };

  isNewsLetterSubscriber = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/newsletter`;
    return this.networkService.get(userEndpoint);
  };

  updateOptIn = (optIn = false) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/optin`;
    return this.networkService.patch(userEndpoint, { newsletter: optIn });
  };

  isOptIn = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/optin`;
    return this.networkService.get(userEndpoint);
  };

  isOptInList = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoint = `${this.resourceServiceRoot}/user/optin/inlist`;
    return this.networkService.get(userEndpoint);
  };

  getPaymentMethod = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const paymentEndpoint = `${this.resourceServiceRoot}/payment/default`;
    return this.networkService.get(paymentEndpoint);
  };

  getPayments = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const paymentEndpoint = `${this.resourceServiceRoot}/payments`;
    return this.networkService.get(paymentEndpoint);
  };

  getPaymentHistory = (item = null) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const paymentEndpoint = `${this.resourceServiceRoot}/payment_history${
      item ? `/${item}` : ""
    }`;
    return this.networkService.get(paymentEndpoint);
  };

  getSubscriptions = (activeOnly = false) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions${
      activeOnly ? "/active" : ""
    }`;
    return this.networkService.get(subscriptionsEndpoints);
  };

  isEdgeSubscriber = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/edge/havingactive`;
    return this.networkService.get(subscriptionsEndpoints);
  };

  isFullSubscriber = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/full/havingactive`;
    return this.networkService.get(subscriptionsEndpoints);
  };

  isSubscriber = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/havingactive`;
    return this.networkService.get(subscriptionsEndpoints);
  };

  gainEdgeAccess = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/edge`;
    return this.networkService.post(subscriptionsEndpoints, {});
  };

  purchaseSubscription = (subscriptionRequest) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/purchase`;
    return this.networkService.post(
      subscriptionsEndpoints,
      subscriptionRequest
    );
  };

  previewSubscription = (subscriptionRequest) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/preview`;
    return this.networkService.post(
      subscriptionsEndpoints,
      subscriptionRequest
    );
  };

  cancelSubscription = (subscriptionRequest) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/${subscriptionRequest.id}`;
    return this.networkService.delete(subscriptionsEndpoints);
  };

  havePastDueSubscription = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const subscriptionsEndpoints = `${this.resourceServiceRoot}/subscriptions/pastdue`;
    return this.networkService.get(subscriptionsEndpoints);
  };

  getAvailablePlan = (currency = null) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    let planEndpoints = `${this.resourceServiceRoot}/plan`;
    if (currency) {
      planEndpoints = `${planEndpoints}/${currency}`;
    }
    return this.networkService.get(planEndpoints);
  };

  getAvailablePlanByCountry = (country = "US") => {
    let planEndpoints = `${this.resourceServiceRoot}/plan/default/country/${country}`;
    return this.networkService.get(planEndpoints);
  };

  getSpecialPlan = (currency = null) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    let planEndpoints = `${this.resourceServiceRoot}/plan/special`;
    if (currency) {
      planEndpoints = `${planEndpoints}/${currency}`;
    }
    return this.networkService.get(planEndpoints);
  };

  /*
  getCampaignPlan = (campaignCode = "", currency = null) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    let planEndpoints = `${this.resourceServiceRoot}/plan/campaign/${campaignCode}`;
    if (currency) {
      planEndpoints = `${planEndpoints}/${currency}`;
    }
    return this.networkService.get(planEndpoints);
  };
  */

  getSpecialPlanByCountry = (country = "US") => {
    let planEndpoints = `${this.resourceServiceRoot}/plan/special/default/country/${country}`;
    return this.networkService.get(planEndpoints);
  };

  /*
  getCampaignPlanByCountry = (campaignCode = "", country = "US") => {
    let planEndpoints = `${this.resourceServiceRoot}/plan/campaign/${campaignCode}/default/country/${country}`;
    return this.networkService.get(planEndpoints);
  };
  */

  getPlan = (id) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const planEndpoints = `${
      this.resourceServiceRoot
    }/plan/id/${encodeURIComponent(id)}`;
    return this.networkService.get(planEndpoints);
  };

  getUserDetails = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/user`;
    return this.networkService.get(userEndpoints);
  };

  getCoupon = (id) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const planEndpoints = `${
      this.resourceServiceRoot
    }/plan/coupons/${encodeURIComponent(id)}`;
    return this.networkService.get(planEndpoints);
  };

  getPartnerUser = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/partneruser`;
    return this.networkService.get(userEndpoints);
  };

  updateAlertSettings = (alertsettings) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/user/alertsettings`;
    return this.networkService.patch(userEndpoints, alertsettings);
  };

  updateFollowingLabelAlertOptions = (alertOption) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/user/alertoption/following`;
    return this.networkService.patch(userEndpoints, alertOption);
  };

  updateMythAlertOptions = (alertOption) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/user/alertoption/myth`;
    return this.networkService.patch(userEndpoints, alertOption);
  };

  updateNewLabelAlertOptions = (alertOption) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/user/alertoption/new`;
    return this.networkService.patch(userEndpoints, alertOption);
  };

  updateUpdatedLabelAlertOptions = (alertOption) => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/user/alertoption/updated`;
    return this.networkService.patch(userEndpoints, alertOption);
  };

  getUserProfile = () => {
    const accessToken = this.authService.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const userEndpoints = `${this.resourceServiceRoot}/user/profile`;
    return this.networkService.get(userEndpoints);
  };

  sendA1Tracking = (subscription) => {
    const lmref = subscription?.stripeSubscription?.metadata?.lm_data;
    const livemode = subscription?.stripeSubscription?.livemode;

    if (lmref && livemode) {
      if (subscription.invoice) {
        let subtotal = subscription.invoice.subtotal
          ? subscription.invoice.subtotal
          : 0;

        subtotal = subtotal / 100;

        const url =
          `https://partner.a1click.at/conversion/f0298dda-70e6-4557-8805-af0ec110080e?saleId=` +
          `${encodeURIComponent(
            subscription.transactionId
          )}&saletotal=${encodeURIComponent(subtotal)}`;
        //this.networkService.get(url);
        const i = document.createElement("iframe");
        i.style.display = "none";
        i.src = `${url}`;
        document.body.appendChild(i);
      }
    }
  };
}
