import { ButtonWhite } from "../../../../FormElements/Button";
import AuthService from "../../../../../service/AuthService";
import LoadingIndicator from "../../../../../common/LoadingIndicator";

import {
  ClearButtonStyles,
  FilterButtonContainerStyles,
  FilterToggleButtonStyles,
  FilterToggleButtonContainerStyles,
  SearchButton,
  SortToggleButtonStyles,
} from "./FilterButtonStyles";

const FilterButtonsContainer = ({
  principal,
  hasFiltersSelected,
  handleClear,
  results,
  isLoading,
  downloading,
  isWidget,
  children,
  onClick,
  searchContext,
  filterContext,
  close,
}) => {
  return (
    <FilterButtonContainerStyles hasFiltersSelected={hasFiltersSelected}>
      {filterContext === "sort" ? (
        <SortButton
          hasFiltersSelected={hasFiltersSelected}
          results={results}
          isLoading={isLoading}
          close={close}
        />
      ) : (
        <FilterButton
          hasFiltersSelected={hasFiltersSelected}
          results={results}
          isLoading={isLoading}
          isWidget={isWidget}
        />
      )}
      {children}
      {AuthService.getInstance().hasCSVExportAccess(principal) &&
      searchContext?.value !== "REPORT" &&
      searchContext?.value !== "MYTH" ? (
        <ButtonWhite
          disabled={!hasFiltersSelected || !results}
          onClick={(e) => onClick(e)}
        >
          {downloading ? <LoadingIndicator /> : "Export"}
        </ButtonWhite>
      ) : (
        ""
      )}

      <ClearButton handleClear={handleClear} />
    </FilterButtonContainerStyles>
  );
};

const FilterButton = ({ results, hasFiltersSelected, isLoading, isWidget }) => {
  const resultsText = isLoading
    ? "Loading Results..."
    : results === 0
    ? "No Results"
    : results === 1
    ? isWidget
      ? `${results} Result`
      : `View ${results} Result`
    : isWidget
    ? `${results} Results`
    : `View ${results} Results`;
  return (
    <SearchButton type="submit" hasFiltersSelected={hasFiltersSelected}>
      {hasFiltersSelected ? resultsText : "No Filters Applied"}
    </SearchButton>
  );
};

const SortButton = ({ results, hasFiltersSelected, isLoading, close }) => {
  const resultsText = isLoading
    ? "Loading Sorted Results..."
    : results === 0
    ? "No Results"
    : results === 1
    ? `View Sorted Result`
    : `View Sorted Results`;
  return (
    <SearchButton
      type="button"
      onClick={hasFiltersSelected ? close : null}
      hasFiltersSelected={hasFiltersSelected}
    >
      {hasFiltersSelected ? resultsText : "No Sorting Applied"}
    </SearchButton>
  );
};

const ClearButton = ({ handleClear }) => {
  return (
    <ClearButtonStyles type="reset" onClick={handleClear}>
      Clear <span>All</span>
    </ClearButtonStyles>
  );
};

const FilterToggleButtonsContainer = ({
  numberOfFilters,
  hasSortSelected,
  onClick,
  isFiltersOpen,
  toggleSort,
  filterContext,
}) => {
  return (
    <FilterToggleButtonContainerStyles>
      {filterContext === "MYTH" && (
        <SortToggleButton
          onClick={toggleSort}
          numberOfFilters={hasSortSelected}
        />
      )}
      <FilterToggleButtonWrapper
        onClick={onClick}
        numberOfFilters={numberOfFilters}
        isFiltersOpen={isFiltersOpen}
      />
    </FilterToggleButtonContainerStyles>
  );
};

const FilterToggleButtonWrapper = ({
  numberOfFilters,
  onClick,
  isFiltersOpen,
}) => {
  const filterText =
    numberOfFilters === 1
      ? "1 Filter"
      : `${numberOfFilters !== 0 ? numberOfFilters : ""} Filters`;
  return (
    <FilterToggleButtonStyles
      onClick={onClick}
      hasFilters={numberOfFilters !== 0}
      isFiltersOpen={isFiltersOpen}
    >
      <span>{filterText}</span>
      <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H16V1.5H0V0ZM2 4H14V5.5H2V4ZM12 8H4V9.5H12V8Z"
          fill="currentColor"
        />
      </svg>
    </FilterToggleButtonStyles>
  );
};

const SortToggleButton = ({ onClick, numberOfFilters }) => {
  return (
    <SortToggleButtonStyles
      onClick={onClick}
      hasFilters={numberOfFilters !== 0}
    >
      <span>Sort</span>
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: "rotate(90deg)",
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4336 4.99894L12.3729 6.0596L8.48385 9.94869L7.42319 8.88803L10.5612 5.75H0.569824V4.25H10.5633L7.42319 1.10985L8.48385 0.0491943L12.3729 3.93828L13.4336 4.99894Z"
          fill="currentColor"
        />
      </svg>
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: "rotate(90deg)",
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.566406 4.99898L1.62707 3.93832L5.51615 0.0492353L6.57681 1.1099L3.43878 4.24792L13.4302 4.24792V5.74792L3.43667 5.74792L6.57681 8.88807L5.51615 9.94873L1.62707 6.05964L0.566406 4.99898Z"
          fill="currentColor"
        />
      </svg>
    </SortToggleButtonStyles>
  );
};

export { FilterToggleButtonsContainer, FilterButtonsContainer };
