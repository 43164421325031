import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { browserName, isEdge, isLegacyEdge } from "react-device-detect";

import ConfigService from "../../service/ConfigService";
import AccountService from "../../service/AccountService";
import {
  AccountCreationCompleteWrapper,
  LinkButton,
  SubscriptionText,
  Title,
  Wrapper,
} from "./SignupStyles";
import { ButtonWithIcon } from "../FormElements/Button";
import { useSelector } from "react-redux";

const PurchaseComplete = (props) => {
  const accountService = AccountService.getInstance();
  const appConfig = useSelector((state) => state.authentication.appConfig);

  const location = useLocation();
  const { subscription } = location?.state;

  const subObj = subscription || props.subscription;

  const loadExternalUrl = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  useEffect(() => {
    // dispatches update user action so we have user when
    accountService.retrieveUserInfoFromServer();
  }, []);

  ConfigService.getInstance().setShownActiveSubModal();

  const browsers = {
    chrome: {
      icon: "/assets/icons/svg/chrome.svg",
      link: "https://bit.ly/2mMUEKj",
    },
    edge: {
      icon: "/assets/icons/svg/edge.svg",
      link: "https://bit.ly/2M3TFlz",
    },
    firefox: {
      icon: "/assets/icons/svg/firefox.svg",
      link: "https://mzl.la/2x4rE5w",
    },
    safari: {
      icon: "/assets/icons/svg/safari.svg",
      link: "https://apple.co/3kPc9pj",
    },
  };

  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="template.thankYou" defaultMessage={"Thank you"} />
        !
      </Title>
      <AccountCreationCompleteWrapper>
        {(isEdge || isLegacyEdge) && subObj?.platform === "EDGE" ? (
          <SubscriptionText>
            <FormattedMessage
              id="template.currentInEdgeSubscription"
              defaultMessage={
                "Your free subscription is valid for Edge browsers only."
              }
            />{" "}
            {/* <FormattedMessage
              id="template.confirmation_edge_sub_header"
              defaultMessage="Click <here>https://www.newsguardtech.com/how-it-works/</here> if you'd like to access NewsGuard on browsers other than Edge."
              values={{
                here: (...chunks) => (
                  <a
                    className="link link--external"
                    target="_blank"
                    href={chunks}
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="template.here"
                      defaultMessage="here"
                    />
                  </a>
                ),
              }}
            /> */}
          </SubscriptionText>
        ) : subObj?.plan?.trial ? (
          <SubscriptionText>
            <FormattedMessage
              id="template.confirmation_trial_header"
              values={{ span: (...chunks) => <span>{chunks}</span> }}
              defaultMessage="Congratulations! <span>Your free 2-Week trial has begun.</span>"
            />
          </SubscriptionText>
        ) : (
          <SubscriptionText>
            <FormattedMessage
              id="template.confirmation_header"
              values={{ span: (...chunks) => <span>{chunks}</span> }}
              defaultMessage="Congratulations! <span>Your subscription has begun.</span>"
            />
          </SubscriptionText>
        )}

        {appConfig ? (
          ""
        ) : (
          <>
            <Title>
              <FormattedMessage
                id="template.getStarted"
                defaultMessage={"Get Started"}
              />
            </Title>

            <SubscriptionText>
              <FormattedMessage
                id="template.extension_notice_1"
                values={{
                  a: (...chunks) => (
                    <a
                      href="https://www.newsguardtech.com/#the-app"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link link--notice"
                    >
                      {chunks}
                    </a>
                  ),
                }}
                default="In order to use your NewsGuard subscription, you will need to <a>install the NewsGuard browser extension</a>. If you already have it installed, you will begin to see NewsGuard rating icons on search engines and social media sites as you browse new pages or reload any existing pages you have opened."
              />
            </SubscriptionText>
            <a
              href={browsers[browserName.toLowerCase()]?.link}
              target="_blank"
              rel="noreferrer"
            >
              <ButtonWithIcon icon={browserName.toLowerCase()}>
                <FormattedMessage
                  id="template.getNewsGuard"
                  defaultMessage="Get NewsGuard Extension"
                />
              </ButtonWithIcon>
            </a>
            <SubscriptionText>
              <FormattedMessage
                id="template.extension_notice_2"
                default="You may use NewsGuard on multiple computers or devices and may share your account with members of your household. To use NewsGuard on a new computer, simply install the browser extension, click the NewsGuard 'shield' icon in your browser bar, and log in when prompted."
              />
            </SubscriptionText>
            <LinkButton
              to={"https://www.newsguardtech.com/how-it-works/"}
              onClick={(e) =>
                loadExternalUrl(
                  e,
                  "https://www.newsguardtech.com/how-it-works/"
                )
              }
            >
              <FormattedMessage
                id="template.howToGuide"
                defaultMessage={"How-To Guide"}
              />
            </LinkButton>
          </>
        )}

        <SubscriptionText>
          <FormattedMessage
            id="template.view_your_account"
            values={{
              account: (...chunks) => (
                <Link to="/account/accountsettings" className="link link--main">
                  {chunks}
                </Link>
              ),
            }}
            defaultMessage="View your <account>Account</account>"
          />
        </SubscriptionText>
      </AccountCreationCompleteWrapper>
    </Wrapper>
  );
};

export default PurchaseComplete;
