import { FilterContainer } from "./ui/FilterContainer";
import ScoreFilter from "./AdvancedSearchFilters/ScoreFilter";
import CriteriaFilterContainer from "./AdvancedSearchFilters/CriteriaFilterContainer";
import CheckboxFilter from "./ui/CheckboxFilter";
import AutoSuggestFilter from "./ui/AutoSuggestFilter";
import ContentService from "../../../../service/ContentService";
import React from "react";
import RadioButtonFilter from "./ui/RadioButtonFilter";
import OfflineToggle from "./ui/OfflineToggle";

const RATINGS_VALUES = [
  { key: "A", value: "A", label: "LOWEST RISK" },
  { key: "B", value: "B", label: "LOW RISK" },
  { key: "M", value: "M", label: "SOME RISK" },
  { key: "F", value: "F", label: "HIGH RISK" },
];

const TV_TYPE = [
  { key: "TV_NETWORK", value: "TV_NETWORK", label: "Network" },
  { key: "TV_PROGRAM", value: "TV_PROGRAM", label: "Program" },
];

const YESNO = [
  { key: "Yes", value: "Yes", label: "Yes" },
  { key: "No", value: "No", label: "No" },
];

const TVAdvancedSearch = ({
  principal,
  selectedFilters,
  openFilters,
  handleSelectChange,
  handleUI,
  handleSelectAndRadioChange,
  handleCriteriaChange,
  handleTextChange,
  handleToggleChange,
  clear,
}) => (
  <>
    <FilterContainer
      filter={selectedFilters?.publisher_type}
      handleClear={clear}
    >
      <CheckboxFilter
        filter={selectedFilters?.publisher_type}
        options={TV_TYPE}
        onChange={handleSelectAndRadioChange}
      />
    </FilterContainer>

    <FilterContainer filter={selectedFilters?.ratings} handleClear={clear}>
      <CheckboxFilter
        filter={selectedFilters?.ratings}
        options={RATINGS_VALUES}
        onChange={handleSelectAndRadioChange}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.score}
      handleClear={clear}
      toggleContainer={handleUI}
      isContainerOpen={openFilters.has("score")}
    >
      <ScoreFilter
        onChange={handleSelectAndRadioChange}
        filter={selectedFilters?.score}
      />
    </FilterContainer>

    <CriteriaFilterContainer
      type={"TV"}
      filters={selectedFilters?.criteria}
      removeCriteriaFilter={clear}
      handleCriteriaChange={handleCriteriaChange}
      toggleContainer={handleUI}
      openFilters={openFilters}
    />

    <FilterContainer
      filter={selectedFilters?.tv_network}
      handleClear={clear}
      smallerContext
      toggleContainer={handleUI}
    >
      <AutoSuggestFilter
        filter={selectedFilters?.tv_network}
        onChange={handleTextChange}
        getValues={ContentService.getInstance().getTVNetworkValues}
      />
    </FilterContainer>

    <FilterContainer filter={selectedFilters?.opinion} handleClear={clear}>
      <RadioButtonFilter
        filter={selectedFilters?.opinion}
        options={YESNO}
        onChange={handleSelectAndRadioChange}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.show_offline}
      handleClear={clear}
      smallerContext
      toggleContainer={handleUI}
      context="TV"
    >
      <OfflineToggle
        filter={selectedFilters?.show_offline}
        onChange={handleToggleChange}
      />
    </FilterContainer>
  </>
);

export default TVAdvancedSearch;
