import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import AuthService from "../service/AuthService";
import ConfigService from "../service/ConfigService";
import LoadingIndicator from "../common/LoadingIndicator";
import AccountService from "../service/AccountService";
import { useCookies } from "react-cookie";
import { redirectToRoute } from "../common/Helper";

const AppleSignRedirectHandler = (props) => {
  let [loading, setLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["campaign", "lmref"]);

  const configService = ConfigService.getInstance();
  const authService = AuthService.getInstance();
  const accountService = AccountService.getInstance();

  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (searchParams.get("code")) {
      const code = {
        code: searchParams.get("code"),
        redirectUri: configService.get().appleRedirectUri,
      };
      authService
        .verifyAppleIdCode(code)
        .then((tokenResponse) => {
          return accountService
            .retrieveUserInfoFromServer(true)
            .then((response) => {
              configService.resetShownActiveSubs();
              redirectToRoute(navigate);
            });
        })
        .catch((e) => {
          // navigate("/errorpage");
          navigate(from, { replace: true });
          return Promise.reject(e);
        })
        .finally((res) => {
          setLoading(false);
        });
    }
  }, [searchParams]);

  if (loading) {
    return <LoadingIndicator className="large" />;
  }

  return (
    <Navigate
      to={{
        pathname: "/subscription/offer",
        state: { location },
      }}
    />
  );
};

export default AppleSignRedirectHandler;
