import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  margin-top: 80px;

  @media (max-width: 600px) {
    margin-top: 30px;
    flex-direction: column;
  }
`;

export const Nav = styled.nav`
  position: sticky;
  top: 175px;
  height: 100%;
  min-width: 250px;
  flex-basis: 350px;

  @media (max-width: 600px) {
    background-color: #fff;
    top: 63px;
    flex-basis: 100%;
    z-index: 1;
  }
`;

export const MainContent = styled.div`
  max-width: 760px;
  flex: 1 0 auto;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
  font-size: var(--20px);
  font-weight: 600;
  line-height: 30px;

  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-size: var(--14px);
    overflow: scroll;
    padding-top: 2px;
  }
`;

export const NavItem = styled.li`
  color: ${(props) =>
    props.active ? "var(--primary-black)" : "var(--primary-gray)"};

  &:has(> button) {
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    white-space: nowrap;
    margin-bottom: 0;

    &:last-of-type {
      display: none;
    }
  }
`;

export const NavLink = styled(Link)`
  color: var(--primary-gray-light);
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: var(--primary-black);
    text-decoration: none;
  }
  &.active {
    color: var(--primary-black);
  }
`;

export const AccountAction = styled.section`
  margin-top: 30px;
`;

export const AccountSection = styled.section`
  margin-bottom: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6e6e6;
`;

export const AccountSectionHeader = styled.h2`
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 60px;
`;

export const AccountDetailsHeader = styled.h3`
  font-size: 20px;
  font-weight: 600;
  /* line-height: 30px; */
  margin-bottom: 22px;
`;

export const AccountModalHeader = styled.h3`
  font-size: 16px;

  font-weight: 600;
  margin-bottom: 10px;
`;

export const AccountSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;

  justify-content: ${({ noHeader }) =>
    noHeader ? "flex-end" : "space-between"};

  align-items: flex-start;
`;

export const AccountDetails = styled.p`
  font-size: var(--16px);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 40px;

  &:has(+ button) {
    margin-bottom: 20px;
  }
`;

export const AccountExtraDetails = styled.p`
  font-size: var(--14px);
  font-weight: 400;
  line-height: 20px;
  margin-top: 20px;
`;

export const FormWrapper = styled.div`
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DateWrapper = styled.p`
  margin-top: 15px;
`;

export const TextWrapper = styled.div`
  margin-bottom: 10px;
`;

export const TextInitialCap = styled.span`
  text-transform: capitalize;
`;

export const RedeemPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.account ? "flex-start" : "center")};
  margin: 50px auto 0;
  justify-content: center;
  max-width: ${(props) => (props.account ? "100%" : "500px")};

  padding: ${(props) => (props.account ? "0" : "20")};
`;
