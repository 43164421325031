import styled from "@emotion/styled";
import { Dialog } from "reach-dialog-react-18";
import "reach-dialog-react-18/styles.css";
import { ButtonBlack } from "../../FormElements/Button";
import VisuallyHidden from "../Helpers/VisuallyHidden";

const ModalModuleStyles = styled.div`
  width: ${(props) => (props.account ? "520px" : "563px")};
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  position: ${(props) => (props.account ? "relative" : "absolute")};

  left: auto;
  right: ${(props) => (props.account ? "auto" : "276px")};
  top: ${(props) => (props.account ? "calc(39vh - 200px)" : "80px")};
  padding: 30px 30px 30px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    left: 0;
    z-index: 20;
    width: 100%;
    top: 60px;
  }
`;

const Close = styled.span`
  color: var(--primary-black);
  height: 100%;
  display: block;
  width: 100%;
  font-size: 24px;
  line-height: 24px;
`;

const CloseButton = styled(ButtonBlack)`
  display: block;
  width: 24px;
  position: absolute;
  right: 10px;
  padding: 0px 5px;
  min-width: 10px;
  top: 10px;
  height: 23px;
  background-color: transparent;

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export default function ModalModule({
  children,
  showDialog,
  close,
  showClose,
  account,
  right,
  top,
}) {
  return (
    <Dialog isOpen={showDialog} onDismiss={close} aria-label={"dialog"}>
      <ModalModuleStyles account={account} right={right} top={top}>
        {showClose && (
          <CloseButton className="close-button" onClick={close}>
            <VisuallyHidden>Close</VisuallyHidden>
            <Close aria-hidden>×</Close>
          </CloseButton>
        )}
        {children}
      </ModalModuleStyles>
    </Dialog>
  );
}
