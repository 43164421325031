import { ResultsInfoStyles } from "./SearchResultsStyles";

export const SearchTermAndCount = ({ totalElements, searchTerm }) => {
  if (totalElements !== undefined) {
    return (
      <ResultsInfoStyles>
        <span>{totalElements}</span> result
        {totalElements !== 1 ? "s" : ""}
        {searchTerm ? (
          <>
            {" for "}
            <span>{searchTerm}</span>
          </>
        ) : null}
      </ResultsInfoStyles>
    );
  }
};
