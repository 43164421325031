import styled from "@emotion/styled/macro";
import { createContext, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.hasImage ? "1.5fr 2fr 50px" : "1fr 50px"};
  grid-template-rows: auto;
  align-items: center;

  font-size: 1.125rem;
  background-color: var(--primary-blue);
  color: var(--primary-white);
  padding: 0.5rem;
  border-radius: 4px;

  @media screen and (max-width: 700px) {
    grid-template-columns: ${(props) =>
      props.hasImage ? "1.5fr" : "1fr 50px"};
    grid-template-rows: ${(props) => (props.hasImage ? 3 : "auto")};
  }
`;

const ImageContainer = styled.div`
  /* flex: 1 2 30%; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;

  /* @media screen and (max-width: 600px) {
    display: none;
  } */
`;

const Image = styled.img`
  max-width: 300px;
  /* @media screen and (max-width: 700px) {
    width: 250px;
  } */
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 1 70%;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 200;
  line-height: 32px;
  /* min-width: 300px; */
  max-width: ${(props) => (props.hasImage ? "100%" : "62ch")};
  padding: 1rem 1rem 1rem 2rem;
  gap: 5px;
  /* margin-right: auto; */
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--primary-white);
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-start;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 700px) {
    grid-row: ${(props) => (props.hasImage ? 1 : "auto")};
    justify-self: ${(props) => (props.hasImage ? "end" : "auto")};
  }
`;

const CTAButton = styled(Link)`
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-white);
  border-radius: 2px;
  color: var(--primary-white);
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  text-decoration: none;
  cursor: pointer;
`;

const Heading = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  padding: 0 0 15px;
`;

const NoticeContext = createContext();

function Notice({ children, name, cookie, exp, path }) {
  const [showNotice, setShowNotice] = useState(true);
  const [hasImage, setHasImage] = useState(false);
  const [cookies, setCookie] = useCookies(["ng-notice"]);

  const defaultCookieExpDate = new Date();
  defaultCookieExpDate.setFullYear(defaultCookieExpDate.getFullYear() + 10);

  useEffect(() => {
    if (cookie && !cookies[name]) {
      setCookie(name, "true", {
        expires: exp || defaultCookieExpDate,
        path: path || "",
      });
    }
  }, []);

  function handleClose() {
    setShowNotice(false);
    if (cookie)
      setCookie(name, "false", {
        expires: exp || defaultCookieExpDate,
        path: path || "",
      });
  }

  if (cookies[name] === "false") {
    return null;
  }

  if (!showNotice) {
    return null;
  }

  return (
    <NoticeContext.Provider value={{ handleClose, hasImage, setHasImage }}>
      <Wrapper hasImage={hasImage}>
        {children}
        <CloseButton onClick={handleClose} hasImage={hasImage}>
          <img src="/images/close.svg" alt="Close" height={50} width={50} />
        </CloseButton>
      </Wrapper>
    </NoticeContext.Provider>
  );
}

function NoticeImage({ children }) {
  const { setHasImage } = useContext(NoticeContext);

  useEffect(() => {
    if (children) {
      setHasImage(true);
    }
  }, [children, setHasImage]);

  if (children) {
    return <ImageContainer>{children}</ImageContainer>;
  } else {
    return null;
  }
}

function NoticeDescription({ children }) {
  const { hasImage } = useContext(NoticeContext);
  return <Description hasImage={hasImage}>{children}</Description>;
}

function NoticeHeading({ children }) {
  return <Heading>{children}</Heading>;
}

function NoticeText({ children }) {
  return <Text>{children}</Text>;
}

function NoticeButton({ to, children }) {
  const { handleClose } = useContext(NoticeContext);

  return (
    <CTAButton to={to} onClick={handleClose}>
      {children}
    </CTAButton>
  );
}

Notice.Image = NoticeImage;
Notice.Description = NoticeDescription;
Notice.Heading = NoticeHeading;
Notice.Text = NoticeText;
Notice.Button = NoticeButton;

export default Notice;
