import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "@emotion/styled/macro";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "reach-menu-button-react-18";

import AuthService from "../service/AuthService";
import RequestModule from "./Partners/common/requests/Request";
import AccountMenu from "./Partners/common/AccountMenu";
import useToggle from "./Partners/Helpers/useToggle";

import ModalModule from "./Partners/common/ModalModules";
import SubscriptionFlowMenu from "./Subscription/SubscriptionFlowMenu";
import { Button } from "./Partners/Settings/SettingsStyles";
import { FormattedMessage } from "react-intl";
import { LinkButton } from "./FormElements/Button";

const Header = styled.header`
  position: fixed;
  top: 0;
  text-align: center;

  margin: 0 auto;
  width: 100%;
  z-index: 21;
`;

const NavStyling = styled.nav`
  text-align: center;
  margin: 0 auto;
  height: 90px;
  background-color: #fff;
  z-index: 1;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
  padding: 31px 20px 31px 35px;
  display: flex;

  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
    height: 60px;
  }
`;

const NavItem = styled.li`
  padding: 0 10px;
  cursor: pointer;

  &:last-of-type {
    padding: 0;
  }
`;

const LogoWrapper = styled.div`
  width: fit-content;
  @media screen and (max-width: 600px) {
    z-index: 20;
  }
`;

const Logo = styled.img`
  width: 185px;
  @media screen and (max-width: 600px) {
    width: 132px;
  }
`;

const Side = styled.div`
  flex: 1;
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 5px;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    background: #fff;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* transition: transform 0.3s ease-in-out; */

    font-size: 18px;
    line-height: 30px;

    padding: 100px 0 0 28px;
    align-items: flex-start;

    > li,
    li li {
      padding-left: 0;
      margin-bottom: 20px;
    }

    .username {
      display: none;
    }
  }
`;

const PitchButton = styled(Button)`
  font-size: var(--16px);

  text-transform: none;
  height: 40px;
  font-weight: 500;
  min-width: auto;
  padding: 10px 20px;
`;

const StyledBurger = styled.button`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    div {
      width: 2rem;
      height: 0.25rem;
      background: ${({ open }) => (open ? "#000" : "#333")};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;

      :first-of-type {
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-of-type(2) {
        opacity: ${({ open }) => (open ? "0" : "1")};
        transform: ${({ open }) =>
          open ? "translateX(20px)" : "translateX(0)"};
      }

      :nth-of-type(3) {
        transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
    }
  }
`;

const MenuButtonStyle = styled(MenuButton)`
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #000;
  &:hover {
    text-decoration: none;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #000;
  &:hover {
    text-decoration: none;
  }
`;

const MenuItemStyle = styled(MenuItem)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  width: 100%;
  padding: 10px 20px;
  font-weight: 300;

  &[data-reach-menu-item][data-selected] {
    background-color: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    padding-bottom: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }
`;

const ListStyle = styled(MenuList)`
  text-align: left;
  position: absolute;
  right: -30px;
  top: 9px;
  width: 280px;
  background: #fff;
  border: none !important;

  list-style: none;
  padding: 10px !important;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 21;

  @media screen and (max-width: 600px) {
    left: 0;
    right: auto;
  }
`;

const handleMenuClick = (e, setOpen, open) => {
  e.preventDefault();
  setOpen(!open);
};

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger
      open={open}
      onClick={(e, open) => handleMenuClick(e, setOpen, open)}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const PartnerHeader = ({ signedIn }) => {
  const location = useLocation();
  const isSignUp = location.pathname.includes("/signup");
  const isSignIn = location.pathname.includes("/signin");
  const isAccount = location.pathname.includes("/account");
  const isPitch = location.pathname.includes("/get-newsguard");
  const isPaymentHistory = location.pathname.includes("/paymenthistory");
  const principal = useSelector((state) => state.authentication.principal);
  const authService = AuthService.getInstance();
  const isPartner = authService.isPartner(principal);

  const [isOpen, toggleIsOpen] = useToggle(false);
  const [context, setContext] = useState(null);

  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const openRequest = (e, context) => {
    e.preventDefault();
    setContext(context);
    setShowRequestDialog(true);
  };
  const closeRequest = () => setShowRequestDialog(false);

  return (
    <>
      <Header>
        <ModalModule
          showDialog={showRequestDialog}
          close={closeRequest}
          onDismiss={closeRequest}
          account={true}
          showClose={true}
        >
          <RequestModule onDismiss={closeRequest} context={context} />
        </ModalModule>
        <NavStyling>
          <Side>
            <LogoWrapper>
              {isAccount ? (
                isPaymentHistory ? (
                  <Link to="/account">
                    <Logo
                      src="/images/NewsGuardLogo_SMALL-Black.svg"
                      alt="NewsGuard Logo"
                    />
                  </Link>
                ) : (
                  <Logo
                    src="/images/NewsGuardLogo_SMALL-Black.svg"
                    alt="NewsGuard Logo"
                  />
                )
              ) : (
                <Link to={isPartner ? "/partner/home" : "/"}>
                  <Logo
                    src="/images/NewsGuardLogo_SMALL-Black.svg"
                    alt="NewsGuard Logo"
                  />
                </Link>
              )}
            </LogoWrapper>
          </Side>
          {isSignUp && (
            <>
              <SubscriptionFlowMenu />
              <Side />
            </>
          )}
          {isPitch && (
            <>
              <Side />
              <Link to="/signin">
                <PitchButton>
                  <FormattedMessage
                    id="template.signin"
                    defaultMessage="Sign in"
                  />
                </PitchButton>
              </Link>
            </>
          )}
          {signedIn ? (
            <NavList open={isOpen}>
              {isPartner && (
                <NavItem>
                  <Menu>
                    <MenuButtonStyle>Make a Request</MenuButtonStyle>
                    <ListStyle as="ul">
                      {AuthService.getInstance().hasLabelAccess(principal) ? (
                        <MenuItemStyle
                          as="li"
                          onSelect={() => {}}
                          onClick={(e) => openRequest(e, "website")}
                        >
                          <LinkItem
                            to="/"
                            onClick={(e) => openRequest(e, "website")}
                          >
                            Website Request
                          </LinkItem>
                        </MenuItemStyle>
                      ) : (
                        ""
                      )}

                      {AuthService.getInstance().hasMythAccess(principal) ||
                      AuthService.getInstance().hasRecentMythOnlyAccess(
                        principal
                      ) ? (
                        <MenuItemStyle
                          as="li"
                          onSelect={() => {}}
                          onClick={(e) => openRequest(e, "myth")}
                        >
                          <LinkItem
                            to="/"
                            onClick={(e) => openRequest(e, "myth")}
                          >
                            False Narrative Request
                          </LinkItem>
                        </MenuItemStyle>
                      ) : (
                        ""
                      )}
                    </ListStyle>
                  </Menu>
                </NavItem>
              )}

              {isPartner ? (
                <NavItem>
                  <LinkButton
                    to="/partner/settings/preferences"
                    icon="/images/alerts.svg"
                  >
                    Alerts
                  </LinkButton>
                </NavItem>
              ) : null}
              <NavItem>
                <AccountMenu
                  principal={principal}
                  isPartner={isPartner}
                  onClick={() => toggleIsOpen(false)}
                />
              </NavItem>
            </NavList>
          ) : null}
          {!isPitch && !isSignIn && isPartner && (
            <Burger open={isOpen} setOpen={toggleIsOpen} />
          )}
        </NavStyling>
      </Header>
    </>
  );
};

export default PartnerHeader;
