import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "reach-menu-button-react-18";
import "reach-menu-button-react-18/styles.css";
import { useMediaQuery } from "react-responsive";
import AuthService from "../../../service/AuthService";
import { useDispatch } from "react-redux";
import ModalModule from "./ModalModules";
import ExportModule from "../ExportModule";
import React, { useState } from "react";
import { useNavigate } from "react-router";

const ListStyle = styled(MenuList)`
  text-align: left;
  position: absolute;
  right: -30px;
  top: 9px;
  width: 280px;
  background: #fff;
  border: none !important;

  list-style: none;
  padding: 10px !important;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 21;

  .account-menu {
    padding: 10px;
  }

  li {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    width: 100%;
    padding: 10px 20px;
    font-weight: 300;

    @media screen and (max-width: 600px) {
      font-size: 18px;
      padding-bottom: 0;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
      font-weight: 600;
    }
    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 1);
      display: inline-block;
      width: 100%;
      font-weight: 400;
      &:hover {
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: 600px) {
    position: initial;
    width: auto;
    border-radius: none;
    background-color: none;
    box-shadow: none;
    margin-left: 0;
    padding: 0 !important;
  }
`;

const MenuButtonStyle = styled(MenuButton)`
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
`;

const Button = styled.button`
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
  font-weight: 400;

  &:hover {
    font-weight: 600;
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const AnchorItem = styled.a`
  text-decoration: none;
  color: #000;
`;

const MenuItemStyle = styled(MenuItem)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  width: 100%;
  padding: 10px 20px;
  font-weight: 300;

  &[data-reach-menu-item][data-selected] {
    background-color: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
    padding-bottom: 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
    font-weight: 500;
  }
  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 1);
    display: inline-block;
    width: 100%;
  }
`;

const UserIcon = styled.span`
  position: relative;
  background: url("/images/person.svg") 7px 3px no-repeat;
  padding-left: 30px;
  font-weight: 500;
  @media screen and (max-width: 600px) {
    background-image: none;
  }
`;

const SubNav = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export default function AccountMenu({ principal, isPartner, onClick }) {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const authService = AuthService.getInstance();
  const hasExportAccess =
    authService.hasCSVExportAccess(principal) &&
    (authService.hasLabelAccess(principal) ||
      authService.hasMythAccess(principal) ||
      authService.hasRecentMythOnlyAccess(principal) ||
      authService.hasTVAccess(principal) ||
      authService.hasPodcastAccess(principal));
  const navigate = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    navigate("/signout");
  };

  const [showDialog, setShowDialog] = useState(false);
  const open = () => {
    setShowDialog(true);
  };

  const close = () => setShowDialog(false);

  return (
    <>
      {hasExportAccess ? (
        <ModalModule
          showDialog={showDialog}
          close={close}
          onDismiss={close}
          account={true}
          showClose={true}
        >
          <ExportModule />
        </ModalModule>
      ) : (
        ""
      )}
      {isMobile ? (
        <SubNav>
          {isPartner && (
            <li onClick={onClick}>
              <AnchorItem
                href={
                  isPartner
                    ? "mailto:dashboard@newsguardtech.com"
                    : "mailto:support@newsguardtech.com"
                }
              >
                Help
              </AnchorItem>
            </li>
          )}
          {hasExportAccess ? (
            <li>
              <Button onClick={open}>Export Data</Button>
            </li>
          ) : (
            ""
          )}
          <li>
            <LinkItem to="/" onClick={logout}>
              Sign Out
            </LinkItem>
          </li>
        </SubNav>
      ) : (
        <Menu>
          <MenuButtonStyle>
            <UserIcon>
              {principal.firstName || principal.lastName || principal.email}
            </UserIcon>
          </MenuButtonStyle>
          <ListStyle as="ul">
            <>
              <MenuItemStyle as="li" onSelect={() => {}}>
                <AnchorItem
                  href={
                    isPartner
                      ? "mailto:dashboard@newsguardtech.com"
                      : "mailto:support@newsguardtech.com"
                  }
                >
                  Help
                </AnchorItem>
              </MenuItemStyle>
            </>

            {hasExportAccess ? (
              <MenuItemStyle as="li" onClick={open} onSelect={() => {}}>
                <Button>Export Data</Button>
              </MenuItemStyle>
            ) : (
              ""
            )}
            <MenuItemStyle as="li" onSelect={() => {}}>
              <LinkItem to="/" onClick={logout}>
                Sign Out
              </LinkItem>
            </MenuItemStyle>
          </ListStyle>
        </Menu>
      )}
    </>
  );
}
