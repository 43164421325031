import { useEffect, useReducer, useState } from "react";
import {
  AccountMessage,
  AccountMessageLink,
  Disclaimer,
  Fieldset,
  Form,
  Title,
  Wrapper,
} from "./Subscription/SignupStyles";

import AuthService from "../service/AuthService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import countryList from "../../../../../../shared/resources/_country.json";

import ConfigService from "../service/ConfigService";
import LoadingIndicator from "../common/LoadingIndicator";
import { FormattedMessage, useIntl } from "react-intl";
import { getCountry, getLanguage } from "../common/_localeMessages";

import AccountService from "../service/AccountService";
import InputModule, { ErrorMessage } from "./FormElements/InputModule";
import SelectModule from "./FormElements/SelectModule";
import { Button } from "./FormElements/Button";
import OAuthButtons from "./OAuthButtons/OAuthButtons";
import PriceTermSignup from "./PriceTermSignup";
import { SalesTaxDisclaimer } from "./Partners/Helpers/SalesTaxDisclaimer";
import { useCookies } from "react-cookie";
import { redirectToRoute } from "../common/Helper";

function SignUp(props) {
  const [searchParams] = useSearchParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [serverError, setServerError] = useState(false);

  const [html5error, setHtml5error] = useState({});
  const [validated, setValidated] = useState(false);

  const config = ConfigService.getInstance().get();
  const accountService = AccountService.getInstance();
  const authService = AuthService.getInstance();
  const [isCampaignPlan, setIsCampaignPlan] = useState(false);

  const linkMinkReferralCode = searchParams.get("lmref");
  const [cookies, setCookie, removeCookie] = useCookies(["campaign"]);
  //const campaign = linkMinkReferralCode || params.campaign || cookies?.campaign;

  const [formFields, setFormFields] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      country: getCountry() || config.country || "US",
      language: getLanguage() || config.language || "en",
    }
  );

  useEffect(() => {
    authService.apiLogout();
    /*
    let lmRef;
    if (window.lm && campaign) {
      // lm.js must be initialized with your API key
      lmRef = window.lm.getReferralData();
    }
    setIsCampaignPlan(campaign && lmRef);
    */
    if (params.err) {
      setError(true);
      setServerError(searchParams.err);
    }
  }, []);

  const handleInputChange = (event) => {
    event.target.setCustomValidity(""); // have to reset custom validity else field never returns to valid
    const target = event.target;
    const inputName = target.name;
    let inputValue = target.value;
    if (inputName === "optIn") {
      inputValue = target.checked;
    }

    setHtml5error((prevState) => ({
      ...prevState,
      [inputName]: undefined,
    }));

    setFormFields({ [inputName]: inputValue });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    setError(null);
    setHtml5error({});

    event.preventDefault();
    if (validate(event)) {
      await authService
        .signup(formFields)
        .then((response) => {
          accountService.retrieveUserInfoFromServer();
          redirectToRoute(navigate);
        })
        .catch((error) => {
          console.log("error", error);
          setError(error);
          // setErrorMessage(error.message);
        })
        .finally((res) => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  const validateConfirmPassword = (e) => {
    const passwordField = document.querySelector("#password");
    const confirmPasswordField = document.querySelector("#confirmPassword");
    if (confirmPasswordField.value !== passwordField.value) {
      confirmPasswordField.setCustomValidity(() => {
        let confirmPassword = {
          id: "message.password_confirm_instruction",
          defaultMessage:
            "Please enter matching passwords for Password and Confirm Password",
          name: "confirmPassword",
          html: true,
        };

        setHtml5error((prevState) => ({
          ...prevState,
          confirmPassword,
        }));
      });
    }
  };

  const checkIsValid = (e) => {
    if (e.target.checkValidity() === false) {
      e.target.parentNode.classList.add("was-validated");
    }
    if (e.target.id === "confirmPassword") {
      validateConfirmPassword();
    }
  };

  const validate = (event) => {
    setValidated(true);

    return event.target.checkValidity();
  };

  return (
    <Wrapper>
      <Form
        className={`  ${validated ? "was-validated" : ""}`}
        onSubmit={handleSubmit}
        disabled={loading}
        aria-busy={loading}
        noValidate
      >
        <Fieldset>
          {serverError ? <ErrorMessage error={serverError} /> : ""}
          <Title>
            <FormattedMessage
              id="template.createAccount"
              defaultMessage={"Create Account"}
            />
          </Title>
          <PriceTermSignup language={formFields.language} />
          <InputModule
            type="text"
            identifier="firstName"
            placeholder={intl.formatMessage({
              id: "template.first_name",
              defaultMessage: "First Name",
            })}
            autoComplete="firstName"
            value={formFields.firstName}
            onChange={handleInputChange}
            onBlur={checkIsValid}
            required={true}
            maxLength={35}
            onInvalid={() => {
              let firstName = {
                id: "template.firstname_blank",
                defaultMessage: "Please enter a first name",
                name: "firstName",
                html: true,
              };

              setHtml5error((prevState) => ({
                ...prevState,
                firstName,
              }));
            }}
            error={html5error.firstName}
          />
          <InputModule
            type="text"
            identifier="lastName"
            placeholder={intl.formatMessage({
              id: "template.last_name",
              defaultMessage: "Last Name",
            })}
            autoComplete="lastName"
            value={formFields.lastName}
            onChange={handleInputChange}
            onBlur={checkIsValid}
            required={true}
            maxLength={35}
            onInvalid={() => {
              let lastName = {
                id: "template.lastname_blank",
                defaultMessage: "Please enter a last name",
                name: "lastName",
                html: true,
              };

              setHtml5error((prevState) => ({
                ...prevState,
                lastName,
              }));
            }}
            error={html5error.lastName}
          />
          <SelectModule
            identifier="country"
            name="country"
            className="form__control form__control--select"
            onChange={handleInputChange}
            value={formFields.country}
            required
            label={intl.formatMessage({
              id: "template.country",
              defaultMessage: "Country",
            })}
            options={countryList
              .filter((countryVal) => countryVal.available)
              .map((countryVal) => (
                <FormattedMessage
                  id={"country." + countryVal.id}
                  defaultMessage={countryVal.label}
                  key={countryVal.value}
                >
                  {(country_name) => (
                    <option key={countryVal.id} value={countryVal.id}>
                      {" "}
                      {countryVal.label}{" "}
                    </option>
                  )}
                </FormattedMessage>
              ))}
          />
          <InputModule
            type="email"
            identifier="email"
            placeholder={intl.formatMessage({
              id: "template.email",
              defaultMessage: "Email",
            })}
            value={formFields.email}
            onChange={handleInputChange}
            onBlur={checkIsValid}
            autoComplete="email"
            required={true}
            onInvalid={() => {
              let email = {
                id: "template.email_invalid_message",
                defaultMessage: "Please enter a correct email address",
                name: "email",
                html: true,
              };
              setHtml5error((prevState) => ({
                ...prevState,
                email,
              }));
            }}
            error={html5error.email || error}
          />

          <InputModule
            type="password"
            identifier="password"
            placeholder={intl.formatMessage({
              id: "template.password",
              defaultMessage: "Password",
            })}
            value={formFields.password}
            onChange={handleInputChange}
            onBlur={checkIsValid}
            autoComplete="new-password"
            minLength={8}
            maxLength={50}
            required={true}
            onInvalid={() => {
              let password = {
                id: "message.password_length_instruction",
                defaultMessage:
                  "Please enter a password that is 8 characters or longer",
                name: "password",
                html: true,
              };
              setHtml5error((prevState) => ({
                ...prevState,
                password,
              }));
            }}
            error={html5error.password}
          />

          <InputModule
            type="password"
            identifier="confirmPassword"
            placeholder={intl.formatMessage({
              id: "template.confirm_password",
              defaultMessage: "Confirm Password",
            })}
            value={formFields.confirmPassword}
            onChange={handleInputChange}
            onBlur={checkIsValid}
            required={true}
            auto-complete="new-password"
            onInvalid={() => {
              let confirmPassword = {
                id: "message.password_confirm_instruction",
                defaultMessage: "Please enter matching passwords",
                name: "confirmPassword",
                html: true,
              };
              setHtml5error((prevState) => ({
                ...prevState,
                confirmPassword,
              }));
            }}
            error={html5error.confirmPassword}
          />

          {error ? <div className="form__error">{errorMessage}</div> : ""}

          <Button type="submit">
            {loading ? (
              <LoadingIndicator button />
            ) : (
              <FormattedMessage id="template.next" defaultMessage="Next" />
            )}
          </Button>
          <AccountMessage>
            <FormattedMessage
              id="dialog.offer_have_subscription"
              default="Already have a subscription?"
            />{" "}
            <AccountMessageLink to="/signin">
              <FormattedMessage id="template.signin" default="Sign In" />
            </AccountMessageLink>
          </AccountMessage>
        </Fieldset>
      </Form>
      <OAuthButtons />

      <Disclaimer>
        <FormattedMessage
          id="template.sign_up_disclaimer"
          defaultMessage="By signing up, you agree to the
                            <tos>https://www.newsguardtech.com/terms-of-service/</tos>
                            and
                            <privacy>https://www.newsguardtech.com/privacy-policy/</privacy>"
          values={{
            tos: (...chunks) => (
              <a
                className="link link--external"
                target="_blank"
                href={chunks}
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="template.tos"
                  defaultMessage="Terms of Service"
                />
              </a>
            ),
            privacy: (...chunks) => (
              <a
                className="link link--external"
                target="_blank"
                href={chunks}
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="template.privacy_policy"
                  defaultMessage="Privacy Policy"
                />
              </a>
            ),
          }}
        />
      </Disclaimer>
      {isCampaignPlan ? "" : <SalesTaxDisclaimer />}
    </Wrapper>
  );
}

export default SignUp;
