import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import SignUpHeader from "../components/Subscription/SignUpHeader";
import { GlobalStyle } from "./MainLayout";

const Wrapper = styled.div`
  display: flex;
  width: 360px;
  flex-direction: column;
  margin: 145px auto 0;
  padding-bottom: 30px;

  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
`;

const SignUpLayout = ({ children, ...rest }) => {
  const Component = (props) => children;

  return (
    <>
      <GlobalStyle />
      <SignUpHeader />
      <Wrapper>
        <Component />
        <Outlet />
      </Wrapper>
    </>
  );
};

export default SignUpLayout;
