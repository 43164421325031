import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Moment from "react-moment";
import styled from "@emotion/styled/macro";
import ContentService from "../../service/ContentService";
import ReportMarker from "./common/ReportMarker";

import LoadingIcon from "./common/LoadingIcon";
import PrintLogo from "./common/PrintLogo";
import Actions from "./common/Actions";
import Byline from "./Byline";
import AuthService from "../../service/AuthService";
import Unauthorized from "./Unauthorized/Unauthorized";
import TagManager from "react-gtm-module";
import { MetadataList } from "./Metadata";
import { getLocale } from "../../common/_localeMessages";

const PageWrapper = styled.div`
  @page {
    margin: 60px;
  }
`;

const ReportContainerWrapper = styled.div`
  border-top: 6px solid rgba(0, 0, 0, 0.07);
  margin-top: 87px;
  padding-top: 60px;
  position: relative;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }

  @media print {
    margin-top: 20px;
  }
`;

const ReportContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 760px) 1fr;
  grid-template-rows: min-content 3fr;
  grid-column-gap: 50px;

  @media (max-width: 924px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  @media print {
    display: block;
  }
`;

const ContentContainer = styled.div`
  @media screen and (max-width: 600px) {
    padding-top: 10px;
    margin-bottom: 20px;
  }
`;

const AsideWrapper = styled.div`
  max-width: 350px;
  min-width: 250px;
  justify-self: self-end;
  width: 100%;
  margin-top: -56px; // to align with locale badge
  @media (max-width: 924px) {
    max-width: 100%;
    justify-self: unset;
    grid-column: 1;
    grid-row: 2;
    margin-top: 0;
  }
`;

const ReportHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    align-items: flex-start;
  }

  @media print {
    margin-bottom: 0;
  }
`;

const ReportHeader = styled.h1`
  margin-bottom: 0;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media print {
    font-size: 30px;
    line-height: 40px;
  }
`;

const ReportSubheader = styled.h2`
  font-size: 16px;
  font-variation-settings: "wght" 600;
  line-height: 30px;
  margin-top: 10px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
`;

const ReportSectionTextStyles = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  a {
    text-decoration: none;
    color: #3355dd !important;

    &:hover {
      text-decoration: underline !important;
    }

    ul {
      list-style-type: inherit;
    }

    &:last-of-type {
      margin-bottom: 35px;
    }
  }
`;

const ReportSectionListItemStyles = styled.li`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
  }

  &:last-of-type {
    margin-bottom: 50px;
  }

  a {
    text-decoration: none;
    color: #3355dd;
    word-break: break-all;
  }
`;

const ReportSectionHeaderStyles = styled.h3`
  font-size: 20px;
  font-variation-settings: "wght" 600;
  line-height: 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 5px;
  }
`;

const AsideContainerStyles = styled.aside`
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 6px solid #000;
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 50px 20px 20px;
  margin-bottom: 45px;

  section {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 600px) {
    word-break: break-all;
    section {
      margin-bottom: 20px;
    }
  }
`;

const AsideHeaderStyles = styled.h4`
  font-size: 14px;
  font-variation-settings: "wght" 600;
  line-height: 18px;
  margin-bottom: 8px;
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const LabelTopLineDesecriptionStyles = styled.p`
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 30px;
    font-variation-settings: "wght" 100;
    margin-bottom: 10px;
  }

  @media print {
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }
`;

const Writeup = ({ report }) => {
  if (report.writeup) {
    const keys = Object.keys(report.writeup).sort();
    return keys.map((key) => {
      if (key !== "topline description") {
        const blurb = report.writeup[key];
        return (
          <>
            {blurb.title !== "Body" ? (
              <ReportSectionHeaderStyles key={key}>
                {blurb.title}
              </ReportSectionHeaderStyles>
            ) : (
              ""
            )}
            {blurb.body.map((paragraph, index) => {
              return (
                <ReportSectionTextStyles
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                  key={`writeup_${key}_${index}`}
                />
              );
            })}
          </>
        );
      }
    });
  }
  return null;
};

const Footnote = ({ report }) => {
  if (report.footnotes && report.footnotes.length > 0) {
    return (
      <div>
        <ReportSubheader>Footnotes</ReportSubheader>
        {report.footnotes.map((footnote, index) => {
          return (
            <ReportSectionTextStyles
              key={`footnote${index}`}
              dangerouslySetInnerHTML={{ __html: footnote }}
            />
          );
        })}
      </div>
    );
  }
  return "";
};

const Report = (props) => {
  const dispatch = useDispatch();
  const principal = useSelector((state) => state.authentication?.principal);

  const reportId = useParams().reportId || props.reportId;
  const profileId = useParams().profileId || props.profileId;

  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});

  const contentServiceInstance = ContentService.getInstance();

  useEffect(() => {
    getData();
  }, [profileId, reportId]);

  const refCallback = (element, index) => {
    if (element) {
      if (element.scrollHeight > element.clientHeight) {
        const div = document.createElement("div");
        div.className = "expand";
        div.innerHTML = "expand";
        div.onclick = () => {
          element.classList.add("expanded");
          div.parentElement.removeChild(div);
        };
        element.parentElement.appendChild(div);
        // element.
      }
    }
  };

  const getData = () => {
    setLoading(true);

    let getReport;
    if (profileId) {
      getReport = contentServiceInstance.getReportByProfileId(profileId);
    } else {
      getReport = contentServiceInstance.getReport(reportId);
    }

    getReport
      .then((report) => {
        setReport(report);

        const tagManagerArgs = {
          dataLayer: {
            event: "PartnerView",
            user: principal?.username,
            partner: principal?.attributes?.partner,
            view: "Label",
            content_type: "REPORT",
            content_title: report.title || report.identifier,
            content_locale: report.locale,
          },
        };

        TagManager.dataLayer(tagManagerArgs);

        dispatch({
          type: "SET_LABEL_DOMAIN",
          payload: report.title || report.name,
        });
        dispatch({
          type: "SET_SEARCH_CONTEXT",
          payload: { value: "REPORT", label: "Reports" },
        });
      })
      .catch((err) => console.log(err))
      .finally((res) => setLoading(false));
  };

  const ReportList = ({ value, metakey, link }) => {
    let list = [];
    if (value) {
      if (Array.isArray(value)) {
        list = value;
      } else {
        list = value.split(",");
      }
    }

    return list.map((term, index) => {
      let displayValue = term;
      return link ? (
        metakey ? (
          <p key={index}>
            <Link
              to={`/partner/search?search_context=REPORT&${metakey}=${term}`}
            >
              {displayValue}
            </Link>
          </p>
        ) : (
          <ReportSectionTextStyles key={index}>
            <Link to={`${term}`} target="_blank" rel="noopener noreferrer">
              {displayValue}
            </Link>
          </ReportSectionTextStyles>
        )
      ) : (
        <p key={index}>{displayValue}</p>
      );
    });
  };

  const componentRef = useRef();

  return loading ? (
    <LoadingIcon />
  ) : AuthService.getInstance().hasOnlineReportAccess(principal) ? (
    <PageWrapper ref={componentRef}>
      <PrintLogo />
      <ReportHeaderWrapper>
        <ReportMarker large />
        <ReportHeader dangerouslySetInnerHTML={{ __html: report.title }} />
      </ReportHeaderWrapper>
      <ReportContainerWrapper>
        <Actions item={report} el={componentRef} showLocale />
        <ReportContainer>
          <ContentContainer>
            <LabelTopLineDesecriptionStyles
              dangerouslySetInnerHTML={{
                __html: report.topline,
              }}
            />

            <Writeup report={report} key={"writeup"} />
            <Footnote report={report} key={"footnote"} />
          </ContentContainer>
          <AsideWrapper>
            <AsideContainerStyles>
              {report.firstPublishedDate && (
                <section>
                  <AsideHeaderStyles>Published</AsideHeaderStyles>
                  <Moment format="MMM DD, YYYY" locale={getLocale()}>
                    {report.firstPublishedDate}
                  </Moment>
                </section>
              )}
              {report.metadata?.category?.body && (
                <section>
                  <AsideHeaderStyles>Categories</AsideHeaderStyles>
                  <MetadataList
                    context={"REPORT"}
                    value={report.metadata?.category?.body}
                    metakey={"category"}
                    link
                  />
                </section>
              )}
              {report.metadata?.subject_tags?.body && (
                <section>
                  <AsideHeaderStyles>Subject Tags</AsideHeaderStyles>
                  <MetadataList
                    context={"REPORT"}
                    value={report.metadata?.subject_tags?.body}
                    metakey={"subject_tags"}
                    link
                  />
                </section>
              )}
            </AsideContainerStyles>
            <Byline label={report} key={"byline"} />
          </AsideWrapper>
        </ReportContainer>
      </ReportContainerWrapper>
    </PageWrapper>
  ) : (
    <Unauthorized myth={true} />
  );
};

export default Report;
