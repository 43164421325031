import PublisherSearchResult from "./PublisherSearchResult";
import MythSearchResult from "./MythSearchResult";
import ReportSearchResult from "./ReportSearchResult";
import Notice from "../../../common/Notice";

export default function SearchResult({
  content,
  index,
  hasMythAccess,
  searchContext,
}) {
  return (
    <>
      {index === 3 && (
        <Notice name="ng-notice" cookie={true}>
          <Notice.Image />
          <Notice.Description>
            <Notice.Heading>
              Stay on top of changes with email alerts
            </Notice.Heading>
            <Notice.Text>
              Sign up for email alerts to get notified when source ratings or
              false narratives are added or updated&#8212;right in your inbox.
            </Notice.Text>
            <Notice.Button
              to={`/partner/settings/preferences/${
                searchContext?.value?.toLowerCase() || ""
              }`}
            >
              Email Settings
            </Notice.Button>
          </Notice.Description>
        </Notice>
      )}
      {content.object === "publisher" ? (
        <PublisherSearchResult
          labels={content.labels}
          index={index}
          hasMythAccess={hasMythAccess}
          associatedMyths={content.myths}
          siteOnline={content.siteOnline}
          associatedDomains={content.associated}
        />
      ) : content.object === "myth" ? (
        <MythSearchResult mythPublished={content} index={index} />
      ) : (
        <ReportSearchResult
          labels={content.labels}
          metadata={content.metadata}
          index={index}
        />
      )}
    </>
  );
}
