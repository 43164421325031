import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthService from "../service/AuthService";
import LoadingIndicator from "../common/LoadingIndicator";
import queryString from "query-string";
import ConfigService from "../service/ConfigService";
import AccountService from "../service/AccountService";
import { useCookies } from "react-cookie";
import { redirectToRoute } from "../common/Helper";

function OAuth2RedirectHandler() {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     loading: true,
  //   };
  // }
  const authenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["campaign", "lmref"]);
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    const authService = AuthService.getInstance();
    const accountService = AccountService.getInstance();

    const parsed = queryString.parse(location.search);
    if (parsed.code) {
      // do authorization code flow.
      authService
        .authCodeFlow(parsed.code)
        .then((response) => {
          return accountService
            .retrieveUserInfoFromServer(false)
            .then((response) => {
              navigate(from, { replace: true });
              ConfigService.getInstance().resetShownActiveSubs();
              redirectToRoute(navigate);
            })
            .catch((error) => {
              navigate(from, { replace: true });
            });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (authService.tryLogin()) {
        return accountService
          .retrieveUserInfoFromServer(false)
          .then((response) => {
            removeCookie("campaign");
            removeCookie("lmref");

            ConfigService.getInstance().resetShownActiveSubs();
            redirectToRoute(navigate);
          })
          .catch((e) => {
            navigate("/signin");
            return Promise.reject(e);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  return loading ? (
    <LoadingIndicator className="large" />
  ) : (
    <Navigate
      to={{
        pathname: "/subscription/offer",
        state: { from: location },
      }}
    />
  );
}

export default OAuth2RedirectHandler;
