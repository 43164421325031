import React, { useState } from "react";
import SubscriptionPurchaseInformation from "./SubscriptionPurchaseInformation";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Title } from "./SignupStyles";
import { Button } from "../FormElements/Button";
import AccountService from "../../service/AccountService";
import { useSelector } from "react-redux";
import AuthService from "../../service/AuthService";
import LoadingIndicator from "../../common/LoadingIndicator";
import styled from "@emotion/styled/macro";
import ErrorMessage from "../FormElements/ErrorMessage";

export const ErrorMessageStyled = styled.div`
  color: #ff4444;
  font-family: Sora;
  font-size: var(--14px);
  font-weight: 400;
  line-height: var(--20px);
  margin: 5px 0 15px 0;
  position: relative;
  top: ${({ longMessage }) => (longMessage ? "-17px" : "0")};
`;

const PurchasePreview = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["campaign", "lmref"]);
  const location = useLocation();
  const { subscription, plan, coupon } = location.state;

  const principal = useSelector((state) => state.authentication.principal);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const accountService = AccountService.getInstance();
  const authService = AuthService.getInstance();

  const purchase = () => {
    if (!isLoading) {
      setIsLoading(true);
      setError(null);

      // >>>>>>>  LinkMink
      // if we have the proper campaign and linkMink referer code,
      // we send the code back
      //
      /*
      let lmRef;

      if (window.lm && cookies?.campaign) {
        // lm.js must be initialized with your API key
        lmRef = window.lm.getReferralData();
      }
      */
      // <<<<<<
      accountService
        .purchaseSubscription({
          customerId: principal.id,
          planId: plan.id,
          couponId: coupon?.id,
          //lmRef: lmRef ? lmRef : "",
          //campaign: cookies?.campaign,
        })
        .then((response) => {
          // setTransactionComplete(true);
          // setSubscription(response);

          // evict full access cache in nutrition side to sync.
          authService.clearFullAccessCache(principal.id);
          navigate("/subscription/complete", {
            state: {
              subscription: response,
            },
          });
        })
        .catch((error) => {
          if (error.response && error.response.code === "CURRENCY_MISMATCH") {
            const currency = plan.currency;
            const newCurrency = error.response.message;

            setError({
              response: {
                code: "CURRENCY_MISMATCH",
                values: {
                  currency1: currency.toUpperCase(),
                  currency2: newCurrency.toUpperCase(),
                },
                defaultMessage:
                  "You are attempting to sign up for a subscription plan charged in {currency1}, but your credit card is from a country that uses {currency2}. Your subscription plan has been updated to charge in the proper currency.",
              },
            });
          } else {
            setError(error);
          }
        })
        .finally((res) => setIsLoading(false));
    }
  };

  return (
    <>
      <Title>
        <FormattedMessage
          id="template.confirmation"
          defaultMessage={"Confirmation"}
        />
      </Title>

      <SubscriptionPurchaseInformation subscription={subscription} />

      <Button className="btn btn--purchase" onClick={purchase}>
        {isLoading ? (
          <LoadingIndicator button />
        ) : plan.trial ? (
          <FormattedMessage
            id="dialog.offer_button_text"
            defaultMessage="Start Free Trial"
          />
        ) : (
          <FormattedMessage
            id="template.purchase"
            defaultMessage="Start Subscription"
          />
        )}
      </Button>
      {error && <ErrorMessage error={error} />}
    </>
  );
};

export default PurchasePreview;
