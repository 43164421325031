import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import {
  isMobileSafari,
  isSafari,
  isEdge,
  isLegacyEdge,
} from "react-device-detect";
import {
  additionalCurrencySuffix,
  getLanguage,
} from "../common/_localeMessages";
import AccountService from "../service/AccountService";
import { useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { SubscriptionText } from "./Subscription/SignupStyles";
import LoadingIndicator from "../common/LoadingIndicator";

const PriceTermSignup = (props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  //const linkMinkReferralCode = searchParams.get("lmref");
  //const [cookies, setCookie, removeCookie] = useCookies(["campaign"]);
  //const campaign = linkMinkReferralCode || params.campaign || cookies?.campaign;

  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState({});
  const [coupon, setCoupon] = useState({});
  const [error, setError] = useState(false);
  const [isCampaignPlan, setIsCampaignPlan] = useState(false);
  const [price, setPrice] = useState(4.95);
  const [regularPrice, setRegularPrice] = useState(4.95);
  const [currency, setCurrency] = useState("usd");
  const [numberFormatOptions, setNumberFormatOptions] = useState({
    style: "currency",
    currency: "usd",
  });

  /*
  const updateCampaign = (code = "") => {
    if (code) {
      //
      // campaign cookie
      // for now, it is session=
      // to do: dynamic cookie expiration date for each campaign
      //

      setCookie("campaign", code, {
        path: "/",
      });
    }
  };
  */

  /*
  useEffect(() => {
    //NetworkService.getInstance().deleteAccessToken();
    updateCampaign(linkMinkReferralCode || params.campaign);
  }, [linkMinkReferralCode, params.campaign]);
  */

  useEffect(() => {
    if (plan?.amount && coupon?.amountOff) {
      setRegularPrice((plan?.amount || 495) / 100);
      setPrice((plan.amount - (coupon.amountOff ? coupon.amountOff : 0)) / 100);
    } else {
      setRegularPrice((plan?.amount || 495) / 100);
      setPrice((plan?.amount || 495) / 100);
    }
    const currency = plan?.currency || "usd";
    setCurrency(currency);
    setNumberFormatOptions({
      style: "currency",
      currency: currency,
    });
  }, [plan, coupon]);

  useEffect(() => {
    refreshPlan(props.country);
  }, [props.country]);

  const getRegularPlan = (country = "US") => {
    //removeCookie("campaign");
    //removeCookie("lmref");

    setIsLoading(true);
    return AccountService.getInstance()
      .getAvailablePlanByCountry(country)
      .then((plan) => {
        setPlan(plan);
      })
      .catch((error) => {
        console.log("error get plan by country", error);
        setError(true);
      })
      .finally((res) => setIsLoading(false));
  };

  /*
  const getCampaignPlan = (campaign, country) => {
    setIsLoading(true);
    setIsCampaignPlan(false);
    return AccountService.getInstance()
      .getCampaignPlanByCountry(campaign, country)
      .then((planBundle) => {
        if (planBundle && planBundle.plan && planBundle.plan.active) {
          setPlan(planBundle.plan);
          setCoupon(planBundle.coupon);
          setIsCampaignPlan(true);
          return Promise.resolve("");
        } else {
          return getRegularPlan(country);
        }
      })
      .catch((error) => {
        console.log("error get campaign plan by country", error);
        return getRegularPlan(country);
      })
      .finally((res) => setIsLoading(false));
  };
  */
  const refreshPlan = (country = "US", campaign) => {
    // >>>>>>>  LinkMink
    // if we have the proper campaign and linkMink referer code,
    // we send the code back
    //
    //let lmRef;

    //if (window.lm && campaign) {
    // lm.js must be initialized with your API key
    //lmRef = window.lm.getReferralData();
    // }

    // only the valid LinkMink referral gets the campaign deal
    //if (campaign && lmRef) {
    //  return getCampaignPlan(campaign, country);
    //} else {
    return getRegularPlan(country);
    //}
  };

  return isLoading ? (
    <LoadingIndicator className="loading--modal" />
  ) : (
    <SubscriptionText extra>
      {isMobileSafari || isSafari ? (
        <FormattedMessage
          id="template.sign_up_free_footer.safari"
          defaultMessage="Please register below for access to NewsGuard. We do not share your information, track your browsing habits or sell you ads."
        />
      ) : isEdge || isLegacyEdge ? (
        <FormattedMessage
          id="template.sign_up_free_footer.edge"
          defaultMessage="Create your account to get free access on Microsoft Edge.  NewsGuard does not track your browsing or use your data to target advertisements.  Learn more"
        />
      ) : isCampaignPlan ? (
        <FormattedMessage
          id="template.a1_footer"
          values={{
            strong: (...chunks) => <strong>{chunks}</strong>,
          }}
          defaultMessage="For unlimited access to NewsGuard, <strong>start your discounted membership today for only €1,10 (€0.92 plus VAT €0.18) per month.</strong>"
        />
      ) : plan.trial ? (
        <FormattedMessage
          id="template.sign_up_trial_footer"
          key={price}
          values={{
            strong: (...chunks) => <strong>{chunks}</strong>,
            price: (
              <>
                <FormattedNumber
                  {...numberFormatOptions}
                  value={price}
                  key={price}
                />
                {additionalCurrencySuffix(currency, getLanguage())}
              </>
            ),
          }}
          defaultMessage="<strong>For unlimited access to NewsGuard, sign up today for a 2-week trial membership.</strong> You may cancel at any time. After your two weeks, you’ll have access to a discounted membership at just {price}/month."
        />
      ) : (
        <FormattedMessage
          id="template.sign_up_discount_footer"
          key={price}
          values={{
            strong: (...chunks) => <strong>{chunks}</strong>,
            price: (
              <>
                <FormattedNumber
                  {...numberFormatOptions}
                  value={price}
                  key={price}
                />
                {additionalCurrencySuffix(currency, getLanguage())}
              </>
            ),
          }}
          defaultMessage="For unlimited access to NewsGuard, <strong>start your discounted membership today for just {price}/month.</strong>"
        />
      )}
      {isCampaignPlan ? "" : "*"}
    </SubscriptionText>
  );
};

export default PriceTermSignup;
