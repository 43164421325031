import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import countryList from "../../../../../../../shared/resources/_country.json";
import ConfigService from "../../service/ConfigService";
import { FormattedMessage, useIntl } from "react-intl";
import { getCountry } from "../../common/_localeMessages";
import { useSelector } from "react-redux";

function CountryForm({ user, changeCountry }) {
  const configService = ConfigService.getInstance();
  const config = configService.get();

  const intl = useIntl();
  const navigate = useNavigate();

  const authenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  const principal = useSelector((state) => state.authentication.principal);

  const [userSelectedCountry, setUserSelectedCountry] = useState(
    getCountry() || this.config.country || "US"
  );

  useEffect(() => {
    if (!!user) {
      navigate("/subscription/offer");
    }
  }, []);

  const onChange = (e) => {
    e.preventDefault();
    setUserSelectedCountry(e.target.value);
  };

  return (
    <div>
      <label htmlFor="country">
        <FormattedMessage id="template.country" defaultMessage="Country" />
      </label>
      <select
        id="country"
        name="country"
        className="form-control"
        onChange={onChange}
        value={userSelectedCountry}
        required
      >
        {countryList
          .filter((countryVal) => countryVal.available)
          .map((countryVal) => (
            <FormattedMessage
              id={"country." + countryVal.id}
              defaultMessage={countryVal.label}
              key={countryVal.value}
            >
              {(country_name) => (
                <option key={countryVal.id} value={countryVal.id}>
                  {" "}
                  {countryVal.label}{" "}
                </option>
              )}
            </FormattedMessage>
          ))}
      </select>
      <button type="button" onClick={() => changeCountry(userSelectedCountry)}>
        <FormattedMessage id="template.continue" defaultMessage="Continue" />
      </button>
    </div>
  );
}

export default CountryForm;
