import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { dateFormatter } from "../Helpers/misc";
import styled from "@emotion/styled/macro";
import { TitleSpan } from "./RecentTable";

const Container = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 10px;
  text-transform: uppercase;
  margin-right: 10px;
  padding: 4px 4px;
  max-width: 150px;

  border: 1px solid var(--primary-black);
  border-radius: 2px;
  color: #888888;
  text-decoration: none !important;

  display: flex;
  align-items: center;
  background: #dddddd;
  border-color: #d2d2d2;
  flex-shrink: 0;
  align-self: flex-start;
`;

const FlexLink = styled(Link)`
  display: flex;
`;
const mythLink = (data) => {
  return (
    <>
      <FlexLink
        to={`/partner/myth/developing/${data.id}`}
        className={`label-link ${data?.notProvablyFalse ? "disabled" : ""}`}
      >
        {data?.notProvablyFalse && (
          <Container>
            <span>Not Provably False</span>
          </Container>
        )}
        {data.identifier}
      </FlexLink>
    </>
  );
};

function RecentColumns(props) {
  const columns = useMemo(
    () => [
      {
        Header: "Date Tracked",
        accessor: "developingDateTime",
        Cell: (data) => {
          return dateFormatter(data.cell.value);
        },
        width: 275,
      },
      {
        Header: "Name",
        accessor: "identifier",
        disableSortBy: true,
        width: 730,
        className: "domain",
        Cell: (data) => {
          return (
            <>
              <TitleSpan>{mythLink(data.cell.row.original)}</TitleSpan>
            </>
          );
        },
      },
    ],
    []
  );
  return columns;
}

export default RecentColumns;
