import styled from "@emotion/styled";
import { components } from "react-select";
import SelectFilter from "../Filters/ui/SelectFilter";

const selectStyles = {
  container: (styles) => ({
    ...styles,
    flexGrow: 1,
    "@media screen and  (max-width: 600px)": {
      minWidth: "225px",
    },
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#eaeaea",
    border: "none",
    borderRadius: "none",
    // width: "756px",
    marginRight: "17px",
    marginLeft: 0,
    "@media screen and (max-width: 600px)": {
      // width: "225px",
      marginLeft: "0px",
      marginRight: "10px",
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  valueContainer: (styles) => ({
    ...styles,
  }),
};

const SelectFilterContainerStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: 12px;

  .spacer {
    width: 30px;
  }
  @media screen and (max-width: 600px) {
    min-width: 90%;
  }
`;

const DropdownIndicatorStyles = styled.div`
  color: #3355dd;
  width: 0;
  height: 0;
  border-width: 0 4px 7px 4px;
  border-color: transparent transparent #3355dd transparent;
  border-style: solid;
  transform: rotate(180deg);
  margin-right: 10px;
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorStyles />
    </components.DropdownIndicator>
  );
};

export function SortByFilter({ value, onChange, options, defaultValue }) {
  return (
    <SelectFilterContainerStyles>
      <SelectFilter
        styles={selectStyles}
        customComponent={{ DropdownIndicator }}
        options={options}
        onChange={onChange}
        value={options.filter(function (option) {
          return option.value === (value.value || defaultValue);
        })}
        id={`sortBy`}
        name={`sortBy`}
      />
    </SelectFilterContainerStyles>
  );
}

export function SortOrderFilter({
  value,
  onChange,
  options,
  defaultValue,
  isDisabled,
}) {
  return (
    <SelectFilterContainerStyles>
      <SelectFilter
        styles={selectStyles}
        customComponent={{ DropdownIndicator }}
        options={options}
        onChange={onChange}
        value={options.filter(function (option) {
          return option.value === (value.value || defaultValue);
        })}
        id={`sortOrder`}
        name={`sortOrder`}
        isDisabled={isDisabled}
      />
    </SelectFilterContainerStyles>
  );
}
