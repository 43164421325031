import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ContentService from "../../../service/ContentService";

export function useGetMediaById({ id }) {
  const {
    data: media,
    isLoading,
    status,
    error,
    isPending,
  } = useQuery({
    queryKey: ["getMediaById", id],
    queryFn: () => getMediaById(id),
    enabled: id !== undefined,
  });

  return { media, isLoading, status, error, isPending };
}

async function getMediaById(id) {
  return await ContentService.getInstance().getMedia(id);
}

export function useGetS3Media({ id, location, type, mediaToken }) {
  const { data, isLoading, status, error, isPending } = useQuery({
    queryKey: ["s3Media", id],
    queryFn: () => getS3MediaUrl(id, location, type, mediaToken),
  });

  return { data, isLoading, status, error, isPending };
}

async function getS3MediaUrl(id, location, type, mediaToken) {
  if (type.startsWith("video/") || type.startsWith("audio/")) {
    return ContentService.getInstance().getMediaStreamingS3Url(
      id,
      location,
      mediaToken
    );
  }
  return ContentService.getInstance().downloadMediaUrl(id, mediaToken);
}

export function useGetMythMediaToken(mythProfileId, mediaId) {
  const { data: mediaToken, isLoading: isMediaTokenLoading } = useQuery({
    queryKey: ["mythMediaToken", mythProfileId, mediaId],
    queryFn: () => getMythMediaToken(mythProfileId, mediaId),
    enabled: !!mythProfileId,
  });

  return { mediaToken, isMediaTokenLoading };
}

async function getMythMediaToken(mythProfileId, mediaId) {
  return ContentService.getInstance().getMythMediaToken(mythProfileId, mediaId);
}
