import styled from "@emotion/styled";

const MythMarkerStyles = styled.div(
  ({ large, searchResult }) => `
  position: relative;
  top: ${searchResult ? "-2px" : 0};
  font-size: ${large ? "14px" : "12px"};
  font-weight: 600;
  letter-spacing: 0;
  line-height: ${large ? "20px" : "15px"};
  
  text-transform: uppercase;
  height: ${large ? "auto" : "auto"};
  width: ${large ? "auto" : "auto"};
  border: ${large ? "2px solid #000000" : "1px solid #000000"};
  border-radius: ${large ? "2px" : "2px"};
  margin-right: ${large ? "15px" : "10px"};
  margin-top: ${large ? "5px" : 0};
  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
    
  }
  @media print {
    margin-top: 0;
 
  }
  padding: 2px 4px;
 flex-grow: 0;
 margin-bottom: auto; 
`
);

export default function MythMarker(props) {
  return <MythMarkerStyles large={props.large}>Report</MythMarkerStyles>;
}
