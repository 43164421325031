import { FormattedMessage } from "react-intl";
import ConfigService from "../service/ConfigService";
import OAuthButton from "../components/OAuthButtons/OAuthButtonsStyles";

const AppleSignIn = ({ loading, setLoading }) => {
  const configService = ConfigService.getInstance();
  const config = configService.get();

  const onClick = () => {
    setLoading({ ...loading, apple: true });
    const url =
      "https://appleid.apple.com/auth/authorize?" +
      "client_id=" +
      encodeURIComponent(config.appleClientId) +
      "&redirect_uri=" +
      encodeURIComponent(config.appleRedirectUri) +
      "&response_type=code" +
      "&scope=" +
      encodeURIComponent("email") +
      "&response_mode=form_post";

    window.location.href = url;
  };

  return (
    <OAuthButton
      service={"apple"}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      loading={loading["apple"]}
    >
      <FormattedMessage
        id="template.apple.signin"
        defaultMessage="Sign in with Apple"
      />
    </OAuthButton>
  );
};

export default AppleSignIn;
