import styled from "@emotion/styled";
import TOSAndPrivacyPolicy from "./TOSAndPrivacyPolicy";

const Footer = styled.footer`
  margin: auto auto 20px 0;

  padding: 0 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
`;

const Disclaimer = styled.div`
  text-transform: uppercase;
`;

const PartnerFooter = () => {
  return (
    <Footer>
      <TOSAndPrivacyPolicy partner />
      <div>&copy; {new Date().getFullYear()} NewsGuard Technologies, Inc.</div>
      <Disclaimer>Confidential and Proprietary</Disclaimer>
    </Footer>
  );
};

export default PartnerFooter;
