import { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import PrintButton from "./PrintButton";
import ContentService from "../../../service/ContentService";
import SelectDropdown from "./SelectDropdown";
import { LocaleValue } from "../../../common/Helper";

const ActionsWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;

  @media print {
    display: none;
  }
`;

const LocaleMarker = styled.div`
  border: 1px solid var(--primary-black);
  border-radius: 2px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 36px;
  font-family: "Sora", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
`;

const Actions = ({ item, el, showLocale, showPrint }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [localizedItemsTransformed, setLocalizedItemsTransformed] = useState(
    []
  );

  useEffect(() => {
    let callbackPromise = null;
    let linkRoot = "/partner/label/";
    if (item?.object === "label") {
      callbackPromise = ContentService.getInstance().getLabelLocalesByProfile(
        item.profileId
      );
    } else if (item?.object === "myth-published") {
      callbackPromise = ContentService.getInstance().getMythLocalesByProfile(
        item.mythProfileId
      );
      linkRoot = "/partner/myth/";
    } else if (item?.object === "report-published") {
      callbackPromise = ContentService.getInstance().getReportLocalesByProfile(
        item.reportProfileId
      );
      linkRoot = "/partner/report/";
    }

    if (callbackPromise) {
      callbackPromise
        .then((response) => {
          const transformedItems = [
            { text: LocaleValue(item.country, item.language), value: "" },
          ];

          response.map((i) => {
            return transformedItems.push({
              text: LocaleValue(i.country, i.language),
              value: linkRoot + i.id,
            });
          });
          setLocalizedItemsTransformed(transformedItems);
        })
        .finally((res) => setLoading(false));
    }
  }, [item]);

  const handlePrint = useReactToPrint({
    content: () => el.current,
  });

  const LocaleIcon = () => {
    return (
      <LocaleMarker>{LocaleValue(item.country, item.language)}</LocaleMarker>
    );
  };

  const LocaleSwitcher = () => {
    const handleChange = (value) => {
      navigate(value);
    };

    // if (loading) return null;

    return localizedItemsTransformed.length > 1 ? (
      <SelectDropdown
        placeholder={LocaleValue(item.country, item.language)}
        handleChange={handleChange}
      >
        {localizedItemsTransformed}
      </SelectDropdown>
    ) : (
      <LocaleIcon />
    );
  };

  return (
    <ActionsWrapper>
      {showLocale && item.locale && <LocaleSwitcher />}

      {showPrint && <PrintButton onClick={handlePrint} />}
    </ActionsWrapper>
  );
};

export default Actions;
