export const config = {
  authServiceRoot: "https://local.newsguardtech.com:9081/account-auth",
  resourceServiceRoot: "https://local.newsguardtech.com:9082/account-resource",
  nutritionServiceRoot: "http://localhost:8080",
  loginUrl: "/oauth2/authorize",
  logoutUrl: "/logout",
  revokeTokenUrl: "/oauth2/token",
  redirectUri: "https://local.newsguardtech.com/oauth2/redirect",
  clientId: "fe35a96e-1e1f-4f34-a4ed-5d9df566060c",
  //clientId: "00119a22-ab3e-4009-abe5-cfc7a74a8a22",
  clientSecret: "gLgB-LodUfR8kv.dmJZiKeRA",
  //  clientId: "77e0e686-6ed5-4e20-b78e-2241898a28f8",
  //  clientSecret: "L2.4kxrgMAWdtZcDJNqXVBTH"
  scope: "full",
  userInfoUrl: "/user",
  signupUrl: "/user/signup",
  stripeKey: "pk_test_PMdgob9Q7NtcefvUgCOP2P4400EutnppkB",
  context: "",
  specialPlan: "",
  language: "en",
  country: "US",
  //gaId: "G-EHJ7H1GB34",
  gaId: "GTM-TLQZ88R",

  // apple sign in props
  appleClientId: "newsguard.account.service",
  appleRedirectUri: "https://local.newsguardtech.com/apple/redirect",
  verifyAppleIdCodeUrl:
    "https://local.newsguardtech.com:9081/account-auth/user/apple/signin",
  env: "local",
};
