import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export const useCardLink = (partner) => {
  const navigate = useNavigate();
  const cardRef = useRef();
  const linkRef = useRef();

  useEffect(() => {
    const currentCardRef = cardRef.current;

    const handleCardClick = (e) => {
      if (!e.target.href && linkRef?.current?.href) {
        const isTextSelected = window.getSelection().toString();
        if (!isTextSelected) {
          const url = new URL(linkRef.current.href);
          navigate(url.pathname);
        }
      }
    };

    if (currentCardRef?.addEventListener) {
      currentCardRef.addEventListener("click", handleCardClick);
    }

    return () => {
      if (currentCardRef?.removeEventListener) {
        currentCardRef.removeEventListener("click", handleCardClick);
      }
    };
  }, [cardRef, linkRef, navigate]);

  return [cardRef, linkRef];
};
