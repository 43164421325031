import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import styled from "@emotion/styled/macro";
import { ButtonBlack } from "../components/FormElements/Button";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Title } from "../components/Subscription/SignupStyles";
import PriceTermPitch from "../components/PriceTermPitch";
import {
  additionalCurrencySuffix,
  getLanguage,
} from "../common/_localeMessages";
import ConfigService from "../service/ConfigService";
import AccountService from "../service/AccountService";
import LoadingIndicator from "../common/LoadingIndicator";

const HeroWrapper = styled.section`
  background-color: var(--primary-blue);
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  min-height: 340px;
  display: flex;
`;

const Hero = styled.section`
  color: var(--primary-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0 60px 0;
  width: fit-content;
  max-width: 960px;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 60px;
`;

const Header2 = styled.h2`
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
`;

const HeroHeader = styled(Header2)`
  font-weight: 600;
`;

const CTA = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Text = styled.p`
  font-size: var(--16px);
  line-height: 30px;
  margin-bottom: 30px;
`;

const ButtonBlackPitch = styled(ButtonBlack)`
  text-transform: none;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 0px 0;
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 20px;
`;

const PitchPage = (props) => {
  const [searchParams] = useSearchParams();
  //const linkMinkReferralCode = searchParams.get("lmref"); /// linkmink refererrer id
  //const params = useParams();
  //const [cookies, setCookie, removeCookie] = useCookies(["campaign"]);
  //const location = useLocation();
  //const config = ConfigService.getInstance().get();

  const [price, setPrice] = useState(4.95);
  //const [discountedPrice, setDiscountedPrice] = useState(1.1);
  const [currency, setCurrency] = useState("eur");
  const [numberFormatOptions, setNumberFormatOptions] = useState({
    style: "currency",
    currency: "eur",
  });

  const [isLoading, setIsLoading] = useState(false);
  //const campaign = linkMinkReferralCode || params.campaign || cookies?.campaign;

  const [plan, setPlan] = useState({});
  const [regularPrice, setRegularPrice] = useState(4.95);
  const [coupon, setCoupon] = useState({});
  const [error, setError] = useState(false);

  /*
  const updateCampaign = (code = "") => {
    if (code) {
      setCookie("campaign", code, {
        path: "/",
      });
    }
  };
  */
  /*
  useEffect(() => {
    //NetworkService.getInstance().deleteAccessToken();
    updateCampaign(linkMinkReferralCode || params.campaign);
  }, [linkMinkReferralCode, params.campaign]);
  */

  useEffect(() => {
    if (plan?.amount && coupon?.amountOff) {
      setRegularPrice((plan?.amount || 495) / 100);
      setPrice((plan.amount - (coupon.amountOff ? coupon.amountOff : 0)) / 100);
    } else {
      setRegularPrice((plan?.amount || 495) / 100);
      setPrice((plan?.amount || 495) / 100);
    }
    const currency = plan?.currency || "usd";
    setCurrency(currency);
    setNumberFormatOptions({
      style: "currency",
      currency: currency,
    });
  }, [plan, coupon]);

  useEffect(() => {
    refreshPlan(props.country);
  }, [props.country]);

  const getRegularPlan = (country = "US") => {
    //removeCookie("campaign");
    //removeCookie("lmref");

    setIsLoading(true);
    return AccountService.getInstance()
      .getAvailablePlanByCountry(country)
      .then((plan) => {
        console.log(plan);
        setPlan(plan);
      })
      .catch((error) => {
        console.log("error get plan by country", error);
        setError(true);
      })
      .finally((res) => setIsLoading(false));
  };

  /*
  const getCampaignPlan = (campaign, country) => {
    setIsLoading(true);

    return AccountService.getInstance()
      .getCampaignPlanByCountry(campaign, country)
      .then((planBundle) => {
        if (planBundle && planBundle.plan && planBundle.plan.active) {
          setPlan(planBundle.plan);
          setCoupon(planBundle.coupon);
          return Promise.resolve("");
        } else {
          return getRegularPlan(country);
        }
      })
      .catch((error) => {
        return getRegularPlan(country);
      })
      .finally((res) => setIsLoading(false));
  };
  */

  const refreshPlan = (country = "US", campaign) => {
    // >>>>>>>  LinkMink
    // if we have the proper campaign and linkMink referer code,
    // we send the code back
    //
    /*
    let lmRef;

    if (window.lm && campaign) {
      // lm.js must be initialized with your API key
      lmRef = window.lm.getReferralData();
    }
    */

    // only the valid LinkMink referral gets the campaign deal
    //if (campaign && lmRef) {
    //  return getCampaignPlan(campaign, country);
    //} else {
    return getRegularPlan(country);
    //}
  };

  return isLoading ? (
    <LoadingIndicator className="loading--modal" />
  ) : (
    <>
      <HeroWrapper>
        <Hero>
          <HeroHeader>
            <FormattedMessage
              id="template.sign_up_trial_header"
              defaultMessage="Get Full Access to NewsGuard With a Free Trial Membership"
            />
          </HeroHeader>
          <Header2>
            <FormattedMessage
              id="template.signup.head.msg"
              defaultMessage="Sign up today to get this exclusive offer while it's still available."
            />
          </Header2>
        </Hero>
      </HeroWrapper>
      <PriceTermPitch
        plan={plan}
        regularPrice={regularPrice}
        price={price}
        currency={currency}
        numberFormatOptions={numberFormatOptions}
      />
      <Section>
        <Title update={true}>
          <FormattedMessage
            id="template.howItWorks"
            defaultMessage="How NewsGuard Works"
          />
        </Title>
        <Text>
          <FormattedMessage
            id="template.howItWorksDescription"
            defaultMessage="As you browse the internet, NewsGuard displays blue rating icons next to links in your search results and social media feeds. Hover your mouse over each icon for a short description of the site and click “See the Full Nutrition Label” for a detailed description of the site and why it received its rating."
          />
        </Text>
        <a
          href="https://www.newsguardtech.com/newsguard-faq/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {getLanguage() === "en" && (
            <ButtonBlackPitch>NewsGuard FAQ</ButtonBlackPitch>
          )}
        </a>
      </Section>
    </>
  );
};

export default PitchPage;
