import styled from "@emotion/styled/macro";

const FilterToggleButtonContainerStyles = styled.div`
  position: sticky;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 21px;
  top: 140px;
  padding-right: 2px;
  flex-shrink: 0;

  @media screen and (max-width: 600px) {
    position: sticky;
    top: ${(p) => (p.filters > 0 ? "80px" : "80px")};
    margin-top: 0;
    padding: 0 1px 20px;
    justify-content: flex-start;
    z-index: 1;
  }
`;

const FilterToggleButtonStyles = styled.button`
  height: 40px;
  font-weight: 500;

  color: ${(props) => (props.hasFilters ? "#fff" : "#000")};
  background-color: ${(props) => (props.hasFilters ? "#000" : "#fff")};
  min-width: ${(props) => (props.isSort ? "100px" : "122px")};
  max-width: 122px;
  border: 1px solid #000000;
  border-radius: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;

  svg {
    flex-shrink: 0;
    transform: ${(props) =>
      props.isFiltersOpen ? `rotate(180deg)` : `rotate(0deg)`};
    transition: transform 0.25s ease-in-out;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    text-align: left;
    background-position-x: 100%;
  }

  cursor: pointer;
  box-shadow: ${(props) =>
    props.type === "basic" ? "none" : "0px 2px 4px rgba(0, 0, 0, 0.25)"};
`;

const SortToggleButtonStyles = styled(FilterToggleButtonStyles)`
  min-width: 100px;
  max-width: 100px;
  padding: 0 10px;
  gap: 0;

  & > span {
    margin-right: 10px;
  }
`;

const FilterButtonContainerStyles = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 20px;
  gap: 16px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    padding: 10px 0 5px;
  }

  button {
    height: 40px;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

const ClearButtonStyles = styled.button`
  width: 108px;
  border: 1px solid #000000;
  border-radius: 2px;
  background-color: #fff;
  white-space: nowrap;

  @media screen and (max-width: 600px) {
    min-width: 100px;
    span {
      display: none;
    }
  }
`;

const SearchButton = styled.button`
  color: ${({ hasFiltersSelected }) =>
    hasFiltersSelected ? "#fff" : "rgba(0,0,0,0.4)"};
  background-color: ${({ hasFiltersSelected }) =>
    hasFiltersSelected ? "#3355dd" : "rgba(0,0,0,0.08)"};
  width: 100%;
  border-radius: 2px;
  border-color: transparent;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
  cursor: ${(isWidget) => (isWidget ? "default" : "pointer")};
`;

export {
  FilterToggleButtonContainerStyles,
  FilterToggleButtonStyles,
  SortToggleButtonStyles,
  FilterButtonContainerStyles,
  ClearButtonStyles,
  SearchButton,
};
