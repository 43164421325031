import { useState } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import SiteModule from "../common/SiteModule";

import FollowingButton from "./FollowingButton";
import LoadingIcon from "../common/LoadingIcon";
import Pagination from "../common/Pagination";
import TVIcon from "../common/svg/TVIcon";
import PodcastIcon from "../common/svg/PodcastIcon";
import { useFollowing } from "./useFollowing";

export const FollowingList = styled.ul`
  font-size: 18px;
  line-height: 22px;
  list-style: none;
  padding: 0 10px;

  > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
      margin-bottom: 0;
    }
  }
`;

const LabelLinkStyle = styled(Link)`
  font-size: 18px;
  line-height: 22px;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const NoFollowContent = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 30px;

  img {
    display: inline-block;
    margin: 10px 0 5px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
    line-height: 26px;
  }
`;

const NoFollow = () => {
  return (
    <NoFollowContent>
      <p>You&rsquo;re not following any websites yet.</p>
      <p>Just click follow at the top of any label page.</p>
      <img
        src="/images/follow.png"
        alt="Follow button at top of label page"
        height="140"
        width="140"
      />
    </NoFollowContent>
  );
};

const FollowingPagedModule = ({ provided, handleOpen, isOpen, id }) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [count, setCount] = useState(0);
  const pageSize = 10;

  const { followings, totalPages, totalElements, isPreviousData } =
    useFollowing(pageIndex, pageSize);

  return (
    <SiteModule
      name="Following"
      id={id}
      listHeaders={["name", "status"]}
      count={count || totalElements}
      drag={true}
      handle={{ ...provided.dragHandleProps }}
      handleToggle={handleOpen}
      isOpen={isOpen}
      noContent={!(followings && followings.length > 0)}
      type="followSettings"
    >
      {isPreviousData ? (
        <LoadingIcon />
      ) : (
        <FollowingList>
          {followings.length === 0 ? <NoFollow /> : null}
          {followings.map((following) => (
            <li key={following.id.profileId}>
              <LabelLinkStyle to={`/partner/profile/${following.id.profileId}`}>
                {following.network === "TV" ? (
                  <TVIcon height="21px" width="31px" />
                ) : following.network === "PODCAST" ? (
                  <PodcastIcon height="22px" width="22px" />
                ) : (
                  ""
                )}
                <span>
                  {following.identifier} ({following.locale})
                </span>
              </LabelLinkStyle>
              <FollowingButton
                following={true}
                display
                id={following.id.profileId}
              >
                Following
              </FollowingButton>
            </li>
          ))}
        </FollowingList>
      )}
      <Pagination
        totalPages={totalPages}
        currentPage={pageIndex}
        getPage={(i) => {
          setPageIndex(i);
        }}
        getNextPage={() => {
          setPageIndex(pageIndex + 1);
        }}
        getPreviousPage={() => {
          setPageIndex(pageIndex - 1);
        }}
      />
    </SiteModule>
  );
};

export default FollowingPagedModule;
