import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import {
  dateFormatter,
  formatLabelHistoryNote,
  scoreFormatter,
} from "../Helpers/misc";
import TVIcon from "../common/svg/TVIcon";
import PodcastIcon from "../common/svg/PodcastIcon";
import styled from "@emotion/styled/macro";

const labelLink = (data) => {
  return (
    <Link to={`/partner/label/${data.labelId}`} className="label-link">
      {data.identifier}
    </Link>
  );
};

const LabelName = styled.span`
  display: flex;
  align-items: ${(props) => (props.TV ? "center" : "flex-start")};

  @media screen and (max-width: 600px) {
    align-items: flex-start;
  }
`;

function RecentUpdateColumns(props) {
  // console.log(props);
  const columns = useMemo(
    () => [
      {
        Header: "Updated",
        accessor: "createdDate",
        Cell: (data) => {
          return dateFormatter(data.cell.value);
        },
        width: 175,
      },
      {
        Header: "Rating",
        accessor: "newScore",
        width: 120,
        Cell: (data) => {
          return scoreFormatter(
            data.cell.row.original.oldRank,
            data.cell.row.original.oldScore,
            data.cell.row.original.newRank,
            data.cell.row.original.newScore,
            data.cell.row.original.network,
            data.cell.row.original.publisherType
          );
        },
      },
      {
        Header: "Name",
        accessor: "identifier",
        disableSortBy: true,
        width: 380,
        className: "domain",
        Cell: (data) => {
          return (
            <LabelName TV={data.cell.row.original.network === "TV"}>
              {data.cell.row.original.network === "TV" ? (
                <TVIcon height="21px" width="31px" />
              ) : data.cell.row.original.network === "PODCAST" ? (
                <PodcastIcon height="21px" width="100%" marginRight={"5px"} />
              ) : (
                ""
              )}
              {labelLink(data.cell.row.original)}
            </LabelName>
          );
        },
      },
      {
        Header: "Note",
        accessor: "note",
        disableSortBy: true,
        Cell: (data) => {
          return formatLabelHistoryNote(data.cell.row.original, props.current);
        },
        width: 340,
        minWidth: 340,
      },

      {
        Header: () => <div className="region">Locale</div>,
        accessor: "country",
        Cell: (data) => {
          return <div className="region">{data.cell.row.original.locale}</div>;
        },
        disableSortBy: true,
        width: 120,
      },
    ],
    []
  );
  return columns;
}

export default RecentUpdateColumns;
