import { useQuery } from "@tanstack/react-query";
import ContentService from "../../../service/ContentService";

const contentService = ContentService.getInstance();

export function useLabels(labelId, profileId) {
  const {
    data: label,
    status,
    error,
    isFetching,
    isLoading: isLabelLoading,
    isSuccess,
    isError,
    isLoadingError,
    isRefetchError,
  } = useQuery({
    queryKey: ["label", labelId, profileId],
    queryFn: () => getData(),
    refetchOnWindowFocus: true,
    cacheTime: 2 * 60 * 1000, // 2 minutes
    staleTime: 2 * 60 * 1000, // 2 minutes
    retry: 3,
  });

  const getData = async () => {
    let id = labelId;
    let getLabelPromise = contentService.getLabel;
    if (profileId) {
      id = profileId;
      getLabelPromise = contentService.getLabelByProfile;
    }

    const label = await getLabelPromise(id);

    return label;
  };

  return {
    label,
    status,
    error,
    isFetching,
    isLabelLoading,
    isSuccess,
    isError,
    isLoadingError,
    isRefetchError,
  };
}

export function useLoadSecondaryTabData(label) {
  const { data: secondaryData, isLoading: isTabLoading } = useQuery({
    queryKey: ["secondaryData", label],
    queryFn: () => loadSecondaryTabData(label),
    enabled: !!label,
  });

  async function loadSecondaryTabData(label) {
    return Promise.all([
      contentService.getAssociatedDomains(label.profileId),
      contentService.getConnectedMedia(label.id),
      contentService.getLabelMythExampleLinks(label.profileId),
    ]);
  }

  return {
    secondaryData,
    isTabLoading,
  };
}

export function useLoadParentLabels(label) {
  const { data: parents } = useQuery({
    queryKey: ["parentQuery", label],
    queryFn: () => getParents(label.id),
    enabled: !!label,
  });

  async function getParents(labelId) {
    return await contentService.getParentLabels(labelId);
  }

  return {
    parents,
  };
}

export function useLoadChildLabels(label) {
  async function getChildren(labelId) {
    return await contentService.getChildLabels(labelId);
  }

  const { data: children, isLoading: isChildLoading } = useQuery({
    queryKey: ["childrenQuery", label],
    queryFn: () => getChildren(label.id),
    enabled: !!label,
  });

  return {
    children,
    isChildLoading,
  };
}

export function useLoadLabelHistory(label) {
  const { data: labelHistory, isLoading: isLabelHistoryLoading } = useQuery({
    queryKey: [`labelHistory_${label?.profileId}`],
    queryFn: () => loadLabelHistory(label),
    enabled: !!label,
  });

  async function loadLabelHistory(label) {
    return await ContentService.getInstance().getProfileLabelHistory(
      label.profileId
    );
  }

  return {
    labelHistory,
    isLabelHistoryLoading,
  };
}
