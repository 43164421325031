import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import useScrollOnParams from "./useScrollOnParams";
import SettingsToggle from "../common/SettingsToggle";
import MythCategoryChooser from "./MythCategoryChooser";
import RadioButton from "../common/ui/RadioButton";

import {
  ChooserToggle,
  FieldsetStyle,
  Section,
  SectionDescription,
  SectionTitles,
  SubcontentWrapper,
} from "./SettingsStyles";
import { AccountDetailsHeader } from "../../Account/AccountStyles";

const MythSettings = ({
  mythAlertOn,
  mythTrigger,
  mythCategories,
  mythFrequency,
  handleChange,
  isLoading,
  handleVisible,
  handleToggle,
}) => {
  const [initialMythTrigger] = useState(mythAlertOn);

  const [localMythOn, setLocalMythOn] = useState(mythAlertOn);
  const [mythCategoriesLocal, setMythCategoriesLocal] =
    useState(mythCategories);

  const [showCategories, setShowCategories] = useState(false);

  const [formRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "-90px 0px 0px 0px",
  });

  const divRef = useRef(null);
  useScrollOnParams(divRef, "type", "mythSettings", 180);

  useEffect(() => {
    if (entry) {
      handleVisible(entry);
    }
  }, [entry, formRef, handleVisible]);

  function handleRadioChange() {
    handleChange(formRef.current, mythCategoriesLocal);
  }

  function handleCategoryChange(e) {
    const categories = e.target.value;
    setMythCategoriesLocal(categories);
    handleChange(formRef.current, categories);
  }

  function toggleSettings() {
    handleToggle(
      "mythSettings",
      !localMythOn,
      formRef.current,
      mythCategoriesLocal
    );
    setLocalMythOn((localMyth) => !localMyth);
  }

  return (
    <div ref={divRef}>
      <form name="mythSettings" ref={formRef}>
        <input
          hidden={true}
          name="alertOn"
          value={localMythOn}
          onChange={handleChange} // so that react doesn't complain about uncontrolled inputs
        />
        <SettingsToggle
          callback={toggleSettings}
          initialValue={initialMythTrigger}
          isLoading={isLoading}
        >
          <Section>
            <AccountDetailsHeader>
              False Narratives Email Alerts
            </AccountDetailsHeader>

            <SectionDescription>
              Use the settings below to receive alerts when new false narratives
              are added to the dashboard.
            </SectionDescription>

            <SettingsToggle.Controls>
              <FieldsetStyle>
                <SectionTitles>False Narratives Alerts</SectionTitles>

                <RadioButton
                  checked={mythTrigger === "ANY"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="ANY"
                  label="Receive a notification when any false narrative is published"
                  onClick={() => setShowCategories(false)}
                />
                <RadioButton
                  checked={mythTrigger === "CATEGORY"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="CATEGORY"
                  label="Receive a notification only for specific false narrative
                categories"
                  onClick={() =>
                    setShowCategories((showCategories) => !showCategories)
                  }
                >
                  <ChooserToggle role="button">
                    <div>
                      {showCategories
                        ? "Hide false narrative categories"
                        : "View and choose false narrative categories"}
                    </div>
                  </ChooserToggle>
                </RadioButton>

                <SubcontentWrapper>
                  {showCategories && (
                    <MythCategoryChooser
                      mythSettings={mythCategories}
                      handleChange={handleCategoryChange}
                    />
                  )}
                </SubcontentWrapper>
              </FieldsetStyle>
              <FieldsetStyle>
                <SectionTitles>
                  False Narratives Notification Frequency
                </SectionTitles>
                <RadioButton
                  checked={mythFrequency === "WEEKLY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="WEEKLY"
                  label="Notify me with a Weekly Email Digest"
                />
                <RadioButton
                  checked={mythFrequency === "DAILY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="DAILY"
                  label="Notify me with a Daily Email Digest"
                />
              </FieldsetStyle>
            </SettingsToggle.Controls>
          </Section>
        </SettingsToggle>
      </form>
    </div>
  );
};

export default MythSettings;
