import { useEffect, useState } from "react";
import AuthService from "../../service/AuthService";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import LoadingIndicator from "../../common/LoadingIndicator";
import AccountService from "../../service/AccountService";
import { Fieldset } from "../Subscription/SignupStyles";
import {
  AccountDetails,
  AccountDetailsHeader,
  AccountExtraDetails,
  AccountSectionHeader,
  RedeemPageWrapper,
} from "../Account/AccountStyles";
import InputModule from "../FormElements/InputModule";
import { ButtonBlack } from "../FormElements/Button";
import ErrorMessage from "../FormElements/ErrorMessage";

const Activation = ({ elementRef, account }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [html5error, setHtml5error] = useState({});
  const [validated, setValidated] = useState(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);

  const authenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  const principal = useSelector((state) => state.authentication.principal);

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const intl = useIntl();

  const authService = AuthService.getInstance();
  const accountService = AccountService.getInstance();

  useEffect(() => {
    let code = searchParams.get("code");
    if (code) {
      setCode(code);
      redeemVoucherCode(code);
    }
  }, []);

  useEffect(() => {
    setIsRegisteredUser(authService.isRegisteredUser(principal));
  }, [principal]);

  const redeemVoucherCode = (code) => {
    authService
      .activateVoucherCode(code)
      .then((response) => {
        setInvalid(false);

        if (isRegisteredUser) {
          authService.clearUserVoucherAccessCache(principal.id);
          navigate("/account/redeemed");
        } else {
          return accountService.retrieveUserInfoFromServer().then((res) => {
            navigate("/redeemed");
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // 403 - over the limit
        // 404 - voucher invalid
        // 409 - already redeemed
        if (error.status === 409) {
          if (isRegisteredUser) {
            navigate("/account/redeemed");
          } else {
            navigate("/redeemed");
          }
        }
        setError({
          response: {
            code: "voucher.redeem.error",
            value: "mailto",
          },
        });
      })
      .finally((res) => setLoading(false));
  };

  const handleInputChange = (event) => {
    event.target.setCustomValidity(""); // have to reset custom validity else field never returns to valid
    const target = event.target;
    const inputName = target.name;
    let inputValue = target.value;

    setError(null);

    setHtml5error((prevState) => ({
      ...prevState,
      [inputName]: "",
    }));

    if (inputValue) {
      const trimedInputValue = inputValue.trim();
      if (trimedInputValue && trimedInputValue !== "") {
        inputValue = trimedInputValue;
      }
    }

    setCode(inputValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setHtml5error({});

    if (validate(event)) {
      redeemVoucherCode(code);
    } else {
      setLoading(false);
    }
  };

  const checkIsValid = (e) => {
    if (e.target.checkValidity() === false) {
      e.target.parentNode.classList.add("was-validated");
    }
  };

  const validate = (event) => {
    setValidated(true);

    return event.target.checkValidity();
  };

  return (
    <RedeemPageWrapper ref={elementRef} account={account} id="redeem">
      {account ? (
        <AccountSectionHeader>
          {/* <FormattedMessage
           id="template.voucher.enter_access_code"
           defaultMessage="Enter an Access Code"
           /> */}
          <FormattedMessage
            id="template.voucher.redeem.code"
            defaultMessage="Redeem Access Code"
          />
        </AccountSectionHeader>
      ) : (
        <AccountDetailsHeader>Access Code</AccountDetailsHeader>
      )}

      <AccountDetails>
        <FormattedMessage
          id="template.voucher.enter_access_code_description"
          defaultMessage="If you have an Access Code for NewsGuard or HealthGuard through your school, business, or other institution, please enter it below to get access to NewsGuard or HealthGuard on this device. If you use NewsGuard on other computers or mobile devices, you'll need to enter your Access Code on each device separately."
        />
      </AccountDetails>

      <form
        className={`form form__signin  ${validated ? "was-validated" : ""} ${
          isRegisteredUser ? "form__signin--redeem" : ""
        }`}
        onSubmit={handleSubmit}
        disabled={loading}
        aria-busy={loading}
        noValidate
      >
        <Fieldset>
          {/* {success && success_msg ? (
           <div className="form__group">
           <FormattedMessage
           id={success_msg}
           defaultMessage="Code is Redeemed!"
           />
           </div>
           ) : (
           ""
           )} */}

          <InputModule
            identifier="code"
            name="code"
            type="text"
            label="Access Code"
            value={code}
            onChange={handleInputChange}
            onBlur={checkIsValid}
            error={html5error.code}
            required
            placeholder={intl.formatMessage({
              id: "template.voucher.access_code",
              defaultMessage: "Access Code",
            })}
            onInvalid={() => {
              let code = {
                id: "message.voucher.field.empty",
                defaultMessage:
                  "We're sorry, but the Access Code you entered is invalid.",
                name: "code",
                html: true,
              };

              setHtml5error((prevState) => ({
                ...prevState,
                code,
              }));
            }}
            medium={true}
          />
          {error && <ErrorMessage error={error} longMessage={true} />}

          {success || invalid ? (
            ""
          ) : (
            <div className="form__group">
              <ButtonBlack
                disabled={success || invalid || loading}
                type="submit"
              >
                {loading ? (
                  <LoadingIndicator button />
                ) : (
                  <FormattedMessage
                    id="template.voucher.redeem"
                    defaultMessage="Redeem"
                  />
                )}
              </ButtonBlack>
            </div>
          )}

          {authenticated ? (
            <AccountExtraDetails>
              <FormattedMessage
                id="template.voucher.have.account.loggedin"
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                }}
                defaultMessage="<strong>Please note:</strong> We'll put your NewsGuard subscription on pause if you have one."
              />
            </AccountExtraDetails>
          ) : (
            <AccountExtraDetails>
              <FormattedMessage
                id="template.voucher.have.account"
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                  accesscodelink: (...chunks) => (
                    <a href="/account/redeem" className="link">
                      {chunks}
                    </a>
                  ),
                  mailto: (...chunks) => (
                    <a href="mailto:support@newsguardtech.com" className="link">
                      {chunks}
                    </a>
                  ),
                  b: (...chunks) => <strong>{chunks}</strong>,
                }}
                defaultMessage="If you have previously created a NewsGuard account, you can sign in using your existing username and password and redeem your Access Code from <accesscodelink>your account page</accesscodelink>. <strong>Please note:</strong> If you currently have a paid NewsGuard subscription, we will put that on pause until your Access Code expires. Feel free to contact <mailto>support@newsguardtech.com</mailto> if you have any questions or concerns."
              />
            </AccountExtraDetails>
          )}
        </Fieldset>
      </form>
      {!isRegisteredUser && (
        <>
          <Link
            to="/signin"
            className="link link--main link--center link--dimiss-action"
          >
            {" "}
            <FormattedMessage
              id="template.maybe_later"
              defaultMessage="Maybe Later"
            />
          </Link>
        </>
      )}
    </RedeemPageWrapper>
  );
};

export default Activation;
