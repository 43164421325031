import MythPublishedSearchResult from "./MythPublishedSearchResult";

export default function MythSearchResult({ mythPublished, index }) {
  const labels = mythPublished.labels;
  const localeLabels = labels.map((label) => {
    return {
      id: label.id,
      locale: label.locale,
      country: label.country,
      language: label.language,
      mythWorkId: label.mythWorkId,
    };
  });

  return (
    <MythPublishedSearchResult
      mythPublished={mythPublished}
      index={index}
      localeLabels={localeLabels}
    />
  );
}
