import styled from "@emotion/styled/macro";
import AddSubtractSVG from "../../../common/AddSubtractSVG";

const AddFilterStyle = styled.button`
  height: 26px;
  width: 26px;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: ${(props) => (props.name ? "none" : "auto")};
  @media screen and (max-width: 600px) {
    position: absolute;
    right: 5px;
    display: block;
    top: 7px;
    font-variation-settings: "wght" 200;
  }
`;

export default function AddFilterButton(props) {
  return (
    <AddFilterStyle onClick={props.onClick} name={props.name} type="button">
      <AddSubtractSVG isSubtract={props.subtract} small />
    </AddFilterStyle>
  );
}
