import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AccountButton } from "../FormElements/Button";
import {
  AccountDetails,
  AccountSection,
  AccountSectionHeader,
  AccountSectionWrapper,
} from "./AccountStyles";

const PaymentHistoryModule = ({ elementRef }) => {
  const navigate = useNavigate();
  return (
    <div ref={elementRef} id="billing-history">
      <AccountSectionHeader>
        {" "}
        <FormattedMessage
          id="template.payment_history"
          defaultMessage="Payment History"
        />
      </AccountSectionHeader>
      <AccountSection>
        <AccountSectionWrapper noHeader>
          <AccountButton onClick={() => navigate("/account/paymenthistory")}>
            {/* <FormattedMessage id="template.change" defaultMessage="Change" /> */}
            <FormattedMessage
              id="template.viewHistory"
              defaultMessage={"View History"}
            />
          </AccountButton>
        </AccountSectionWrapper>
        <AccountDetails>
          <FormattedMessage
            id="template.viewPaymentDetails"
            defaultMessage={
              "View details of your NewsGuard subscription payments."
            }
          />
        </AccountDetails>
      </AccountSection>
    </div>
  );
};

export default PaymentHistoryModule;
