import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
`;

const Container = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: uppercase;
  margin-right: 10px;
  padding: 2px 4px;
  max-width: 81px;

  border: 1px solid var(--primary-black);
  border-radius: 2px;
  color: var(--primary-black);
  text-decoration: none !important;
  ${(props) => sizes[props.size]}
  ${(props) => colors[props.backgroundColor]}
  ${(props) => colors[props.color]}
`;

const sizes = {
  large: css`
    font-size: 0.875rem;
    max-width: revert;
    padding: 9.5px 10px;
    font-weight: 600;
  `,
  small: css`
    font-size: 0.6rem;
    width: 100%;
    max-width: 63px;
    padding: 2.5px 3px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  `,
};

const colors = {
  FL: css`
    background-color: var(--primary-white);
    color: var(--primary-black);
  `,
  L: css`
    background-color: var(--primary-white);
    color: var(--primary-black);
  `,
  P: css`
    background-color: var(--primary-white);
    color: var(--primary-black);
  `,
  S: css`
    /* background-color: rgba(255 145 0 / 60%); */
    background-color: var(--primary-white);
    color: var(--primary-black);
  `,
};

export default function LabelTypeBadge({ rating, type, size, publisherType }) {
  if (publisherType === "TV_NETWORK" && rating === "P") {
    rating = "TVN";
  }
  const blurb = {
    FL: `Flagged`,
    AI: `Flagged`,
    L: `Low News ${type === "full" ? "Content" : ""}`,
    P: `Platform`,
    S: `Satire`,
    TVN: `Network`,
  };

  return (
    <Wrapper>
      <Container size={size} backgroundColor={rating} color={rating}>
        <span>{blurb[rating]}</span>
      </Container>
    </Wrapper>
  );
}
