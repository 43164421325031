import { useQuery } from "@tanstack/react-query";
import AccountService from "../../../service/AccountService";

export function useUserProfile() {
  const {
    data: userProfile,
    error,
    status,
    isLoading,
  } = useQuery({
    queryKey: ["emailPreferences"],
    queryFn: AccountService.getInstance().getUserProfile,
  });

  return { userProfile, error, status, isLoading };
}
