import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled/macro";
import { useParams } from "react-router-dom";
import { useGetMythMediaToken } from "../common/useMedia";
import { useGetMediaById } from "../common/useMedia";
import { useMyths } from "./useMyths";
import Media from "../Media";
import LoadingIcon from "../common/LoadingIcon";
import AuthService from "../../../service/AuthService";
import { MythHeader, MythHeaderWrapper, PageWrapper } from "./MythStyles";
import MythMarker from "../common/MythMarker";
import Unauthorized from "../Unauthorized/Unauthorized";
import LoadingIndicator from "../../../common/LoadingIndicator";

const MythMediaSection = styled.div`
  border-top: 6px solid rgba(0, 0, 0, 0.07);
  margin-top: 87px;
  padding-top: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export default function MythMedia() {
  const principal = useSelector((state) => state.authentication?.principal);
  const mythId = useParams().mythId;
  const profileId = useParams().profileId;
  const mediaId = useParams().mediaId;

  const { myth, isLoading } = useMyths(profileId, mythId);

  const { media, isPending, error } = useGetMediaById({
    id: mediaId,
  });

  const { mediaToken, isMediaTokenLoading } = useGetMythMediaToken(
    myth?.mythProfileId,
    mediaId
  );

  const headerText = myth?.developing
    ? myth?.identifier
    : myth?.writeup?.name?.body;

  return isLoading || isMediaTokenLoading ? (
    <LoadingIcon />
  ) : AuthService.getInstance().hasMythAccess(principal) ||
    AuthService.getInstance().hasRecentMythOnlyAccess(principal) ? (
    <PageWrapper>
      <MythHeaderWrapper>
        <MythMarker large developing={myth.developing} />
        <MythHeader dangerouslySetInnerHTML={{ __html: headerText }} />
      </MythHeaderWrapper>

      <MythMediaSection>
        {isPending ? (
          <LoadingIndicator />
        ) : (
          <Media
            mythId={mythId}
            profileId={profileId}
            mediaId={mediaId}
            media={media}
            mediaToken={mediaToken}
          />
        )}
      </MythMediaSection>
    </PageWrapper>
  ) : (
    <Unauthorized myth={true} />
  );
}
