import styled from "@emotion/styled/macro";
import { useLocation } from "react-router-dom";
import { isEdge, isLegacyEdge } from "react-device-detect";
import Step1 from "../../common/svg/Step1.js";
import Step2 from "../../common/svg/Step2.js";
import Step3 from "../../common/svg/Step3.js";
import Step2Edge from "../../common/svg/Step2Edge.js";

const SubscriptionFlowMenuStyle = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 45px;
  list-style: none;
  padding: 0 10px;

  @media screen and (max-width: 600px) {
    gap: 30px;
  }
`;

const SubscriptionFlowMenuItem = styled.li`
  height: 50px;
  width: 50px;
  position: relative;
  &:hover {
  }

  &:not(:last-of-type) {
    position: relative;

    &:after {
      content: "";
      display: block;
      background-size: 100%;
      background-color: rgba(0, 0, 0, 0.08);
      mask-size: cover;
      position: absolute;
      left: 58px;
      top: 50%;
      width: 29px;
      height: 2px;

      @media screen and (max-width: 600px) {
        left: 39px;
        width: 22px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    height: 35px;
    width: 35px;
  }
`;

const SubscriptionFlowMenu = () => {
  const location = useLocation();
  const { pathname } = location;
  let step;
  if (pathname.includes("signup")) {
    step = 1;
  }
  if (pathname.includes("offer")) {
    step = 2;
  }

  if (pathname.includes("preview") || pathname.includes("complete")) {
    step = 3;
  }

  return (
    <SubscriptionFlowMenuStyle>
      <SubscriptionFlowMenuItem>
        <Step1 active={step === 1} />
      </SubscriptionFlowMenuItem>
      <SubscriptionFlowMenuItem>
        {isEdge || isLegacyEdge ? (
          <Step2Edge active={step === 2} />
        ) : (
          <Step2 active={step === 2} />
        )}
      </SubscriptionFlowMenuItem>
      <SubscriptionFlowMenuItem>
        <Step3 active={step === 3} />
      </SubscriptionFlowMenuItem>
    </SubscriptionFlowMenuStyle>
  );
};

export default SubscriptionFlowMenu;
