import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ContentService from "../../../service/ContentService";

export function useFollowingToggle(id, labelId, following) {
  const queryClient = useQueryClient();

  const { isLoading, mutate: toggleFollowing } = useMutation({
    mutationFn: () => toggleFollowingButton(id, labelId, following),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["followingStatus", id, labelId],
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return {
    toggleFollowing,
    isLoading,
  };
}

async function follow(id, labelId) {
  if (id) {
    return await ContentService.getInstance().follow(id);
  } else if (labelId) {
    return await ContentService.getInstance().followLabel(labelId);
  }
}

async function unFollow(id, labelId) {
  if (id) {
    return await ContentService.getInstance().unfollow(id);
  } else if (labelId) {
    return await ContentService.getInstance().unfollowLabel(labelId);
  }
}

export function useFollowingStatus(id, labelId) {
  const { data, isLoading, status, isFetching } = useQuery({
    queryKey: ["followingStatus", id, labelId],
    queryFn: () => getFollowStatus(id, labelId),

    refetchOnWindowFocus: true,

    staleTime: 60 * 1000, // 2 minutes
    retry: 3,
  });

  return {
    isFollowing: data?.success && data.message === "following",
    isLoadingFollowingStatus: isFetching,
  };
}

async function getFollowStatus(id, labelId) {
  if (id) {
    return await ContentService.getInstance().isFollowing(id);
  } else if (labelId) {
    return await ContentService.getInstance().isFollowingLabel(labelId);
  }
}

async function toggleFollowingButton(id, labelId, following) {
  if (following) {
    return await unFollow(id, labelId);
  } else {
    return await follow(id, labelId);
  }
}
