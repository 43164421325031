import { useEffect, useState } from "react";
import CheckoutForm from "../Subscription/CheckoutForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import { getLocale } from "../../common/_localeMessages";
import { FormattedMessage } from "react-intl";
import LoadingIndicator from "../../common/LoadingIndicator";
import AccountService from "../../service/AccountService";
import ConfigService from "../../service/ConfigService";
import { Title } from "../Subscription/SignupStyles";
import { AccountDetails } from "./AccountStyles";
import { ButtonBlack } from "../FormElements/Button";

const UpdatePaymentMethod = (props) => {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const [currency, setCurrency] = useState("usd");
  const [paymentMethodType, setPaymentMethodType] = useState(
    props.paymentMethodType
  );
  const [email, setEmail] = useState(props.user?.email);
  const [name, setName] = useState(props.user?.name);

  useEffect(() => {
    const { user } = props;
    if (user) {
      const { stripeCustomer } = user;
      if (stripeCustomer && stripeCustomer.currency) {
        setCurrency(stripeCustomer.currency);
      } else {
        AccountService.getInstance()
          .getCustomerCurrency()
          .then((response) => {
            setCurrency(response.currency);
          });
      }
    }
  }, []);

  useEffect(() => {
    setPaymentMethodType(props.paymentMethodType);
  }, [props.paymentMethodType]);

  // this.state = {
  //   error: false,
  //   complete: false,
  //   loading: false,
  //   currency: "usd",
  //   paymentMethodType: this.props.paymentMethodType,
  // };

  const stripePromise = loadStripe(
    ConfigService.getInstance().get().stripeKey ||
      "pk_test_PMdgob9Q7NtcefvUgCOP2P4400EutnppkB"
  );

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.paymentMethodType !== this.props.paymentMethodType) {
  //     this.setState({ paymentMethodType: this.props.paymentMethodType });
  //   }
  // }

  const onSubmit = (response) => {
    if (props.onSubmit) {
      props.onSubmit();
    }
    setComplete(true);
  };

  const UpdateSuccess = () => {
    return (
      <>
        <Title update>
          <FormattedMessage
            id="template.updatePaymentDetails"
            defaultMessage="Update Payment Details"
          />
        </Title>
        <AccountDetails>
          <FormattedMessage
            id="template.payment_method_successfully_updated"
            defaultMessage="Your payment method has been successfully updated."
          />
        </AccountDetails>
        <ButtonBlack onClick={onDismiss}>
          <FormattedMessage id="template.close" defaultMessage="Close" />
        </ButtonBlack>
      </>
    );
  };

  const PaymentUpdateForm = () => {
    return (
      <>
        <Elements stripe={stripePromise} locale={getLocale()}>
          <>
            {loading ? <LoadingIndicator className="loading--modal" /> : ""}
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <CheckoutForm
                  stripe={stripe}
                  elements={elements}
                  submit={onSubmit}
                  update={true}
                  setLoading={setLoading}
                  currency={currency}
                  email={email}
                  name={name}
                  paymentMethodType={paymentMethodType}
                  updatePaymentMethodType={updatePaymentMethodType}
                  // onPaymentMethodError={onPaymentMethodError}
                  onDismiss={onDismiss}
                />
              )}
            </ElementsConsumer>
          </>
        </Elements>
      </>
    );
  };

  const UpdatePaymentModule = () => {
    if (complete) {
      return <UpdateSuccess />;
    } else {
      return <PaymentUpdateForm />;
    }
  };

  const updatePaymentMethodType = (method) => {
    setPaymentMethodType(method);
    setError(false);
  };

  const onPaymentMethodError = () => {
    setError(true);
  };

  const onDismiss = (e) => {
    setComplete(false);
    setLoading(false);
    props.onDismiss();
  };

  return <UpdatePaymentModule />;
};

export default UpdatePaymentMethod;
