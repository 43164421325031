const NetworkService = (() => {
  let instance;

  function createInstance() {
    return new NetworkServiceInstance();
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default NetworkService;

class NetworkServiceInstance {
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  formatHeader = (headers) => {
    let configHeaders = this.headers;
    if (headers) {
      configHeaders = Object.assign({}, configHeaders, headers);
    }
    return configHeaders;
  };

  get = (url, headers = {}, credentials = "omit") => {
    return fetch(url, {
      headers: this.formatHeader(headers),
      credentials: credentials,
    }).then((response) => this.handleResponse(response));
  };

  delete = (url, headers = {}, credentials = "omit") => {
    return fetch(url, {
      method: "DELETE",
      headers: this.formatHeader(headers),
      credentials: credentials,
    }).then((response) => this.handleResponse(response));
  };

  patch = (url, data, headers = {}, credentials = "omit") => {
    return fetch(url, {
      method: "PATCH",
      headers: this.formatHeader(headers),
      body: JSON.stringify(data),
      credentials: credentials,
    }).then((response) => this.handleResponse(response));
  };

  post = (url, data, headers = {}, credentials = "omit") => {
    return fetch(url, {
      method: "POST",
      headers: this.formatHeader(headers),
      body: JSON.stringify(data),
      credentials: credentials,
    }).then((response) => this.handleResponse(response));
  };

  postForm = (url, data, headers = {}) => {
    const formDataValues = new URLSearchParams();
    for (let property in data) {
      formDataValues.append(property, data[property]);
    }

    return fetch(url, {
      method: "POST",
      headers: this.formatHeader(headers),
      body: formDataValues,
    }).then((response) => this.handleResponse(response));
  };

  upload = (url, data) => {
    const headers = { Authorization: this.headers.Authorization };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: data,
    }).then((response) => this.handleResponse(response));
  };

  downloadByPost = (url, data, credentials = "omit") => {
    const headers = { Authorization: this.headers.Authorization };
    return fetch(url, {
      method: "POST",
      headers: this.formatHeader(headers),
      body: JSON.stringify(data),
      credentials: credentials,
    }).then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        return response.json().then((value) => {
          return Promise.reject({
            status: response.status,
            response: value,
          });
        });
      }
    });
  };

  download = (url) => {
    const headers = { Authorization: this.headers.Authorization };
    return fetch(url, {
      method: "GET",
      headers: headers,
    }).then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        return response.json().then((value) => {
          return Promise.reject({
            status: response.status,
            response: value,
          });
        });
      }
    });
  };

  handleResponse = (response) => {
    if (response.ok) {
      try {
        return response.json();
      } catch (e) {
        return null;
      }
    } else {
      try {
        return response
          .json()
          .then((value) =>
            Promise.reject({
              status: response.status,
              response: value,
            })
          )
          .catch((e) => {
            if (e.status) {
              return Promise.reject({
                status: e.status,
                response: e.response,
              });
            }

            return Promise.reject({
              status: response.status,
              response: response,
            });
          });
      } catch (e) {
        return Promise.reject({
          status: response.status,
          response: response,
        });
      }
    }
  };

  setAccessToken(accessToken) {
    if (accessToken) {
      this.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  deleteAccessToken() {
    this.headers.Authorization = ``;
  }
}
