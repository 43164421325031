export default function AddSubtractSVG({ isSubtract, small }) {
  if (isSubtract) {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        width={small ? "26" : "30"}
        height={small ? "26" : "30"}
        viewBox="0 0 30 30"
      >
        <title>Close</title>
        <g id="Symbols">
          <g id="mod_search_filter_add" data-name="mod/search/filter/add">
            <circle id="Oval" cx="15" cy="15" r="15" style={{ fill: "#35d" }} />
            <g id="ii_system_close-small" data-name="ii/system/close-small">
              <path
                id="plus"
                d="M16,8.2v6h6v1.6H16v6H14.4v-6h-6V14.2h6v-6Z"
                style={{ fill: "#fff", fillRule: "evenodd" }}
              />
            </g>
          </g>
        </g>
        <rect
          x="14.19"
          y="8.2"
          width="2.79"
          height="6"
          style={{ fill: "#35d" }}
        />
        <rect
          x="14.19"
          y="15.8"
          width="3.08"
          height="6.52"
          style={{ fill: "#35d" }}
        />
      </svg>
    );
  }
  return (
    <svg
      width={small ? "26" : "30"}
      height={small ? "26" : "30"}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Open</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="mod/search/filter/add">
          <circle id="Oval" fill="#3355DD" cx="15" cy="15" r="15"></circle>
          <g
            id="ii/system/close-small"
            transform="translate(3.000000, 3.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M13,5.2 L13,11.199 L19,11.2 L19,12.8 L13,12.799 L13,18.8 L11.4,18.8 L11.4,12.8 L5.4,12.8 L5.4,11.2 L11.4,11.2 L11.4,5.2 L13,5.2 Z"
              id="plus"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
