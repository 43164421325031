import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../service/AuthService";
import LoadingIndicator from "../common/LoadingIndicator";
import AccountService from "../service/AccountService";

const AppleAppSigninRedirectHandler = (props) => {
  let [loading, setLoading] = useState(false);
  let [target, setTarget] = useState("/");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/account/app/voucher") {
      setTarget("/redeem");
    }

    setLoading(true);
    if (AuthService.getInstance().processSignInByLink()) {
      AccountService.getInstance()
        .retrieveUserInfoFromServer(true)
        .catch((e) => {
          navigate("/errorpage");
          return Promise.reject(e);
        })
        .finally((res) => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoadingIndicator className="large" />;
  }

  return (
    <Navigate
      to={{
        pathname: target || "/",
        state: { from: location },
      }}
    />
  );
};

export default AppleAppSigninRedirectHandler;
