import { createContext, useContext, useEffect, useState } from "react";
import SettingToggle from "./SettingToggle";
import styled from "@emotion/styled/macro";

const Wrapper = styled.div`
  position: relative;
`;

const ToggleWrapper = styled.div`
  ${(props) =>
    props.location !== "advanced" && "position: absolute; top: 0; right: 0;"}
`;

const ChildrenWrapper = styled.div`
  opacity: ${(props) => (props.isOn ? 1 : 0.65)};
  pointer-events: ${(props) => (props.isOn ? "auto" : "none")};
`;

const SettingsToggleContext = createContext();

export default function SettingsToggle({
  children,
  callback,
  initialValue,
  location,
  size,
}) {
  const [isOn, setIsOn] = useState(initialValue);

  // useEffect(() => {
  //   setIsOn(initialValue);
  // }, [initialValue]);

  function handleToggle() {
    setIsOn((isOn) => !isOn);
    callback();
  }

  return (
    <SettingsToggleContext.Provider value={{ isOn, setIsOn }}>
      <Wrapper>
        <ToggleWrapper location={location}>
          <SettingToggle callback={handleToggle} isOn={isOn} size={size} />
        </ToggleWrapper>
        {children}
      </Wrapper>
    </SettingsToggleContext.Provider>
  );
}

function ControlsWrapper({ children }) {
  const { isOn } = useContext(SettingsToggleContext);

  return <ChildrenWrapper isOn={isOn}>{children}</ChildrenWrapper>;
}

SettingsToggle.Controls = ControlsWrapper;
