import styled from "@emotion/styled/macro";

const ListStyle = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  gap: 4px;
  position: relative;
  z-index: 1;
  padding: 0;
  margin-top: 35px;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const TagStyles = styled.li`
  height: 30px;
  border: 1px solid rgba(51, 85, 221, 1);
  border-radius: 30px;
  color: rgba(51, 85, 221, 1);
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 6px 12px;
  /* cursor: pointer; */
  text-transform: uppercase;
  margin-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    padding-right: 30px;
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/close-small' fill='%233355DD' transform='translate(-1.000000, -1.000000)'%3E%3Cpath d='M17.0666667,6.93333333 L17.0663333,14.9333333 L25.0666667,14.9333333 L25.0666667,17.0666667 L17.0663333,17.0663333 L17.0666667,25.0666667 L14.9333333,25.0666667 L14.9333333,17.0663333 L6.93333333,17.0666667 L6.93333333,14.9333333 L14.9333333,14.9333333 L14.9333333,6.93333333 L17.0666667,6.93333333 Z' id='close' transform='translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      no-repeat 95% 55%;
    background-size: 20px;
  }
`;

export default function SelectedFilterTags({
  filters,
  searchContext,
  removeFilter,
}) {
  if (!filters) {
    return null;
  }
  return (
    <ListStyle>
      {Object.entries(filters).map(([key, filter]) => {
        if (key === "sortBy" || key === "sortOrder") {
          return null;
        }
        if (key === "criteria" && filter.length > 0) {
          const numberOfCriteria = [];
          filter.map((filter) => {
            if (filter.value && filter.toggleValue) {
              numberOfCriteria.push(filter);
            }
            return numberOfCriteria;
          });
          return numberOfCriteria.length > 0 ? (
            <TagStyles key={key} onClick={() => removeFilter("criteria")}>
              {numberOfCriteria.length} Criteria
            </TagStyles>
          ) : null;
        } else if (key === "score" && filter?.value?.low) {
          return (
            <TagStyles key={key} onClick={() => removeFilter("score")}>
              {filter.name} {filter.value.low}
              {filter.scoreType === "range" ? `-${filter.value.high}` : null}
            </TagStyles>
          );
        } else if (
          filter?.type === "date_range" &&
          (filter?.value?.start || filter?.value?.end)
        ) {
          return (
            <TagStyles key={key} onClick={() => removeFilter(key)}>
              {filter.name}
            </TagStyles>
          );
        } else if (filter.single === true && filter.value) {
          return (
            <TagStyles key={key} onClick={() => removeFilter(key)}>
              1 {filter.name}
            </TagStyles>
          );
        } else if (key === "show_offline" && !filter.value) {
          return (
            <TagStyles key={key} onClick={() => removeFilter(key)}>
              {searchContext?.value === "TV" ||
              searchContext?.value === "PODCAST"
                ? "Exclude Off-Air Shows"
                : "Exclude Offline Sites"}
            </TagStyles>
          );
        } else if (filter.value?.length && filter.slug) {
          return (
            <TagStyles key={key} onClick={() => removeFilter(filter.slug)}>
              {filter.value.length}{" "}
              {filter.value?.length === 1
                ? filter.name === "Categories"
                  ? "Category"
                  : filter.name.endsWith("s")
                  ? filter.name.slice(0, -1)
                  : filter.name
                : filter.name}
            </TagStyles>
          );
        }
        return null;
      })}
    </ListStyle>
  );
}
