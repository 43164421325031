import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import queryString from "query-string";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchHistory from "./SearchHistory";
import ContentService from "../../../../service/ContentService";
import AuthService from "../../../../service/AuthService";
import {
  DropdownIndicatorStyles,
  dropDownStyles,
  FadeOut,
  SearchButtonStyle,
  SearchFieldWrapper,
  SearchModule,
  SearchModuleContainer,
  TopOverflowCover,
} from "./SearchBarStyles";
import FollowingButton from "../../Following/FollowingButton";
import {
  resetAllFilters,
  setSelectedSearchContext,
  setSelectedSearchTerm,
} from "../../../../_actions";
import VisuallyHidden from "../../Helpers/VisuallyHidden";

export const contextDropDown = {
  ALL: { value: "all", label: "All" },
  WEB: { value: "WEB", label: "Websites" },
  TV: { value: "TV", label: "TV" },
  PODCAST: { value: "PODCAST", label: "Podcasts" },
  MYTH: { value: "MYTH", label: "False Narratives" },
  REPORT: { value: "REPORT", label: "Reports" },
};

const buildDropDownValues = (principal) => {
  const dropdowns = [];

  if (AuthService.getInstance().hasLabelAccess(principal)) {
    dropdowns.push(contextDropDown.WEB);
  }
  if (AuthService.getInstance().hasTVAccess(principal)) {
    dropdowns.push(contextDropDown.TV);
  }
  if (AuthService.getInstance().hasPodcastAccess(principal)) {
    dropdowns.push(contextDropDown.PODCAST);
  }
  if (
    AuthService.getInstance().hasMythAccess(principal) ||
    AuthService.getInstance().hasRecentMythOnlyAccess(principal)
  ) {
    dropdowns.push(contextDropDown.MYTH);
  }
  if (AuthService.getInstance().hasOnlineReportAccess(principal)) {
    dropdowns.push(contextDropDown.REPORT);
  }
  if (dropdowns.length > 1) {
    dropdowns.unshift(contextDropDown.ALL);
  }
  return dropdowns;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorStyles />
    </components.DropdownIndicator>
  );
};

export default function SearchBar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef();

  const selectedSearchTerm = useSelector((state) => state.searchTerms.term);

  const selectedDomain = useSelector((state) => state.searchTerms.domain);
  const selectedContext = useSelector((state) => state.searchTerms.context);
  const principal = useSelector((state) => state.authentication.principal);

  const isLabel =
    location?.pathname?.startsWith("/partner/label") ||
    location?.pathname?.startsWith("/partner/profile");
  const isReport = location?.pathname.startsWith("/partner/report");
  const isMyth = location?.pathname?.startsWith("/partner/myth");
  const isHome =
    location?.pathname?.startsWith("/partner/home") ||
    location?.pathname === "/partner" ||
    location?.pathname === "/partner/";

  const getDefaultSearchContext = () => {
    const parsed = queryString.parse(location?.search);

    if (parsed.context === "all") {
      return contextDropDown.ALL;
    } else if (parsed.context === "tv" && isLabel) {
      return contextDropDown.TV;
    } else if (parsed.context === "podcast" && isLabel) {
      return contextDropDown.MYTH;
    } else if (parsed.context === "myth" || isMyth) {
      return contextDropDown.MYTH;
    } else if (parsed.context === "report" || isReport) {
      return contextDropDown.REPORT;
    }

    if (AuthService.getInstance().hasLabelAccess(principal)) {
      return contextDropDown.WEB;
    }
    if (AuthService.getInstance().hasTVAccess(principal)) {
      return contextDropDown.TV;
    }
    if (AuthService.getInstance().hasPodcastAccess(principal)) {
      return contextDropDown.PODCAST;
    }
    if (
      AuthService.getInstance().hasMythAccess(principal) ||
      AuthService.getInstance().hasRecentMythOnlyAccess(principal)
    ) {
      return contextDropDown.MYTH;
    }
    if (AuthService.getInstance().hasOnlineReportAccess(principal)) {
      return contextDropDown.REPORT;
    }
  };

  const [showHistory, setShowHistory] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);

  const [initialSearchTerms, setInitialSearchTerms] =
    useState(selectedSearchTerm);
  const [initialSearchContext, setInitialSearchContext] = useState(
    selectedContext || getDefaultSearchContext()
  );
  const [searchFieldValue, setSearchFieldValue] = useState(selectedSearchTerm);

  useEffect(() => {
    if (isLabel || isMyth || isReport) {
      inputRef.current.value = selectedDomain || null;
      setSearchFieldValue(inputRef.current.value);
    } else {
      inputRef.current.value = selectedSearchTerm;
    }
  }, [isLabel, isMyth, isReport, selectedDomain, selectedSearchTerm]);

  useEffect(() => {
    dispatch(setSelectedSearchContext(selectedContext || initialSearchContext));
  }, []);

  const backToSearch = (terms, context) => {
    dispatch(setSelectedSearchTerm(terms));
    dispatch(setSelectedSearchContext(context));

    navigate(`/partner/search/`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setInitialSearchTerms(searchFieldValue);
    setInitialSearchContext(selectedContext || initialSearchContext);
    backToSearch(searchFieldValue, selectedContext || initialSearchContext);
    if (isHome) {
      dispatch(resetAllFilters());
    }
  };

  const handleChange = (context) => {
    setInitialSearchTerms(searchFieldValue);
    setInitialSearchContext(context);
    updateSearchContext(context);
    dispatch(resetAllFilters());
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);

      if (e.currentTarget?.id === "search") {
        e.currentTarget.blur(e);
      }
    }
  };

  const handleHistorySearch = (e, item) => {
    e.preventDefault();
    // set term in search bar
    dispatch(setSelectedSearchTerm(item));
    setSearchFieldValue(item);
    backToSearch(item, selectedContext || initialSearchContext);
  };

  const onCloseout = () => {
    if (isLabel || isMyth) {
      updateSearchTerms(initialSearchTerms);
      updateSearchContext(initialSearchContext);
      navigate(`/partner/search/`);
    } else {
      updateSearchTerms(null);

      if (AuthService.getInstance().hasLabelAccess(principal)) {
        updateSearchContext(contextDropDown.WEB);
      } else if (AuthService.getInstance().hasTVAccess(principal)) {
        updateSearchContext(contextDropDown.TV);
      } else if (AuthService.getInstance().hasPodcastAccess(principal)) {
        updateSearchContext(contextDropDown.PODCAST);
      } else if (
        AuthService.getInstance().hasMythAccess(principal) ||
        AuthService.getInstance().hasRecentMythOnlyAccess(principal)
      ) {
        updateSearchContext(contextDropDown.MYTH);
      } else if (AuthService.getInstance().hasOnlineReportAccess(principal)) {
        updateSearchContext(contextDropDown.REPORT);
      } else {
        updateSearchContext(contextDropDown.ALL);
      }
      navigate("/partner/home");
    }
  };

  const updateSearchContext = (context) => {
    dispatch(setSelectedSearchContext(context));
    dispatch(resetAllFilters());
  };

  const updateSearchTerms = (terms) => {
    setSearchFieldValue(terms);
    dispatch(setSelectedSearchTerm(terms));
  };

  const handleFocus = (e) => {
    if (e.currentTarget?.id === "search") {
      const history = ContentService.getInstance().getSearchHistory();

      if (history.length > 0) {
        setSearchHistory(history);
        setShowHistory(true);
      }
    }
  };

  const handleBlur = (e) => {
    if (e.currentTarget?.id === "search") {
      if (showHistory) {
        setTimeout(() => setShowHistory(false), 200);
      } else {
        setShowHistory(false);
      }
    }
  };

  const clearSearchHistory = () => {
    const contentService = ContentService.getInstance();
    contentService.clearSearchHistory();
    setSearchHistory(contentService.getSearchHistory());
  };

  if (AuthService.getInstance().hasSearchAccess(principal)) {
    return (
      <SearchModuleContainer header={props.header} first={props.first}>
        {!isHome ? <TopOverflowCover></TopOverflowCover> : null}
        <SearchModule large={props.large} header={props.header} isHome={isHome}>
          <form onSubmit={handleSubmit}>
            {props.header ? (
              <nav>
                <Link to="/partner/home">
                  <img
                    src="/images/shield.svg"
                    alt="NewsGuard Logo"
                    title="Go to home"
                    className="logo"
                    height="37"
                    width="30"
                  />
                </Link>
              </nav>
            ) : null}
            <VisuallyHidden>
              <label htmlFor="search">Search</label>
            </VisuallyHidden>
            <SearchFieldWrapper>
              <input
                type="text"
                placeholder="Search"
                className="search-field"
                value={searchFieldValue || ""}
                onChange={(e) => setSearchFieldValue(e.target.value)}
                onKeyUp={handleKeyPress}
                onFocus={handleFocus}
                onBlur={handleBlur}
                id="search"
                autoComplete="off"
                ref={inputRef}
              />
              <FadeOut />
            </SearchFieldWrapper>
            <>
              <div className="select-wrapper">
                {isLabel && selectedDomain === searchFieldValue ? (
                  <FollowingButton {...props} display={isLabel} />
                ) : (
                  <Select
                    value={selectedContext || initialSearchContext}
                    styles={dropDownStyles}
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    name="search-context"
                    options={buildDropDownValues(principal)}
                    className={`basic-single show`}
                    classNamePrefix="select"
                    onChange={handleChange}
                    isSearchable={false}
                    onFocus={() => {}}
                    onBlur={() => {}}
                  />
                )}
              </div>
              <SearchButtonStyle
                type="image"
                alt="Search"
                title="Search"
                src="/images/search.svg"
              />
            </>

            {isHome ? null : (
              <>
                <div className="divider" />
                <div className="clearOut" onClick={onCloseout} />
              </>
            )}
          </form>

          {showHistory && (
            <SearchHistory
              history={searchHistory}
              onClick={handleHistorySearch}
              clear={clearSearchHistory}
              isHome={isHome}
            />
          )}
        </SearchModule>
      </SearchModuleContainer>
    );
  }
  return "";
}
