import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import SearchBar, { contextDropDown } from "./Search/SearchBar/SearchBar";
import AuthService from "../../service/AuthService";
import {
  resetAllFilters,
  setSelectedSearchContext,
  setSelectedSearchTerm,
} from "../../_actions";
import Notice from "../../common/Notice";

const PartnerHomeSection = styled.section`
  margin-top: 20px;
  margin-bottom: ${(props) => (props.firstLaunch ? "40px" : "60px")};
  color: var(--primary-white);
  min-width: 601px;
  padding: 0 20px;

  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @media screen and (max-width: 600px) {
    padding: 0px;
    margin-top: 63px;
    margin-bottom: 0px;
    min-width: 300px;
  }
`;

const Header = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  margin-bottom: 2.1rem;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 15px;
    padding: 10px;
    padding-left: 0;
    text-align: center;
  }
`;

const HeaderSpan = styled.span`
  font-weight: 300;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  background-color: #3355dd;
  border-radius: 4px;
  min-height: 400px;

  @media screen and (max-width: 600px) {
    min-height: auto;
  }
`;

const HomeHeroWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: #35d;
  border-radius: 4px;
  min-height: 400px;
  padding-top: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    min-height: 246px;
    min-width: 335px;
    padding: 20px 7px 30px 6px;
    border-radius: 0;
  }

  .credit {
    font-size: 9px;
  }

  .credit,
  .credit a {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const SearchLinkStyles = styled.ul`
  color: #ffffff;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  margin: 10px 20px 0;
  width: 100%;
  max-width: 1160px;
  align-self: flex-end;
  padding: 0;

  @media screen and (max-width: 801px) {
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 5px;
    padding: 0 20px;
  }

  & li {
    color: #ffffff;
    font-family: Sora;
    font-size: 16px;
    line-height: 21px;

    @media screen and (max-width: 800px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media screen and (max-width: 600px) {
      font-size: 12px;
      line-height: 16px;
    }

    :not(:last-child):after {
      content: "|";
      padding: 0 8px;
      font-weight: 300;
      @media screen and (max-width: 500px) {
        padding: 0 5px;
      }
    }

    & a {
      text-decoration: none;
      color: #ffffff;
    }
  }
`;

const NoMobileWrapper = styled.span`
  @media screen and (max-width: 600px) {
    /* display: none; */
  }
`;

const AdvancedSearchListItem = styled.li`
  @media screen and (max-width: 600px) {
    /* display: none; */
  }
`;

const ContentsWrapper = styled.div`
  margin: 0 auto;
  background-color: var(--primary-blue);
  border-radius: 4px;
`;

const InnerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export default function HomePageHero(props) {
  const [firstLaunch, setFirstLaunch] = useState(true);
  const [cookies] = useCookies(["newsguard-firstLaunch"]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (cookies["newsguard_firstLaunch"]) {
      setFirstLaunch(false);
    }
  }, [cookies]);

  const handleSearchLinkClick = (context) => {
    dispatch(setSelectedSearchTerm(""));
    dispatch(setSelectedSearchContext(contextDropDown[context]));
    dispatch(resetAllFilters());
  };

  const SearchLinks = () => (
    <SearchLinkStyles>
      {AuthService.getInstance().hasLabelAccess(props.principal) &&
      AuthService.getInstance().hasAdvancedSearchAccess(props.principal) ? (
        <AdvancedSearchListItem>
          <Link
            to={{
              pathname: "/partner/search",
            }}
            state={{
              advanced: true,
            }}
            onClick={() => handleSearchLinkClick("WEB")}
          >
            Advanced Search (Websites)
          </Link>
        </AdvancedSearchListItem>
      ) : (
        ""
      )}
      {AuthService.getInstance().hasLabelAccess(props.principal) ? (
        <li>
          <Link
            to="/partner/search"
            onClick={() => handleSearchLinkClick("WEB")}
          >
            <NoMobileWrapper>All</NoMobileWrapper> Websites
          </Link>
        </li>
      ) : (
        ""
      )}
      {AuthService.getInstance().hasTVAccess(props.principal) ? (
        <li>
          <Link
            to="/partner/search"
            onClick={() => handleSearchLinkClick("TV")}
          >
            <NoMobileWrapper>All</NoMobileWrapper> TV
          </Link>
        </li>
      ) : (
        ""
      )}

      {AuthService.getInstance().hasPodcastAccess(props.principal) ? (
        <li>
          <Link
            to="/partner/search"
            onClick={() => handleSearchLinkClick("PODCAST")}
          >
            <NoMobileWrapper>All</NoMobileWrapper> Podcasts
          </Link>
        </li>
      ) : (
        ""
      )}
      {AuthService.getInstance().hasMythAccess(props.principal) ||
      AuthService.getInstance().hasRecentMythOnlyAccess(props.principal) ? (
        <li>
          <Link
            to={{
              pathname: "/partner/search",
              state: {
                advanced: false,
              },
            }}
            onClick={() => handleSearchLinkClick("MYTH")}
          >
            <NoMobileWrapper>All</NoMobileWrapper> False Narratives
          </Link>
        </li>
      ) : (
        ""
      )}
      {AuthService.getInstance().hasOnlineReportAccess(props.principal) ? (
        <li>
          <Link
            to={{
              pathname: "/partner/search",
              state: {
                advanced: false,
              },
            }}
            onClick={() => handleSearchLinkClick("REPORT")}
          >
            <NoMobileWrapper>All</NoMobileWrapper> Reports
          </Link>
        </li>
      ) : (
        ""
      )}
    </SearchLinkStyles>
  );

  return firstLaunch ? (
    <>
      <PartnerHomeSection firstLaunch={firstLaunch}>
        <ContentsWrapper>
          <InnerWrapper>
            <Notice name="newsguard_firstLaunch" cookie={true} path="/">
              <Notice.Image>
                <img
                  src="/images/insight_welcome.svg"
                  width="446"
                  height="393"
                  alt="Illustration of person climbing ladder and looking through telescope"
                />
              </Notice.Image>
              <Notice.Description>
                <Notice.Heading>
                  Welcome to NewsGuard’s Dashboard
                </Notice.Heading>
                <Notice.Text>
                  Search NewsGuard ratings and Nutrition Labels, browse false
                  narratives tracked by our team, or filter labels based on
                  specific criteria, score range, or metadata with our advanced
                  search. Please contact us at{" "}
                  <a href="mailto:clients@newsguardtech.com">
                    clients@newsguardtech.com
                  </a>{" "}
                  with any questions.
                </Notice.Text>
                <Notice.Button to="/">Ok, thanks!</Notice.Button>
              </Notice.Description>
            </Notice>
          </InnerWrapper>
        </ContentsWrapper>
        <SearchBar {...props} first />
      </PartnerHomeSection>
    </>
  ) : (
    <PartnerHomeSection>
      <ContentWrapper>
        <HomeHeroWrapper>
          <Header>
            NewsGuard<HeaderSpan>&#8212;Trust Ratings for News</HeaderSpan>
          </Header>
          <SearchBar
            searchTerms={props.searchTerms}
            setSearchTerms={props.setSearchTerms}
            searchContext={props.searchContext}
            setSearchContext={props.setSearchContext}
            runSearch={props.runSearch}
            setRunSearch={props.setRunSearch}
            page={props.page}
            setPage={props.setPage}
            advancedFilters={props.advancedFilters}
            setAdvancedFilters={props.setAdvancedFilters}
            principal={props.principal}
            first={false}
          />
          <SearchLinks />
        </HomeHeroWrapper>
      </ContentWrapper>
    </PartnerHomeSection>
  );
}
