import { FormattedMessage } from "react-intl";

import {
  AccountModalHeader,
  ButtonsWrapper,
  TextWrapper,
} from "./AccountStyles";
import { ButtonBlack, ButtonRed } from "../FormElements/Button";
import LoadingIndicator from "../../common/LoadingIndicator";
import { useState } from "react";
import AuthService from "../../service/AuthService";

const AccountDeletion = ({ subscriptions, close }) => {
  const [deleteRequested, setDeleteRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const subscription = subscriptions?.length ? subscriptions[0] : null;

  function handleDeleteRequest(e) {
    e.preventDefault();
    setLoading(true);
    AuthService.getInstance()
      .sendAccountDeleteRequest()
      .then((res) => {})
      .catch((err) => {})
      .finally((res) => {
        setLoading(false);
        setDeleteRequested(true);
      });
  }

  return (
    <>
      <AccountModalHeader>
        <FormattedMessage
          id="template.delete_account"
          defaultMessage="Delete Account"
        />
      </AccountModalHeader>
      {deleteRequested ? (
        <>
          <TextWrapper>
            <FormattedMessage
              id="template.deleteRequested"
              defaultMessage="Your request to delete your NewsGuard user data and cancel your subscription has been forwarded to our tech team. We will implement this request as soon as possible."
            />
            <br />
            <br />
          </TextWrapper>

          <ButtonBlack
            className="btn btn__small btn__small--cancel"
            onClick={close}
          >
            <FormattedMessage id="template.close" defaultMessage="Close" />
          </ButtonBlack>
        </>
      ) : (
        <>
          <TextWrapper>
            <p>
              <FormattedMessage
                id="template.deleteRequest"
                defaultMessage="I am requesting that all user data associated with my NewsGuard account be deleted. "
              />

              {subscription?.platform !== "APPLE" && (
                <FormattedMessage
                  id="template.deleteRequestDetails"
                  defaultMessage="I understand that my associated NewsGuard subscription will be canceled upon data deletion. "
                />
              )}
            </p>
            <p>&nbsp;</p>
          </TextWrapper>
          {subscription?.platform === "APPLE" && (
            <>
              <TextWrapper>
                <FormattedMessage
                  id="template.cancel_apple_subscription"
                  defaultMessage="You created this NewsGuard subscription using Apple's App Store. Please cancel the subscription using your Mac, iPhone or iPad. Detailed instructions can be found by <applelink>clicking here</applelink>."
                  values={{
                    applelink: (...chunks) => (
                      <a
                        href="https://support.apple.com/en-us/HT202039"
                        className="link link-external"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </TextWrapper>
            </>
          )}
          <ButtonsWrapper>
            <ButtonBlack onClick={close}>
              <FormattedMessage id="template.keep" defaultMessage="Keep Plan" />
            </ButtonBlack>
            <ButtonRed
              onClick={(e) => handleDeleteRequest(e)}
              style={{ width: "256px" }}
            >
              {loading ? (
                <LoadingIndicator button />
              ) : (
                <FormattedMessage
                  id="template.deleteAccount"
                  defaultMessage="Delete Account"
                />
              )}
            </ButtonRed>
          </ButtonsWrapper>
        </>
      )}
    </>
  );
};

export default AccountDeletion;
