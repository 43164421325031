import HistoryUpdatesTable from "../RecentUpdates/HistoryUpdatesTable";
import LoadingIcon from "../common/LoadingIcon";

const LabelHistoryModule = ({ label, labelHistory, isLabelHistoryLoading }) => {
  return isLabelHistoryLoading ? (
    <LoadingIcon />
  ) : (
    <HistoryUpdatesTable
      labels={labelHistory}
      current={label}
      context="history"
    />
  );
};
export default LabelHistoryModule;
