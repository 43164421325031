import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";
import MythMarker from "../common/MythMarker";
import Moment from "react-moment";
import { PublishedDate } from "../Myths/MythStyles";
import { getLocale } from "../../../common/_localeMessages";
import MythExampleLinkExcerpt from "../Myths/MythExampleLinkExcerpt";

const MythExampleLinksList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  margin-bottom: 43px;
`;

const MythExampleLinksListItem = styled.li`
  display: flex;
  flex-direction: row;
`;

const MythTitleStyles = styled.h3`
  font-size: 22px;
  line-height: 27px;
  a {
    text-decoration: none;
    color: #3355dd;
  }
`;

const MythList = styled.ul`
  /* list-style-type: none; */
  color: #3355dd;
  padding: 0 0 0 20px;
`;

const MythSectionListItemStyles = styled.li`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 25px;
  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
  }

  &:last-of-type {
    margin-bottom: 50px;
  }

  a {
    text-decoration: none;
    color: #3355dd;
    word-break: break-all;
  }
`;

const AdditionalInfo = styled.div``;

const AdditionalMetadata = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MythExcerptWrapper = styled.div`
  font-family: Sora;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border: 1px solid #3355dd;
  background-color: #eeeffc;
  padding: 10px 10px 12px;
  margin: 10px 0 12px;
  position: relative;
  border-radius: 0 2px 2px 2px;

  & .expand {
    cursor: pointer;
    color: #3355dd;
    font-family: Sora;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: right;
  }

  &::before {
    top: -63px;
    left: -44px;
    content: "";
    border-bottom: 112px solid #3355dd;
    border-right: 96px solid transparent;
    position: absolute;
    transform: scale(0.105);
  }

  &::after {
    top: -61px;
    left: -43px;
    content: "";
    border-bottom: 112px solid #eeeffc;
    border-right: 95px solid transparent;
    position: absolute;
    transform: scale(0.09);
  }
`;

const MythExcerpt = styled.blockquote`
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;

  &.expanded {
    overflow: initial;
    -webkit-line-clamp: initial;
  }
`;

const ExampleMetadata = styled.div`
  display: flex;
  font-size: 14px;
  margin-left: -8px;
`;

const ArchiveLink = styled.a`
  background-color: rgba(0, 0, 0, 0.08);
  height: 26px;
  padding: 4px 8px;
  color: #000000 !important;
  border: 0.5px solid #cccccc;
  border-radius: 2px;
  font-family: Sora;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
`;

const LabelMythExampleLinks = ({ labelMythExampleLinks }) => {
  const hasMyths = labelMythExampleLinks
    ? labelMythExampleLinks.length > 0
    : false;

  return hasMyths ? (
    labelMythExampleLinks.map((labelMythExampleLinksEntity) => {
      const mythExampleLinkList = labelMythExampleLinksEntity.mythExampleLinks;
      if (mythExampleLinkList && mythExampleLinkList.length > 0) {
        return (
          <MythExampleLinksList key={mythExampleLinkList[0].myth.id}>
            <MythExampleLinksListItem>
              <MythMarker developing={mythExampleLinkList[0].myth.developing} />
              <MythTitleStyles>
                <Link
                  to={`/partner/myth/${mythExampleLinkList[0].myth.id}`}
                  dangerouslySetInnerHTML={{
                    __html: mythExampleLinkList[0].myth.writeup?.name?.body,
                  }}
                />

                {mythExampleLinkList.map((mythExampleLink, index) => (
                  <MythList key={`${mythExampleLink.link}-${index}`}>
                    <MythSectionListItemStyles>
                      <div>
                        <a
                          href={mythExampleLink.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {mythExampleLink.link}
                        </a>
                      </div>

                      <AdditionalInfo>
                        {mythExampleLink.excerpt ? (
                          <MythExampleLinkExcerpt
                            excerpt={mythExampleLink.excerpt}
                          />
                        ) : null}
                        <AdditionalMetadata>
                          <div>
                            {mythExampleLink.stateSponsored ? (
                              <ExampleMetadata>
                                <svg
                                  width="30px"
                                  height="30px"
                                  viewBox="0 0 60 60"
                                  version="1.1"
                                >
                                  <title>ii/system/flag</title>
                                  <g
                                    id="Symbols"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="#000"
                                    fillRule="evenodd"
                                  >
                                    <g id="ii/system/flag" fill="%23000000">
                                      <path
                                        d="M20,15 L44,27 L23,37.5 L23,47 L20,47 L20,15 Z"
                                        id="Combined-Shape"
                                      />
                                    </g>
                                  </g>
                                </svg>
                                <div>State-Controlled Source</div>
                              </ExampleMetadata>
                            ) : null}
                            {mythExampleLink.dateTime && (
                              <PublishedDate>
                                <Moment
                                  format="MM/DD/YYYY"
                                  locale={getLocale()}
                                >
                                  {mythExampleLink.dateTime}
                                </Moment>
                              </PublishedDate>
                            )}
                          </div>
                          {mythExampleLink.archiveLink ? (
                            <ArchiveLink
                              href={mythExampleLink.archiveLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Archived
                            </ArchiveLink>
                          ) : null}
                        </AdditionalMetadata>
                      </AdditionalInfo>
                    </MythSectionListItemStyles>
                  </MythList>
                ))}
              </MythTitleStyles>
            </MythExampleLinksListItem>
          </MythExampleLinksList>
        );
      }
    })
  ) : (
    <p>This label has no associated false narratives.</p>
  );
};

export default LabelMythExampleLinks;
