import Select, { components } from "react-select";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled/macro";
import AsyncSelect from "react-select/async";

const multiSelectFilterStyles = {
  container: (styles) => ({
    ...styles,
    flexGrow: 1,
    maxWidth: 720,
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#fff",
    // width: "880px",
    "@media screen and (max-width: 600px)": {
      width: "320px",
      fontSize: "16px",
    },
    border: state.selectProps.isMobile ? "1px solid rgba(0,0,0,0.2)" : "none", //no border on desktop
    borderRradius: "4px",
    marginRight: "22px",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#fff",
    marginRight: "4px",
    alignItems: "center",
    ":hover .select__multi-value__remove": {
      display: "flex",
    },
  }),
  multiValueLabel: (styles, state) => ({
    height: state.selectProps.isMobile ? "auto" : "30px",
    border: "1px solid #3355DD",
    borderRadius: "30px",
    color: "#3355DD",
    fontSize: state.selectProps.isMobile ? "12px" : "14px",
    lineHeight: "28px",
    textAlign: "center",
    backgroundColor: "#fff",
    padding: "0 25px 0 10px",
    "@media screen and (max-width: 600px)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),
  multiValueRemove: () => ({
    // marginLeft: "-3px",
    // marginTop: "-12px",
    color: "#3355DD",
    display: "none",
    alignItems: "center",
  }),
  menu: (styles) => ({
    ...styles,

    "@media screen and (max-width: 600px)": {
      fontSize: "12px",
      width: "350px",
    },
    zIndex: 2,
    isolation: "isolate",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? "rgba(0,0,0,0.04)" : "auto",
    ":hover": {
      backgroundColor: "rgba(0,0,0,0.04)",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    flexGrow: 1,
    "@media screen and (max-width: 600px)": { width: "240px" },
  }),
};

function RemoveButton({ onClick }) {
  return <RemoveFilterButton onClick={onClick} />;
}

const RemoveFilterButton = styled.button`
  height: 15px;
  width: 15px;
  cursor: pointer;
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Emod/search/filter/clear%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/close-small' fill='%233355DD' transform='translate(-1.000000, -1.000000)'%3E%3Cpath d='M17.0666667,6.93333333 L17.0663333,14.9333333 L25.0666667,14.9333333 L25.0666667,17.0666667 L17.0663333,17.0663333 L17.0666667,25.0666667 L14.9333333,25.0666667 L14.9333333,17.0663333 L6.93333333,17.0666667 L6.93333333,14.9333333 L14.9333333,14.9333333 L14.9333333,6.93333333 L17.0666667,6.93333333 Z' id='close' transform='translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") -1px -1px
    no-repeat;
  background-size: 20px;
  margin: -2px 10px 0 -22px;
  padding: 0;
  inset: 0;
  border: 0;
`;

function MultiSelectFilter({
  filter,
  onChange,
  async,
  loadOptions,
  options,
  menuIsOpen,
  handleMenuClose,
  handleBlur,
  innerRef,
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <RemoveButton {...props} />
      </components.MultiValueRemove>
    );
  };

  return async ? (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      styles={multiSelectFilterStyles}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueRemove: MultiValueRemove,
      }}
      isMulti
      isMobile={isMobile}
      menuIsOpen={menuIsOpen}
      isDisabled={!menuIsOpen}
      onBlur={() => handleBlur()}
      onMenuClose={() => handleMenuClose()}
      openMenuOnClick={true}
      name={filter?.slug}
      className="basic-multi-select"
      classNamePrefix="select"
      ref={innerRef}
      placeholder={null}
      onChange={onChange}
      value={filter?.value}
      shouldFocus={menuIsOpen}
      isClearable={false}
      noOptionsMessage={() => "No results found"}
      closeMenuOnSelect={false}
      openMenuOnFocus={true}
    />
  ) : (
    <Select
      styles={multiSelectFilterStyles}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueRemove: MultiValueRemove,
      }}
      isMulti
      isMobile={isMobile}
      menuIsOpen={menuIsOpen}
      isDisabled={!menuIsOpen}
      onBlur={() => handleBlur()}
      onMenuClose={() => handleMenuClose()}
      openMenuOnClick={true}
      name={filter?.slug}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      ref={innerRef}
      placeholder={null}
      onChange={onChange}
      value={filter?.value}
      shouldFocus={menuIsOpen}
      isClearable={false}
      closeMenuOnSelect={false}
      openMenuOnFocus={true}
    />
  );
}

export default MultiSelectFilter;
