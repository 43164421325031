import styled from "@emotion/styled";

const SvgSTyles = styled.svg`
  height: 17px;
  width: 17px;

  transform: translateY(-1px);
  flex-shrink: 0;
  margin: 0 5px 0 0px;

  /* @media screen and (max-width: 600px) {
    height: ${(props) => (props.label ? "30px" : "22px")};
  } */
`;

export default function TimeslotIcon() {
  return (
    <SvgSTyles
      viewBox="12 12 35 35"
      // width="60px"
      // height="60px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Duration</title>
      <g
        id="ii/system/duration"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group-5"
          transform="translate(14.000000, 14.000000)"
          fill="#231F20"
        >
          <path
            d="M29,16 C29,23.168 23.168,29 16,29 C8.832,29 3,23.168 3,16 C3,8.832 8.832,3 16,3 C19.299,3 22.402,4.233 24.811,6.462 L23.065,8.208 L30.843,10.329 L28.722,2.551 L26.932,4.341 C23.956,1.547 20.101,0 16,0 C7.178,0 0,7.178 0,16 C0,24.822 7.178,32 16,32 C24.822,32 32,24.822 32,16 L29,16 Z"
            id="Fill-1"
          ></path>
          <polygon
            id="Fill-3"
            points="14.5 7.7554 14.5 16.9344 23.444 21.3294 24.767 18.6374 17.5 15.0664 17.5 7.7554"
          ></polygon>
        </g>
      </g>
    </SvgSTyles>
  );
}
