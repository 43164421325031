import styled from "@emotion/styled";

const LoadingIconContainer = styled.div`
  --size: ${(props) => (props.small ? "2" : "10")}em;
  --border-width: ${(props) => (props.small ? "0.2" : "1.1")}em;
  --margin: ${(props) => (props.small ? "6px 0 0" : "60px auto")};

  /* https://projects.lukehaas.me/css-loaders/ */
  :after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  margin: var(--margin);
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: var(--border-width) solid rgba(51, 85, 221, 0.2);
  border-right: var(--border-width) solid rgba(51, 85, 221, 0.2);
  border-bottom: var(--border-width) solid rgba(51, 85, 221, 0.2);
  border-left: var(--border-width) solid #3355dd;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;

  @-webkit-keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default function LoadingIcon({ small }) {
  return <LoadingIconContainer small={small}>Loading...</LoadingIconContainer>;
}
