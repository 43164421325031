import { useEffect, useRef, useState } from "react";
import { MythExcerpt, MythExcerptWrapper } from "./MythStyles";

function MythExampleLinkExcerpt({ excerpt }) {
  const [showOpenButton, setShowOpenButton] = useState(false);
  const ref = useRef();

  function handleClick() {
    ref.current.classList.add("expanded");
    setShowOpenButton(false);
  }

  useEffect(() => {
    if (ref.current) {
      const container = ref.current;
      const lineHeight = parseFloat(
        window.getComputedStyle(container).lineHeight
      );
      const maxHeight = lineHeight * 6; //max lines to show is 6

      container.style.height = "auto";
      const contentHeight = container.scrollHeight;

      if (contentHeight > maxHeight) {
        // If content height exceeds 6 lines, clamp it at 4 lines
        container.classList.add("clamp-4");
      } else {
        container.classList.remove("clamp-4");
      }

      container.style.height = "";

      if (ref.current.scrollHeight !== ref.current.clientHeight) {
        setShowOpenButton(true);
      }
    }
  }, [ref]);

  return (
    <MythExcerptWrapper>
      <MythExcerpt ref={ref}>&ldquo;{excerpt}&rdquo;</MythExcerpt>
      {showOpenButton && (
        <button className="expand" onClick={handleClick}>
          expand
        </button>
      )}
    </MythExcerptWrapper>
  );
}

export default MythExampleLinkExcerpt;
