import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

export const SearchResultsWrapper = styled.div``;

export const ScoreStyling = styled.div`
  flex: 0 0 90px;
  max-width: 90px;
  min-width: 40px;
  @media screen and (max-width: 760px) {
    flex: 0 0 auto;
  }
  & div {
    background-color: ${(props) => {
      let color;

      switch (props.rank) {
        case "A":
        case "B":
        case "C":
        case "D":
        case "F":
        case "T":
        case "N":
          color = "rgba(51, 85, 221, .3)";
          break;
        case "P":
          color = "rgba(153, 153, 153, 0.4)";
          break;
        case "S":
          color = "rgba(255, 145, 0, 0.4)";
          break;
        case "TK":
          color = "rgba(0, 0, 0, 0.1)";
          break;
        default:
          color = "transparent";
      }
      return color;
    }};
    border-radius: 25px;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 12px;
      line-height: 35px;
      border-radius: 25px;
      width: 35px;
      height: 35px;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: auto;
    min-width: auto;
    align-self: flex-end;
  }
`;

export const PodcastScoreWrapper = styled.div`
  flex: 0 0 90px;
  max-width: 90px;
  @media screen and (max-width: 760px) {
    flex: 0 0 auto;
  }
`;

export const PodcastScore = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: ${(props) => {
    let color;
    switch (props.rank) {
      case "T":
      case "A":
      case "B":
      case "C":
        color = "rgba(1, 204, 136, 0.4)";
        break;
      case "N":
      case "D":
      case "F":
        color = "rgba(255, 68, 68, 0.4)";
        break;
      case "P":
        color = "rgba(153, 153, 153, 0.4)";
        break;
      case "S":
        color = "rgba(255, 145, 0, 0.4)";
        break;
      case "TK":
        color = "rgba(0, 0, 0, 0.1)";
        break;
      case "M":
        color = "rgba(255, 145, 0, 0.4)";
        break;

      default:
        color = "rgba(0, 204, 136, 0.4)";
    }
    return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 42' style='enable-background:new 0 0 42 42' xml:space='preserve'%3E%3Cpath d='M21,0c11.6,0,21,9.4,21,21s-9.4,21-21,21S0,32.6,0,21S9.4,0,21,0z M21,3.2 c-9.9,0-17.9,8-17.9,17.9s8,17.8,17.9,17.8s17.8-8,17.8-17.8S30.9,3.2,21,3.2z M21,6.3c8.1,0,14.7,6.6,14.7,14.7S29.1,35.7,21,35.7 S6.3,29.1,6.3,21S12.9,6.3,21,6.3z M21,9.4C14.6,9.4,9.4,14.6,9.4,21S14.6,32.5,21,32.5S32.5,27.4,32.5,21S27.4,9.4,21,9.4z M21,12.6c4.6,0,8.4,3.8,8.4,8.4s-3.8,8.4-8.4,8.4s-8.4-3.8-8.4-8.4S16.4,12.6,21,12.6z M21,15.8c-2.9,0-5.2,2.4-5.2,5.2 s2.4,5.2,5.2,5.2s5.2-2.4,5.2-5.2S23.9,15.8,21,15.8z' style='fill:${color}'/%3E%3C/svg%3E")`;
  }};
`;

export const TVScoreWrapper = styled.div`
  flex: 0 0 90px;
  max-width: 90px;
  @media screen and (max-width: 760px) {
    flex: 0 0 auto;
  }
`;

export const TVScore = styled.div`
  height: 30px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: ${(props) => {
    let color;
    switch (props.rank) {
      case "T":
      case "A":
      case "B":
      case "C":
        color = "rgba(1, 204, 136, 0.4)";
        break;
      case "N":
      case "D":
      case "F":
        color = "rgba(255, 68, 68, 0.4)";
        break;
      case "P":
        color = "rgba(153, 153, 153, 0.4)";
        break;
      case "S":
        color = "rgba(255, 145, 0, 0.4)";
        break;
      case "TK":
        color = "rgba(0, 0, 0, 0.1)";
        break;
      case "M":
        color = "rgba(255, 145, 0, 0.4)";
        break;

      default:
        color = "rgba(0, 204, 136, 0.4)";
    }
    return `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62 42' style='enable-background:new 0 0 62 42' xml:space='preserve'%3E%3Cpath d='M31.7 0h6.4l2.3.1 2 .1h2.3l1.8.1 1.3.1 1.3.1 1.2.1.8.1.7.1h.4l.7.1.6.1c.7.1 1.3.2 1.8.3C57.9 2 60 4 60.7 6.6c.2.8.4 1.7.6 2.7l.1.7v.3l.1.7.1.7.1.7.1.7.1 1.1.1 1.1.1 1.3v8.2L62 26v.6l-.1 1.3-.1 1-.1.6-.1 1-.1 1c-.2 1.4-.4 2.7-.7 3.8-.7 2.6-2.8 4.6-5.5 5.3-.4.1-.9.2-1.5.3l-.6.1-.6.1-.7.1h-.4l-.8.1-.7.1-.8.1-.8.1-1.7.1-1.8.1-1.6.1-2.4.1H39l-4 .1h-9.3l-2.7-.2-2.3-.1-1.9-.1-1.9-.1-1.4-.1-.9-.1-1.6.1-1.3-.1h-.4l-.8-.1h-.4l-.7-.1H9l-.7-.2c-.6-.1-1.2-.2-1.6-.3C4.1 40 2 38 1.3 35.4c-.1-.5-.3-1.1-.4-1.7l-.1-.6c0-.1 0-.2-.1-.3l-.1-.7-.1-.7-.1-.7V30l-.1-.7-.1-1.1v-.7l-.1-1v-9.6l.1-1.3.1-1 .1-1 .1-.7-.1-.9.1-.7.1-.7.1-.7.1-.7c0-.1 0-.2.1-.3l.1-.6c0-.6.1-1.2.3-1.7C2 4 4.1 1.9 6.7 1.2c.4-.1.9-.2 1.5-.2l.6-.1.6-.1.7-.1h.4l.8-.1h.7l.8-.1.8-.1 1.7-.1 1.8-.1 1.6-.1 2.4-.1H23l4-.1h1.1l3.6.1z' style='fill:${color}'/%3E%3C/svg%3E")`;
  }};
`;

export const LabelNameStyling = styled.h3`
  font-family: Sora;
  font-size: 22px;

  line-height: 27px;

  display: flex;
  align-items: center;
  margin-left: -4px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const CardSubhead = styled.h4`
  font-weight: 600;
`;

export const LabelDescriptionStyling = styled.p`
  font-family: Sora;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;

  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const MetaDataContent = styled.div`
  display: flex;
  align-items: flex-start;

  h4 {
    margin-right: 10px;
    font-size: 12px;
    line-height: 20px;
    min-width: 40px;
  }
  span {
    font-size: 12px;
    line-height: 20px;
    min-width: 112px;
    overflow-wrap: break-word;
    hyphens: auto;

    @media screen and (max-width: 1000px) {
      min-width: 80px;
    }

    &.locale {
      white-space: nowrap;
    }
  }
  a {
    text-decoration: none;
    color: #3355dd;
  }
`;

export const LabelMetaAndScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 17%;
  margin-left: auto;
  @media screen and (max-width: 760px) {
    flex-direction: ${(p) => (p.myth ? "row" : "row-reverse")};
    flex: 0 0 auto;
    margin-left: 0;
  }
`;

export const SearchResultLink = styled(Link)`
  color: var(--primary-black);
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const SearchResultWrapper = styled.div`
  margin-left: -14px;
  margin-right: -14px;
  padding-right: 15px;
  border-radius: 4px;

  margin-bottom: 0px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
  margin-top: 16px;
  color: #000;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: auto;
    margin-left: 0;
    padding-right: 10px;
    padding-left: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const SearchResultStyles = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  justify-content: flex-start;

  @media screen and (max-width: 760px) {
    flex-direction: column;
    width: auto;
    margin-top: 0;
    padding-bottom: 10px;
    min-width: 275px;
  }

  & .brand-name {
    /* width: 265px; */
    margin-left: auto;

    @media screen and (max-width: 760px) {
      margin-left: 0;
    }
    @media screen and (max-width: 600px) {
      margin-top: 4px;
      width: 100%;
    }
  }
`;

export const FlagList = styled.ul`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding: 0 0 0 18px;
  list-style: none;

  background-image: url("data:image/svg+xml,%3Csvg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eii/system/flag%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/flag' fill='%23000000'%3E%3Cpath d='M20,15 L44,27 L23,37.5 L23,47 L20,47 L20,15 Z' id='Combined-Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: -9px -4px;
  background-repeat: no-repeat;
  background-size: 30px;
`;

export const FlagListItem = styled.li`
  display: inline;
  margin-right: 7px;
  &:after {
    content: ",";
  }

  &:last-child:after {
    content: "";
  }
`;

export const LabelNameAndDescriptionWrapper = styled.div`
  margin-right: 50px;
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 900px) {
    margin-right: 0;
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    flex: 0 0 auto;
  }
`;

export const MetadataWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(70px, 80px) minmax(180px, 320px);
  font-size: 12px;
  grid-auto-rows: max-content;
  grid-row-gap: 4px;
  margin-left: ${(props) => (props.myth ? "90px" : "auto")};

  @media screen and (max-width: 960px) {
    grid-template-columns: minmax(70px, 80px) minmax(125px, 320px);
    margin-left: 0;
  }
  @media screen and (max-width: 600px) {
    margin-top: 4px;
    width: 100%;
  }
`;

export const SearchResultHover = styled.div`
  margin-left: -14px;
  margin-right: -14px;
  padding-right: 15px;
  /* width: 1190px; */
  border-radius: 4px;

  margin-bottom: 0px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 15px;
  margin-top: 16px;
  color: #000;
  text-decoration: none;

  @media screen and (max-width: 600px) {
    width: auto;
    margin-left: 0;
    padding-right: 10px;
    padding-left: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const SearchInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ hasFilters }) => (hasFilters ? "10px" : "50px")};
  margin-bottom: ${({ hasFilters }) => (hasFilters ? "10px" : "45px")};

  @media screen and (max-width: 600px) {
    margin-top: 50px;
    margin-bottom: ${({ hasFilters }) => (hasFilters ? 0 : "45px")};
  }
`;

export const ResultsInfoStyles = styled.p`
  font-size: 18px;
  line-height: 22px;
  margin-top: 9px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-left: 2px;
    margin-bottom: 12px;
  }

  span {
    font-weight: 500;
  }
`;

export const ResultsAndTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActiveNotice = styled.p`
  font-style: italic;
`;
