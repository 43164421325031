import styled from "@emotion/styled/macro";
import { Dialog } from "reach-dialog-react-18";
import "reach-dialog-react-18/styles.css";

const FilterContainerStyle = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;

  :first-of-type {
    padding-top: 14px;
    padding-bottom: 14px;

    @media screen and (max-width: 600px) {
      padding-top: 11px;
      padding-bottom: 28px;
    }
  }

  > div:first-of-type {
    width: 119px;
    display: flex;
    align-items: center;

    &.context__smaller {
      width: 119px;
    }
  }

  &.context__open--false.category {
    > div:nth-of-type(2) {
      display: none;
    }
  }

  &.context__open--true.category {
    align-items: flex-start;
    > div:nth-of-type(1) {
      margin-top: 4px;
    }
    > div {
      display: block;
    }
  }

  @media screen and (max-width: 600px) {
    height: 32px;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    padding-top: 11px;
    padding-bottom: 28px;
    margin-bottom: 0;

    &.context__open--true {
      height: 100%;
      overflow: visible;
      z-index: 200;

      > div {
        display: block;
      }

      &.context__open--true.category {
        > div:first-of-type {
          margin-top: 0px;
        }
      }
    }
  }
`;

const FilterNameStyle = styled.div`
  font-size: 12px;
  font-weight: 550;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
  min-width: 110px;
  width: 110px;

  @media screen and (max-width: 600px) {
    margin-bottom: 13px;
    white-space: nowrap;
  }
`;

const FilterCountStyle = styled.div`
  height: 30px;
  min-width: 30px;
  width: 30px;
  border-radius: 50%;
  border: 0.5px solid #3355dd;
  color: #3355dd;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
`;

const RemoveFilterButton = styled.button`
  height: 15px;
  width: 15px;
  cursor: pointer;
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Emod/search/filter/clear%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/close-small' fill='%233355DD' transform='translate(-1.000000, -1.000000)'%3E%3Cpath d='M17.0666667,6.93333333 L17.0663333,14.9333333 L25.0666667,14.9333333 L25.0666667,17.0666667 L17.0663333,17.0663333 L17.0666667,25.0666667 L14.9333333,25.0666667 L14.9333333,17.0663333 L6.93333333,17.0666667 L6.93333333,14.9333333 L14.9333333,14.9333333 L14.9333333,6.93333333 L17.0666667,6.93333333 Z' id='close' transform='translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") -1px -1px
    no-repeat;
  background-size: 20px;
  margin-right: 5px;
  padding: 0;
  inset: 0;
  border: 0;
`;

const FilterCountWrapper = styled.div`
  flex-basis: 35px;

  @media screen and (min-width: 601px) {
    ${FilterContainerStyle}:hover & {
      display: none;
    }
  }
`;

const RemoveAndCountIconsWrapper = styled.div`
  flex-basis: 35px;
  display: flex;
  margin-left: 5px;
  width: 26px;
  height: 26px;
  margin-left: auto;
  @media screen and (max-width: 600px) {
    position: absolute;
    top: 5px;
    right: 55px;
    line-height: 25px;
    height: 25px;
    width: 25px;
    min-width: 25px;
  }
`;

const RemoveFilterWrapper = styled.div`
  display: none;
  @media screen and (min-width: 601px) {
    ${FilterContainerStyle}:hover & {
      display: flex;
      width: 35px;
      flex-basis: 35px;
      align-self: center;
      justify-content: center;
    }
  }
`;

const AdvancedSearchWrapper = styled(Dialog)`
  width: auto;
  max-width: 1200px;

  @media screen and (max-width: 600px) {
    height: 100%;
    width: 100%;
    min-width: 375px;
  }
`;

const MobileRemoveWidgetWrapper = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    background-color: #f5f5f5;
    display: initial;
    color: red;
    border-radius: 30px;
    width: 120px;
    z-index: 25;
    position: absolute;
    top: -7px;
    right: 43px;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
  }
`;

export {
  FilterContainerStyle,
  FilterCountStyle,
  FilterNameStyle,
  FilterCountWrapper,
  RemoveFilterButton,
  RemoveFilterWrapper,
  RemoveAndCountIconsWrapper,
  AdvancedSearchWrapper,
  MobileRemoveWidgetWrapper,
};
