import styled from "@emotion/styled/macro";
import { FormattedMessage } from "react-intl";
import _stripeErrorMap from "./_stripeErrorMap";

export const ErrorLink = styled.a`
  color: var(--pimary-red);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default function ErrorMessageView(props) {
  let { code, defaultMessage, error } = props;

  if (error && error.html) {
    const { id, defaultMessage, values } = error;
    return (
      <FormattedMessage
        id={id}
        defaultMessage={defaultMessage}
        values={values}
      />
    );
  }

  if (error && error.response) {
    // server side validation error
    if (error.response.errors) {
      let result = [];
      error.response.errors.map((errorElement) => {
        const { code, field, defaultMessage } = errorElement;
        if (code === "NotBlank" || code === "NotEmpty" || code === "NotNull") {
          result.push(
            <FormattedMessage
              id={`message.${field.toLowerCase()}_blank`}
              defaultMessage={`${field} ${defaultMessage}`}
            />
          );
        } else if (code === "Email") {
          result.push(
            <FormattedMessage
              id={`message.email_invalid`}
              defaultMessage={`${field} ${defaultMessage}`}
            />
          );
        } else {
          result.push(
            <FormattedMessage
              id={`message.${field.toLowerCase()}_invalid`}
              defaultMessage={`${field} ${defaultMessage}`}
            />
          );
        }
        return null;
      });
      if (result && result.length > 0) {
        return <div>{result}</div>;
      }
    } else if (error.response.code) {
      code = error.response.code;
      if (error.response.code === "UNAUTHORIZED") {
        // sign in failure
        return (
          <FormattedMessage
            id="message.bad_credentials"
            defaultMessage="The email address and password combination you entered is not correct. Please try again."
          />
        );
      }
      defaultMessage = error.response.message;
    } else if (
      error?.response?.error === "invalid_grant" ||
      error?.response?.message === "invalid_grant"
    ) {
      // sign in failure
      return (
        <FormattedMessage
          id="message.bad_credentials"
          defaultMessage="The email address and password combination you entered is not correct. Please try again."
        />
      );
    } else if (error?.response?.error_description === "locked_account") {
      code = error.response.error_description;
      defaultMessage = error.response.error_description;
    }
  }

  if (code) {
    //console.log("code", code);
    if (code.startsWith("STRIPE_")) {
      code = _stripeErrorMap[code];
      if (code) {
      } else {
        code = "message.ERROR";
      }
    } else {
      code = `message.${code}`;
    }
    if (error.response && error.response.value) {
      let value = error.response.value;
      if (value === "mailto") {
        return (
          <FormattedMessage
            id={code}
            defaultMessage={defaultMessage}
            values={{
              mailto: (...chunks) => (
                <ErrorLink href="mailto:support@newsguardtech.com">
                  {chunks}
                </ErrorLink>
              ),
            }}
          />
        );
      }
    } else if (error.response && error.response.values) {
      let values = error.response.values;
      return (
        <FormattedMessage
          id={code}
          defaultMessage={defaultMessage}
          values={values}
        />
      );
    }
  } else {
    code = "message.ERROR";
  }
  return (
    <FormattedMessage
      id={code}
      defaultMessage={defaultMessage}
      values={{
        mail_link: (...chunks) => (
          <ErrorLink href="mailto:support@newsguardtech.com">
            {chunks}
          </ErrorLink>
        ),
      }}
    />
  );
}
