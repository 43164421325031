import styled from "@emotion/styled";
import VisuallyHidden from "../Helpers/VisuallyHidden";

const ButtonWrapper = styled.div`
  height: 36px;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.08);
  height: 36px;
`;

const PrinterIcon = styled.svg`
  height: 36px;
  width: 36px;
`;

const PrintButton = ({ onClick }) => {
  return (
    <ButtonWrapper>
      <Button onClick={onClick}>
        <PrinterIcon
          width="60px"
          height="60px"
          viewBox="0 0 60 60"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Print</title>
          <g
            id="ii/system/print"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Group-4"
              transform="translate(13.000000, 14.000000)"
              fill="#010202"
            >
              <polygon
                id="Fill-1"
                points="10.9999 27.0002 22.9999 27.0002 22.9999 25.0002 10.9999 25.0002"
              ></polygon>
              <polygon
                id="Fill-2"
                points="10.9999 23.0002 22.9999 23.0002 22.9999 21.0002 10.9999 21.0002"
              ></polygon>
              <path
                d="M3.5,0 L3.5,8 L4.4408921e-16,8 L4.4408921e-16,19.527 L3.5,21.657 L3.5,34 L30.5,34 L30.5,21.657 L34,19.527 L34,8 L30.5,8 L30.5,0 L3.5,0 Z M6.5,8 L27.501,8 L27.501,3.001 L6.5,3.001 L6.5,8 Z M6.5,31 L27.501,31 L27.501,17 L6.5,17 L6.5,31 Z"
                id="Fill-3"
              ></path>
            </g>
          </g>
          <VisuallyHidden>Print</VisuallyHidden>
        </PrinterIcon>
      </Button>
    </ButtonWrapper>
  );
};

export default PrintButton;
