import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";
import AddFilterButton from "../AdvancedSearchFilters/AddFilterButton";

import {
  FilterContainerStyle,
  FilterCountStyle,
  FilterCountWrapper,
  FilterNameStyle,
  MobileRemoveWidgetWrapper,
  RemoveAndCountIconsWrapper,
  RemoveFilterButton,
  RemoveFilterWrapper,
} from "./AdvancedSearchStyles";

const FilterCountIcon = ({
  children,
  slug,
  handleClear,
  showRemoveWidget,
  setShowRemoveWidget,
  isMobile,
}) => {
  if (children) {
    return (
      <FilterCountWrapper>
        <FilterCountStyle
          onClick={
            isMobile ? () => setShowRemoveWidget(!showRemoveWidget) : null
          }
        >
          {children}
          {showRemoveWidget && (
            <MobileRemoveWidget
              slug={slug}
              handleClear={handleClear}
              setShowRemoveWidget={setShowRemoveWidget}
            />
          )}
        </FilterCountStyle>
      </FilterCountWrapper>
    );
  }
  return null;
};

const MobileRemoveWidget = ({ slug, handleClear, setShowRemoveWidget }) => {
  return (
    <MobileRemoveWidgetWrapper
      onClick={() => {
        handleClear(slug);
        setShowRemoveWidget(false);
      }}
    >
      Remove Filter
    </MobileRemoveWidgetWrapper>
  );
};
const RemoveAndCountIcons = ({ filterCount, handleClear, slug, isMobile }) => {
  const [showRemoveWidget, setShowRemoveWidget] = useState(false);
  return (
    <RemoveAndCountIconsWrapper>
      <RemoveFilterWrapper>
        {!isMobile && filterCount > 0 && (
          <RemoveFilterButton onClick={() => handleClear(slug)} type="button" />
        )}
      </RemoveFilterWrapper>
      <FilterCountIcon
        handleClear={handleClear}
        slug={slug}
        setShowRemoveWidget={setShowRemoveWidget}
        showRemoveWidget={showRemoveWidget}
        isMobile={isMobile}
      >
        {filterCount}
      </FilterCountIcon>
    </RemoveAndCountIconsWrapper>
  );
};

export const FilterContainer = ({
  filter,
  isComplete,
  children,
  handleClear,
  align,
  toggleContainer,
  isContainerOpen,
  index,
  context,
}) => {
  const selectItem = useRef();
  const [filterCount, setFilterCount] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (filter.single) {
      setFilterCount(filter?.value?.length === 0 ? 0 : 1);
    } else if (filter.slug === "score") {
      setFilterCount(filter?.value?.low ? 1 : 0);
    } else if (filter.slug === "show_offline") {
      setFilterCount(filter?.value ? 0 : 1);
    } else if (filter.slug === "criteria") {
      setFilterCount(filter?.value && isComplete ? 1 : 0);
    } else if (filter.type === "date_range") {
      setFilterCount(filter?.value?.start || filter?.value?.end ? 1 : 0);
    } else {
      setFilterCount(filter?.value?.length);
    }
  }, [filter?.value, filter?.single, filter?.slug, isComplete]);

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const needsContainerCheck =
    filter.slug === "ratings" ||
    filter.slug === "score" ||
    filter.slug === "criteria" ||
    filter.slug === "categories";

  const handleMenuClose = () => {
    setMenuIsOpen(false);

    // super hack to handle competing blur and close menus
    setTimeout(() => {
      setIsClicked(false);
    }, 0);
  };

  const toggleMenuIsOpen = () => {
    if (needsContainerCheck) {
      if (filter.slug === "criteria") {
        toggleContainer(`criteria-${index}`);
        return;
      }
      toggleContainer(filter.slug);
      return;
    }

    if (!isClicked) {
      const selectEl = selectItem.current;
      setMenuIsOpen((value) => !value);
      toggleContainer(filter.name);
      if (!selectEl) return;
      if (menuIsOpen) {
        selectEl.blur();
      } else {
        // super hack to handle competing blur and close menus
        setTimeout(() => {
          selectEl.focus();
        }, 0);
      }

      setIsClicked(true);
    } else {
      setIsClicked(false);
    }
  };

  const handleBlur = () => {
    selectItem?.current.blur();
  };

  const isOpen = needsContainerCheck ? isContainerOpen : menuIsOpen;
  const filterName =
    filter.slug === "show_offline"
      ? context === "web"
        ? filter.name
        : "Include Off-Air Shows"
      : filter.name;

  return (
    <FilterContainerStyle
      className={`context__open--${isOpen} ${
        filter.name === "Categories" ? "category" : ""
      }`}
      align={align}
    >
      <FilterNameStyle className="filter-name">{filterName}</FilterNameStyle>

      {isMobile || filter.multiline || filter.name === "Categories" ? (
        <AddFilterButton onClick={toggleMenuIsOpen} subtract={isOpen} />
      ) : null}

      {/* adding props to passed child */}
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return null;
        return cloneElement(child, {
          handleMenuClose,
          menuIsOpen,
          innerRef: (node) => {
            selectItem.current = node;
          },

          handleBlur,
        });
      })}
      <RemoveAndCountIcons
        filterCount={filterCount}
        handleClear={handleClear}
        slug={filter.slug}
      />
    </FilterContainerStyle>
  );
};
