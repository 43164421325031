import { useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { useFollowingStatus, useFollowingToggle } from "./useFollowingButton";
import LoadingIndicator from "../../../common/LoadingIndicator";

const FollowingButtonStyle = styled.span`
  height: 40px;
  padding: 0 20px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.following ? "#3355dd" : "rgba(0, 0, 0, 0.08)"};
  color: ${(props) => (props.following ? "#fff" : " #000")};
  font-family: Sora;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: ${(props) => (props.show ? "inline-block" : "none")};
  margin-left: 10px;
  margin-top: -1px;
  min-width: 130px;

  @media screen and (max-width: 600px) {
    background-color: transparent;
    padding: ${(props) => (props.isLoading ? "0 10px 0 0" : "0 20px")};
    background-image: ${(props) =>
      props.isLoading
        ? "none"
        : props.following
        ? "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eii/status selected%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/status-selected' fill='%233355DD'%3E%3Cpath d='M24,0 C25.1045695,-2.02906125e-16 26,0.8954305 26,2 L26,24 C26,25.1045695 25.1045695,26 24,26 L2,26 C0.8954305,26 1.3527075e-16,25.1045695 0,24 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L24,0 Z M18,9 L11.9987302,15 L8,11 L6,13 L12,19 L20,11 L18,9 Z' id='check'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
        : "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Enav/m/search/follow%3C/title%3E%3Cg id='nav/m/search/follow' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Crect id='bg' fill='%23000000' opacity='0.08' x='0' y='0' width='26' height='26' rx='2'%3E%3C/rect%3E%3Cpath d='M14,6 L14,12 L20,12 L20,14 L14,14 L14,20 L12,20 L12,14 L6,14 L6,12 L12,12 L12,6 L14,6 Z' id='Combined-Shape' fill='%23000000'%3E%3C/path%3E%3C/g%3E%3C/svg%3E\")"};
    background-position: ${(props) => (props.isHome ? " 100% 0%" : "20% 50%")};
    background-repeat: no-repeat;
    background-size: 20px;
    font-size: 0px;
    position: relative;
    left: 16px;
    min-width: auto;
  }
`;
export default function FollowingButton(props) {
  const id = useParams().profileId || props.id;

  const { labelId } = useParams();

  const { isFollowing, isLoadingFollowingStatus } = useFollowingStatus(
    id,
    labelId
  );
  const { toggleFollowing, isLoading } = useFollowingToggle(
    id,
    labelId,
    isFollowing
  );

  return (
    <FollowingButtonStyle
      following={isFollowing}
      onClick={toggleFollowing}
      show={true}
      isLoading={isLoadingFollowingStatus || isLoading}
    >
      {isLoadingFollowingStatus || isLoading ? (
        <LoadingIndicator scale={0.5} />
      ) : isFollowing ? (
        "Following"
      ) : (
        "Follow"
      )}
    </FollowingButtonStyle>
  );
}
