import styled from "@emotion/styled/macro";
import ErrorMessageView from "../../common/ErrorMessageView";

export const ErrorMessageStyled = styled.div`
  color: #ff4444;
  font-family: Sora;
  font-size: var(--14px);
  font-weight: 400;
  line-height: var(--20px);
  margin: 5px 0 15px 0;
  position: relative;
  top: ${({ longMessage }) => (longMessage ? "-17px" : "0")};
`;

const ErrorMessage = ({ error, longMessage }) => {
  return (
    <ErrorMessageStyled longMessage={longMessage}>
      <ErrorMessageView error={error} />
    </ErrorMessageStyled>
  );
};

export default ErrorMessage;
