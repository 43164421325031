import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useCookies } from "react-cookie";

const Error = () => {
  const [searchParams] = useSearchParams();
  //const [campaign, setCampaign] = useCookies(["campaign"]);
  const [serverError, setServerError] = useState("ERROR");

  useEffect(() => {
    const err = searchParams.get("err");
    if (err) {
      setServerError(err);
    }
  }, []);

  return (
    <>
      <h1>
        <FormattedMessage id="template.sorry" defaultMessage="Sorry" />
      </h1>
      <div>
        {serverError !== "ERROR" ? (
          <FormattedMessage
            id={serverError}
            defaultMessage={<FormattedMessage id="message.ERROR" />}
            values={{
              signup_link: (...chunks) => (
                <a
                  target="_blank"
                  //href={`/signup/${campaign}`}
                  href={`/signup`}
                  rel="noopener noreferrer"
                  className="link"
                >
                  {chunks}
                </a>
              ),
              mail_link: (...chunks) => (
                <a href="mailto:support@newsguardtech.com" className="link">
                  {chunks}
                </a>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="message.ERROR"
            defaultMessage={<FormattedMessage id="message.ERROR" />}
            values={{
              signup_link: (...chunks) => (
                <a
                  target="_blank"
                  //href={`/signup/${campaign}`}
                  href={`/signup`}
                  rel="noopener noreferrer"
                  className="link"
                >
                  {chunks}
                </a>
              ),
              mail_link: (...chunks) => (
                <a href="mailto:support@newsguardtech.com" className="link">
                  {chunks}
                </a>
              ),
            }}
          />
        )}
      </div>
    </>
  );
};

export default Error;
