import styled from "@emotion/styled";

const SvgSTyles = styled.svg`
  --icon-height: ${(props) => props.height || "30px"};
  --icon-width: ${(props) => props.width || "45px"};
  height: var(--iconHeight);
  width: var(--iconWidth);
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "12px")};
  transform: translateY(-1px);
  flex-shrink: 0;
  /* border: 1px solid #e5e5e5; */

  @media screen and (max-width: 600px) {
    height: ${(props) => (props.label ? "30px" : "22px")};
    margin-right: 6px;
  }
`;

export default function PodcastIcon({ height, width, label, marginRight }) {
  return (
    <SvgSTyles
      height={height}
      width={width}
      label={label}
      marginRight={marginRight}
      x="0"
      y="0"
      viewBox="0 0 42 42"
      style={{ enableBackground: "new 0 0 31 31" }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Podcast</title>
      <g id="ii_x2F_branded_x2F_-podcast">
        <g id="Group-9" transform="translate(-0.000000, 0.000000)">
          <path
            id="Fill-1"
            style={{
              fillRrule: "evenodd",
              clipRule: "evenodd",
              fill: "#010202",
            }}
            d="M21,26.2c2.9,0,5.2-2.4,5.2-5.2s-2.4-5.2-5.2-5.2s-5.3,2.4-5.3,5.2S18.1,26.2,21,26.2"
          />
          <path
            id="Fill-3"
            style={{
              fillRrule: "evenodd",
              clipRule: "evenodd",
              fill: "#010202",
            }}
            d="M34.1,21c0-7.2-5.9-13.1-13.1-13.1S7.9,13.8,7.9,21c0,4.9,2.7,9.2,6.7,11.4l0.7-3.3
			C12.7,27.3,11,24.4,11,21c0-5.5,4.5-10,10-10s10,4.5,10,10c0,3.4-1.7,6.3-4.2,8.1l0.7,3.3C31.4,30.2,34.1,25.9,34.1,21"
          />
          <path
            id="Fill-5"
            style={{
              fillRrule: "evenodd",
              clipRule: "evenodd",
              fill: "#010202",
            }}
            d="M21,0C9.4,0,0,9.4,0,21c0,8.8,5.4,16.3,13,19.4l0.6-3.2C7.4,34.4,3.2,28.2,3.2,21
			c0-9.9,8-17.9,17.9-17.9s17.8,8,17.8,17.9c0,7.2-4.3,13.4-10.5,16.3l0.6,3.2c7.6-3.1,13-10.7,13-19.4C42,9.4,32.6,0,21,0"
          />
          <path
            id="Fill-7"
            style={{
              fillRrule: "evenodd",
              clipRule: "evenodd",
              fill: "#010202",
            }}
            d="M21,29.4c-1.4,0-2.6,0.3-3.8,0.8L15,41.1c1.9,0.6,3.9,0.9,6,0.9s4.1-0.3,6-0.9l-2.2-10.9
			C23.6,29.7,22.4,29.4,21,29.4"
          />
        </g>
      </g>
    </SvgSTyles>
  );
}
