import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/de";
import AuthService from "../../service/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import TOSAndPrivacyPolicy from "../TOSAndPrivacyPolicy";
import { useSelector } from "react-redux";
import { getLocale } from "../../common/_localeMessages";
import LoadingIndicator from "../../common/LoadingIndicator";

import { Title } from "../Subscription/SignupStyles";

const Wrapper = styled.div`
  width: 760px;
  margin: 0 auto;
  flex-direction: column;

  padding-bottom: 30px;
`;
const Text = styled.p`
  margin-bottom: 20px;
`;

const Redeemed = () => {
  let [loading, setLoading] = useState(true);
  let [activation, setActivation] = useState(false);
  const principal = useSelector((state) => state.authentication.principal);
  const navigate = useNavigate();

  const authService = AuthService.getInstance();

  useEffect(() => {
    getActivation();
  }, []);

  useEffect(() => {
    AuthService.getInstance().triggerUserSignIn();
    AuthService.getInstance().triggerUserVoucher();
  }, [activation]);

  const getActivation = () => {
    AuthService.getInstance()
      .getCustomerVoucherActivation()
      .then((response) => {
        if (response) {
          setActivation(response);
          return Promise.resolve(response);
        }
        return Promise.reject("NO_ACTIVATION");
      })
      .catch((error) => {
        navigate("/errorpage");
      })
      .finally((res) => setLoading(false));
  };

  moment.locale(getLocale());
  const validUntilDate = moment(activation.validUntil).format("LL");

  const isRegistered = authService.isRegisteredUser(principal);
  return loading ? (
    <LoadingIndicator className="large" />
  ) : (
    <Wrapper>
      <Title>
        <FormattedMessage
          id="template.voucher.redeemed"
          defaultMessage="Your Access Code Worked!"
        />
      </Title>
      <Text>
        <FormattedMessage
          id="template.voucher.redeemed.description"
          defaultMessage="Congratulations. You now have access to NewsGuard or HealthGuard on this device."
        />
      </Text>
      <Text>
        <FormattedMessage
          id="template.voucher.redeemed.description.2"
          defaultMessage="If you use NewsGuard on other devices, like a phone or laptop, be sure to enter your Access Code on each device to ensure you can use NewsGuard wherever you browse the internet."
        />
      </Text>
      <Text>
        <FormattedMessage
          id="template.voucher.will.expire"
          defaultMessage="This Access Code will expire on {dateValue}."
          values={{
            dateValue: validUntilDate,
          }}
        />
      </Text>
      {isRegistered ? (
        <FormattedMessage
          id="template.view_your_account"
          values={{
            account: (...chunks) => (
              <Link to="/account/accountsettings">{chunks}</Link>
            ),
          }}
          defaultMessage="View your <account>Account Information</account>"
        />
      ) : (
        " "
      )}
      <TOSAndPrivacyPolicy />
    </Wrapper>
  );
};

export default Redeemed;
