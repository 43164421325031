import React from "react";
import styled from "@emotion/styled/macro";
import { FormattedMessage, useIntl } from "react-intl";

import webCriteria from "../../../../../../../shared/resources/_criteria";
import webRatingsMap from "../../../../../../../shared/resources/_rating";

import tvCriteria from "../../../../../../../shared/resources/_tv_criteria.json";
import tvRatingsMap from "../../../../../../../shared/resources/_tv_rating.json";

import podcastCriteria from "../../../../../../../shared/resources/_podcast_criteria.json";
import podcastRatingsMap from "../../../../../../../shared/resources/_podcast_rating.json";

import Checkmark from "./Checkmark";
import Icon from "./Icon";
import LabelTypeBadge from "./common/LabelTypeBadge";
import { hasCriteria } from "./Helpers/misc";

const HeaderStyles = styled.header`
  display: ${(props) => (props.hasScore ? "block" : "flex")};
  align-items: flex-start;
  margin-top: ${(props) =>
    props.hasScore || props.isProvisional ? "0" : "9px"};
  flex-direction: column;
  gap: 10px;
`;

const ScoreAndDescriptionStyles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ScoreStyles = styled.div`
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 15px;

  & span {
    font-variation-settings: "wght" 300;
  }
  @media screen and (max-width: 600px) {
    font-size: 24px;
    span {
      font-variation-settings: "wght" 300;
    }
  }
  @media print {
    font-size: 18px;
    line-height: 20px;
  }
`;

const TVScore = styled(ScoreStyles)`
  margin-left: 48px; // for optical alignment
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    margin-left: 22px; // for optical alignment
    margin-bottom: 10px;
  }
`;

const RatingsDescriptionStyles = styled.p`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;

  margin: ${(props) =>
    props.isProvisional ? "0" : "20px 0"}; // aligns icon better with text */
  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin: 5px 0 10px;
  }

  @media print {
    font-size: 14px;
    line-height: 18px;
  }
`;

const RatingsNote = styled(RatingsDescriptionStyles)`
  text-transform: none;
  font-weight: 400;
`;

const ImportantDescription = styled.span`
  font-weight: 700;
`;

const TVRatingsDescription = styled(RatingsDescriptionStyles)`
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: ${(props) => (props.noMargin ? 0 : "inherit")};
  @media screen and (max-width: 600px) {
    font-size: 24px;
    margin: 5px 0;
  }
  @media screen and (max-width: 1200px) {
    line-height: 38px;
  }
`;

const CriteriaContainerStyles = styled.div`
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 6px solid #000;
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 14px;
  margin-bottom: 65px;
  @media screen and (max-width: 924px) {
    margin-bottom: 15px;
  }
`;

const CriteriaStyles = styled.div`
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
  display: flex;
  margin-bottom: 32px;
  align-items: center;

  @media print {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 22px;
  }
`;

const PostCriteriaStyles = styled(CriteriaStyles)`
  margin-bottom: 0;
  margin-top: 10px;
`;

const CriteriaText = styled.span`
  margin-right: 5px;
`;

const CriteriaNoteStyles = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-family: Sora;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;

  & a {
    color: var(--primary-blue);
    font-family: Sora;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  @media screen and (max-width: 925px) {
    margin-bottom: 35px;
  }
  @media print {
    display: none;
  }
`;

const Points = styled.span`
  display: inline-block;
  height: 22px;
  min-width: 22px;

  border-radius: 11px;
  border: none;
  background-color: rgba(0, 0, 0, 0.08);
  color: #000;
  font-size: 11px;
  line-height: 22px;
  text-align: center;
`;

const IconWrapper = styled.div`
  align-self: ${(props) => props.alignSelf || "center"};
  margin-left: -5px;
  height: 38px;

  @media screen and (max-width: 600px) {
    height: 22px;
  }
`;

function PointsDisplay({ points, hasLabel }) {
  return (
    <Points>
      &nbsp;&nbsp;{`${points} ${hasLabel ? " points " : ""}`}&nbsp;
    </Points>
  );
}

const ExtraNoteWrapper = styled.div`
  border-top: 4px solid rgba(0, 0, 0, 0.08);
  padding: 7px 30px 6px 0;
`;

const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 5px;
  flex-wrap: ${(props) => (props.hasLabelCriteria ? "wrap" : "nowrap")};
  margin-bottom: ${(props) => (props.hasLabelCriteria ? "0" : "15px")};
  flex-direction: column;
  gap: 10px;
`;

const Score = styled.div`
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0;
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const CurrentScore = styled.span`
  font-weight: 600;
`;

const RatingView = ({ label, children }) => {
  const intl = useIntl();
  const hasLabelCriteria = hasCriteria(label.rank);

  let key = "rating." + label.rank + ".description";
  const isTV = label.network === "TV";
  const isPodcast = label.network === "PODCAST";
  const isTVNetworkPublisher = label.publisherType === "TV_NETWORK";

  const learnMoreMap = {
    TV: {
      link: "https://www.newsguardtech.com/ratings/rating-process-criteria-tv/",
      id: "moreInfoLinktv",
    },
    PODCAST: {
      link: "https://www.newsguardtech.com/ratings/rating-process-criteria-podcasts/",
      id: "moreInfoLinkPodcast",
    },
    WEB: {
      link: "https://www.newsguardtech.com/ratings/rating-process-criteria/",
      id: "moreInfoLink",
    },
  };

  let totalLengthRating = 0;
  let totalHours = 0;
  let averageRating = 0;
  if (children) {
    children.map((child) => {
      const lengthData = child.metadata?.weekly_length;
      if (lengthData) {
        const length = parseInt(lengthData.body);
        totalLengthRating += length * child.score;
        totalHours += length;
      }
    });
    averageRating = Math.round((totalLengthRating / totalHours) * 100) / 100;
  }

  let criteriaMap = {};

  const getCriteria = () => {
    switch (label.network) {
      case "TV":
        return tvCriteria;
      case "PODCAST":
        return podcastCriteria;
      default:
        return webCriteria;
    }
  };

  const criteria = getCriteria();

  if (label.criteria && hasLabelCriteria) {
    Object.keys(label.criteria).forEach((key) => {
      const topic = label.criteria[key];
      criteriaMap[topic.title] = topic.body;
    });
  } else {
    Object.keys(criteria).forEach((key) => (criteriaMap[key] = "NA"));
  }

  const getRatingsMap = () => {
    switch (label.network) {
      case "TV":
        return tvRatingsMap;
      case "PODCAST":
        return podcastRatingsMap;
      default:
        return webRatingsMap;
    }
  };
  const ratingsMap = getRatingsMap();

  let rankValue = label.rank;
  if (rankValue === "FL") {
    rankValue = (label?.criteria && label.criteria["subrating"]?.body) || "FN";
  }
  let ratingInfo = ratingsMap[rankValue];

  return (
    <aside>
      <HeaderStyles hasScore={hasLabelCriteria} isProvisional={label.stub}>
        {isTV || isPodcast ? (
          <>
            <ScoreAndDescriptionStyles>
              <IconWrapper
                alignSelf={isTVNetworkPublisher ? "flex-start" : "center"}
              >
                <Icon rating={label.rank} height="38px" />
              </IconWrapper>
              <TVRatingsDescription
                hasScore={hasLabelCriteria}
                noMargin={isTVNetworkPublisher}
              >
                <FormattedMessage
                  id={key}
                  defaultMessage={ratingInfo.description}
                  values={{
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
                {isTVNetworkPublisher && !hasLabelCriteria && averageRating ? (
                  <RatingsNote>
                    Average Program Score:{" "}
                    <ImportantDescription>{averageRating}</ImportantDescription>
                  </RatingsNote>
                ) : (
                  ""
                )}
              </TVRatingsDescription>
            </ScoreAndDescriptionStyles>
            <ScoreAndDescriptionStyles>
              {hasLabelCriteria ? (
                <TVScore>
                  {label.score}
                  <span> / 10 </span>
                </TVScore>
              ) : null}
            </ScoreAndDescriptionStyles>
          </>
        ) : (
          <>
            <ScoreAndDescriptionStyles>
              {label.stub ? (
                <LabelTypeBadge
                  identifier={label.identifier}
                  rating={label.rank}
                  size="large"
                  type="full"
                />
              ) : (
                <IconWrapper>
                  <Icon rating={label.rank} height={"38px"} isWebsite large>
                    {hasLabelCriteria ? label.score : null}
                  </Icon>
                </IconWrapper>
              )}
            </ScoreAndDescriptionStyles>
            <RatingsDescriptionStyles
              hasScore={hasLabelCriteria}
              isProvisional={label.stub}
            >
              <FormattedMessage
                id={key}
                defaultMessage={ratingInfo.description}
                values={{
                  strong: (...chunks) => <strong>{chunks}</strong>,
                }}
              />
            </RatingsDescriptionStyles>
          </>
        )}
      </HeaderStyles>
      {hasLabelCriteria ? (
        <>
          <CriteriaContainerStyles>
            {Object.keys(criteria).map((key) => {
              if (key !== "rating" && key !== "subrating") {
                const topic = criteriaMap[key];

                if (topic) {
                  return (
                    <CriteriaStyles key={`criteria_${key}`}>
                      <Checkmark value={topic} />
                      <div>
                        <CriteriaText>
                          <FormattedMessage
                            id={`criteria.${key}.title`}
                            defaultMessage={
                              label.metadata?.category?.body === "network" &&
                              key === "newsOrganization"
                                ? criteria[key].networkTitle
                                : criteria[key].title
                            }
                          />
                        </CriteriaText>
                        <PointsDisplay
                          points={criteria[key].weight}
                          hasLabel={key === "falseContent"}
                        />
                      </div>
                    </CriteriaStyles>
                  );
                }
              }
              return null;
            })}
            {label?.metadata?.opinion?.body === "Yes" && isTV ? (
              <ExtraNoteWrapper>
                <PostCriteriaStyles>
                  <Checkmark value={"OPINIONATED"} />
                  <div>
                    <CriteriaText>
                      <FormattedMessage
                        id={123}
                        defaultMessage="Strongly Opinionated"
                      />
                    </CriteriaText>
                  </div>
                </PostCriteriaStyles>
              </ExtraNoteWrapper>
            ) : null}

            {isPodcast && label.metadata?.minimal_news?.body ? (
              <ExtraNoteWrapper>
                <PostCriteriaStyles>
                  <Checkmark value={"OPINIONATED"} />
                  <div>
                    <CriteriaText>Minimal News Content</CriteriaText>
                  </div>
                </PostCriteriaStyles>
              </ExtraNoteWrapper>
            ) : (
              ""
            )}
          </CriteriaContainerStyles>

          <CriteriaNoteStyles>
            <FormattedMessage
              id="msg.listImportance"
              defaultMessage={"Criteria are listed in order of importance"}
            />
            .{" "}
            <a
              href={intl.formatMessage({
                id: learnMoreMap[label.network].id,
                defaultMessage: learnMoreMap[label.network].link,
              })}
              target={"_blank"}
              rel="noreferrer"
            >
              <FormattedMessage
                id="msg.moreInfo"
                defaultMessage={"Learn More"}
              />
              .
            </a>
          </CriteriaNoteStyles>
        </>
      ) : null}
    </aside>
  );
};

export default RatingView;
