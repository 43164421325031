import { useCardLink } from "./useCardLink";
import Icon from "../Icon";
import {
  LabelDescriptionStyling,
  LabelMetaAndScoreContainer,
  LabelNameStyling,
  MetadataWrapper,
  PodcastScore,
  PodcastScoreWrapper,
  ScoreStyling,
  SearchResultStyles,
  TVScore,
  TVScoreWrapper,
  SearchResultLink,
  LabelNameAndDescriptionWrapper,
  FlagList,
  FlagListItem,
  SearchResultWrapper,
  CardSubhead,
  ActiveNotice,
} from "./SearchResultsStyles";
import TVIcon from "../common/svg/TVIcon";
import PodcastIcon from "../common/svg/PodcastIcon";
import React from "react";
import { Link } from "react-router-dom";
import { LocaleValue } from "../../../common/Helper";
import { getFlagValue } from "./Filters/WebAdvancedSearch";
import LabelTypeBadge from "../common/LabelTypeBadge";

const DomainCount = ({ associatedDomains = [] }) => {
  if (associatedDomains && associatedDomains.length) {
    return (
      <>
        <h4>Domains</h4>
        <span>{associatedDomains?.length + 1}</span>
      </>
    );
  }
  return null;
};

const Flags = ({ body, isStub }) => {
  let values = body;
  let flags = [];
  if (body) {
    if (!Array.isArray(body)) {
      values = body.split(",");
    }
    if (Array.isArray(values)) {
      values.map((flag) => flags.push(getFlagValue(flag, isStub)));
    }

    return (
      <FlagList>
        {flags.map((flag) => (
          <FlagListItem key={flag}>{flag}</FlagListItem>
        ))}
      </FlagList>
    );
  }
  return null;
};

const MythCount = ({ hasAccess, myths }) => {
  if (hasAccess && myths?.length) {
    return (
      <>
        <h4>False Narratives</h4>
        <span>{myths.length}</span>
      </>
    );
  }
  return null;
};

export default function LabelSearchResult({
  label,
  hasMythAccess,
  associatedMyths = [],
  localeLabels = [],
  siteOnline = true,
  associatedDomains = [],
}) {
  const [cardRef, linkRef] = useCardLink();

  if (label) {
    const isTV = label.network === "TV";
    const isPodcast = label.network === "PODCAST";
    const isStub = label.rank === "FL" || label.rank === "L";

    return (
      <SearchResultWrapper key={label.id} ref={cardRef}>
        <SearchResultStyles>
          <LabelNameAndDescriptionWrapper>
            <LabelNameStyling>
              {isTV ? (
                <TVIcon height="30px" width="45px" />
              ) : isPodcast ? (
                <PodcastIcon width="30px" />
              ) : isStub ? (
                <LabelTypeBadge rating={label.rank} />
              ) : (
                <Icon rating={label.rank} outline isWebsite />
              )}
              <SearchResultLink
                to={
                  label.profileId
                    ? `/partner/profile/${label.profileId}`
                    : `/partner/label/${label.id}`
                }
                ref={linkRef}
              >
                {label.groupName
                  ? `${label.groupName}`
                  : label.identifierAlt || label.identifier}
              </SearchResultLink>
            </LabelNameStyling>
            {label.publisherType === "TV_NETWORK" && (
              <CardSubhead>NETWORK</CardSubhead>
            )}
            <LabelDescriptionStyling
              dangerouslySetInnerHTML={{
                __html: label.topline ? label.topline : "",
              }}
            />

            <ActiveNotice>
              {siteOnline === false
                ? isTV || isPodcast
                  ? "This show is no longer on air."
                  : "This website is no longer online."
                : ""}
            </ActiveNotice>

            <Flags body={label.metadata?.flags?.body} isStub={isStub} />
          </LabelNameAndDescriptionWrapper>
          <LabelMetaAndScoreContainer>
            {label.rank !== "P" &&
            label.rank !== "S" &&
            label.rank !== "FL" &&
            label.rank !== "L" ? (
              isTV ? (
                <TVScoreWrapper>
                  <TVScore rank={label.rank}>{label.score}</TVScore>
                </TVScoreWrapper>
              ) : isPodcast ? (
                <PodcastScoreWrapper>
                  <PodcastScore rank={label.rank}>{label.score}</PodcastScore>
                </PodcastScoreWrapper>
              ) : (
                <ScoreStyling rank={label.rank}>
                  <div>{label.score}</div>
                </ScoreStyling>
              )
            ) : (
              <ScoreStyling>{/* <div /> */}</ScoreStyling>
            )}
            <MetadataWrapper>
              <DomainCount associatedDomains={associatedDomains} />
              {label.metadata?.brand?.body ? (
                <>
                  <h4>Brand</h4>

                  <span>{label.metadata?.brand.body}</span>
                </>
              ) : (
                ""
              )}

              {isTV &&
              label?.publisherType !== "TV_NETWORK" &&
              (label.parents?.length > 0 || label.metadata?.network?.body) ? (
                <>
                  <h4>Network</h4>
                  <span>
                    {label.parents?.length > 0
                      ? label.parents.join(", ")
                      : label.metadata?.network.body}
                  </span>
                </>
              ) : (
                ""
              )}
              {isTV &&
              label?.publisherType !== "TV_NETWORK" &&
              label.metadata?.timeslot?.body ? (
                <>
                  <h4>Time</h4>
                  <span>{label.metadata?.timeslot?.body}</span>
                </>
              ) : (
                ""
              )}

              <>
                <h4>Locale</h4>
                <span className="locale">
                  {localeLabels.map((localeLabel) => (
                    <div key={localeLabel.id}>
                      <Link to={`/partner/label/${localeLabel.id}`}>
                        {LocaleValue(localeLabel.country, localeLabel.language)}
                      </Link>
                    </div>
                  ))}
                </span>
              </>
              <MythCount myths={associatedMyths} hasAccess={hasMythAccess} />
            </MetadataWrapper>
          </LabelMetaAndScoreContainer>
        </SearchResultStyles>
      </SearchResultWrapper>
    );
  }
  return "";
}
