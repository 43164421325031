import { useEffect } from "react";
import AppReduxStore from "../AppReduxStore";
import { LOGOUT } from "../_actions";

const SignOut = () => {
  useEffect(() => {
    AppReduxStore.dispatch({ type: LOGOUT });
  }, []);
  return null;
};
export default SignOut;
