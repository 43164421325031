import styled from "@emotion/styled";

const PaginationArrowStyle = styled.li`
  width: 24px;
  cursor: pointer;
  transform: ${(props) =>
    props.side === "right" ? "rotate(90deg)" : "rotate(270deg)"};
`;

const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 10px;
  padding-top: ${(props) => (props.padding ? "40px" : "5px")};
`;

const PaginationList = styled.ul`
  font-size: 14px;

  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > span {
    display: inline-block;
    margin: 0 7px;
  }
`;

const PaginationItem = styled.li`
  font-size: 14px;
  text-align: center;
  height: 30px;
  width: 30px;
  line-height: ${(props) => (props.currentPage ? "28px" : "30px")};
  margin: 0 5px;
  border: ${(props) => (props.currentPage ? "0.5px solid #000000" : "none")};
  border-radius: ${(props) => (props.currentPage ? "50%" : "none")};
  cursor: ${(props) => (props.currentPage ? "auto" : "pointer")};
`;

const PaginationArrow = ({ side, onClick, empty }) => {
  return (
    <PaginationArrowStyle side={side} onClick={onClick} empty={empty}>
      {empty ? null : (
        <svg
          viewBox="0 0 60 60"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="ii/system/arrow-down" fill="#000000">
              <polygon
                id="Rectangle"
                transform="translate(29.750000, 25.750000) rotate(45.000000) translate(-29.750000, -25.750000) "
                points="41.5 14 41.5 37.5 18 37.5 18 34.5 38.499 34.5 38.5 14"
              ></polygon>
            </g>
          </g>
        </svg>
      )}
    </PaginationArrowStyle>
  );
};

export default function Pagination({
  currentPage,
  totalPages,
  getPage,
  getNextPage,
  getPreviousPage,
  padding,
}) {
  const PageLinks = ({ getPage }) => {
    const links = [];
    const begIndex = Math.max(1, currentPage - 2);
    const endIndex = Math.min(totalPages, begIndex + 4);

    if (begIndex > 2) {
      links.push(<span key={"initial-spacer"}>...</span>);
    }

    for (let i = begIndex; i <= endIndex; i++) {
      links.push(
        <PaginationItem
          key={i}
          currentPage={i === currentPage}
          onClick={i !== currentPage ? () => getPage(i) : null}
        >
          {i}
        </PaginationItem>
      );
    }

    if (endIndex < totalPages - 1) {
      links.push(<span key={"spacer"}>...</span>);
    }

    return <>{links}</>;
  };

  return totalPages > 1 ? (
    <PaginationWrapper aria-label="pagination" padding>
      <PaginationList>
        {currentPage > 1 ? (
          <PaginationArrow onClick={getPreviousPage} side="right" />
        ) : (
          <PaginationArrow empty />
        )}
        <PageLinks getPage={getPage} />
        {totalPages && currentPage < totalPages ? (
          <PaginationArrow right onClick={getNextPage} />
        ) : (
          <PaginationArrow empty />
        )}
      </PaginationList>
    </PaginationWrapper>
  ) : null;
}
