import styled from "@emotion/styled/macro";

const Checkbox = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid var(--primary-black);
  border-radius: 2px;
  height: calc(26 / 16 * 1rem);
  width: calc(26 / 16 * 1rem);

  accent-color: var(--primary-blue);

  &:checked {
    /* background-color: var(--primary-blue); */
    background-image: url("data:image/svg+xml,%3Csvg width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Eii/status selected%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/status-selected' fill='%233355DD'%3E%3Cpath d='M24 0 C25.1045695 -2.02906125e-16 26 0.8954305 26 2 L26 24 C26 25.1045695 25.1045695 26 24 26 L2 26 C0.8954305 26 1.3527075e-16 25.1045695 0 24 L0 2 C-1.3527075e-16 0.8954305 0.8954305 2.02906125e-16 2 0 L24 0 Z M18 9 L11.9987302 15 L8 11 L6 13 L12 19 L20 11 L18 9 Z' id='check'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    border: none;
  }
`;

export default Checkbox;
