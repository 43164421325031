import styled from "@emotion/styled/macro";

const ScoreFilterContainerStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -3px;
  @media screen and (max-width: 600px) {
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

/* from https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
const LabelStyle = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--primary-black);
  margin-right: 40px;
  margin-left: 3px;
  &:not(:first-of-type) {
    margin-right: 60px;
    @media screen and (max-width: 600px) {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-right: 10px;
  }

  .radio__label {
    line-height: 1;
    transition: 180ms all ease-in-out;
    opacity: 0.8;
  }

  .radio__input {
    display: flex;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:focus + .radio__control {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em var(--primary-blue);
      }
    }
  }

  input:checked + .radio__control {
    background: radial-gradient(
      var(--primary-blue) 50%,
      rgba(255, 0, 0, 0) 51%
    );
  }

  .radio__control {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid currentColor;
    transform: translateY(-0.05em);
    margin: -1px 15px 0 0;

    @media screen and (max-width: 600px) {
      margin-right: 5px;
    }
  }
  /* display: flex;
  align-items: center;
  margin-right: 30px;

  :nth-of-type(2) {
    margin-right: 60px;
  } */
`;

const RangeInputStyle = styled.input`
  height: 30px;
  width: 45px;
  accent-color: var(--primary-blue);
  border: 1px solid black;
  border-radius: 2px;
`;

const RangeDividerStyle = styled.span`
  display: inline-block;
  margin: 0 16px;
  color: ${(props) => (props.disabled ? "rgba(0,0,0,0.08)" : "#000")};

  @media screen and (max-width: 600px) {
    margin: 0 10px;
  }
`;

export default function ScoreFilter({ filter, onChange }) {
  const disabled = filter?.scoreType !== "range";
  return (
    <ScoreFilterContainerStyles>
      <LabelStyle className="radio radio-gradient">
        <span className="radio__input">
          <input
            type="radio"
            name="rangeType"
            onChange={(e) => onChange(e)}
            value="single"
            id="single"
            checked={filter?.scoreType === "single"}
          />
          <span className="radio__control"></span>
        </span>
        <span className="radio__label">Single</span>
      </LabelStyle>
      <LabelStyle className="radio radio-gradient">
        <span className="radio__input">
          <input
            type="radio"
            name="rangeType"
            onChange={(e) => onChange(e)}
            value="range"
            id="range"
            checked={filter?.scoreType === "range"}
          />
          <span className="radio__control"></span>
        </span>
        <span className="radio__label">Range</span>
      </LabelStyle>
      <div>
        <RangeInputStyle
          type="text"
          maxLength={4}
          name="score-low"
          onChange={onChange}
          pattern="[0-9.]+"
          value={filter.value.low}
          placeholder={"#"}
          max={100}
        />
        {!disabled && (
          <>
            <RangeDividerStyle disabled={disabled}>-</RangeDividerStyle>
            <RangeInputStyle
              type="text"
              maxLength={4}
              name="score-high"
              onChange={onChange}
              disabled={disabled}
              pattern="[0-9.]+"
              value={filter.value.high}
              placeholder="#"
              max={100}
            />
          </>
        )}
      </div>
    </ScoreFilterContainerStyles>
  );
}
