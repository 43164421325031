import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import Moment from "react-moment";
import MythMarker from "../common/MythMarker";
import PrintLogo from "../common/PrintLogo";
import Actions from "../common/Actions";
import Byline from "../Byline";
import AuthService from "../../../service/AuthService";
import Unauthorized from "../Unauthorized/Unauthorized";
import TVIcon from "../common/svg/TVIcon";
import PodcastIcon from "../common/svg/PodcastIcon";
import Icon from "../Icon";
import { getLocale } from "../../../common/_localeMessages";
import LabelTypeBadge from "../common/LabelTypeBadge";
import { MetadataList, MetadataListItem, MetadataUL } from "../Metadata";
import mythMetadataDefinitions from "../../../../../../../../shared/resources/_myth_metadata.json";

import Tip from "../common/Tooltip";
import {
  AdditionalInfo,
  ArchiveLink,
  AsideContainerStyles,
  AsideHeaderStyles,
  AsideWrapper,
  Column,
  DomainLink,
  DomainLinkWrapper,
  ExampleMetadata,
  IconWrapper,
  LinkWrapper,
  MythContainer,
  MythContainerWrapper,
  MythHeader,
  MythHeaderWrapper,
  MythHighlight,
  MythHighlightHeaderStyles,
  MythSectionHeaderStyles,
  MythSectionList,
  MythSectionListItemStyles,
  MythSectionTextStyles,
  MythVariationListItem,
  PageWrapper,
  PublishedDate,
  Wrapper,
  SpecialNote,
} from "./MythStyles";
import LoadingIndicator from "../../../common/LoadingIndicator";
import MythExampleLinkExcerpt from "./MythExampleLinkExcerpt";

const DomainLinkElement = ({ profiles, mythExampleLink }) => {
  const associatedProfiles = profiles.filter(
    (p) => p.publisherId === mythExampleLink.publisherId
  );
  if (associatedProfiles.length === 0) {
    return null;
  }
  return (
    <DomainLinkWrapper>
      {associatedProfiles.map((p, index) => (
        <div key={`${p.publisherId}-${index}`}>
          {p.rank === "FL" && (
            <LabelTypeBadge
              identifier={p.identifier}
              rating={p.rank}
              size="small"
              type="full"
            />
          )}
          <DomainLink to={`/partner/profile/${p.id}`}>
            {p.network === "TV" ? (
              <TVIcon height="30px" width="45px" />
            ) : p.network === "PODCAST" ? (
              <PodcastIcon width="30px" />
            ) : (
              p.rank !== "FL" && (
                <IconWrapper>
                  <Icon
                    rating={p.rank}
                    height={"20"}
                    isWebsite
                    marginRight={"5"}
                  >
                    {p.score}
                  </Icon>
                </IconWrapper>
              )
            )}
            {p.identifier} ({p.locale})
          </DomainLink>
        </div>
      ))}
    </DomainLinkWrapper>
  );
};

const Writeup = ({ myth }) => {
  let writeUp = myth.writeup;
  if (myth?.sortedWriteup) {
    writeUp = myth.sortedWriteup;
  }

  if (writeUp) {
    const keys = Object.keys(writeUp).sort();
    return keys.map((key) => {
      const blurb = writeUp[key];
      const title = blurb.title;
      return title !== "Name" ? (
        title === "myth variations" ? (
          ""
        ) : (
          <div key={key}>
            <MythSectionHeaderStyles>
              {title === "Description"
                ? "The " +
                  (myth.developing ? "Developing" : "False") +
                  " Narrative"
                : title === "Debunk"
                ? "The Facts"
                : blurb.title}
            </MythSectionHeaderStyles>

            {blurb.body.map((para, index) => (
              <MythSectionTextStyles
                dangerouslySetInnerHTML={{ __html: para }}
                key={index}
              />
            ))}
          </div>
        )
      ) : (
        ""
      );
    });
  }
  return null;
};

const MetadataListWrappper = ({ value, metadataDef, link = false }) => {
  return (
    value &&
    value.length > 0 && (
      <section>
        <AsideHeaderStyles>
          {metadataDef.title}
          <Tip>{metadataDef.displayInfo}</Tip>
        </AsideHeaderStyles>
        <MetadataList
          context={"MYTH"}
          value={value}
          metakey={metadataDef.key}
          link={link}
        />
      </section>
    )
  );
};

const MythView = ({
  myth,
  firstAppeared,
  mythExampleLinks,
  profiles,
  isMythExampleLinksLoading,
  mythMedia,
  isMythMediaLoading,
}) => {
  const dispatch = useDispatch();
  const principal = useSelector((state) => state.authentication?.principal);
  const componentRef = useRef();

  const FirstAppearedDateSection = () => {
    if (!firstAppeared) {
      return null;
    }
    return firstAppeared?.split("-").length > 2 ? (
      <p>
        <Moment format="MMMM DD, YYYY" locale={getLocale()}>
          {firstAppeared}
        </Moment>
      </p>
    ) : firstAppeared?.split("-").length > 1 ? (
      <p>
        <Moment format="MMMM, YYYY" locale={getLocale()}>
          {firstAppeared}
        </Moment>
      </p>
    ) : (
      <p>
        {" "}
        <Moment format="YYYY" locale={getLocale()}>
          {firstAppeared}
        </Moment>
      </p>
    );
  };

  useEffect(() => {
    if (myth) {
      const tagManagerArgs = {
        dataLayer: {
          event: "PartnerView",
          user: principal?.username,
          partner: principal?.attributes?.partner,
          view: "Label",
          content_type: "MYTH",
          content_title: myth.title || myth.identifier,
          content_locale: myth.locale,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  }, [myth, principal]);

  useEffect(() => {
    if (myth) {
      dispatch({
        type: "SET_LABEL_DOMAIN",
        payload: myth.title || myth.identifier,
      });
      dispatch({
        type: "SET_SEARCH_CONTEXT",
        payload: { value: "MYTH", label: "False Narratives" },
      });
    }
  }, [myth, dispatch]);

  const isThisMetadataEmpty = (value, name) => {
    if (value) {
      let list = [];
      if (Array.isArray(value)) {
        list = value;
      } else {
        list = value.split(",");
      }
      value = list;
      return value.length === 0;
    }
    return true;
  };

  const headerText = myth?.developing
    ? myth?.identifier
    : myth?.writeup?.name?.body;

  return myth ? (
    AuthService.getInstance().hasMythAccess(principal) ||
    AuthService.getInstance().hasRecentMythOnlyAccess(principal) ? (
      <PageWrapper ref={componentRef}>
        <PrintLogo />
        <MythHeaderWrapper>
          <MythMarker
            large
            developing={myth.developing}
            notProvablyFalse={myth.notProvablyFalse}
          />
          <MythHeader dangerouslySetInnerHTML={{ __html: headerText }} />
        </MythHeaderWrapper>

        <MythContainerWrapper>
          {myth.notProvablyFalse ? (
            <SpecialNote>
              This narrative was reviewed by NewsGuard’s team and determined not
              to be provably false.
            </SpecialNote>
          ) : (
            <>
              <Actions item={myth} el={componentRef} showLocale />

              <MythContainer>
                <div className="content-description">
                  <Writeup myth={myth} key={"writeup"} />
                  {myth?.variations && myth?.variations.length > 0 && (
                    <div>
                      <MythSectionHeaderStyles>
                        Variations of this False Narrative
                      </MythSectionHeaderStyles>
                      <ul>
                        {myth?.variations?.map((variation, index) => (
                          <MythVariationListItem
                            dangerouslySetInnerHTML={{
                              __html: variation.description,
                            }}
                            key={index}
                          />
                        ))}
                      </ul>
                    </div>
                  )}

                  {myth.developing ? (
                    ""
                  ) : (
                    <Byline label={myth} key={"byline"} />
                  )}

                  {mythExampleLinks && mythExampleLinks.length > 0 && (
                    <div>
                      <MythSectionHeaderStyles>
                        Examples of this{" "}
                        {myth.developing ? "Developing" : "False"} Narrative
                        <Tip>
                          A list of specific pieces of content—website pages,
                          social media posts, videos, etc.--that convey this
                          false narrative, with an exact quote or excerpt where
                          applicable.
                        </Tip>
                      </MythSectionHeaderStyles>
                      <MythSectionList>
                        {mythExampleLinks.map((mythExampleLink, index) => (
                          <MythSectionListItemStyles
                            key={`link-${index}`}
                            firstAppearance={mythExampleLink.firstAppearance}
                            hasFirstAppearanceDate={firstAppeared}
                          >
                            {mythExampleLink.firstAppearance && (
                              <MythHighlight>
                                <MythHighlightHeaderStyles>
                                  First Appearance
                                </MythHighlightHeaderStyles>
                                {mythExampleLink.dateTime ? (
                                  <PublishedDate>
                                    <Moment
                                      format="MM/DD/YYYY"
                                      locale={getLocale()}
                                    >
                                      {mythExampleLink.dateTime}
                                    </Moment>
                                  </PublishedDate>
                                ) : (
                                  <FirstAppearedDateSection />
                                )}
                              </MythHighlight>
                            )}

                            <LinkWrapper>
                              <a
                                href={mythExampleLink.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {mythExampleLink.link}
                              </a>
                            </LinkWrapper>

                            {mythExampleLink.excerpt ||
                            mythExampleLink.archiveLink ||
                            mythExampleLink.stateSponsored ? (
                              <AdditionalInfo>
                                {mythExampleLink.excerpt ? (
                                  <MythExampleLinkExcerpt
                                    excerpt={mythExampleLink.excerpt}
                                  />
                                ) : null}

                                <Wrapper>
                                  <Column>
                                    {mythExampleLink.dateTime &&
                                    !mythExampleLink.firstAppearance ? (
                                      <PublishedDate>
                                        <Moment
                                          format="MM/DD/YYYY"
                                          locale={getLocale()}
                                        >
                                          {mythExampleLink.dateTime}
                                        </Moment>
                                      </PublishedDate>
                                    ) : (
                                      ""
                                    )}
                                    <DomainLinkElement
                                      profiles={profiles}
                                      mythExampleLink={mythExampleLink}
                                    />
                                    {mythExampleLink.stateSponsored ? (
                                      <ExampleMetadata>
                                        <svg
                                          width="30px"
                                          height="30px"
                                          viewBox="0 0 60 60"
                                          version="1.1"
                                        >
                                          <title>Flag icon</title>
                                          <g
                                            id="Symbols"
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="#000"
                                            fillRule="evenodd"
                                          >
                                            <g
                                              id="ii/system/flag"
                                              fill="%23000000"
                                            >
                                              <path
                                                d="M20,15 L44,27 L23,37.5 L23,47 L20,47 L20,15 Z"
                                                id="Combined-Shape"
                                              ></path>
                                            </g>
                                          </g>
                                        </svg>
                                        <div>State-Controlled Source</div>
                                      </ExampleMetadata>
                                    ) : null}
                                  </Column>
                                  {mythExampleLink.archiveLink ? (
                                    <ArchiveLink
                                      href={mythExampleLink.archiveLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Archived
                                    </ArchiveLink>
                                  ) : (
                                    <div />
                                  )}
                                </Wrapper>
                              </AdditionalInfo>
                            ) : (
                              <AdditionalInfo>
                                <Wrapper>
                                  <Column>
                                    {mythExampleLink.dateTime ? (
                                      <PublishedDate>
                                        <Moment
                                          format="MM/DD/YYYY"
                                          locale={getLocale()}
                                        >
                                          {mythExampleLink.dateTime}
                                        </Moment>
                                      </PublishedDate>
                                    ) : (
                                      ""
                                    )}
                                    <DomainLinkElement
                                      profiles={profiles}
                                      mythExampleLink={mythExampleLink}
                                    />
                                  </Column>
                                </Wrapper>
                              </AdditionalInfo>
                            )}

                            {/* {mythExampleLink.dateTime ? (
                       <Moment format="MM/DD/YYYY" locale={getLocale()}>
                       {mythExampleLink.dateTime}
                       </Moment>
                       ) : (
                       ""
                       )} */}
                          </MythSectionListItemStyles>
                        ))}
                      </MythSectionList>
                    </div>
                  )}

                  {myth.metadata?.reliable_sources?.body && (
                    <div>
                      <MythSectionHeaderStyles>
                        Reliable Sources
                        <Tip>
                          A list of links to credible information refuting this
                          false narrative.
                        </Tip>
                      </MythSectionHeaderStyles>
                      <MetadataList
                        context={"MYTH"}
                        value={myth.metadata?.reliable_sources?.body}
                        link
                      />
                    </div>
                  )}
                </div>

                <AsideWrapper>
                  <AsideContainerStyles>
                    {isMythMediaLoading || isMythExampleLinksLoading ? (
                      <LoadingIndicator />
                    ) : !isThisMetadataEmpty(myth.metadata?.harm_risk?.body) ||
                      firstAppeared ||
                      !isThisMetadataEmpty(myth.metadata?.category?.body) ||
                      !isThisMetadataEmpty(
                        myth.metadata?.manipulated_media?.body
                      ) ||
                      (mythMedia && mythMedia.length > 0) ||
                      !isThisMetadataEmpty(myth.metadata?.location?.body) ||
                      !isThisMetadataEmpty(
                        myth.metadata?.spread_location?.body
                      ) ||
                      !isThisMetadataEmpty(myth.metadata?.subject_tags?.body) ||
                      !isThisMetadataEmpty(myth.metadata?.search_terms?.body) ||
                      !isThisMetadataEmpty(
                        myth.metadata?.hashtags_and_keywords?.body
                      ) ||
                      (!myth.developing && profiles && profiles.length > 0) ? (
                      <>
                        <MetadataListWrappper
                          value={myth.metadata?.harm_risk?.body}
                          metadataDef={mythMetadataDefinitions.harm_risk}
                        />
                        {firstAppeared && (
                          <section>
                            <AsideHeaderStyles>
                              {
                                mythMetadataDefinitions.date_first_appearance
                                  .dashboard_title
                              }
                              <Tip>
                                {
                                  mythMetadataDefinitions.date_first_appearance
                                    .displayInfo
                                }
                              </Tip>
                            </AsideHeaderStyles>
                            {FirstAppearedDateSection()}
                          </section>
                        )}

                        <MetadataListWrappper
                          value={myth.metadata?.category?.body}
                          metadataDef={mythMetadataDefinitions.category}
                          link={true}
                        />

                        <MetadataListWrappper
                          value={myth.metadata?.manipulated_media?.body}
                          metadataDef={
                            mythMetadataDefinitions.manipulated_media
                          }
                        />

                        {mythMedia && mythMedia.length > 0 && (
                          <section>
                            <AsideHeaderStyles>Media Files</AsideHeaderStyles>
                            <MetadataUL>
                              {mythMedia?.map((media, index) => (
                                <MetadataListItem key={index} type={media.type}>
                                  <Link
                                    to={`/partner/myth/profile/${myth.mythProfileId}/media/${media.dbFile.id}`}
                                    target="_blank"
                                  >
                                    {media.dbFile.fileName}
                                  </Link>
                                </MetadataListItem>
                              ))}
                            </MetadataUL>
                          </section>
                        )}

                        <MetadataListWrappper
                          value={myth.metadata?.location?.body}
                          metadataDef={mythMetadataDefinitions.location}
                        />

                        <MetadataListWrappper
                          value={myth.metadata?.spread_location?.body}
                          metadataDef={mythMetadataDefinitions.spread_location}
                        />

                        <MetadataListWrappper
                          value={myth.metadata?.subject_tags?.body}
                          metadataDef={mythMetadataDefinitions.subject_tags}
                          link={true}
                        />

                        <MetadataListWrappper
                          value={myth.metadata?.search_terms?.body}
                          metadataDef={mythMetadataDefinitions.search_terms}
                        />

                        <MetadataListWrappper
                          value={myth.metadata?.hashtags_and_keywords?.body}
                          metadataDef={
                            mythMetadataDefinitions.hashtags_and_keywords
                          }
                        />

                        {!myth.developing &&
                          profiles &&
                          profiles.length > 0 && (
                            <section>
                              <AsideHeaderStyles>
                                Associated Labels
                                <Tip>
                                  Sources rated by NewsGuard that were found to
                                  have published content containing this false
                                  narrative.
                                </Tip>
                              </AsideHeaderStyles>
                              {profiles.map((profile) => (
                                <p key={profile.id}>
                                  <Link to={`/partner/profile/${profile.id}`}>
                                    {profile.identifier} ({profile.locale})
                                  </Link>
                                </p>
                              ))}
                            </section>
                          )}
                      </>
                    ) : (
                      <div>Additional data to come....</div>
                    )}
                  </AsideContainerStyles>

                  {myth.developing ? (
                    ""
                  ) : (
                    <>
                      <div>
                        Published:{" "}
                        <Moment format="MMMM DD, YYYY" locale={getLocale()}>
                          {myth.firstPublishedDate}
                        </Moment>
                      </div>
                      <div>
                        Last Updated:{" "}
                        <Moment format="MMMM DD, YYYY" locale={getLocale()}>
                          {myth.lastPublishedDate || myth.createdDate}
                        </Moment>
                      </div>
                    </>
                  )}
                </AsideWrapper>
              </MythContainer>
            </>
          )}
        </MythContainerWrapper>
      </PageWrapper>
    ) : (
      <Unauthorized myth={true} />
    )
  ) : (
    ""
  );
};

export default MythView;
