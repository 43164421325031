import ReportPublishedSearchResult from "./ReportPublishedSearchResult";

export default function ReportSearchResult({ labels, index, metadata }) {
  const localeLabels = labels.map((label) => {
    return {
      id: label.id,
      locale: label.locale,
      country: label.country,
      language: label.language,
      reportMainId: label.reportMainId,
    };
  });

  return (
    <ReportPublishedSearchResult
      report={labels[0]}
      index={index}
      metadata={metadata}
      localeLabels={localeLabels}
    />
  );
}
