const Step2Edge = ({ active }) =>
  active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 50 50"
    >
      <circle cx="25.2" cy="25" r="25" fill="#35d" />

      <path
        d="M12.2 13.2v4.9h1.6v-4.9h4.9v-1.6h-6.5v1.6zM37 37.9h-5v-1.6h5v-4.9h1.6v6.5H37zm1.6-26.4V18H37v-4.9h-5v-1.6h6.6zM12.2 36.3v-4.9h1.6v4.9h4.9v1.6h-6.5v-1.6zM20.9 24.2c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l3.6 3.6c.3.3.7.3 1 0l7.8-7.8c.3-.3.3-.7 0-1-.3-.3-.7-.3-1 0L24 27.3l-3.1-3.1z"
        style={{ fill: "#fff", fillRule: "evenodd" }}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 50 50"
    >
      <circle
        cx="25.2"
        cy="25"
        r="25"
        style={{ isolation: "isolate", opacity: 0.08 }}
      />

      <path
        d="M12.2 13.2v4.9h1.6v-4.9h4.9v-1.6h-6.5v1.6zM37 37.9h-5v-1.6h5v-4.9h1.6v6.5H37zm1.6-26.4V18H37v-4.9h-5v-1.6h6.6zM12.2 36.3v-4.9h1.6v4.9h4.9v1.6h-6.5v-1.6zM20.9 24.2c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l3.6 3.6c.3.3.7.3 1 0l7.8-7.8c.3-.3.3-.7 0-1-.3-.3-.7-.3-1 0L24 27.3l-3.1-3.1z"
        style={{ fillRule: "evenodd" }}
      />
    </svg>
  );

export default Step2Edge;
