const Step3 = ({ active }) =>
  active ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <defs>
        <mask
          id="a"
          x="15"
          y="15"
          width="20"
          height="20"
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M18.92 25.24a.83.83 0 1 0-1.18 1.18l4.17 4.17a.85.85 0 0 0 1.18 0l9.17-9.17a.83.83 0 1 0-1.18-1.18l-8.58 8.58Z"
            style={{ fill: "#fff", fillRule: "evenodd" }}
          />
        </mask>
      </defs>

      <g data-name="mod/-steps">
        <circle cx="25" cy="25" r="25" style={{ fill: "#35d" }} />
        <path
          data-name="path-1"
          d="M18.92 25.24a.83.83 0 1 0-1.18 1.18l4.17 4.17a.85.85 0 0 0 1.18 0l9.17-9.17a.83.83 0 1 0-1.18-1.18l-8.58 8.58Z"
          style={{ fill: "#fff", fillRule: "evenodd" }}
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <defs>
        <mask
          id="a"
          x="15"
          y="15"
          width="20"
          height="20"
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M18.92 25.24a.83.83 0 1 0-1.18 1.18l4.17 4.17a.85.85 0 0 0 1.18 0l9.17-9.17a.83.83 0 1 0-1.18-1.18l-8.58 8.58Z"
            style={{ fill: "#fff", fillRule: "evenodd" }}
          />
        </mask>
      </defs>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="insights/-register-account-filled-[new]-password">
            <g data-name="mod/signin/drawer-copy">
              <g data-name="mod/-steps">
                <circle
                  cx="25"
                  cy="25"
                  r="25"
                  style={{ isolation: "isolate", opacity: 0.08 }}
                />
                <path
                  data-name="path-1"
                  d="M18.92 25.24a.83.83 0 1 0-1.18 1.18l4.17 4.17a.85.85 0 0 0 1.18 0l9.17-9.17a.83.83 0 1 0-1.18-1.18l-8.58 8.58Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

export default Step3;
