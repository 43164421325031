import styled from "@emotion/styled";
import { useSortBy, useTable } from "react-table";
import MythTableColumns from "./MythTableColumns";
import ReportTableColumns from "./ReportTableColumns";
import DevelopingNarrativeTableColumns from "./DevelopingNarrativeTableColumns";

const TableWrapper = styled.div`
  max-height: "auto";
  min-height: "auto";
  overflow-x: "auto";
  @media screen and (max-width: 600px) {
    width: 100%;
    overflow: scroll;
  }
`;

const TableStylings = styled.table`
  color: #000000;
  font-family: Sora;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 22px;
  padding: 0 5px;

  @media screen and (max-width: 600px) {
    margin-top: 5px;
  }

  tr {
    vertical-align: top;
  }

  th {
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 15px;
  }

  //hack to target column
  th:nth-of-type(4n) {
    padding-left: 20px;
  }

  td {
    padding-bottom: 15px;
    padding-right: 10px;
  }

  .sort {
    position: relative;
    display: inline;
  }

  .date {
    display: flex;
    width: 175px;

    &--month-day {
      width: 100px;
    }
    &--time {
      width: 75px;
      text-align: left;
    }
  }

  .note {
    white-space: nowrap;
  }

  .region {
    text-transform: uppercase;
    text-align: right;
  }

  .label-link {
    text-decoration: none;
    color: #000000;
  }

  .disabled {
    color: #888888;
  }
`;

const SortIconStyling = styled.svg`
  width: 36px;
  cursor: pointer;
  height: 35px;
  position: absolute;
  top: -7px;
  left: 1px;
  /* top: ${(props) => (props.open ? "18px" : "-5px")}; */

  transform: ${(props) => (props.ascending ? "rotate(180deg)" : "none")};
`;

const SortIcon = (props) => (
  <div className="sort">
    <SortIconStyling
      ascending={props.ascending}
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ii/system/arrow-up-small" fill="#000000">
          <polygon
            id="Rectangle"
            transform="translate(29.995942, 26.750000) rotate(45.000000) translate(-29.995942, -26.750000) "
            points="36.7459415 20 36.7459415 33.5 23.2459415 33.5 23.2459415 30.5 33.7459415 30.4993593 33.7459415 20"
          ></polygon>
        </g>
      </g>
    </SortIconStyling>
  </div>
);

export const TitleSpan = styled.span`
  display: flex;
  align-items: "flex-start";

  @media screen and (max-width: 600px) {
    align-items: flex-start;
  }
`;

export default function RecentTable(props) {
  const { contents } = props;

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
        },
        useSortBy
      );

    return (
      <>
        <TableWrapper>
          <TableStylings {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props

                    <th
                      width={column.width}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortIcon />
                        ) : (
                          <SortIcon ascending />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableStylings>
        </TableWrapper>
      </>
    );
  }

  return (
    <>
      <Table
        columns={
          props.id === "report"
            ? ReportTableColumns(props)
            : props.id === "developing_narrative"
            ? DevelopingNarrativeTableColumns(props)
            : MythTableColumns(props)
        }
        data={contents}
      />
    </>
  );
}
