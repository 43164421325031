import React from "react";
import { useParams } from "react-router-dom";

import { useMythExampleLinks, useMythMedia, useMyths } from "./useMyths";
import LoadingIcon from "../common/LoadingIcon";
import MythView from "./MythView";

const Myth = (props) => {
  const mythId = useParams().mythId || props.mythId;
  const profileId = useParams().profileId || props.profileId;

  const { myth, isLoading, firstAppeared, isError } = useMyths(
    profileId,
    mythId
  );
  const { mythExampleLinks, profiles, isMythExampleLinksLoading } =
    useMythExampleLinks(myth);

  const { mythMedia, isMythMediaLoading } = useMythMedia(myth);

  return isLoading ? (
    <LoadingIcon />
  ) : (
    <MythView
      myth={myth}
      firstAppeared={firstAppeared}
      mythExampleLinks={mythExampleLinks}
      profiles={profiles}
      isMythExampleLinksLoading={isMythExampleLinksLoading}
      mythMedia={mythMedia}
      isMythMediaLoading={isMythMediaLoading}
    />
  );
};

export default Myth;
