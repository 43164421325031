import styled from "@emotion/styled/macro";
import React, { useState } from "react";
import { AlertTriangle, Eye, EyeOff, Mail } from "react-feather";
import ErrorMessageView from "../../common/ErrorMessageView";

export const GroupWrapper = styled.div`
  position: relative;
  max-width: ${(props) =>
    props.update ? "100%" : props.medium ? "500px" : "360px"};
  ${(props) =>
    props.icon
      ? `&::after {
      content: "";
      position: absolute;
      top: 18px;
      right: 20px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-image: url("/assets/icons/svg/chevron-down.svg");
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;
`
      : ""}
`;

export const Label = styled.label`
  font-family: Sora;
  font-size: var(--12px);
  font-weight: 400;
  line-height: var(--15px);
  padding: 0;
  margin: 0;
  font-weight: 400;
  display: block;
  transition: transform 0.3s;
  text-align: left;
  transform: translateY(-84px);
  width: -moz-fit-content;
  width: fit-content;

  ${(props) =>
    props.error || props.serverError
      ? `opacity: 0;
    visibility: hidden;
    transform: translateY(-2.5rem);`
      : ""}
`;
export const Input = styled.input`
  border: ${(props) =>
    props.error || props.serverError
      ? "1px solid #ff4444 "
      : "1px solid var(--primary-black)"};
  border-radius: 2px;
  font-size: var(--16px);
  line-height: var(--20px);
  font-weight: 400;
  margin-bottom: ${(props) =>
    props.account ? "8px" : "23px"}; // removing some space for the hidden label
  padding: var(--10px) var(--12px);
  background-clip: padding-box;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-size: var(--14px);
  }

  &:placeholder-shown + ${Label} {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2.5rem);
  }

  &:user-invalid {
    outline: 1px solid red;
    border: 1px solid red;
  }
`;

export const ErrorMessage = styled.div`
  color: #ff4444;
  font-family: Sora;
  font-size: 12px;
  font-weight: 400;
  line-height: var(--15px);

  position: relative;
  top: -7px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputModule = ({
  type,
  identifier,
  autoComplete,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  onInvalid,
  error,
  serverError,
  maxLength,
  minLength,
  form,
  update,
  account,
  medium,
  onFocus,
  pattern,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleChangePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <GroupWrapper update={update} medium={medium}>
      {error ? (
        <ErrorMessage className="form__error">
          <ErrorMessageView error={error} />
        </ErrorMessage>
      ) : serverError ? (
        <ErrorMessage className="form__error">
          <ErrorMessageView error={serverError} />
        </ErrorMessage>
      ) : (
        ""
      )}
      <InputWrapper>
        <Input
          type={
            (identifier === "password" || identifier === "confirmPassword") &&
            showPassword
              ? "text"
              : type
          }
          id={identifier}
          name={identifier}
          autoComplete={autoComplete}
          placeholder={`*${placeholder}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          onInvalid={onInvalid}
          error={error}
          serverError={serverError}
          minLength={minLength}
          maxLength={maxLength}
          form={form}
          account={account}
          onFocus={onFocus}
          pattern={pattern}
        />
        <Label htmlFor={identifier} error={error || serverError}>
          {placeholder}
        </Label>
        <IconWrapper
          style={{
            cursor: identifier === "password" && error ? "auto" : "pointer",
          }}
          onClick={
            identifier === "password" || identifier === "confirmPassword"
              ? handleChangePasswordVisibility
              : () => {}
          }
        >
          {error || serverError ? (
            <AlertTriangle color={"red"} size={18} />
          ) : identifier === "password" || identifier === "confirmPassword" ? (
            showPassword ? (
              <EyeOff size={16} />
            ) : (
              <Eye size={16} />
            )
          ) : identifier === "email" ? (
            <Mail size={16} />
          ) : null}
        </IconWrapper>
      </InputWrapper>
    </GroupWrapper>
  );
};
export default InputModule;
