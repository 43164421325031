import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import React from "react";
import TVIcon from "../common/svg/TVIcon";
import PodcastIcon from "../common/svg/PodcastIcon";
import { localeValueTransformer } from "../Helpers/misc";

const TextStyle = styled.p`
  font-size: 16px;
  line-height: 30px;

  &:first-of-type {
    margin-bottom: 20px;
  }
`;

const LinkStyle = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ConnectedRatings = ({ labels }) => {
  const hasLabels = labels ? labels.length > 0 : false;

  return (
    <>
      {hasLabels ? (
        <div>
          <TextStyle>
            If NewsGuard has rated other media—such as websites, TV programs or
            networks, or podcasts—related to this news outlet, they are listed
            below.
          </TextStyle>
          {labels.map((label) => (
            <TextStyle>
              <LinkStyle to={`/partner/profile/${label.profileId}`}>
                {label.network === "TV" ? (
                  <TVIcon height="30px" width="45px" />
                ) : label.network === "PODCAST" ? (
                  <PodcastIcon width="30px" />
                ) : (
                  ""
                )}
                {label.groupName
                  ? `${label.groupName}`
                  : label.identifierAlt || label.identifier}{" "}
                (
                <span className="locale">
                  {localeValueTransformer(label.locale)}
                </span>
                )
              </LinkStyle>
            </TextStyle>
          ))}
        </div>
      ) : (
        <p>This label has no associated domains.</p>
      )}
    </>
  );
};

export default ConnectedRatings;
