import styled from "@emotion/styled/macro";
import {
  AccountMessage,
  AccountMessageSpecial,
  AccountMessageWrapper,
} from "./SignupStyles";
import { FormattedMessage } from "react-intl";
import Checkbox from "../FormElements/Checkbox";
import ErrorMessageView from "../../common/ErrorMessageView";
import React, { useState } from "react";

const PersonalUseElement = ({
  personalUseAccepted,
  setPersonalUseAccepted,
  personalUseAcceptedError,
  setPersonalUseAcceptedError,
}) => {
  const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  `;

  return (
    <div
      className={`form__group form__group--checkbox form__group--personal-use ${
        personalUseAcceptedError
          ? "checkout__input--invalid checkout__input--confirm"
          : ""
      }`}
    >
      <AccountMessageWrapper>
        <AccountMessage>
          <FormattedMessage
            id="template.personal_use_acknowledgement"
            defaultMessage="I acknowledge that NewsGuard is for personal use only. I agree not to use NewsGuard for any commercial, research, or nonpersonal use, such as academic research, curating advertising inventory, training or enhancing artificial intelligence models, including generative AI models and chatbots, or moderating content on a digital platform, unless expressly authorized to do so by NewsGuard."
          />
          <br />
          <br />
        </AccountMessage>
      </AccountMessageWrapper>

      {personalUseAcceptedError ? (
        <AccountMessageSpecial>
          <CheckboxWrapper>
            <Checkbox
              type="checkbox"
              className="form__control form__control--checkbox personalUseAccepted"
              id="personalUseAccepted"
              name="personalUseAccepted"
              checked={personalUseAccepted}
              onChange={(event) => {
                setPersonalUseAccepted(event.target.checked);
                // setPersonalUseAcceptedError(null);
              }}
              required
            />
            <label htmlFor="personalUseAccepted">
              <FormattedMessage
                id="template.IAgree"
                defaultMessage={"I Agree"}
              />
            </label>
          </CheckboxWrapper>
          <ErrorMessageView error={personalUseAcceptedError} />
        </AccountMessageSpecial>
      ) : (
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            className="form__control form__control--checkbox personalUseAccepted"
            id="personalUseAccepted"
            name="personalUseAccepted"
            checked={personalUseAccepted}
            onChange={(event) => {
              setPersonalUseAccepted(event.target.checked);
              setPersonalUseAcceptedError(null);
            }}
            required
          />
          <label htmlFor="personalUseAccepted">
            <FormattedMessage id="template.IAgree" defaultMessage={"I Agree"} />
          </label>
        </CheckboxWrapper>
      )}
    </div>
  );
};

export default PersonalUseElement;
