import { useEffect, useReducer, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { useDispatch } from "react-redux";
import AuthService from "../service/AuthService";
import { FormattedMessage, useIntl } from "react-intl";
import LoadingIndicator from "../common/LoadingIndicator";
import ConfigService from "../service/ConfigService";
import TOSAndPrivacyPolicy from "./TOSAndPrivacyPolicy";
import AccountService from "../service/AccountService";

import { Fieldset, Form, LinkButton, Title } from "./Subscription/SignupStyles";
import InputModule from "./FormElements/InputModule";
import { Button } from "./FormElements/Button";
import ErrorMessage from "./FormElements/ErrorMessage";
import Checkbox from "./FormElements/Checkbox";
import OAuthButtons from "./OAuthButtons/OAuthButtons";
import { useCookies } from "react-cookie";

const Container = styled.div`
  width: 360px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  @media screen and (max-width: 600px) {
    width: auto;
  }
`;

const SecondaryLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & p:last-child {
    text-align: right;
  }
`;

const CheckboxAndWrapper = styled.div`
  padding-bottom: 24px;
  @media screen and (max-width: 600px) {
    margin-right: 10px;
    margin-bottom: 5px;
  }
`;

const Label = styled.label`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GroupWrapper = styled.div`
  position: relative;
`;

function SignIn() {
  const authService = AuthService.getInstance();
  const accountService = AccountService.getInstance();
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: authService.getEmail(),
      password: "",
      remember_email: false,
    }
  );

  const navigate = useNavigate();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [html5error, setHtml5error] = useState({});
  const [validated, setValidated] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["campaign", "lmref"]);
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    authService.apiLogout();
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;

    let inputValue = target.value;

    if (inputName === "remember_email") {
      inputValue = target.checked;
    }
    setHtml5error((prevState) => ({
      ...prevState,
      [inputName]: undefined,
    }));
    setFormFields({ [inputName]: inputValue });
  };

  const handleSubmit = (event) => {
    setLoading(true);
    setError(null);
    setHtml5error({});

    event.preventDefault();

    if (validate(event)) {
      if (formFields.remember_email) {
        authService.saveEmail(formFields.email);
      } else {
        authService.saveEmail("");
      }

      authService
        .passwordFlow(formFields)
        .then(() => {
          removeCookie("campaign");
          removeCookie("lmref");

          accountService
            .retrieveUserInfoFromServer()
            .then(() => {
              ConfigService.getInstance().resetShownActiveSubs();
              sessionStorage.removeItem("_auth_return_location");
              navigate(from, { replace: true });
            })
            .catch((error) => {
              setError(error);
              navigate(from, { replace: true });
            });
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const checkIsValid = (e) => {
    if (e.target.checkValidity() === false) {
      e.target.parentNode.classList.add("was-validated");
    }
  };

  const validate = (event) => {
    setValidated(true);
    return event.target.checkValidity();
  };

  return (
    <Container>
      <Title>
        <FormattedMessage
          id="template.sign_in_with_email"
          defaultMessage="Sign in with Email"
        />
      </Title>
      <Form
        className={` ${validated ? "was-validated" : ""}`}
        onSubmit={handleSubmit}
        noValidate
        id="signin-form"
      >
        <Fieldset disabled={loading} aria-busy={loading}>
          <GroupWrapper>
            <InputModule
              type="email"
              identifier="email"
              placeholder={intl.formatMessage({
                id: "template.email",
                defaultMessage: "Email",
              })}
              value={formFields.email}
              onChange={handleInputChange}
              onBlur={checkIsValid}
              required
              onInvalid={() => {
                let email = {
                  id: "template.email_invalid_message",
                  defaultMessage: "Please enter a valid email address",
                  name: "email",
                  html: true,
                };

                setHtml5error((prevState) => ({
                  ...prevState,
                  email,
                }));
              }}
              error={html5error?.email}
              autoComplete="email"
            />
          </GroupWrapper>
          <GroupWrapper>
            <InputModule
              type="password"
              identifier="password"
              placeholder={intl.formatMessage({
                id: "template.password",
                defaultMessage: "Password",
              })}
              value={formFields.password}
              onChange={handleInputChange}
              onBlur={checkIsValid}
              autoComplete="password"
              required
              onInvalid={() => {
                let passwordMsg = {
                  id: "message.password_length",
                  defaultMessage:
                    "Please enter a password that is 8 characters or longer",
                  name: "password",
                  html: true,
                };
                setHtml5error((prevState) => ({
                  ...prevState,
                  password: passwordMsg,
                }));
              }}
              error={html5error.password}
              minLength={8}
            />
          </GroupWrapper>
          <CheckboxAndWrapper>
            <Label>
              <Checkbox
                type="checkbox"
                id="remember_email"
                name="remember_email"
                onChange={handleInputChange}
                checked={formFields.remember_email}
              />

              <FormattedMessage
                id="template.remember_my_email"
                defaultMessage="Remember my email"
              />
            </Label>
          </CheckboxAndWrapper>
          {error ? <ErrorMessage error={error} /> : ""}

          <Button type="submit">
            {loading ? (
              <LoadingIndicator button />
            ) : (
              <FormattedMessage id="template.signin" defaultMessage="Sign in" />
            )}
          </Button>

          <SecondaryLinksWrapper>
            <p>
              <Link to="/forgotPassword" className="link">
                <FormattedMessage
                  id="template.forgot_password"
                  defaultMessage="Forgot Password?"
                />
              </Link>
            </p>
            <p>
              <FormattedMessage
                id="template.new_user"
                defaultMessage="New User?"
              />{" "}
              <Link to="/signup" className="link">
                <FormattedMessage
                  id="template.signup"
                  defaultMessage="Sign Up"
                />
                !
              </Link>
            </p>
          </SecondaryLinksWrapper>
        </Fieldset>
      </Form>

      <OAuthButtons location="signin" />
      <LinkButton to="/redeem">
        <FormattedMessage
          id="template.voucher.do_you_have_an_access_code"
          defaultMessage="Have an access code?"
        />
      </LinkButton>

      <TOSAndPrivacyPolicy partner />
    </Container>
  );
}

export default SignIn;
