export const LOGOUT = "LOGOUT";
export const INITIAL = "INITIAL";
export const APP_CONFIG = "APP_CONFIG";
export const GET_PRINCIPAL = "GET_PRINCIPAL";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_SEARCH_TERM = "DELETE_SEARCH_TERM";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_LABEL_DOMAIN = "SET_LABEL_DOMAIN";
export const SET_SEARCH_CONTEXT = "SET_SEARCH_CONTEXT";
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const DELETE_SELECTED_LABEL = "DELETE_SELECTED_LABEL";
export const SET_SELECTED_LABEL = "SET_SELECTED_LABEL";
export const SET_SUBSCRIBED = "SET_SUBSCRIBED";
export const SET_SUSPENDED = "SET_SUSPENDED";
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_CRITERIA_FILTERS = "SET_CRITERIA_FILTERS";
export const SET_SCORE_FILTERS = "SET_SCORE_FILTERS";
export const SET_SCORE_TYPE = "SET_SCORE_TYPE";
export const RESET_SELECTED_FILTER = "RESET_SELECTED_FILTER";
export const SET_SELECTED_RATINGS_FILTERS = "SET_SELECTED_RATINGS_FILTERS";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
export const RESET_ALL_RATINGS_FILTERS = "RESET_ALL_RATINGS_FILTERS";
export const RESET_SELECTED_RATINGS_FILTER = "RESET_SELECTED_RATINGS_FILTER";
export const RESET_ALL_SORT_FILTERS = "RESET_ALL_SORT_FILTERS";

/*
 * action creators
 */

export function initial() {
  return { type: INITIAL };
}

export function appConfig(appConfig) {
  return { type: APP_CONFIG, appConfig };
}

export function logout() {
  return { type: LOGOUT };
}

export function getPrincipal(principal, authenticated) {
  return { type: GET_PRINCIPAL, principal, authenticated };
}

export function updateUser(user) {
  return { type: UPDATE_USER, user };
}

export function setSelectedSearchTerm(term) {
  return { type: SET_SEARCH_TERM, payload: term };
}

export function deleteSelectedSearchTerm() {
  return { type: DELETE_SEARCH_TERM, payload: null };
}

export function setSelectedSearchContext(context) {
  return { type: SET_SEARCH_CONTEXT, payload: context };
}

export function setSearchPage(page) {
  return { type: SET_SEARCH_PAGE, payload: page || 0 };
}

export function setSelectedLabel(label) {
  return { type: SET_SELECTED_LABEL, payload: label };
}

export function deleteSelectedLabel() {
  return { type: DELETE_SELECTED_LABEL, payload: null };
}

export function setSubscribed(subscribed) {
  return { type: SET_SUBSCRIBED, payload: subscribed };
}

export function setSuspended(suspended) {
  return { type: SET_SUSPENDED, payload: suspended };
}

export function setSelectedFilters(name, value, scoreContext, principal) {
  return {
    type: SET_SELECTED_FILTERS,
    payload: { name, value, scoreContext, principal },
  };
}

export function setCriteriaFilters(filters, scoreContext, principal) {
  return {
    type: SET_CRITERIA_FILTERS,
    payload: { filters, scoreContext, principal },
  };
}

export function setScoreFilters(
  scoreValueType,
  scoreContext,
  value,
  principal
) {
  return {
    type: SET_SCORE_FILTERS,
    payload: { scoreValueType, scoreContext, value, principal },
  };
}

export function setScoreType(scoreType, scoreContext, principal) {
  return {
    type: SET_SCORE_TYPE,
    payload: { scoreType, scoreContext, principal },
  };
}

export function resetSelectedFilter(filter, index) {
  return { type: RESET_SELECTED_FILTER, payload: { filter, index } };
}

export function resetAllFilters() {
  return { type: RESET_ALL_FILTERS, payload: null };
}

export function setSelectedRatingFilters(name, value) {
  return { type: SET_SELECTED_RATINGS_FILTERS, payload: { name, value } };
}

export function resetSelectedRatingFilter(filter, index) {
  return { type: RESET_SELECTED_RATINGS_FILTER, payload: { filter, index } };
}

export function resetAllRatingFilters() {
  return { type: RESET_ALL_RATINGS_FILTERS, payload: null };
}

export function resetAllSortFilters() {
  return { type: RESET_ALL_SORT_FILTERS, payload: null };
}
