import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { useGetS3Media } from "./common/useMedia";
import LoadingIndicator from "../../common/LoadingIndicator";
import ContentService from "../../service/ContentService";
import { Button } from "./Settings/SettingsStyles";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 600px;
`;

const UploadedImage = styled.img`
  max-height: 600px;
  display: block;
`;

const UploadedVideo = styled.video`
  max-height: 300px;
  border: 2px solid #ccc;
`;

const UploadedAudio = ({ src, type }) => (
  <audio controls>
    <source src={src} type={type} />
  </audio>
);

const UploadedAudioWrapper = styled(UploadedAudio)`
  max-height: 300px;
  border: 2px solid #ccc;
`;

const MediaInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 600px;
`;

const FileName = styled.div`
  font-weight: 600;
`;

const Description = styled.p`
  max-width: 75ch;
  font-style: italic;
  margin-bottom: 10px;
`;

function Image({ setLoading, mediaId, description, mediaToken }) {
  return (
    <UploadedImage
      src={ContentService.getInstance().downloadMediaUrl(mediaId, mediaToken)}
      alt={description}
      height={600}
      onLoad={() => setLoading(false)}
    />
  );
}

function MediaContainer({ mediaId, description, children }) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <div style={{ display: loading ? "block" : "none", height: 600 }}>
        <LoadingIndicator />
      </div>
      <div style={{ display: loading ? "none" : "block" }}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            setLoading,
          });
        })}
      </div>
    </>
  );
}

const VideoS3 = ({ mediaId, location, type, mediaToken }) => {
  const { isPending, data: mediaUrl } = useGetS3Media({
    id: mediaId,
    location,
    type,
    mediaToken,
  });

  return mediaUrl ? (
    <UploadedVideo
      src={mediaUrl}
      controls
      muted={true}
      height={400}
      autoPlay={false}
    />
  ) : (
    ""
  );
};

const AudioS3 = ({ mediaId, location, type, mediaToken }) => {
  const { isPending, data: mediaUrl } = useGetS3Media({
    id: mediaId,
    location,
    type,
    mediaToken,
  });

  return mediaUrl ? <UploadedAudioWrapper type={type} src={mediaUrl} /> : "";
};

export default function Media(props) {
  const mediaId = useParams().mediaId || props.mediaId;
  const { media } = props;

  const download = () => {
    ContentService.getInstance()
      .downloadMedia(mediaId, props.mediaToken)
      .then((blob) => {
        if (blob && blob.size > 0) {
          const href = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.download = media?.fileName || "media";
          a.href = href;
          a.click();
        }
      });
  };

  return (
    <Section>
      <Description>{media?.description}</Description>
      {media?.fileType.startsWith("image") ? (
        <MediaContainer>
          <Image
            mediaId={mediaId}
            description={media?.description}
            mediaToken={props.mediaToken}
          />
        </MediaContainer>
      ) : media?.fileType.startsWith("video") ? (
        <VideoS3
          mediaId={mediaId}
          location={media?.location}
          type={media?.fileType}
          mediaToken={props.mediaToken}
        />
      ) : media?.fileType.startsWith("audio") ? (
        <AudioS3
          mediaId={mediaId}
          location={media?.location}
          type={media?.fileType}
          mediaToken={props.mediaToken}
        />
      ) : (
        ""
      )}
      <MediaInfo>
        <div>
          <FileName>{media?.fileName}</FileName>
          <div>File Type: {media?.fileType}</div>
        </div>
        <Button onClick={(e) => download()}>Download</Button>
      </MediaInfo>
    </Section>
  );
}
