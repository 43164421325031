import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import tvRatingsMap from "../../../../../../../../shared/resources/_tv_rating.json";
import podcastRatingsMap from "../../../../../../../../shared/resources/_podcast_rating.json";
import LoadingIndicator from "../../../common/LoadingIndicator";

const LabelContainerStyles = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const LabelTitleStyles = styled.h3`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 22px;
  line-height: 27px;
`;

const ChildLabelWrapper = styled.div`
  margin-bottom: 50px;
`;
const ScoreWrapper = styled.div`
  background-color: ${(props) => `${props.rank?.color}66`};
  border-radius: 4px;
  /* padding: 1px 17px 1px 18px; */
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  width: 63px;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

const LabelLink = styled(Link)`
  text-decoration: none;
  color: #35d;
  font-size: 14px;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }
`;

const PartnerLabelHeader = styled.h3`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ChildLabels = (props) => {
  const { children, loading } = props;

  return loading ? (
    <ChildLabelWrapper>
      <LoadingIndicator button className="spinner-align-left" />
    </ChildLabelWrapper>
  ) : children?.length > 0 ? (
    <ChildLabelWrapper>
      <PartnerLabelHeader>Program Ratings</PartnerLabelHeader>
      {children.map((label, idx) => {
        let ratingsMap;
        switch (label.network) {
          case "TV":
            ratingsMap = tvRatingsMap;
            break;
          case "PODCAST":
          default:
            ratingsMap = podcastRatingsMap;
            break;
        }

        return (
          <LabelContainerStyles key={idx}>
            <LabelTitleStyles>
              <ScoreWrapper rank={ratingsMap[label.rank]}>
                {label.score}
              </ScoreWrapper>
              <LabelLink
                to={`/partner/label/${label.id}`}
                dangerouslySetInnerHTML={{ __html: label.identifier }}
              />
            </LabelTitleStyles>
          </LabelContainerStyles>
        );
      })}
    </ChildLabelWrapper>
  ) : (
    ""
  );
};

export default ChildLabels;
