import styled from "@emotion/styled/macro";

const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
`;

const Note = styled.p`
  font-size: 12px;
  margin-bottom: 30px;
`;

const ErrorMessage = styled.div`
  color: #ff4444;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  border: ${(props) =>
    props.error || props.serverError
      ? "1px solid #ff4444 "
      : "1px solid var(--primary-black)"};
  border-radius: 2px;
  font-size: var(--16px);
  line-height: var(--20px);
  font-weight: 400;
  margin-bottom: 20px; // removing some space for the hidden label
  padding: var(--10px) var(--12px);
  background-clip: padding-box;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus-visible {
    outline-color: var(--primary-blue);
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-size: var(--14px);
  }
  &:user-invalid {
    border: 1px solid red;
    outline-color: red;
  }
`;

const Label = styled.label`
  font-weight: 600;
  margin-top: 5px;
  &:has(+ ${Input}:user-invalid) {
    color: red;
    position: relative;
  }

  &:has(+ ${Input}:user-invalid)::after {
    content: "Please enter a valid website url";
    color: red;
    position: absolute;
    right: 0;
  }

  &:has(+ input[id="urgent-reason"]:user-invalid)::after {
    content: "";
  }

  &:has(+ textarea:user-invalid) {
    color: red;
    position: relative;
  }

  & > p {
    font-weight: 400;
  }
`;

const H4 = styled.h4`
  font-weight: 600;
`;

const Wrapper = styled.div`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.25" : "1")};

  transition: all 0.3s ease-in-out;
`;

const WrapperBotttom = styled(Wrapper)``;

const Form = styled.form`
  height: ${(props) => (props.canRequest ? "0px" : "auto")};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

export {
  RadioWrapper,
  Note,
  ErrorMessage,
  Input,
  Label,
  H4,
  Wrapper,
  WrapperBotttom,
  Form,
};
