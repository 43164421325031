import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";

import SiteModule from "../common/SiteModule";
import LoadingIcon from "../common/LoadingIcon";
import Pagination from "../common/Pagination";
import AuthService from "../../../service/AuthService";
import RecentTable from "./RecentTable";
import { useRecentContents } from "./useRecentContents";

const LoadingWrapper = styled.div`
  min-height: 412px; //keep this height to prevent the table from jumping up when loading
`;
const RecentContentsPagedModule = ({
  provided,
  handleOpen,
  isOpen,
  id,
  isMobile,
  showAlertButton,
}) => {
  console.log(id);
  const [pageIndex, setPageIndex] = useState(1);
  const principal = useSelector((state) => state.authentication?.principal);

  const pageSize = isMobile ? 7 : 10;

  const { recentContents, totalPages, totalElements, isPreviousData } =
    useRecentContents(pageIndex, pageSize, id);

  const authService = AuthService.getInstance();
  const hasAccess =
    (id === "myth" &&
      (authService.hasMythAccess(principal) ||
        authService.hasRecentMythOnlyAccess(principal))) ||
    (id === "developing_narrative" &&
      authService.hasDevelopingStoryAccess(principal)) ||
    (id === "report" && authService.hasOnlineReportAccess(principal));

  const type =
    id === "myth"
      ? "falseNarrativeSettings"
      : id === "developing_narrative"
      ? "developingNarrativeSettings"
      : "reportSettings";

  return (
    hasAccess &&
    (type !== "developingNarrativeSettings" || totalElements > 0) && (
      <SiteModule
        id={id}
        count={totalElements || 0}
        drag={true}
        handle={{ ...provided.dragHandleProps }}
        handleToggle={handleOpen}
        isOpen={isOpen}
        type={type}
        showAlertButton={showAlertButton}
      >
        {isPreviousData ? (
          <LoadingWrapper>
            <LoadingIcon />
          </LoadingWrapper>
        ) : (
          <RecentTable contents={recentContents} id={id} />
        )}
        <Pagination
          totalPages={totalPages}
          currentPage={pageIndex}
          getPage={(i) => {
            setPageIndex(i);
          }}
          getNextPage={() => {
            setPageIndex(pageIndex + 1);
          }}
          getPreviousPage={() => {
            setPageIndex(pageIndex - 1);
          }}
        />
      </SiteModule>
    )
  );
};
export default RecentContentsPagedModule;
