const Step1 = ({ active }) =>
  active ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" title="Step 1">
      <defs>
        <mask
          id="a"
          x="15"
          y="15"
          width="20"
          height="20"
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M28.33 26.67a4.17 4.17 0 0 1 4.17 4.16v1.67a.83.83 0 0 1-.83.83.84.84 0 0 1-.84-.83v-1.67a2.5 2.5 0 0 0-2.5-2.5h-6.66a2.5 2.5 0 0 0-2.5 2.5v1.67a.84.84 0 0 1-.84.83.83.83 0 0 1-.83-.83v-1.67a4.17 4.17 0 0 1 4.17-4.16h6.66Zm-3.33-10a4.17 4.17 0 1 1-4.17 4.16A4.17 4.17 0 0 1 25 16.67Zm0 1.66a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5Z"
            style={{ fillRule: "evenodd", fill: "#fff" }}
          />
        </mask>
      </defs>

      <g data-name="mod/-steps">
        <circle cx="25" cy="25" r="25" style={{ fill: "#35d" }} />
        <path
          data-name="path-3"
          d="M28.33 26.67a4.17 4.17 0 0 1 4.17 4.16v1.67a.83.83 0 0 1-.83.83.84.84 0 0 1-.84-.83v-1.67a2.5 2.5 0 0 0-2.5-2.5h-6.66a2.5 2.5 0 0 0-2.5 2.5v1.67a.84.84 0 0 1-.84.83.83.83 0 0 1-.83-.83v-1.67a4.17 4.17 0 0 1 4.17-4.16h6.66Zm-3.33-10a4.17 4.17 0 1 1-4.17 4.16A4.17 4.17 0 0 1 25 16.67Zm0 1.66a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5Z"
          style={{ fill: "#fff", fillRule: "evenodd" }}
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" title="Step 1">
      <defs>
        <mask
          id="a"
          x="15"
          y="15"
          width="20"
          height="20"
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M28.33 26.67a4.17 4.17 0 0 1 4.17 4.16v1.67a.83.83 0 0 1-.83.83.84.84 0 0 1-.84-.83v-1.67a2.5 2.5 0 0 0-2.5-2.5h-6.66a2.5 2.5 0 0 0-2.5 2.5v1.67a.84.84 0 0 1-.84.83.83.83 0 0 1-.83-.83v-1.67a4.17 4.17 0 0 1 4.17-4.16h6.66Zm-3.33-10a4.17 4.17 0 1 1-4.17 4.16A4.17 4.17 0 0 1 25 16.67Zm0 1.66a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5Z"
            style={{ fillRule: "evenodd", fill: "#fff" }}
          />
        </mask>
      </defs>

      <g data-name="mod/-steps">
        <circle
          cx="25"
          cy="25"
          r="25"
          style={{ isolation: "isolate", opacity: 0.08 }}
        />
        <path
          data-name="path-3"
          d="M28.33 26.67a4.17 4.17 0 0 1 4.17 4.16v1.67a.83.83 0 0 1-.83.83.84.84 0 0 1-.84-.83v-1.67a2.5 2.5 0 0 0-2.5-2.5h-6.66a2.5 2.5 0 0 0-2.5 2.5v1.67a.84.84 0 0 1-.84.83.83.83 0 0 1-.83-.83v-1.67a4.17 4.17 0 0 1 4.17-4.16h6.66Zm-3.33-10a4.17 4.17 0 1 1-4.17 4.16A4.17 4.17 0 0 1 25 16.67Zm0 1.66a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5Z"
          style={{ fill: "#000", fillRule: "evenodd" }}
        />
      </g>
    </svg>
  );

export default Step1;
