import NetworkService from "./NetworkService";

const ResourceConfig = require("../config/ResoureConfig").config;

const ConfigService = (() => {
  let instance;

  function createInstance() {
    return new ConfigServiceInstance();
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default ConfigService;

class ConfigServiceInstance {
  _data = {};
  load = async () => {
    let headers = {};

    if (typeof window._csrf != "undefined" && window._csrf) {
      headers[window._csrf.header] = window._csrf.token;
      headers.xsrfHeaderName = window._csrf.header;
    }

    const endpoint = `${this._data && this._data.context}/config.json`;
    return await NetworkService.getInstance()
      .get(endpoint, headers)
      .then((response) => {
        sessionStorage.setItem("config", JSON.stringify(response));
        this._data = Object.assign({}, ResourceConfig, response);
        return Promise.resolve(response);
      })
      .catch((error) => {
        this._data = Object.assign({}, ResourceConfig);
        return Promise.reject(error);
      });
  };

  get = () => {
    const sessionData = sessionStorage.getItem("config");
    return sessionData ? JSON.parse(sessionData) : this._data;
  };

  formulateContextPath = (path) => {
    return `${this._data.context}${path}`;
  };

  getContextPath = (path) => {
    return path.replace(this._data.context, "");
  };

  resetShownActiveSubs = () => {
    localStorage.removeItem("shownActiveSub");
  };

  setShownActiveSubModal = () => {
    localStorage.setItem("shownActiveSub", JSON.stringify({ shown: true }));
  };

  hasShownActiveSubModal = () => {
    const showActiveSub = localStorage.getItem("shownActiveSub");
    return showActiveSub ? JSON.parse(showActiveSub).shown : false;
  };

  constructor() {
    this._data = ResourceConfig;
  }
}
