import * as Select from "@radix-ui/react-select";
import styled from "@emotion/styled/macro";

const InitialMarker = styled.div`
  border: none;
  background-color: var(--primary-gray);
  border-radius: 2px;
  display: flex;
  gap: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 36px;
  font-family: "Sora", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  cursor: default;
`;

const StyledSelectContent = styled(Select.Content)`
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;

  font-family: "Sora", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
`;

const StyledSelectGroup = styled(Select.Group)`
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;

  padding: 1px 2px;
  min-width: 107px;

  font-family: "Sora", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;

  & :hover {
    background-color: #e5e5e5;
  }
`;

const StyledSelectItem = styled(Select.Item)`
  background-color: #f2f2f2;
  color: var(--primary-black);
  border: none;
  border-radius: 2px;
  padding: 4px 8px;
  min-width: 135px;
  cursor: pointer;

  & :hover {
    background-color: #e5e5e5;
  }

  &:focus-visible,
  &:focus {
    outline: 1px solid var(--primary-black);
    background-color: #e5e5e5;
  }
`;

const StyledSelectIcon = styled.span`
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 7'%3E%3Cg id='Layer_1-2'%3E%3Cpolyline id='dropdown' points='0 7 4 0 8 7' style='fill:%23010101; fill-rule:evenodd;'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 7px;
  width: 8px;
  height: 7px;
  margin-left: 5px;
  transform: rotate(180deg);
`;

const SelectDropdown = ({ placeholder, handleChange, children }) => (
  <Select.Root onValueChange={(value) => handleChange(value)}>
    <Select.Trigger asChild>
      {/* <Select.Value placeholder={placeholder} />
       */}
      <InitialMarker>
        {placeholder} <StyledSelectIcon />
      </InitialMarker>
    </Select.Trigger>
    {/* <Select.Portal> */}
    <StyledSelectContent>
      <Select.ScrollUpButton />
      <Select.Viewport>
        <Select.Item>
          <Select.ItemText />
          <Select.ItemIndicator />
        </Select.Item>

        <StyledSelectGroup>
          {children.map((child) => {
            return (
              <div key={child.value}>
                <Select.Label />
                <StyledSelectItem value={child.value}>
                  <Select.ItemText>{child.text}</Select.ItemText>
                  <Select.ItemIndicator />
                </StyledSelectItem>
              </div>
            );
          })}
        </StyledSelectGroup>

        <Select.Separator />
      </Select.Viewport>
      <Select.ScrollDownButton />
      <Select.Arrow />
    </StyledSelectContent>
    {/* </Select.Portal> */}
  </Select.Root>
);

export default SelectDropdown;
