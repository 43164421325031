import React from "react";
import { useParams } from "react-router-dom";

import { useMythExampleLinks, useMyths } from "./useMyths";
import LoadingIcon from "../common/LoadingIcon";
import MythView from "./MythView";

const Myth = (props) => {
  const mythMainId = useParams().mythMainId || props.mythMainId;

  const { myth, isLoading, firstAppeared, isError } = useMyths(
    null,
    null,
    mythMainId
  );
  const { mythExampleLinks, profiles, isMythExampleLinksLoading } =
    useMythExampleLinks(myth);

  return isLoading ? (
    <LoadingIcon />
  ) : (
    <MythView
      myth={myth}
      firstAppeared={firstAppeared}
      mythExampleLinks={mythExampleLinks}
      profiles={profiles}
      isMythExampleLinksLoading={isMythExampleLinksLoading}
    />
  );
};

export default Myth;
