import { useQuery } from "@tanstack/react-query";
import ContentService from "../../../service/ContentService";

export function useRecentUpdates(pageIndex, pageSize, filters) {
  const { data, isLoading, error, status, isPreviousData } = useQuery({
    queryKey: ["recentUpdates", pageIndex, pageSize, filters],
    queryFn: () => getData(pageIndex, pageSize, "createdDate", "desc", filters),
    keepPreviousData: true,
  });

  return {
    recentUpdates: data,
    labelHistories: data?.content || [],
    totalPages: data?.totalPages,
    isLoading,
    error,
    status,
    isPreviousData,
  };
}

async function getData(
  pageIndex = 1,
  pageSize = 10,
  sortField,
  sortOrder,
  filters
) {
  return await ContentService.getInstance().getRecentlyUpdatedLabelPage(
    pageIndex,
    pageSize,
    sortField,
    sortOrder,
    filters
  );
}
