import styled from "@emotion/styled/macro";

const AutoSuggestionWrapper = styled.div`
  width: 500px;
  padding: 10px 0 0;
  display: ${({ list }) => (list.length > 0 ? "block" : "none")};
  position: absolute;
  top: 36px;
  left: 0;
  z-index: 13;
  background-color: var(--primary-white);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  /* border: 1px solid var(--primary-gray); */
  border-top: none;
  
  box-shadow: 0 8px 10px -10px rgba(0, 0, 0, 0.12)
  border-radius: 0 0 4px 4px;
  @media only screen and (max-width: 600px) {
    width: 354px;
  }

  div {
    text-align: left;
    color: #3355dd;
    font-family: Sora, sans-serif;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    margin-top: 15px;
  }
`;

const SearchList = styled.ul`
  padding: 0;
  list-style: none;
  text-align: left;
  color: #000;
  font-family: Sora;
  font-size: 14px !important;
  line-height: 24px;
  max-height: 112px;
  overflow-y: scroll;
  width: 98%;

  li {
    margin-bottom: 16px;
    cursor: pointer;
  }
`;

export default function AutoSuggestion({ list, onClick }) {
  return (
    <AutoSuggestionWrapper list={list}>
      <SearchList>
        {list.map((item, index) => (
          <li key={`item-${index}`} onClick={(e) => onClick(e, item)}>
            {item}
          </li>
        ))}
      </SearchList>
    </AutoSuggestionWrapper>
  );
}
