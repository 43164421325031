import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";
import AccountService from "../../../service/AccountService";

export function useEditAlertSettings() {
  const queryClient = useQueryClient();
  const {
    isLoading: loading,
    mutate: editEmailPreferences,
    status,
    isError,
  } = useMutation({
    mutationFn: ({ alertOn, settingType, trigger, frequency, categories }) =>
      AccountService.getInstance().updateAlertSettings({
        [settingType]: categories
          ? {
              alertOn: alertOn,
              trigger: trigger,
              frequency: frequency,
              categories:
                settingType === "mythSettings" ||
                settingType === "reportSettings"
                  ? categories
                  : [],
            }
          : { alertOn: alertOn, trigger: trigger, frequency: frequency },
      }),
    onMutate: async (variables) => {
      const { alertOn, settingType, trigger, frequency, categories } =
        variables;

      await queryClient.cancelQueries({ queryKey: ["emailPreferences"] });
      const previousValue = queryClient.getQueryData(["emailPreferences"]);

      let trimmedSettingType = settingType.replace("Settings", "");
      if (trimmedSettingType === "follow") {
        trimmedSettingType = "following";
      }

      queryClient.setQueryData(["emailPreferences"], (old) => {
        return {
          ...old,
          [`${trimmedSettingType}AlertOn`]: alertOn,
          [`${trimmedSettingType}Frequency`]: frequency,
          [`${trimmedSettingType}Categories`]: categories,
          [`${trimmedSettingType}Trigger`]: trigger,
        };
      });

      return { previousValue };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["emailPreferences"],
      });
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["emailPreferences"], context.previousValue);
      console.error(err);
    },
  });

  return { loading, editEmailPreferences, status, isError };
}
