import styled from "@emotion/styled/macro";
import LoadingIndicator from "../../common/LoadingIndicator";
import { Button } from "../FormElements/Button";

export const OAuthSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0 10px;
`;

const OAuthSVG = {
  facebook: `url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='facebook-f' class='svg-inline--fa fa-facebook-f fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'/%3E%3C/svg%3E") no-repeat`,
  google: `url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='google' class='svg-inline--fa fa-google fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 488 512'%3E%3Cpath fill='currentColor' d='M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z'/%3E%3C/svg%3E") no-repeat`,
  apple: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.46 19'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Elogo-whiteAsset 1%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cg id='Left-White-Logo-Medium'%3E%3Cpath id='_' data-name='' class='cls-1' d='M8,4.38A3.72,3.72,0,0,0,10.53,3a4.33,4.33,0,0,0,1-2.66,2.1,2.1,0,0,0,0-.37A4.23,4.23,0,0,0,8.71,1.45,4.12,4.12,0,0,0,7.67,4a1.19,1.19,0,0,0,0,.34ZM4.94,19c1.17,0,1.69-.79,3.15-.79S9.9,19,11.21,19s2.14-1.19,2.95-2.35a10.65,10.65,0,0,0,1.3-2.69,4.2,4.2,0,0,1-2.54-3.84A4.33,4.33,0,0,1,15,6.47a4.49,4.49,0,0,0-3.76-1.88c-1.44,0-2.61.87-3.35.87S6,4.64,4.77,4.64C2.4,4.64,0,6.6,0,10.29a11.65,11.65,0,0,0,2,6.29C2.94,17.91,3.76,19,4.94,19Z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat`,
};

export const OAuthButtonStyle = styled(Button)`
  background-color: var(--primary-black);
  text-transform: none;
  line-height: auto;
  position: relative;
`;

export const SocialButtonIcons = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    display: ${({ loading }) => (loading ? "none" : "block")};
    content: "";
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 22px;
    width: 1.5rem;
    background-size: 100%;
    background-color: #fff;
    mask-size: cover;
    margin-top: -1px;
    margin-right: 7px;
    width: ${(props) => (props.service === "facebook" ? "14px" : "22px")};
    mask: ${(props) => OAuthSVG[props.service]};
  }
`;

const OAuthButton = ({ service, onClick, children, loading }) => {
  return (
    <OAuthButtonStyle>
      {loading ? (
        <LoadingIndicator button />
      ) : (
        <SocialButtonIcons
          onClick={onClick}
          service={service}
          loading={loading}
        >
          {children}
        </SocialButtonIcons>
      )}
    </OAuthButtonStyle>
  );
};

export default OAuthButton;
