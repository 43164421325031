import styled from "@emotion/styled/macro";
import { FormattedMessage } from "react-intl";
import { getCountry, getLocale } from "../../../common/_localeMessages";
import ConfigService from "../../../service/ConfigService";

const Footnote = styled.p`
  font-size: var(--12px);
  font-style: italic;
  align-self: flex-start;

  &::before {
    content: "*";
  }
`;

const config = ConfigService.getInstance().get();
const defaultCountry = getCountry() || config.country || "US";

export const SalesTaxDisclaimer = ({ country }) => {
  const userCountry = country || defaultCountry;
  let taxTypeString = "VAT";

  if (userCountry === "US") {
    taxTypeString = "sales tax";
  }

  if (userCountry === "AU") {
    taxTypeString = "GST";
  }

  const hasTranslation =
    getLocale() === "en" ||
    getLocale() === "fr" ||
    getLocale() === "fr-FR" ||
    getLocale() === "de" ||
    getLocale() === "de-DE" ||
    getLocale() === "it" ||
    getLocale() === "it-IT";

  return (
    <Footnote>
      {hasTranslation ? (
        <FormattedMessage
          defaultMessage="excluding sales tax"
          id="template.salesTaxDisclaimer"
        />
      ) : (
        <>{`excluding ${taxTypeString}`}</>
      )}
    </Footnote>
  );
};
