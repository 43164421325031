import styled from "@emotion/styled/macro";
import { useState } from "react";
import { AccountModalHeader } from "../../../Account/AccountStyles";
import { Button, ButtonBlack } from "../../../FormElements/Button";
import InputModule from "../../../FormElements/InputModule";
import { Header4, Text, Textarea } from "./Request";

const RadioWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
`;

const MythRequests = ({ onSubmit, submitted, onDismiss }) => {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState({});
  const [value, setValue] = useState("");
  const [urgent, setUrgent] = useState(false);

  const checkIsValid = (e) => {
    if (e.target.checkValidity() === false) {
      setValidated(true);

      e.target.parentNode.classList.add("was-validated");
    } else {
      setValidated(true);

      e.target.parentNode.classList.remove("was-validated");
    }
  };
  const validate = (event) => {
    setValidated(true);
    return event.target.checkValidity();
  };

  const submit = (event) => {
    event.preventDefault();
    const form = document.getElementById("website-request");
    const formData = new FormData(form);

    if (validate(event)) {
      onSubmit(event, formData);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setError(false);
  };

  return (
    <>
      <AccountModalHeader>False Narrative Request</AccountModalHeader>
      {submitted ? (
        <>
          <p>
            Thank you for submitting your request. Our team will review your
            request and contact you with any questions.
          </p>
          <Button onClick={onDismiss}>Close</Button>
        </>
      ) : (
        <>
          <Text>
            NewsGuard catalogs top false claims spreading on the open web and
            social media outlets, with a focus on false narratives that are
            gaining traction. If you would like to suggest a false narrative for
            us to capture as a false narrative entry, please let us know below.
          </Text>

          <form onSubmit={submit} noValidate id="website-request">
            {/* <Header4> What is the false narrative?</Header4> */}
            <InputModule
              identifier={"myth"}
              label={"Myth"}
              type={"text"}
              placeholder={"What is the false narrative?"}
              onChange={handleChange}
              onBlur={checkIsValid}
              required
              onInvalid={() => {
                let myth = {
                  id: "template.myth",
                  defaultMessage: "Please describe the false narrative",
                  name: "myth",
                  html: true,
                };

                setError((prevState) => ({
                  ...prevState,
                  myth,
                }));
              }}
              error={error.myth}
              medium
            />
            {/* <Header4>
             Where have you encountered the false narrative?
             </Header4> */}
            <InputModule
              identifier={"myth-location"}
              label={"False narrative Location"}
              type={"text"}
              placeholder={"Where did you encounter the false narrative?"}
              onChange={handleChange}
              onBlur={checkIsValid}
              required
              onInvalid={() => {
                let mythLocation = {
                  id: "template.myth",
                  defaultMessage:
                    "Please describe where you encountered the false narrative?",
                  name: "mythLocationmyth",
                  html: true,
                };

                setError((prevState) => ({
                  ...prevState,
                  mythLocation,
                }));
              }}
              error={error.mythLocation}
              medium
            />
            <Header4>Why are you interested in this false narrative?</Header4>
            <p>
              Let us know if there’s any information about the false narrative,
              how you came across it, specific examples you’re concerned about,
              or any other context our team should have.{" "}
            </p>
            <Textarea name="extra-information"></Textarea>

            <ButtonBlack type="submit">Request</ButtonBlack>
          </form>
        </>
      )}
    </>
  );
};

export default MythRequests;
