import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useQuery } from "@tanstack/react-query";

import AuthService from "../../../service/AuthService";
import AdvancedSearch from "./Filters/AdvancedSearch";

import SearchContext from "./SearchContext";
import SearchResult from "./SearchResult";

import LoadingIcon from "../common/LoadingIcon";
import Pagination from "../common/Pagination";

import {
  ResultsAndTagsWrapper,
  SearchInformationWrapper,
  SearchResultsWrapper,
} from "./SearchResultsStyles";

import {
  resetAllFilters,
  resetSelectedFilter,
  setSearchPage,
  setSelectedFilters,
  setSelectedSearchContext,
} from "../../../_actions";

import { FilterToggleButtonsContainer } from "./Filters/ui/FiltersButtons";
import useToggle from "../Helpers/useToggle";
import { contextDropDown } from "./SearchBar/SearchBar";
import { CategoryFilterSetup } from "../Settings/MythCategoryChooser";
import { SearchTermAndCount } from "./SearchTermAndCount";
import getData, { downloadData } from "./Filters/searchRequestHelpers";
import ModalModule from "../common/ModalModules";
import ExportModule from "../ExportModule";
import MythSort from "./Sort/MythSort";

export default function Search(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const principal = useSelector((state) => state.authentication.principal);
  const searchContext = useSelector((state) => state.searchTerms.context);
  const searchTerm = useSelector((state) => state.searchTerms.term);
  const selectedFilters = useSelector(
    (state) => state.searchFilter.selectedFilters
  );
  const searchPage = useSelector((state) => state.searchTerms.page);

  const [advanced, setIsAdvanced] = useState(
    location.state ? location.state.advanced : false
  );
  const [isOpen, toggleIsOpen] = useToggle(false);
  if (advanced && !isOpen) {
    toggleIsOpen(true);
  }

  const [isSortOpen, toggleSort] = useToggle(false);

  const [showDialog, setShowDialog] = useState(false);
  const open = () => {
    setShowDialog(true);
  };
  const close = () => setShowDialog(false);

  const authInstance = AuthService.getInstance();
  const hasAdvancedSearchAccess =
    authInstance.hasAdvancedSearchAccess(principal);
  const hasMythAccess =
    authInstance.hasMythAccess(principal) ||
    authInstance.hasRecentMythOnlyAccess(principal);
  const hasReportAccess = authInstance.hasOnlineReportAccess(principal);

  const sizePerPage = 50;

  useEffect(() => {
    if (searchParams.has("search_context")) {
      dispatch(
        setSelectedSearchContext(
          contextDropDown[searchParams.get("search_context")]
        )
      );
      searchParams.delete("search_context");
      // if context changes from params, reset filters
      dispatch(resetAllFilters());
    }

    if (searchParams.has("owner")) {
      dispatch(
        setSelectedFilters("owner", searchParams.get("owner"), principal)
      );
      searchParams.delete("owner");
      setSearchParams("");
    }

    if (searchParams.has("category")) {
      const categoryValues = CategoryFilterSetup(
        searchParams.get("category"),
        []
      );

      dispatch(setSelectedFilters("categories", categoryValues, principal));
      searchParams.delete("category");
      setSearchParams(searchParams);
    }

    if (searchParams.has("subject_tags")) {
      const value = [
        {
          label: searchParams.get("subject_tags"),
          value: searchParams.get("subject_tags"),
        },
      ];

      dispatch(setSelectedFilters("subject_tags", value, principal));
      searchParams.delete("subject_tags");
      setSearchParams(searchParams);
    }

    if (searchParams.has("manipulated_media")) {
      const value = [
        {
          label: searchParams.get("manipulated_media"),
          value: searchParams.get("manipulated_media"),
        },
      ];

      dispatch(setSelectedFilters("manipulated_media", value, principal));
      searchParams.delete("manipulated_media");
      setSearchParams(searchParams);
    }
  }, [searchParams, dispatch, setSearchParams]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PartnerView",
        user: principal?.username,
        partner: principal?.attributes?.partner,
        view: "Search",
        tab: searchContext?.value,
        content_type: "",
        content_title: "",
        content_locale: "",
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  }, [principal]);

  const getAllCompleteCriteriasLength = useMemo(() => {
    const count = selectedFilters?.criteria.reduce(
      (acc, cur) => (cur.complete ? ++acc : acc),
      0
    );

    return count;
  }, [selectedFilters?.criteria]);

  const numberOfSortFiltersSelected = useMemo(
    () =>
      Object.keys(selectedFilters).reduce((acc, key) => {
        if (selectedFilters[key].single === true) {
          if (key === "sortBy" || key === "sortOrder") {
            if (selectedFilters[key].value) {
              return acc + 1;
            } else {
              return acc;
            }
          }
        }
        return acc;
      }, 0),
    [selectedFilters]
  );

  const numberOfFiltersSelected = useMemo(
    () =>
      Object.keys(selectedFilters).reduce((acc, key) => {
        if (selectedFilters[key].single === true) {
          if (key === "sortBy" || key === "sortOrder") {
            return acc;
          }
          if (selectedFilters[key].value) {
            return acc + 1;
          } else {
            return acc;
          }
        }

        if (key === "score") {
          if (selectedFilters[key].value.low) {
            return acc + 1;
          } else {
            return acc;
          }
        }

        if (key === "criteria") {
          return acc + getAllCompleteCriteriasLength;
        }

        if (key === "manipulated_media") {
          return (
            acc +
            (selectedFilters?.manipulated_media?.value
              ? selectedFilters?.manipulated_media.value.length
              : 0)
          );
        }

        if (key === "show_offline") {
          if (selectedFilters[key].value) {
            return acc;
          } else {
            return acc + 1;
          }
        }

        if (key === "first_appearance_date") {
          if (
            selectedFilters[key].value?.start ||
            selectedFilters[key].value?.end
          ) {
            return acc + 1;
          } else {
            return acc;
          }
        }

        return acc + selectedFilters[key].value.length;
      }, 0),
    [selectedFilters, getAllCompleteCriteriasLength]
  );

  const clear = (slug, index) => {
    dispatch(resetSelectedFilter(slug, index));
  };

  const clearAllFilters = () => {
    dispatch(resetAllFilters());
  };

  const clearAllSortFilters = () => {
    dispatch(resetSelectedFilter("sortBy"));
    dispatch(resetSelectedFilter("sortOrder"));
  };

  const download = async () => {
    try {
      return await downloadData(
        searchTerm,
        selectedFilters,
        searchContext?.value
      );
    } catch (err) {}
  };

  const {
    data,
    status,
    error,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    isLoadingError,
    isRefetchError,
  } = useQuery(
    [
      "search",
      searchTerm,
      selectedFilters,
      searchPage,
      sizePerPage,
      searchContext?.value,
      principal,
    ],
    () =>
      getData(
        searchTerm,
        selectedFilters,
        searchPage,
        sizePerPage,
        searchContext?.value
      ),
    {
      refetchOnWindowFocus: true,
      cacheTime: 2 * 60 * 1000, // 2 minutes
      staleTime: 2 * 60 * 1000, // 2 minutes
      retry: 3,
    }
  );

  const toggleAdvancedFiltersModule = () => {
    if (advanced) {
      setIsAdvanced(false);
      navigate(location.pathname, { state: { advanced: false } });
    }

    if (isOpen) {
      toggleIsOpen(false);
    } else {
      toggleIsOpen(true);
      window.scrollTo(0, 0);
    }
  };

  const hasFillter =
    (hasAdvancedSearchAccess &&
      authInstance.hasLabelAccess(principal) &&
      searchContext?.value === "WEB") ||
    (authInstance.hasTVAccess(principal) && searchContext?.value === "TV") ||
    (authInstance.hasPodcastAccess(principal) &&
      searchContext?.value === "PODCAST") ||
    ((authInstance.hasMythAccess(principal) ||
      authInstance.hasRecentMythOnlyAccess(principal)) &&
      searchContext?.value === "MYTH") ||
    (authInstance.hasOnlineReportAccess(principal) &&
      searchContext?.value === "REPORT");

  return (
    <>
      {hasFillter ? (
        <>
          <ModalModule
            showDialog={showDialog}
            close={close}
            onDismiss={close}
            account={true}
            showClose={true}
          >
            <ExportModule
              exportContext={searchContext?.value}
              filteredDownload={download}
              closeModal={close}
            />
          </ModalModule>
          {isSortOpen && (
            <MythSort
              clear={clear}
              clearAll={clearAllSortFilters}
              filters={selectedFilters}
              principal={principal}
              isOpen={isSortOpen}
              toggleModule={toggleSort}
              results={data?.totalElements}
              loading={isFetching || isLoading}
              numberOfFilters={numberOfSortFiltersSelected}
            />
          )}

          {isOpen && (
            <AdvancedSearch
              searchTerms={searchTerm}
              searchContext={searchContext}
              principal={principal}
              isOpen={isOpen}
              toggleAdvancedFiltersModule={toggleAdvancedFiltersModule}
              advanced={advanced}
              clear={clear}
              clearAll={clearAllFilters}
              numberOfFilters={numberOfFiltersSelected}
              results={data?.totalElements}
              loading={status === "loading"}
              openSecondModal={open}
            />
          )}
          <FilterToggleButtonsContainer
            onClick={toggleAdvancedFiltersModule}
            numberOfFilters={numberOfFiltersSelected}
            hasSortSelected={numberOfSortFiltersSelected}
            toggleSort={toggleSort}
            filterContext={searchContext?.value}
          />
        </>
      ) : (
        ""
      )}

      <SearchInformationWrapper hasFilters={hasFillter}>
        <ResultsAndTagsWrapper>
          <SearchTermAndCount
            totalElements={data?.totalElements}
            searchTerm={searchTerm}
          />

          {searchContext?.value !== "ALL" && numberOfFiltersSelected !== 0 && (
            <SearchContext
              numberOfFilters={numberOfFiltersSelected}
              filters={selectedFilters}
              clear={clear}
              passedInView={false}
              handleClick={toggleAdvancedFiltersModule}
              searchContext={searchContext}
            />
          )}
        </ResultsAndTagsWrapper>
      </SearchInformationWrapper>

      {status === "loading" || isFetching ? (
        <LoadingIcon />
      ) : (
        <SearchResultsWrapper>
          {data?.content.length > 0
            ? data?.content.map((result, index) => {
                return (
                  <SearchResult
                    content={result.content}
                    index={index}
                    hasMythAccess={hasMythAccess}
                    hasReportAccess={hasReportAccess}
                    key={index}
                    searchContext={searchContext}
                  />
                );
              })
            : null}
        </SearchResultsWrapper>
      )}
      <Pagination
        padding
        totalPages={data?.totalPages}
        currentPage={data?.pageable?.pageNumber + 1}
        getPage={(i) => {
          dispatch(setSearchPage(i - 1));
        }}
        getNextPage={() => {
          dispatch(setSearchPage(searchPage + 1));
        }}
        getPreviousPage={() => {
          dispatch(setSearchPage(searchPage - 1));
        }}
      />
    </>
  );
}
