import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { FormattedMessage } from "react-intl";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import AccountService from "../../service/AccountService";
import PurchaseComplete from "./PurchaseComplete";
import CheckoutForm from "./CheckoutForm";
import PurchasePreview from "./PurchasePreview";
import Country from "../Account/CountryForm";
import { getLocale } from "../../common/_localeMessages";

import LoadingIndicator from "../../common/LoadingIndicator";
import AuthService from "../../service/AuthService";
import ConfigService from "../../service/ConfigService";
import { FinalText, LinkButton, Title } from "./SignupStyles";
import ErrorMessage from "../FormElements/ErrorMessage";
import { SalesTaxDisclaimer } from "../Partners/Helpers/SalesTaxDisclaimer";
import { isEdge, isLegacyEdge } from "react-device-detect";
import PersonalUserForm from "./PersonalUseForm";
import AppReduxStore from "../../AppReduxStore";

function Offer(props) {
  //const [cookies, setCookie, removeCookie] = useCookies(["campaign", "lmref"]);
  const navigate = useNavigate();

  const principal = useSelector((state) => state.authentication.principal);
  const [transactionComplete, setTransactionComplete] = useState(false);
  const [transactionPreview, setTransactionPreview] = useState(false);
  const [plan, setPlan] = useState({});
  const [coupon, setCoupon] = useState({});
  const [subscription, setSubscription] = useState({});
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [billingCountry, setBillingCountry] = useState("US");

  const accountService = AccountService.getInstance();
  const authService = AuthService.getInstance();
  const stripePromise = loadStripe(
    ConfigService.getInstance().get().stripeKey ||
      "pk_test_PMdgob9Q7NtcefvUgCOP2P4400EutnppkB"
  );

  const getBillingCountry = () => {
    accountService.getCustomerBillingCountry().then((countryModel) => {
      setBillingCountry(countryModel.country);
    });
  };

  const purchase = () => {
    setIsLoading(true);
    setError(null);
    setTransactionPreview(false);

    // >>>>>>>  LinkMink
    // if we have the proper campaign and linkMink referer code,
    // we send the code back
    //
    /*
    let lmRef;

    if (window.lm && cookies?.campaign) {
      // lm.js must be initialized with your API key
      lmRef = window.lm.getReferralData();
    }
    */
    // <<<<<<
    accountService
      .purchaseSubscription({
        customerId: principal.id,
        planId: plan.id,
        couponId: coupon?.id,
        //lmRef: lmRef ? lmRef : "",
        //campaign: cookies?.campaign,
      })
      .then((response) => {
        setTransactionComplete(true);
        setSubscription(response);

        // evict full access cache in nutrition side to sync.
        authService.clearFullAccessCache(principal.id);
      })
      .catch((error) => {
        if (error.response && error.response.code === "CURRENCY_MISMATCH") {
          getBillingCountry();
          const currency = plan.currency;
          const newCurrency = error.response.message;

          setError({
            response: {
              code: "CURRENCY_MISMATCH",
              values: {
                currency1: currency.toUpperCase(),
                currency2: newCurrency.toUpperCase(),
              },
              defaultMessage:
                "You are attempting to sign up for a subscription plan charged in {currency1}, but your credit card is from a country that uses {currency2}. Your subscription plan has been updated to charge in the proper currency.",
            },
          });
        } else {
          setError(error);
        }
      })
      .finally((res) => setIsLoading(false));
  };

  const preview = (payment) => {
    setIsLoading(true);
    setError(null);

    accountService
      .previewSubscription({
        customerId: principal.id,
        planId: plan.id,
        couponId: coupon?.id,
        //campaign: cookies?.campaign,
      })
      .then((response) => {
        setTransactionPreview(true);
        setSubscription(response);
        navigate("/subscription/preview", {
          state: {
            subscription: response,
            plan: plan,
            coupon: coupon,
            // dismiss: dismiss,
          },
        });
      })
      .catch((error) => {
        if (error.response && error.response.code === "CURRENCY_MISMATCH") {
          const currency = plan.currency;
          const newCurrency = error.response.message;
          getBillingCountry();

          setError({
            response: {
              code: "CURRENCY_MISMATCH",
              values: {
                currency1: currency.toUpperCase(),
                currency2: newCurrency.toUpperCase(),
              },
              defaultMessage:
                "You are attempting to sign up for a subscription plan charged in {currency1}, but your credit card is from a country that uses {currency2}. Your subscription plan has been updated to charge in the proper currency.",
            },
          });

          // get the plan with new currency
          getPlan(newCurrency);
        } else {
          setError(error);
        }
      })
      .finally((res) => setIsLoading(false));
  };

  const getEdgeAccess = () => {
    setIsLoading(true);
    setError(null);

    // <<<<<<
    accountService
      .gainEdgeAccess()
      .then((response) => {
        setTransactionComplete(true);
        setSubscription(response);
        navigate("/subscription/complete", {
          state: {
            subscription: response,
          },
        });

        // evict full access cache in nutrition side to sync.
        authService.clearEdgeAccessCache(principal.id);
      })
      .catch((error) => {
        setError(error);
      })
      .finally((res) => setIsLoading(false));
  };

  const dismiss = () => {
    setTransactionComplete(false);
  };

  useEffect(() => {
    setIsLoading(true);
    refreshUser();
    // setIsLoading(false);
    return () => {
      setIsLoading(false);
    };
  }, []);

  /*
  useEffect(() => {
    if (transactionComplete) {
      removeCookie("campaign");
      removeCookie("lmref");
    }
  }, [transactionComplete]);
  */

  const getRegularPlan = (currency) => {
    accountService
      .getAvailablePlan(currency)
      .then((plan) => {
        setPlan(plan);

        return Promise.resolve("");
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getPlan = (currency = null) => {
    // >>>>>>>  LinkMink
    // if we have the proper campaign and linkMink referer code,
    // we send the code back
    //
    /*
    let lmRef;

    if (window.lm && cookies?.campaign) {
      // lm.js must be initialized with your API key
      lmRef = window.lm.getReferralData();
    }
    */
    //if (cookies?.campaign && lmRef) {
    //  return accountService
    //    .getCampaignPlan(cookies?.campaign, currency)
    //    .then((planBundle) => {
    //      if (planBundle && planBundle.plan) {
    //        setPlan(planBundle.plan);
    //        setCoupon(planBundle.coupon);

    //        return Promise.resolve("");
    //      } else {
    //        return getRegularPlan(currency);
    //      }
    //    })
    //    .catch((error) => {
    //      return getRegularPlan(currency);
    //    });
    //} else {
    return getRegularPlan(currency);
    //}
  };

  const refreshUser = () => {
    return accountService
      .getUser()
      .then((user) => {
        setUser(user);
        setIsLoading(false);
        getBillingCountry();
        if (user.country) {
          getPlan();
        }
        return Promise.resolve("");
      })
      .catch((error) => {
        if (
          error.code === 401 ||
          error.status === 401 ||
          error.code === 403 ||
          error.status === 403
        ) {
          AuthService.getInstance().logOut();
        }
        navigate("/errorpage");
        return Promise.reject(error);
      });
  };

  const changeCountry = (country) => {
    setError(null);
    setBillingCountry(country);

    return accountService
      .updateCustomerCountry(country)
      .then((response) => refreshUser())
      .catch((error) => {
        setError(error);
      });
  };

  return isLoading ? (
    <LoadingIndicator className="large" />
  ) : user ? (
    user.country ? (
      transactionPreview ? (
        <PurchasePreview
          dismiss={dismiss}
          purchase={purchase}
          subscription={subscription}
          isTrial={plan.trial}
        />
      ) : transactionComplete ? (
        <PurchaseComplete subscription={subscription} />
      ) : isEdge || isLegacyEdge ? (
        <>
          <Title>
            <FormattedMessage
              id="template.personal_use_terms"
              defaultMessage={"Personal Use Terms"}
            />
          </Title>
          {error && <ErrorMessage error={error} longMessage={true} />}
          <PersonalUserForm
            country={user.country || "US"}
            submit={getEdgeAccess}
            email={principal.email}
            name={principal.name}
            setLoading={setIsLoading}
          />
        </>
      ) : (
        <>
          <Title>
            <FormattedMessage
              id="template.paymentDetails"
              defaultMessage={"Payment Details"}
            />
          </Title>
          {error && <ErrorMessage error={error} longMessage={true} />}
          <Elements stripe={stripePromise} locale={getLocale()}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <CheckoutForm
                  stripe={stripe}
                  elements={elements}
                  currency={plan.currency}
                  country={user.country || "US"}
                  submit={preview}
                  email={principal.email}
                  name={principal.name}
                  plan={plan}
                  coupon={coupon}
                  setLoading={setIsLoading}
                />
              )}
            </ElementsConsumer>
          </Elements>
          <FinalText>
            <FormattedMessage
              id="template.notReadyYet?"
              defaultMessage="Not ready yet?"
            />{" "}
            <Link to="/account/accountsettings">
              <FormattedMessage
                id="template.maybe_later"
                defaultMessage="Maybe Later"
              />
            </Link>
          </FinalText>
          <LinkButton to="/account/redeem">
            <FormattedMessage
              id="template.voucher.do_you_have_an_access_code"
              defaultMessage="Have an Access Code?"
            />
          </LinkButton>
          <SalesTaxDisclaimer country={billingCountry || "US"} />
        </>
      )
    ) : (
      <>
        <Title>Choose Country</Title>
        <Country user={user} changeCountry={changeCountry} />
      </>
    )
  ) : (
    navigate("/errorpage")
  );
}

export default Offer;
