import React, { useState } from "react";

import styled from "@emotion/styled/macro";
import ContentService from "../../../../../service/ContentService";
import AutoSuggestion from "./AutoSuggestion";

const FilterContainerStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  @media screen and (max-width: 600px) {
    margin-top: 10px;
    flex-wrap: wrap;
  }
`;

const TextInput = styled.input`
  height: 38px;
  width: 500px;

  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 0 0 0 10px;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 600px) {
    width: 354px;
  }
`;

export default function AutoSuggestFilter({ filter, onChange, getValues }) {
  const [showList, setShowList] = useState(false);
  const [searchList, setSearchList] = useState([]);

  const handleChange = (e) => {
    getValues(e.target.value).then((values) => {
      setSearchList(values);
    });

    onChange(e);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onChange(e);

      if (e.currentTarget?.id === "search") {
        e.currentTarget.blur(e);
      }
    }
  };

  const handleFocus = (e) => {
    setShowList(true);
  };

  const handleBlur = (e) => {
    if (showList) {
      setTimeout(() => setShowList(false), 200);
    } else {
      setShowList(false);
    }
  };

  const handleSelection = (e, item) => {
    e.preventDefault();
    // set term in search bar

    const newEvent = {
      target: {
        value: item,
        name: filter?.slug,
      },
    };

    onChange(newEvent);
  };

  return (
    <FilterContainerStyles>
      <TextInput
        type="text"
        maxLength={100}
        name={filter?.slug}
        id={filter?.slug}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={filter?.value || ""}
        autoComplete="off"
      />

      {showList ? (
        <AutoSuggestion list={searchList} onClick={handleSelection} />
      ) : null}
    </FilterContainerStyles>
  );
}
