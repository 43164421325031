import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";

import SiteModule from "../common/SiteModule";
import HistoryUpdatesTable from "./HistoryUpdatesTable";
import LoadingIcon from "../common/LoadingIcon";
import Pagination from "../common/Pagination";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../../service/AuthService";
import { useRecentUpdates } from "./useRecentUpdates";
import RecentUpdatesFilters from "./RecentUpdatesFilters";
import {
  resetAllRatingFilters,
  resetSelectedRatingFilter,
  setSelectedRatingFilters,
} from "../../../_actions";
import useToggle from "../Helpers/useToggle";

import ContentService from "../../../service/ContentService";
import moment from "moment";

const LoadingWrapper = styled.div`
  min-height: 402px; //keep this height to prevent the table from jumping up when loading
`;
const RecentUpdatesPagedModule = ({
  provided,
  handleOpen,
  isOpen,
  id,
  isMobile,
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [filters, setFilters] = useState({});
  const [isFiltersOpen, toggleFilters] = useToggle(false);
  const principal = useSelector((state) => state.authentication?.principal);

  const download = (e) => {
    return ContentService.getInstance().downloadRecentUpdates(filters);
  };

  const pageSize = isMobile ? 7 : 10;
  const {
    recentUpdates,
    labelHistories,
    totalPages,
    error,
    isPreviousData,
    isLoading,
    status,
  } = useRecentUpdates(pageIndex, pageSize, filters, setPageIndex);

  const handleToggleFilters = (e, id) => {
    if (!isOpen && !isFiltersOpen) {
      handleOpen(e, id);
      toggleFilters(true);
    } else {
      toggleFilters(false);
    }
  };

  const handleOpenToggle = (e, id) => {
    handleOpen(e, id);

    if (isFiltersOpen) {
      toggleFilters(false);
    }
  };

  const authService = AuthService.getInstance();

  const dispatch = useDispatch();

  const ratingsFilters = useSelector(
    (state) => state.ratingFilter.selectedFilters
  );

  const clear = (slug, index) => {
    dispatch(resetSelectedRatingFilter(slug, index));
  };

  const clearAll = () => {
    dispatch(resetAllRatingFilters());
  };

  const handleSelectChange = (selectData, action) => {
    dispatch(setSelectedRatingFilters(action.name, selectData));
  };

  const handleDateChange = (e) => {
    dispatch(setSelectedRatingFilters(e.target.name, e.target.value));
  };

  useEffect(() => {
    const filtersCopy = {};
    if (ratingsFilters.rating_type?.value) {
      const networkValues = ratingsFilters.rating_type.value.map(
        (val) => val.value
      );

      filtersCopy.network = {
        filterValList: networkValues,
      };
    }

    if (ratingsFilters.update_type?.value) {
      const updateTypeValues = ratingsFilters.update_type.value.map(
        (val) => val.value
      );

      filtersCopy.updateType = {
        filterValList: updateTypeValues,
      };
    }

    const priorDate = moment().subtract(30, "days");
    const defaultStartDate = moment(priorDate).format("YYYY-MM-DD");

    const startDate = ratingsFilters.start_date?.value || defaultStartDate;
    const endDate = ratingsFilters.end_date?.value;
    filtersCopy.createdDate = {
      filterVal: startDate,
      filterVal2: endDate,
    };

    setFilters(filtersCopy);
    setPageIndex(1);
  }, [ratingsFilters]);

  const numberOfFiltersSelected = useMemo(
    () =>
      Object.keys(ratingsFilters).reduce((acc, key) => {
        if (ratingsFilters[key].single === true) {
          if (ratingsFilters[key].value) {
            return acc + 1;
          } else {
            return acc;
          }
        }

        return acc + ratingsFilters[key].value.length;
      }, 0),
    [ratingsFilters]
  );

  return authService.hasLabelAccess(principal) ||
    authService.hasTVAccess(principal) ||
    authService.hasPodcastAccess(principal) ? (
    <SiteModule
      name="Rating Updates"
      id={id}
      count={recentUpdates?.totalElements || 0}
      drag={true}
      handle={{ ...provided.dragHandleProps }}
      handleToggle={handleOpenToggle}
      isOpen={isOpen}
      type="updatedRatingsSettings"
      handleFiltersToggle={handleToggleFilters}
      numberOfFiltersSelected={numberOfFiltersSelected}
      isFiltersOpen={isFiltersOpen}
    >
      <RecentUpdatesFilters
        handleClear={clear}
        handleClearAll={clearAll}
        numberOfFilters={numberOfFiltersSelected}
        handleChange={handleSelectChange}
        handleDateChange={handleDateChange}
        selectedFilters={ratingsFilters}
        results={recentUpdates?.totalElements || 0}
        loading={isPreviousData}
        isFiltersOpen={isFiltersOpen}
        download={download}
      />

      {isPreviousData || isLoading || status === "loading" ? (
        <LoadingWrapper>
          <LoadingIcon />
        </LoadingWrapper>
      ) : (
        <HistoryUpdatesTable labels={labelHistories} />
      )}
      <Pagination
        totalPages={totalPages}
        currentPage={pageIndex}
        getPage={(i) => {
          setPageIndex(i);
        }}
        getNextPage={() => {
          setPageIndex(pageIndex + 1);
        }}
        getPreviousPage={() => {
          setPageIndex(pageIndex - 1);
        }}
      />
    </SiteModule>
  ) : (
    ""
  );
};
export default RecentUpdatesPagedModule;
