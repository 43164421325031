import { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import styled from "@emotion/styled/macro";
import AuthService from "../../service/AuthService";
import RecentUpdatesPagedModule from "./RecentUpdates/RecentUpdatesPagedModule";
import FollowingPagedModule from "./Following/FollowingPagedModule";
import RecentContentsPagedModule from "./RecentContents/RecentContentsPagedModule";
import { useMediaQuery } from "react-responsive";
import HomePageHero from "./PartnerHomeHero";
import TagManager from "react-gtm-module";

const Container = styled.div`
  width: 100%;
`;
const MODULES = {
  FOLLOW: "follow",
  UPDATE: "update",
  MYTH: "myth",
  REPORT: "report",
  DEVELOPING_NARRATIVE: "developing_narrative",
};

const useInitialMount = () => {
  // from
  // https://www.benmvp.com/blog/8-helpful-custom-react-hooks/?utm_source=twitter&utm_medium=social&utm_campaign=icymi
  // refs exist across component re-renders, so we can use it to store a value for the subsequent renders. We're
  // tracking if it's the first render, which is initially `true`
  const isFirst = useRef(true);

  // the very first render, the ref will be
  // `true`. but we immediately set it to `false`
  // so that every render after will be `false`
  if (isFirst.current) {
    isFirst.current = false;

    // return true the very first render
    return true;
  }

  // return false every following render
  return false;
};
export default function PartnerHome() {
  const principal = useSelector((state) => state.authentication?.principal);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PartnerView",
        user: principal?.username,
        partner: principal?.attributes?.partner,
        view: "Home",
        content_type: "",
        content_title: "",
        content_locale: "",
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  }, [principal]);

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const isInitialMount = useInitialMount();

  const [draggableItemsArray, setDraggableItemsArray] = useState([
    MODULES.UPDATE,
  ]);

  const [isModuleOpen, toggleModuleOpen] = useState({
    [MODULES.FOLLOW]: true,
    [MODULES.UPDATE]: true,
    [MODULES.MYTH]: true,
    [MODULES.REPORT]: true,
    [MODULES.DEVELOPING_NARRATIVE]: true,
  });

  const [moduleStateBeforeDrag, setModuleStateBeforeDrag] = useState({});
  const [hasLabelAccess, setHasLabelAccess] = useState(false);
  const [hasMythAccess, setHasMythAccess] = useState(false);
  const [hasOnlineReportAccess, setHasOnlineReportAccess] = useState(false);

  const [cookies, setCookie] = useCookies();

  const updateDraggableItems = useCallback(() => {
    if (hasLabelAccess) {
      setDraggableItemsArray((prev) => {
        if (!prev.includes(MODULES.FOLLOW)) {
          return [...prev, MODULES.FOLLOW];
        }
        if (!prev.includes(MODULES.UPDATE)) {
          return [...prev, MODULES.UPDATE];
        }
        return prev;
      });
    }
    if (hasMythAccess) {
      setDraggableItemsArray((prev) => {
        if (!prev.includes(MODULES.MYTH)) {
          return [...prev, MODULES.MYTH];
        }
        if (!prev.includes(MODULES.DEVELOPING_NARRATIVE)) {
          return [...prev, MODULES.DEVELOPING_NARRATIVE];
        }
        return prev;
      });
    }
    if (hasOnlineReportAccess) {
      setDraggableItemsArray((prev) => {
        if (!prev.includes(MODULES.REPORT)) {
          return [...prev, MODULES.REPORT];
        }
        return prev;
      });
    }
  }, [hasLabelAccess, hasMythAccess, hasOnlineReportAccess]);

  const updateCookie = useCallback(() => {
    updateDraggableItems();
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 10);

    setCookie(
      "newsguard-home",
      { isModuleOpen, draggableItemsArray },
      {
        expires: cookieDate,
        path: "/",
      }
    );
  }, [isModuleOpen, draggableItemsArray, setCookie, updateDraggableItems]);

  useEffect(() => {
    setHasLabelAccess(
      AuthService.getInstance().hasLabelAccess(principal) ||
        AuthService.getInstance().hasTVAccess(principal) ||
        AuthService.getInstance().hasPodcastAccess(principal)
    );

    setHasMythAccess(
      AuthService.getInstance().hasMythAccess(principal) ||
        AuthService.getInstance().hasRecentMythOnlyAccess(principal)
    );
    setHasOnlineReportAccess(
      AuthService.getInstance().hasOnlineReportAccess(principal)
    );
  }, [principal]);

  useEffect(() => {
    if (isInitialMount) {
      const cookie = cookies["newsguard-home"];
      if (cookie) {
        toggleModuleOpen(cookie.isModuleOpen);
        const itemsArray = cookie.draggableItemsArray || [];
        setDraggableItemsArray(itemsArray.filter((item) => item));
        updateDraggableItems();
      }
    }
  }, [
    isModuleOpen,
    isInitialMount,
    draggableItemsArray,
    hasLabelAccess,
    hasMythAccess,
    hasOnlineReportAccess,
    cookies,
    updateCookie,
    updateDraggableItems,
  ]);

  useEffect(() => {
    updateCookie();
  }, [isModuleOpen, draggableItemsArray, updateCookie]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newOrder = [...draggableItemsArray];
    draggableItemsArray.map((item, cur) => {
      if (destination.index > source.index) {
        if (cur > source.index && cur <= destination.index) {
          newOrder[cur - 1] = item;
        }
      } else {
        if (cur < source.index && cur >= destination.index) {
          newOrder[cur + 1] = item;
        }
      }
    });

    newOrder[destination.index] = draggableItemsArray[source.index];
    setDraggableItemsArray(newOrder);
    toggleModuleOpen(moduleStateBeforeDrag);
  };

  const onDragStart = (result) => {
    setModuleStateBeforeDrag(isModuleOpen);
    toggleModuleOpen({
      [MODULES.FOLLOW]: false,
      [MODULES.UPDATE]: false,
      [MODULES.MYTH]: false,
      [MODULES.REPORT]: false,
      [MODULES.DEVELOPING_NARRATIVE]: false,
    });
  };

  const handleOpen = (e, module) => {
    e?.preventDefault();
    const newModuleState = !isModuleOpen[module];
    toggleModuleOpen({ ...isModuleOpen, [module]: newModuleState });
  };

  return (
    <>
      <HomePageHero principal={principal} />

      <div>
        {!isInitialMount ? (
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="home-container" className="test" id="fool">
              {(provided) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%" }}
                  >
                    {draggableItemsArray.map((item, index) =>
                      console.log(item)
                    )}
                    {draggableItemsArray.map((item, index) => (
                      <Draggable draggableId={item} index={index} key={item}>
                        {(provided) => (
                          <Container
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            {item === "follow" ? (
                              hasLabelAccess && (
                                <FollowingPagedModule
                                  principal={principal}
                                  provided={provided}
                                  handleOpen={handleOpen}
                                  isOpen={isModuleOpen[MODULES.FOLLOW]}
                                  id={MODULES.FOLLOW}
                                  showAlertButton={true}
                                />
                              )
                            ) : item === "developing_narrative" ? (
                              hasMythAccess && (
                                <RecentContentsPagedModule
                                  principal={principal}
                                  provided={provided}
                                  handleOpen={handleOpen}
                                  isOpen={
                                    isModuleOpen[MODULES.DEVELOPING_NARRATIVE]
                                  }
                                  id={MODULES.DEVELOPING_NARRATIVE}
                                  showAlertButton={true}
                                />
                              )
                            ) : item === "myth" ? (
                              hasMythAccess && (
                                <RecentContentsPagedModule
                                  principal={principal}
                                  provided={provided}
                                  handleOpen={handleOpen}
                                  isOpen={isModuleOpen[MODULES.MYTH]}
                                  id={MODULES.MYTH}
                                  showAlertButton={true}
                                />
                              )
                            ) : item === "report" ? (
                              hasOnlineReportAccess && (
                                <RecentContentsPagedModule
                                  principal={principal}
                                  provided={provided}
                                  handleOpen={handleOpen}
                                  isOpen={isModuleOpen[MODULES.REPORT]}
                                  id={MODULES.REPORT}
                                  showAlertButton={true}
                                />
                              )
                            ) : (
                              <RecentUpdatesPagedModule
                                principal={principal}
                                provided={provided}
                                handleOpen={handleOpen}
                                isOpen={isModuleOpen[MODULES.UPDATE]}
                                id={MODULES.UPDATE}
                                isMobile={isMobile}
                                showAlertButton={true}
                              />
                            )}
                          </Container>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
