import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import useScrollOnParams from "./useScrollOnParams";
import SettingsToggle from "../common/SettingsToggle";
import ReportCategoryChooser from "./ReportCategoryChooser";
import RadioButton from "../common/ui/RadioButton";

import {
  ChooserToggle,
  FieldsetStyle,
  Section,
  SectionDescription,
  SectionTitles,
  SubcontentWrapper,
} from "./SettingsStyles";
import { AccountDetailsHeader } from "../../Account/AccountStyles";

const ReportSettings = ({
  reportAlertOn,
  reportTrigger,
  reportCategories,
  reportFrequency,
  handleChange,
  isLoading,
  handleVisible,
  handleToggle,
}) => {
  const initialReportTrigger = reportAlertOn;

  const [localReportOn, setLocalReportOn] = useState(reportAlertOn);
  const [reportCategoriesLocal, setReportCategoriesLocal] =
    useState(reportCategories);
  const [showCategories, setShowCategories] = useState(false);

  const [formRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "-90px 0px 0px 0px",
  });

  const divRef = useRef(null);
  useScrollOnParams(divRef, "type", "reportSettings", 180);

  useEffect(() => {
    if (entry) {
      handleVisible(entry);
    }
  }, [entry, formRef, handleVisible]);

  function handleRadioChange(e) {
    handleChange(formRef.current, reportCategoriesLocal);
  }

  function handleCategoryChange(e) {
    const categories = e.target.value;
    setReportCategoriesLocal(categories);
    handleChange(formRef.current, categories);
  }

  function toggleSettings() {
    handleToggle(
      "reportSettings",
      !localReportOn,
      formRef.current,
      reportCategoriesLocal
    );
    setLocalReportOn((localReport) => !localReport);
  }

  return (
    <div ref={divRef}>
      <form name="reportSettings" ref={formRef}>
        <input
          hidden={true}
          name="alertOn"
          value={localReportOn}
          onChange={handleChange} // so that react doesn't complain about uncontrolled inputs
        />

        <SettingsToggle
          callback={toggleSettings}
          initialValue={initialReportTrigger}
          isLoading={isLoading}
        >
          <Section>
            <AccountDetailsHeader>Reports Email Alerts</AccountDetailsHeader>

            <SectionDescription>
              Use the settings below to receive alerts when new reports are
              added to the dashboard.
            </SectionDescription>

            <SettingsToggle.Controls>
              <FieldsetStyle>
                <SectionTitles>Reports Alerts</SectionTitles>

                <RadioButton
                  checked={reportTrigger === "ANY"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="ANY"
                  label="Receive a notification when any report is published"
                  onClick={() => setShowCategories(false)}
                />
                <RadioButton
                  checked={reportTrigger === "CATEGORY"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="CATEGORY"
                  label="Receive a notification only for specific report
                categories"
                  onClick={() =>
                    setShowCategories((showCategories) => !showCategories)
                  }
                >
                  <ChooserToggle role="button">
                    <div>
                      {showCategories
                        ? "Hide report categories"
                        : "View and choose report categories"}
                    </div>
                  </ChooserToggle>
                </RadioButton>

                <SubcontentWrapper>
                  {showCategories && (
                    <ReportCategoryChooser
                      reportSettings={reportCategories}
                      handleChange={handleCategoryChange}
                    />
                  )}
                </SubcontentWrapper>
              </FieldsetStyle>
              <FieldsetStyle>
                <SectionTitles>Reports Notification Frequency</SectionTitles>
                <RadioButton
                  checked={reportFrequency === "WEEKLY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="WEEKLY"
                  label="Notify me with a Weekly Email Digest"
                />
                <RadioButton
                  checked={reportFrequency === "DAILY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="DAILY"
                  label="Notify me with a Daily Email Digest"
                />
              </FieldsetStyle>
            </SettingsToggle.Controls>
          </Section>
        </SettingsToggle>
      </form>
    </div>
  );
};

export default ReportSettings;
