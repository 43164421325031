import styled from "@emotion/styled/macro";

const SvgSTyles = styled.svg`
  height: ${(props) => (props.height ? props.height + "px" : "26px")};
  margin-right: ${({ marginRight }) =>
    marginRight ? marginRight + "px" : "12px"};

  @media screen and (max-width: 600px) {
    height: 22px;
    margin-right: 6px;
  }
`;

const Score = styled.div`
  font-size: ${({ large }) => (large ? "30px" : "16px")};
  font-family: "Sora", sans-serif;
  background: #35d;
  border-radius: 0 4px 4px 0;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0;

  padding: ${({ large }) => (large ? "3px 5px" : "3px")};
  line-height: ${({ large }) => (large ? "32px" : "14px")};
  letter-spacing: -0.04rem;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  height: ${(props) => (props.wrapperHeight ? props.wrapperHeight : "34px")};
`;

const InPageBlueSVG = styled.svg`
  height: ${(props) => (props.height ? props.height : "100%")};
  align-self: flex-start;
  flex-shrink: 0;
`;

const CurrentScore = styled.span`
  font-weight: 600;
`;

export default function Icon({
  rating,
  children,
  height,
  marginRight,
  isWebsite,
  outline,
  large,
}) {
  const WideBlueSVG = (
    <Wrapper wrapperHeight={height}>
      <InPageBlueSVG
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 74 88"
        height={height}
      >
        <g id="Layer_1">
          <path
            d="M66,0V33.09c0,23.09,0,38.36-30.76,53.78l-2.24,1.13-2.25-1.13C0,71.43,0,56.18,0,33.09V0H66ZM10,33.08c0,21.4,0,31.53,23,43.66,23.02-12.13,23.02-22.25,23-43.63V10H10v23.08Zm39.4,54.92c9.86-6.44,16.34-13.55,20.06-21.89,4.54-10.18,4.54-20.77,4.54-33.02v54.91h-24.6Z"
            fill="#35d"
            fillRule="evenodd"
          />
        </g>
      </InPageBlueSVG>
      <Score large={large}>
        <CurrentScore>{children}</CurrentScore> / 100
      </Score>
    </Wrapper>
  );

  const BlueOutlineSVG = (
    <SvgSTyles
      height={height}
      viewBox="0 0 88 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      marginRight={marginRight}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="#3355DD"
        fillRule="evenodd"
      >
        <g>
          <path
            id="shield"
            d="M44,88l-2.2-1.1C11,71.4,11,56.2,11,33.1V0h66v33.1c0,23.1,0,38.4-30.8,53.8L44,88z M21,10v23.1
			c0,21.4,0,31.5,23,43.7c23-12.1,23-22.3,23-43.6V10H21z"
          />
        </g>
      </g>
    </SvgSTyles>
  );

  const greenSvg = (
    <SvgSTyles
      height={height}
      viewBox="0 0 88 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      marginRight={marginRight}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ii/branded/shield-check" fill="#00CC88">
          <path
            d="M77,0 L77,33.0915335 L76.9999692,33.7818852 L76.9999692,33.7818852 L76.9991696,35.1486998 C76.9723187,57.0973913 76.07729,71.9111452 46.2430005,86.87254 L44,88 L41.7511886,86.87254 C11.9225357,71.8942333 11.0276761,57.0963765 11.0008303,35.1486542 L11,0 L77,0 Z M63.754834,22.228426 L40.4203102,45.5629497 L25.5710678,30.7137073 L18.5,37.7847751 L40.4203102,59.7050854 L70.8259018,29.2994938 L63.754834,22.228426 Z"
            id="shield"
          ></path>
        </g>
      </g>
    </SvgSTyles>
  );

  const platformSvg = (
    <Wrapper wrapperHeight={height}>
      <SvgSTyles
        height={height}
        marginRight={marginRight}
        viewBox="0 0 27 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#999999"
          d="M12,0v6c0,4.2,0,7-5.6,9.8L6,16l-0.4-0.2C0,13,0,10.2,0,6V0H12z M1.8,6c0,3.9,0,5.7,4.2,7.9v0
	c4.2-2.2,4.2-4,4.2-7.9V1.8H1.8V6z M13.5,0H14v16H9.1c4.4-2.9,4.4-6.2,4.4-10V0z"
        />
        <path
          fill="#999999"
          d="M13.6,0H26c0.6,0,1,0.4,1,1v14c0,0.6-0.5,1-1,1H13.6V0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M21.7,6.5h-2.9V8h1.1v2.9h-1.1v1.5h3.8v-1.5h-0.9V6.5z M20.6,3.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
	s1.3-0.6,1.3-1.3C21.9,3.8,21.3,3.3,20.6,3.3z"
        />
      </SvgSTyles>
    </Wrapper>
  );

  const redSvg = (
    <SvgSTyles
      height={height}
      viewBox="0 0 88 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      marginRight={marginRight}
    >
      <title>ii/branded/shield rejected</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ii/branded/shield-rejected" fill="#FF4444">
          <path
            d="M77,0 L77,33.0915335 L76.9999692,33.7818852 L76.9999692,33.7818852 L76.9991696,35.1486998 C76.9723187,57.0973913 76.07729,71.9111452 46.2430005,86.87254 L44,88 L41.7511886,86.87254 C11.9225357,71.8942333 11.0276761,57.0963765 11.0008303,35.1486542 L11,0 L77,0 Z M49.5,52 L38.5,52 L38.5,63 L49.5,63 L49.5,52 Z M49.5,15 L38.5,15 L38.5,43 L49.5,43 L49.5,15 Z"
            id="shield"
          ></path>
        </g>
      </g>
    </SvgSTyles>
  );

  const satireSvg = (
    <Wrapper wrapperHeight={height}>
      <SvgSTyles
        height={height}
        marginRight={marginRight}
        viewBox="0 0 28 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12,0v6c0,4.2,0,7-5.6,9.8L6,16l-0.4-0.2C0,13,0,10.2,0,6V0H12z M1.8,6c0,3.9,0,5.7,4.2,7.9v0
        c4.2-2.2,4.2-4,4.2-7.9V1.8H1.8V6z M13.5,0H14v16H9.1c4.4-2.9,4.4-6.2,4.4-10V0z"
          fill="#FF9100"
        />
        <path
          d="M13.5,0H26c0.6,0,1,0.4,1,1v14c0,0.6-0.5,1-1,1H13.5V0z"
          fill="#FF9100"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4,9L22.4,9c-0.4,0.8-1.2,1.3-2,1.3s-1.6-0.5-2-1.3l-1.6,0.8l0,0.1c0.7,1.4,2.1,2.3,3.6,2.3s3-0.9,3.6-2.3
        L22.4,9L22.4,9z M22.7,4.4c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c1,0,1.7-0.8,1.7-1.7S23.6,4.4,22.7,4.4z M18.3,4.4
        c-1,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c1,0,1.7-0.8,1.7-1.7S19.3,4.4,18.3,4.4z M22.7,5.6c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5
        c-0.3,0-0.5-0.2-0.5-0.5S22.4,5.6,22.7,5.6z M18.3,5.6c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5
        S18.1,5.6,18.3,5.6z"
          fill="white"
        />
      </SvgSTyles>
    </Wrapper>
  );

  const unratedSvg = (
    <SvgSTyles
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 64"
      height={height}
      marginRight={marginRight}
    >
      <g>
        <rect
          fill="#abaaab"
          x="6.1479"
          y="17.7231"
          width="36.3428"
          height="16.3457"
        />
        <path
          fill="#abaaab"
          fillRule="evenodd"
          d="M49-.5H0L0,23.5676c-.01,16.7918-.02,27.8865,22.7887,39.1165l1.6625.8159,1.6675-.8159C48.9274,51.4541,49.01,40.3594,49,23.5676ZM39.9888,28.5647a1.8852,1.8852,0,0,1-.5452,1.3731,1.8556,1.8556,0,0,1-1.3589.5622H10.9153a1.8476,1.8476,0,0,1-1.3589-.5622A1.8757,1.8757,0,0,1,9,28.5647V22.4353a1.8757,1.8757,0,0,1,.5564-1.3731A1.8476,1.8476,0,0,1,10.9153,20.5H38.0847a1.8556,1.8556,0,0,1,1.3589.5622A1.8952,1.8952,0,0,1,40,22.4353Z"
          transform="translate(0 0.5)"
        />
        <path
          fill="#abaaab"
          fillRule="evenodd"
          d="M23.4718,62.2287C.4845,51.1369.49,40.172.5,23.5753V0H49.4992V23.5675c.0156,16.5723.0254,27.5205-23.0645,38.6612l-1.4844.7158Z"
          transform="translate(0 0.5)"
        />
        <path
          fill="#abaaab"
          fillRule="evenodd"
          d="M48.9994.5V23.5685c.0147,16.3826.0244,27.2056-22.7819,38.21l-1.2664.6108-1.2625-.6108C.9841,50.8233.99,40.5672,1,23.5676L1,.5H48.9994m1-1H0L0,23.5676C-.01,40.3546-.02,51.4493,23.254,62.6792l1.6965.8208,1.7014-.8208c23.2737-11.23,23.3626-22.32,23.3475-39.1116V-.5Z"
          transform="translate(0 0.5)"
        />
        <rect x="6.1479" y="17.7231" width="36.3428" height="16.3457" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M49-.5H0L0,23.5676c-.01,16.7918-.02,27.8865,22.7887,39.1165l1.6625.8159,1.6675-.8159C48.9274,51.4541,49.01,40.3594,49,23.5676ZM39.9888,28.5647a1.8852,1.8852,0,0,1-.5452,1.3731,1.8556,1.8556,0,0,1-1.3589.5622H10.9153a1.8476,1.8476,0,0,1-1.3589-.5622A1.8757,1.8757,0,0,1,9,28.5647V22.4353a1.8757,1.8757,0,0,1,.5564-1.3731A1.8476,1.8476,0,0,1,10.9153,20.5H38.0847a1.8556,1.8556,0,0,1,1.3589.5622A1.8952,1.8952,0,0,1,40,22.4353Z"
          transform="translate(0 0.5)"
        />
        <path
          d="M48.9994.5V23.5685c.0147,16.3826.0244,27.2056-22.7819,38.21l-1.2664.6108-1.2625-.6108C.9841,50.8233.99,40.5672,1,23.5676L1,.5H48.9994m1-1H0L0,23.5676C-.01,40.3546-.02,51.4493,23.254,62.6792l1.6965.8208,1.7014-.8208c23.2737-11.23,23.3626-22.32,23.3475-39.1116V-.5Z"
          transform="translate(0 0.5)"
        />
      </g>
    </SvgSTyles>
  );

  const neutralSvg = (
    <Wrapper wrapperHeight={height}>
      <SvgSTyles
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0V6.02C12 10.22 12 13 6.41 15.8L6 16L5.59 15.8C0 12.99 0 10.22 0 6.02V0H12ZM1.82 6.02C1.82 9.91 1.82 11.75 6 13.96V13.95C10.18 11.75 10.18 9.91 10.18 6.02V1.82H1.82V6.02ZM13.5 0H14V16H9.05C13.5 13.06 13.5 9.77 13.5 6.02V0Z"
          fill="#FF9100"
        />
        <path
          d="M14 0H26C26.5523 0 27 0.447715 27 1V15C27 15.5523 26.5523 16 26 16H14V0Z"
          fill="#FF9100"
        />
        <path
          d="M23.9545 7.09094V8.90892H17.0455V7.09094H23.9545Z"
          fill="white"
        />
      </SvgSTyles>
    </Wrapper>
  );

  const NeutralTVSvg = (
    <SvgSTyles
      height={height}
      viewBox="0 0 88 88"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      marginRight={marginRight}
    >
      <title>Neutral</title>
      <g
        id="ii/branded/-shield-neutral"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M77,0 L76.9980755,35.8050274 L76.9851217,38.3884018 L76.9711403,39.6555394 L76.9503593,40.9067765 L76.9214313,42.1424629 L76.8830089,43.362948 L76.8337448,44.5685814 C76.0526767,61.5448393 71.6021466,74.1553544 46.2430005,86.87254 L44,88 L41.7511886,86.87254 C16.3968336,74.1409793 11.9471443,61.5397937 11.1662238,44.5672531 L11.116969,43.3618724 L11.0785538,42.1416191 C11.0729798,41.9369846 11.0678013,41.7317157 11.0629902,41.5258052 L11.0288543,39.6550871 L11.0148755,38.3881048 L11.0019241,35.8049482 L11,0 L77,0 Z M63,32 L25,32 L25,42 L63,42 L63,32 Z"
          id="unrated"
          fill="#FFBB00"
        ></path>
      </g>
    </SvgSTyles>
  );
  switch (rating) {
    case "S":
      return satireSvg;
    case "P":
      return platformSvg;
    case "T":
    case "A":
    case "B":
      return isWebsite ? (outline ? BlueOutlineSVG : WideBlueSVG) : greenSvg;
    case "N":
    case "C":
    case "D":
    case "F":
      return isWebsite ? (outline ? BlueOutlineSVG : WideBlueSVG) : redSvg;
    case "TK":
      return unratedSvg;
    case "M":
      return NeutralTVSvg;
    case "FL":
    case "L":
    default:
      return neutralSvg;
  }
}
