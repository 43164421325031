import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";

const BylineContainer = styled.div`
  a {
    color: #3355dd;
    text-decoration: none;
  }
`;

const BylineText = styled.p`
  &:last-of-type {
    margin-bottom: 50px;
  }
`;

export default function Byline(props) {
  const renderByLine = (byline, naString = "") => {
    return byline ? (
      byline.bio ? (
        <a href={byline.bio} target={"_blank"} rel="noreferrer">
          {byline.name}
        </a>
      ) : (
        byline.name
      )
    ) : (
      naString
    );
  };

  const formatByLine = (authors = []) => {
    let byLine = [];
    const rowLen = authors.length;
    if (rowLen >= 1) {
      authors.forEach((author, i) => {
        if (i > 0) {
          byLine.push(", ");
          /*
           if (rowLen === i + 1) {
           if (rowLen > 2) {
           byLine.push(",");
           }
           byLine.push(" and ");
           } else {
           byLine.push(", ");
           }
           */
        }
        byLine.push(
          author.user.bio ? (
            <a
              key={`authors_${i}`}
              href={author.user.bio}
              target={"_blank"}
              rel="noreferrer"
            >
              {author.user.name}
            </a>
          ) : (
            author.user.name
          )
        );
      });
    }

    return byLine;
  };

  let authorString = "";
  let contributorString = "";
  let editorString = [];
  let translatorString = [];

  if (props.label.profileWriters && props.label.profileWriters.length > 0) {
    authorString = formatByLine(props.label.profileWriters);
    contributorString = formatByLine(props.label.profileContributors);

    editorString = formatByLine(props.label.profileEditors);
    translatorString = formatByLine(props.label.profileTranslators);
  } else if (props.label.rank !== "FL") {
    authorString = renderByLine(props.label.byline);

    if (props.label.contributor) {
      if (props.label.contributor.type === "DUAL_BYLINE") {
        authorString = (
          <div>
            {" "}
            {authorString}, {renderByLine(props.label.contributor)}{" "}
          </div>
        );
      } else {
        contributorString = renderByLine(props.label.contributor);
      }
    }

    if (props.label.editors) {
      editorString = [];
      const rowLen = props.label.editors.length;
      if (rowLen >= 1) {
        props.label.editors.forEach((editor, i) => {
          if (i > 0) {
            editorString.push(", ");
          }
          editorString.push(renderByLine(editor));
        });
      } else if (rowLen === 1) {
        const editor = props.label.editors[0];
        editorString.push(renderByLine(editor));
      } else {
        editorString = ["NA"];
      }
    } else {
      editorString = ["NA"];
    }
  }

  return (
    <BylineContainer>
      {authorString && authorString.length > 0 ? (
        <BylineText>
          <FormattedMessage
            id={"msg.writtenBy"}
            defaultMessage={"Written by"}
          />
          : <span>{authorString}</span>
        </BylineText>
      ) : null}
      {contributorString && contributorString.length > 0 ? (
        <BylineText>
          <FormattedMessage
            id={"msg.contributing"}
            defaultMessage={"Contributing"}
          />
          : <span>{contributorString}</span>
        </BylineText>
      ) : null}
      {editorString && editorString.length > 0 ? (
        <BylineText>
          <FormattedMessage id={"msg.editedBy"} defaultMessage={"Edited by"} />:{" "}
          <span>{editorString}</span>
        </BylineText>
      ) : null}
      {translatorString && translatorString.length > 0 ? (
        <BylineText>
          <FormattedMessage
            id={"msg.translatedBy"}
            defaultMessage={"Translated by"}
          />
          : <span>{translatorString}</span>
        </BylineText>
      ) : null}
      {props.label.feedbackLink ? (
        <BylineText>
          <a href={props.label.feedbackLink} target={"_blank"} rel="noreferrer">
            <FormattedMessage
              id={"msg.seeFeedback"}
              defaultMessage={"See feedback from this website"}
            />
          </a>
        </BylineText>
      ) : (
        ""
      )}
    </BylineContainer>
  );
}
