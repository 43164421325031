import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";

export const Button = styled.button`
  background-color: ${(props) =>
    props.disabled ? "var(--primary-gray)" : "var(--primary-blue)"};
  border: none;
  color: ${(props) =>
    props.disabled ? "var(--primary-gray-disabled)" : "#fff"};
  display: inline-block;
  width: 100%;

  height: calc(40 / 16 * 1rem);
  padding: 0 20px;
  border-radius: 2px;
  font-family: Sora, sans-serif;
  font-size: var(--14px);
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const AccountButton = styled(Button)`
  background-color: var(--primary-gray);
  color: var(--primary-black);
  width: auto;
  margin-bottom: 0;
  white-space: nowrap;
`;

export const AccountCancelButton = styled(AccountButton)`
  width: auto;
`;

export const ButtonRed = styled(Button)`
  background-color: var(--primary-red);
  color: #fff;
  /* width: auto; */
  margin-bottom: 0;
`;

export const ButtonBlack = styled(Button)`
  background-color: var(--primary-black);
  color: #fff;
  width: initial;
  min-width: 100px;
  margin-bottom: 0;
  flex-shrink: 0;
`;

export const ButtonWhite = styled(Button)`
  background-color: ${(props) =>
    props.disabled ? "var(--primary-gray)" : "#fff"};
  color: ${(props) =>
    props.disabled ? "var(--primary-gray-disabled)" : "var(--primary-black)"};
  border: ${(props) =>
    props.disabled ? "none" : "1px solid var(--primary-black)"};
  width: auto;
`;

export const ButtonWhiteAccountMenu = styled(ButtonWhite)`
  @media (max-width: 600px) {
    font-size: var(--12px);
    line-height: 30px;
    padding: 0 10px;
    height: calc(30 / 16 * 1rem);
  }
`;

export const ButtonWhitePitch = styled(ButtonWhite)`
  color: var(--primary-blue);
  border: 1px solid var(--primary-white);
  text-transform: none;
  padding: 0;
`;

export const ButtonBluePitch = styled(ButtonWhite)`
  background-color: var(--primary-blue);
  color: var(--primary-white);
  border: 1px solid var(--primary-white);
  text-transform: none;
  padding: 0;
`;

export const ButtonWithIcon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: ${({ icon }) =>
      icon ? `url(/images/browser_icons/${icon}.svg)` : ""};
    display: block;
    height: 38px;

    width: 38px;
    transform: scale(75%);
  }
`;

export const ButtonWithIconGray = styled(Button)`
  background-color: var(--primary-gray);
  color: var(--primary-black);
  padding: 0 35px 0 10px;
  margin-bottom: 0;
  width: auto;
  position: relative;

  &::after {
    content: ${({ icon }) => (icon ? `url(${icon})` : "")};
    display: block;
    height: 30px;
    width: 30px;
    transform: scale(55%);
    position: absolute;
    right: 12px;
    top: -3px;
  }
`;

export function LinkButton({ children, icon, to }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (to) {
      navigate(to);
    }
  };

  return (
    <ButtonWithIconGray icon={icon} onClick={handleClick}>
      {children}
    </ButtonWithIconGray>
  );
}
