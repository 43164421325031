import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import useScrollOnParams from "./useScrollOnParams";
import SettingsToggle from "../common/SettingsToggle";
import RadioButton from "../common/ui/RadioButton";

import {
  FieldsetStyle,
  Section,
  SectionDescription,
  SectionTitles,
} from "./SettingsStyles";
import { AccountDetailsHeader } from "../../Account/AccountStyles";

const UpdatedRatingsSettings = ({
  ratingsOn,
  ratingsFrequency,
  ratingsTriggers,
  handleChange,
  handleVisible,
  handleToggle,
}) => {
  const initialRatingsOn = ratingsOn;
  const [localRatingOn, setLocalRatingOn] = useState(ratingsOn);
  const [formRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "-90px 0px 0px 0px",
  });

  const divRef = useRef(null);
  useScrollOnParams(divRef, "type", "updatedRatingsSettings", 180);

  useEffect(() => {
    if (entry) {
      handleVisible(entry);
    }
  }, [entry, handleVisible]);

  function handleRadioChange() {
    handleChange(formRef.current);
  }

  function toggleSettings() {
    handleToggle("updatedRatingsSettings", !localRatingOn, formRef.current);
    setLocalRatingOn((localRating) => !localRating);
  }
  return (
    <div ref={divRef}>
      <form
        name="updatedRatingsSettings"
        ref={formRef}
        id="updatedRatingsSettings"
      >
        <input
          hidden={true}
          name="alertOn"
          value={localRatingOn}
          onChange={handleChange} // so that react doesn't complain about uncontrolled inputs
        />

        <SettingsToggle
          callback={toggleSettings}
          initialValue={initialRatingsOn}
        >
          <Section>
            <AccountDetailsHeader>Updated Ratings</AccountDetailsHeader>
            <SectionDescription>
              Use the settings below to receive alerts when ratings are updated.
            </SectionDescription>
            <SettingsToggle.Controls>
              <FieldsetStyle>
                <SectionTitles>Updated Ratings Alerts</SectionTitles>
                <SectionDescription>
                  Choose which kind of updated ratings you would like to be
                  alerted about.
                </SectionDescription>

                <RadioButton
                  checked={ratingsTriggers === "ANY"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="ANY"
                  label="All rating updates"
                />

                <RadioButton
                  checked={ratingsTriggers === "SCORE"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="SCORE"
                  label="Only ratings with score or criteria changes"
                />

                <RadioButton
                  checked={ratingsTriggers === "RATING"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="RATING"
                  label="Only ratings with overall rating level changes"
                />
              </FieldsetStyle>
              <FieldsetStyle>
                <SectionTitles>
                  Updated Ratings Notification Frequency
                </SectionTitles>
                <RadioButton
                  checked={ratingsFrequency === "WEEKLY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="WEEKLY"
                  label="Notify me with a Weekly Email Digest"
                />
                <RadioButton
                  checked={ratingsFrequency === "DAILY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="DAILY"
                  label="Notify me with a Daily Email Digest"
                />
              </FieldsetStyle>
            </SettingsToggle.Controls>
          </Section>
        </SettingsToggle>
      </form>
    </div>
  );
};
export default UpdatedRatingsSettings;
