import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import LoadingIndicator from "../../common/LoadingIndicator";
import { Button } from "../FormElements/Button";
import {
  AccountMessage,
  AccountMessageWrapper,
  SubscriptionText,
  Wrapper,
} from "./SignupStyles";
import { ButtonsWrapper } from "../Account/AccountStyles";
import PersonalUseElement from "./PersonalUseElement";

const PersonalUserForm = (props) => {
  const [email, setEmail] = useState(props.email);
  const [name, setName] = useState(props.name);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [personalUseAccepted, setPersonalUseAccepted] = useState(false);
  const [personalUseAcceptedError, setPersonalUseAcceptedError] =
    useState(false);

  const submit = (e) => {
    e.preventDefault();

    if (name === "") {
      setNameError({
        id: "template.name_invalid_message",
        defaultMessage: "Please enter your name",
        name: "name",
        html: true,
      });

      return false;
    }

    if (email === "") {
      setEmailError({
        id: "template.email_invalid_message",
        defaultMessage: "Please enter a correct email address",
        name: "email",
        html: true,
      });

      return false;
    }
    if (!personalUseAccepted) {
      setPersonalUseAcceptedError({
        id: "template.personal_use_unchecked",
        defaultMessage:
          "Please check the box confirming that you will not use NewsGuard for prohibited purposes or <licensing>click here</licensing> to learn more about licensing.",
        name: "name",
        html: true,
        values: {
          licensing: (...chunks) => (
            <a
              href="https://www.newsguardtech.com/feedback/give/"
              target="_blank"
              rel="noopener noreferrer"
              className="error"
            >
              {chunks}
            </a>
          ),
        },
      });

      return false;
    }
    if (props.submit) {
      return props.submit();
    }
  };

  return (
    <>
      {isLoading ? <LoadingIndicator className="large" /> : ""}

      <Wrapper>
        <PersonalUseElement
          personalUseAccepted={personalUseAccepted}
          setPersonalUseAccepted={setPersonalUseAccepted}
          personalUseAcceptedError={personalUseAcceptedError}
          setPersonalUseAcceptedError={setPersonalUseAcceptedError}
        />
        <ButtonsWrapper>
          <Button onClick={submit} disabled={error || nameError || !name}>
            {isLoading ? (
              <LoadingIndicator button />
            ) : (
              <FormattedMessage
                id="template.get_access"
                defaultMessage="Get Access"
              />
            )}
          </Button>
        </ButtonsWrapper>
        <AccountMessageWrapper>
          <AccountMessage>
            <FormattedMessage
              id="template.credit_card_disclaimer"
              defaultMessage="By clicking the {offer_text} button above, you are agreeing to our <tos>https://www.newsguardtech.com/terms-of-service/</tos>."
              values={{
                offer_text: (
                  <FormattedMessage
                    id="template.get_access"
                    defaultMessage="Get Access"
                  />
                ),
                tos: (...chunks) => (
                  <a
                    className="link link--external"
                    target="_blank"
                    href={chunks}
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="template.tos"
                      defaultMessage="Terms of Service"
                    />
                  </a>
                ),
              }}
            />
          </AccountMessage>
        </AccountMessageWrapper>
      </Wrapper>
    </>
  );
};

export default PersonalUserForm;
