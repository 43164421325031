import styled from "@emotion/styled/macro";

/* from https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
const LabelStyle = styled.label`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  color: var(--primary-black);
  margin-bottom: 20px;

  /* &:has(+ div) {
    margin-bottom: 10px;
  } */
`;

const Text = styled.span`
  line-height: 23px;
  transition: 180ms all ease-in-out;
  opacity: 0.8;
`;

const Input = styled.input`
  height: 25px;
  width: 25px;
  appearance: none;
  border-radius: 50%;
  background: inherit;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px var(--primary-blue);
  flex: 0 0 25px;

  &:checked {
    background: var(--primary-blue);
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 3px 1px var(--primary-blue);
  }
`;

function RadioButton({
  name,
  onChange,
  value,
  checked,
  label,
  labelStyle,
  textStyle,
  radioStyle,
  children,
  onClick,
}) {
  return (
    <LabelStyle style={labelStyle}>
      <Input
        type="radio"
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
        style={radioStyle}
        onClick={onClick}
      />
      <Text style={textStyle}>
        {label}
        {children}
      </Text>
    </LabelStyle>
  );
}

export default RadioButton;
