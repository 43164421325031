import AuthService from "../../../../service/AuthService";
import ContentService from "../../../../service/ContentService";

const getData = async (
  searchTerm,
  advancedFilters = [],
  page,
  sizePerPage,
  searchContext,
  principal
) => {
  window.scrollTo(0, 0);
  const filterInfo = formatFilters(
    searchTerm,
    advancedFilters,
    searchContext,
    principal
  );

  let sort = {};

  if (advancedFilters.sortBy?.value) {
    sort[advancedFilters.sortBy?.value] =
      advancedFilters.sortOrder?.value || "desc";
  }

  return await ContentService.getInstance().search(
    searchTerm,
    filterInfo,
    sort,
    page,
    sizePerPage
  );
};

export const downloadData = async (
  searchTerm,
  advancedFilters = [],
  searchContext,
  principal
) => {
  const filterInfo = formatFilters(
    searchTerm,
    advancedFilters,
    searchContext,
    principal
  );

  let sort = {};

  if (advancedFilters.sortBy?.value) {
    sort[advancedFilters.sortBy?.value] =
      advancedFilters.sortOrder?.value || "desc";
  }

  return await ContentService.getInstance().searchDownload(
    searchTerm,
    filterInfo,
    sort,
    searchContext
  );
};
const formatFilters = (
  searchTerm,
  advancedFilters = [],
  searchContext,
  principal
) => {
  const filters = {};

  filters["type"] = {
    key: "type",
    filterVal:
      searchContext ||
      (AuthService.getInstance().hasLabelAccess(principal)
        ? "WEB"
        : AuthService.getInstance().hasTVAccess(principal)
        ? "TV"
        : AuthService.getInstance().hasPodcastAccess(principal)
        ? "PODCAST"
        : AuthService.getInstance().hasMythAccess(principal) ||
          AuthService.getInstance().hasRecentMythOnlyAccess(principal)
        ? "MYTH"
        : AuthService.getInstance().hasOnlineReportAccess(principal)
        ? "REPORT"
        : "NONE"),
  };

  const criteriaFilterInfo = [];
  if (advancedFilters?.criteria) {
    advancedFilters.criteria.forEach((criteria) => {
      if (criteria && criteria.value && criteria.toggleValue) {
        criteriaFilterInfo.push({
          key: criteria.value,
          filterVal: criteria.toggleValue,
        });
      }
    });
  }
  const topicFilterInfo = [];
  if (advancedFilters?.topics?.value) {
    advancedFilters.topics.value.forEach((topic) => {
      if (topic && topic.value) {
        topicFilterInfo.push(topic.label);
      }
    });
  }
  const flagsFilterInfo = [];
  if (advancedFilters?.flags?.value) {
    advancedFilters.flags.value.forEach((flags) => {
      if (flags && flags.value) {
        flagsFilterInfo.push(flags.label);
        flagsFilterInfo.push(flags.value);
      }
    });
  }
  const mediumFilterInfo = [];
  if (advancedFilters?.medium?.value) {
    advancedFilters?.medium?.value.forEach((medium) => {
      if (medium && medium.value) {
        mediumFilterInfo.push(medium.label);
      }
    });
  }
  const coverageFilterInfo = [];
  if (advancedFilters?.coverage?.value) {
    advancedFilters.coverage.value.forEach((coverage) => {
      if (coverage && coverage.value) {
        coverageFilterInfo.push(coverage.label);
      }
    });
  }
  const orientationFilterInfo = [];
  if (advancedFilters?.orientation?.value) {
    advancedFilters.orientation.value.forEach((orientation) => {
      if (orientation && orientation.value) {
        orientationFilterInfo.push(orientation.label);
      }
    });
  }
  const dmaFilterInfo = [];
  if (advancedFilters?.location?.value) {
    advancedFilters.location.value.forEach((location) => {
      if (location && location.value) {
        dmaFilterInfo.push(location.value);
      }
    });
  }
  const localeFilterInfo = [];
  if (advancedFilters?.locale?.value) {
    advancedFilters.locale.value.forEach((locale) => {
      if (locale && locale.value) {
        localeFilterInfo.push(locale.value);
      }
    });
  }

  const ratingsFilterInfo = [];
  if (advancedFilters?.ratings?.value) {
    advancedFilters.ratings.value.forEach((rating) => {
      if (rating) {
        if (rating.value) {
          ratingsFilterInfo.push(rating.value);
        } else {
          ratingsFilterInfo.push(rating);
        }
      }
    });
  }

  let subjectTagsFilterInfo = [];
  if (
    advancedFilters?.subject_tags?.value &&
    Array.isArray(advancedFilters?.subject_tags?.value)
  ) {
    subjectTagsFilterInfo = advancedFilters?.subject_tags?.value.map(
      (subject_tag) => subject_tag.value
    );
  }

  let publisherTypeFilterInfo = [];
  advancedFilters.publisher_type.value.forEach((publisher_type_element) => {
    if (publisher_type_element) {
      if (publisher_type_element.value) {
        publisherTypeFilterInfo.push(publisher_type_element.value);
      } else {
        publisherTypeFilterInfo.push(publisher_type_element);
      }
    }
  });

  let harmRiskFilterInfo = [];
  advancedFilters.harm_risk.value.forEach((harmRisk) => {
    if (harmRisk) {
      if (harmRisk.value) {
        harmRiskFilterInfo.push(harmRisk.value);
      } else {
        harmRiskFilterInfo.push(harmRisk);
      }
    }
  });

  let mythLocationFilterInfo = [];
  advancedFilters.myth_location.value.forEach((mythLocation) => {
    if (mythLocation) {
      if (mythLocation.value) {
        mythLocationFilterInfo.push(mythLocation.value);
      } else {
        mythLocationFilterInfo.push(mythLocation);
      }
    }
  });

  let mythManuipulatedMediailterInfo = [];

  advancedFilters.manipulated_media.value.forEach((manipulatedMedia) => {
    if (manipulatedMedia) {
      if (manipulatedMedia.value) {
        mythManuipulatedMediailterInfo.push(manipulatedMedia.value);
      } else {
        mythManuipulatedMediailterInfo.push(manipulatedMedia);
      }
    }
  });

  let mythSpreadLocationFilterInfo = [];
  advancedFilters.spread_location.value.forEach((spreadLocation) => {
    if (spreadLocation) {
      if (spreadLocation.value) {
        mythSpreadLocationFilterInfo.push(spreadLocation.value);
      } else {
        mythSpreadLocationFilterInfo.push(spreadLocation);
      }
    }
  });

  const communitiesServedFilterInfo = [];
  if (advancedFilters?.communities_served?.value) {
    advancedFilters.communities_served.value.forEach((community) => {
      if (community && community.value) {
        communitiesServedFilterInfo.push(community.value);
      }
    });
  }

  const bgSegmentsFilterInfo = [];
  if (advancedFilters?.bg_segments?.value) {
    advancedFilters.bg_segments.value.forEach((seg) => {
      if (seg && seg.value) {
        bgSegmentsFilterInfo.push(seg.value);
      }
    });
  }

  const advancedFilterInfo = {
    topic: { filterVal: topicFilterInfo ? topicFilterInfo.join(",") : "" },
    flags: { filterVal: flagsFilterInfo ? flagsFilterInfo.join(",") : "" },
    medium: { filterVal: mediumFilterInfo ? mediumFilterInfo.join(",") : "" },
    coverage: {
      filterVal: coverageFilterInfo ? coverageFilterInfo.join(",") : "",
    },
    orientation: {
      filterVal: orientationFilterInfo ? orientationFilterInfo.join(",") : "",
    },
    locale: { filterVal: localeFilterInfo ? localeFilterInfo.join(",") : "" },
    dma: { filterVal: dmaFilterInfo ? dmaFilterInfo.join(",") : "" },
    ratings: {
      filterVal: ratingsFilterInfo ? ratingsFilterInfo.join(",") : "",
    },
    score: {
      filterVal: advancedFilters?.score?.value?.low || 0,
      filterVal2:
        (advancedFilters?.score?.scoreType || "") === "range"
          ? advancedFilters?.score?.value?.high || 100
          : advancedFilters?.score?.value?.low || 100,
    },
    criteria: {
      filterType: "MULTISELECT",
      subFilters: criteriaFilterInfo,
    },
    owner: {
      filterType: "text",
      filterVal: advancedFilters?.owner?.value || "",
    },
    categories: {
      filterValList: [...advancedFilters?.categories?.value],
      filterType: "LIST",
      key: "category",
    },
    subject_tags: {
      filterValList: [...subjectTagsFilterInfo],
      filterType: "LIST",
      key: "subject_tags",
    },
    publisher_type: {
      filterVal: publisherTypeFilterInfo
        ? publisherTypeFilterInfo.join(",")
        : "",
    },
    show_offline: {
      filterVal: advancedFilters?.show_offline?.value || false,
      key: "show_offline",
    },
    communities_served: {
      filterValList: [...communitiesServedFilterInfo],
      filterType: "LIST",
    },
    bg_segments: {
      filterValList: [...bgSegmentsFilterInfo],
      filterType: "LIST",
    },
    tv_network: {
      filterType: "text",
      filterVal: advancedFilters?.tv_network?.value || "",
    },
    opinion: {
      filterType: "text",
      filterVal: advancedFilters?.opinion?.value || "",
    },
    harm_risk: {
      filterVal: harmRiskFilterInfo ? harmRiskFilterInfo.join(",") : "",
    },
    myth_location: {
      filterVal: mythLocationFilterInfo ? mythLocationFilterInfo.join(",") : "",
    },
    myth_spread_location: {
      filterVal: mythSpreadLocationFilterInfo
        ? mythSpreadLocationFilterInfo.join(",")
        : "",
    },
    first_appearance_date: {
      filterVal: advancedFilters?.first_appearance_date?.value?.start,
      filterVal2: advancedFilters?.first_appearance_date?.value?.end,
    },
    manipulated_media: {
      filterVal: mythManuipulatedMediailterInfo
        ? mythManuipulatedMediailterInfo.join(",")
        : "",
    },
  };

  return Object.assign(filters, advancedFilterInfo);
};

export default getData;
