import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { ButtonRed, ButtonWhite } from "../FormElements/Button";
import ModalModule from "../Partners/common/ModalModules";
import {
  additionalCurrencySuffix,
  getLanguage,
  getLocale,
} from "../../common/_localeMessages";
import CancelSubscription from "../Subscription/CancelSubscription";
import {
  AccountAction,
  AccountDetails,
  AccountDetailsHeader,
  AccountSection,
  AccountSectionHeader,
  AccountSectionWrapper,
} from "./AccountStyles";
import AccountDeletion from "./AccountDelete";

const locale = getLocale();

const renewDateOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const getRenewDate = (date) => {
  return new Date(date).toLocaleDateString(locale, renewDateOptions);
};

const Membership = ({
  user,
  activation,
  subscriptions,
  validUntilDate,
  onCancel,
  isExpiring,
  elementRef,
  loading,
}) => {
  const [modalOpen, setModalOpen] = useState({
    cancel: false,
    delete: false,
  });

  const handleOpen = (e, type) => {
    e.preventDefault();
    setModalOpen((prevState) => ({ ...prevState, [type]: true }));
  };

  const handleClose = (type) => {
    setModalOpen((prevState) => ({ ...prevState, [type]: false }));
  };

  const options = {
    year: "numeric",
    month: "long",
  };

  const startedDate = new Date(user?.createdDate).toLocaleDateString(
    locale,
    options
  );

  return (
    <div ref={elementRef} id="membership">
      <AccountSectionHeader>
        {" "}
        <FormattedMessage
          id="template.membership"
          defaultMessage={"Membership"}
        />
      </AccountSectionHeader>
      <AccountSection>
        <AccountSectionWrapper>
          {/* <AccountDetailsHeader>
           {" "}
           <FormattedMessage
           id="template.memberSince"
           defaultMessage={"Member Since"}
           />
           </AccountDetailsHeader> */}
        </AccountSectionWrapper>
        <AccountDetails>
          {" "}
          <FormattedMessage
            id="template.membersince"
            defaultMessage={"Member Since"}
          />{" "}
          {startedDate}
        </AccountDetails>
        {user?.origin ? (
          <AccountDetails>
            <FormattedMessage
              id="template.created_with"
              values={{ origin: user?.origin }}
              defaultMessage="This account was created with {origin}"
            />
          </AccountDetails>
        ) : (
          <p>{""}</p>
        )}
      </AccountSection>

      {(subscriptions && subscriptions.length > 0) ||
      (activation && activation.validUntil) ? (
        <AccountSection>
          <AccountSectionWrapper>
            <AccountDetailsHeader>
              {" "}
              <FormattedMessage
                id="template.subscription"
                defaultMessage={"Subscription"}
              />
            </AccountDetailsHeader>
          </AccountSectionWrapper>

          {activation && activation.validUntil ? (
            <p className="account-notes account-notes--voucher">
              <FormattedMessage
                id="template.voucher.account.description"
                defaultMessage="You are using an Access Code from {institution} which will expire on {dateValue}"
                values={{
                  institution: activation.appSiteName,
                  dateValue: validUntilDate,
                }}
              />
            </p>
          ) : (
            // could use date.toLocale here
            <div>
              {subscriptions && subscriptions.length > 0
                ? subscriptions.map((subscription) => {
                    const price = subscription.invoice?.amountDue
                      ? subscription.invoice.amountDue / 100
                      : null;
                    const currency = subscription.invoice?.currency || "usd";
                    const numberFormatOptions = {
                      style: "currency",
                      currency: currency,
                    };

                    return (
                      <div key={`${subscription}`}>
                        {subscription.status === "paused" ? (
                          <p className="account-information__button account-information__button--pause">
                            Your subscription is temporarily suspended. Contact{" "}
                            <a href="mailto:support@newsguardtech.com">
                              support@newsguardtech.com
                            </a>{" "}
                            for more information.
                          </p>
                        ) : subscription.platform === "EDGE" ? (
                          <AccountDetails>
                            <FormattedMessage
                              id="template.currentInEdgeSubscription"
                              defaultMessage={
                                "Your free subscription is valid for Edge browsers only."
                              }
                            />
                          </AccountDetails>
                        ) : subscription.autoRenewal ? (
                          <>
                            {subscription.status === "trialing" && (
                              <AccountDetails>
                                <FormattedMessage
                                  id="template.currentInFreeTrial"
                                  defaultMessage={
                                    "You are currently in your free trial."
                                  }
                                />
                              </AccountDetails>
                            )}
                            <AccountDetails>
                              <FormattedMessage
                                id="template.subscriptionAutoRenew"
                                defaultMessage="Your subscription will auto-renew on {date}."
                                values={{
                                  date: getRenewDate(
                                    subscription.expirationDate
                                  ),
                                }}
                              />{" "}
                              {price ? (
                                <FormattedMessage
                                  id="template.yourCharge"
                                  defaultMessage="Your charge is {price}/month."
                                  values={{
                                    price: (
                                      <>
                                        <FormattedNumber
                                          {...numberFormatOptions}
                                          value={price}
                                          key={price}
                                        />
                                        {additionalCurrencySuffix(
                                          currency,
                                          getLanguage()
                                        )}
                                      </>
                                    ),
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </AccountDetails>

                            <ButtonWhite
                              onClick={(e) => handleOpen(e, "cancel")}
                            >
                              <FormattedMessage
                                id="template.cancel_subscription"
                                defaultMessage="Cancel Subscription"
                              />
                            </ButtonWhite>
                            <ModalModule
                              showDialog={modalOpen.cancel}
                              close={() => handleClose("cancel")}
                              account
                              onDismiss={() => handleClose("cancel")}
                            >
                              <CancelSubscription
                                subscriptions={subscriptions}
                                close={() => handleClose("cancel")}
                                onCancel={(e) => onCancel(e, subscription)}
                                isExpiring={isExpiring}
                                loading={loading}
                              />
                            </ModalModule>

                            <p className="account-notes">
                              <FormattedMessage
                                id="template.cancel_subscription_disclaimer"
                                values={{
                                  strong: (...chunks) => (
                                    <strong>{chunks}</strong>
                                  ),
                                }}
                                defaultMessage="<strong>Please note</strong>:
                                 Upon cancellation this subscription will only be available
                                 during the remainder of the existing monthly
                                 term. After your term has ended this NewsGuard account will no
                                 longer be available and all of your saved data will be permanently
                                 deleted."
                              />
                            </p>
                          </>
                        ) : (
                          <AccountDetails>
                            {/* need this here to keep cancel modal open when no auto renew (right after cancel) */}
                            {modalOpen.cancel && (
                              <ModalModule
                                showDialog={modalOpen.cancel}
                                close={() => handleClose("cancel")}
                                account
                                onDismiss={() => handleClose("cancel")}
                              >
                                <CancelSubscription
                                  subscriptions={subscriptions}
                                  close={() => handleClose("cancel")}
                                  onCancel={(e) => onCancel(e, subscription)}
                                  isExpiring={isExpiring}
                                  loading={loading}
                                />
                              </ModalModule>
                            )}
                            <FormattedMessage
                              id="template.cancel_subscription_expiring"
                              values={{
                                expiration_date: getRenewDate(
                                  subscription.expirationDate
                                ),
                              }}
                              defaultMessage="Your subscription is set to expire at the end of its term, {expiration_date}"
                            />
                          </AccountDetails>
                        )}
                      </div>
                    );
                  })
                : ""}
            </div>
          )}
        </AccountSection>
      ) : (
        ""
      )}
      <AccountAction>
        <AccountSection>
          <AccountSectionWrapper>
            <AccountDetailsHeader>
              {" "}
              <FormattedMessage
                id="template.delete_account"
                defaultMessage="Delete Account"
              />
            </AccountDetailsHeader>
          </AccountSectionWrapper>
          <ButtonWhite onClick={(e) => handleOpen(e, "delete")}>
            <FormattedMessage
              id="template.delete_account"
              defaultMessage="Delete Account"
            />
          </ButtonWhite>
          <ModalModule
            showDialog={modalOpen.delete}
            close={() => handleClose("delete")}
            account
            onDismiss={() => handleClose("delete")}
          >
            <AccountDeletion
              subscriptions={subscriptions}
              close={() => handleClose("delete")}
              loading={loading}
            />
          </ModalModule>
          <p>
            <FormattedMessage
              id="template.delete_disclaimer"
              values={{
                strong: (...chunks) => <strong>{chunks}</strong>,
              }}
              defaultMessage="<strong>Please note</strong>:
                                 Upon account deletion all of your account data will be permanently deleted."
            />
          </p>
        </AccountSection>
      </AccountAction>
    </div>
  );
};

export default Membership;
