import styled from "@emotion/styled/macro";
import { Tab, TabList } from "reach-tabs-react-18";

export const Section = styled.section`
  margin-bottom: 50px;
  padding-bottom: 30px;
  position: relative;

  border-bottom: 1px solid rgb(230, 230, 230);
`;

export const TextStyle = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const SectionDescription = styled(TextStyle)`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 20px;
  max-width: 600px;
`;

export const ToggleWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ChooserToggle = styled.div`
  cursor: pointer;

  padding: 0;

  & > div {
    color: var(--primary-blue);
    font-size: 0.875rem;
    margin-top: 2px;
  }
`;

export const SubcontentWrapper = styled.div`
  padding-left: 40px;
`;

const TitleStyle = styled.h1`
  font-size: 40px;
  line-height: 51px;
  margin-bottom: 50px;

  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 25px;
  }
`;

const SubTitleStyle = styled.h2`
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 25px;
  }
`;

/* from https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
const LabelStyle = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--primary-black);
  margin-bottom: 20px;
  margin-right: 40px;

  &:has(+ div) {
    margin-bottom: 10px;
  }

  .radio__label {
    line-height: 23px;
    transition: 180ms all ease-in-out;
    opacity: 0.8;
  }

  .radio__input {
    display: flex;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:focus + .radio__control {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em var(--primary-blue);
      }
    }
  }

  input:checked + .radio__control {
    background: radial-gradient(
      var(--primary-blue) 50%,
      rgba(255, 0, 0, 0) 51%
    );
  }

  .radio__control {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid currentColor;
    transform: translateY(-0.05em);
    margin: -1px 15px 0 0;
  }
`;

const FieldsetStyle = styled.fieldset`
  opacity: ${(props) => (props.disabled ? 0.4 : "inherit")};

  padding: 10px 0 0;
  outline: none;
  border: none;

  &:has(+ fieldset) {
    margin-bottom: 15px;
  }
`;

const SectionTitles = styled.h4`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 10px;

  &:has(+ label) {
    margin-bottom: 20px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 150px;
  margin-top: 70px;
`;

const Button = styled.button`
  display: block;
  height: 50px;
  color: ${(props) =>
    props.cancel ? "rgba(0,0,0,1)" : "rgba(255, 255, 255, 1)"};
  background-color: ${(props) =>
    props.cancel ? "rgba(0,0,0,0.06)" : "rgba(51, 85, 221, 1)"};
  font-family: Sora;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 10px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  /* margin: 0 auto 20px; */
  text-transform: uppercase;
  min-width: 150px;
`;

const ContentWrapper = styled.div`
  max-width: 620px;
  min-width: 375px;
  margin: 0 auto;
  padding: 90px 0;

  p {
    margin-bottom: 20px;
    &.error {
      color: #d53734;
    }
  }
`;

const TabStyles = styled(Tab)`
  border-bottom: 6px solid rgba(0, 0, 0, 0.07);
  padding: 0 0 10px 0;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 30px;
  cursor: pointer;
  white-space: nowrap;
  position: relative;

  &:not(:first-of-type) {
    margin-left: 40px;

    @media screen and (max-width: 600px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 400px) {
      margin-left: 10px;
    }
  }

  &[data-reach-tab][data-selected] {
    border-bottom: 6px solid #3355dd;
  }

  &::after {
    content: "";
    height: calc(100% + 6px);
    width: 40px;
    border-bottom: 6px solid rgba(0, 0, 0, 0.07);
    padding-bottom: 10px;
    position: absolute;
    @media screen and (max-width: 600px) {
      width: 20px;
    }
    @media screen and (max-width: 400px) {
      width: 10px;
    }
  }

  &:last-of-type::after {
    display: none;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const TabFiller = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 6px solid rgba(0, 0, 0, 0.07);
  padding-bottom: 10px;
`;

const TabWrapper = styled(TabList)`
  background-color: transparent;
`;

const Checkbox = styled.input`
  align-self: flex-start;
  margin-right: 14px;
  width: 30px;
  height: 30px;
`;

export {
  LabelStyle,
  Button,
  ContentWrapper,
  FieldsetStyle,
  TabStyles,
  TabWrapper,
  TabFiller,
  TitleStyle,
  SubTitleStyle,
  SectionTitles,
  ButtonsWrapper,
  Checkbox,
};
