import styled from "@emotion/styled";

const CheckboxStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (max-width: 600px) {
    width: 80%;
    gap: 0;
  }
`;

const CheckboxStyleBox = styled.div`
  margin-right: 40px;
  & :first-of-type {
    margin-left: 1px;
  }

  @media screen and (max-width: 600px) {
    width: 120px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  label {
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  /* from https://moderncss.dev/pure-css-custom-checkbox-style/ */
  .checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 16px;
    color: #000;
    /* &--disabled {
      color: var(--disabled);
    } */
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  .checkbox__control {
    display: inline-grid;
    height: 26px;
    width: 26px;
    border: 1px solid currentColor;
    border-radius: 2px;

    @media screen and (max-width: 600px) {
      height: 22px;
      width: 22px;
    }

    svg {
      /* transition: transform 0.01s ease-in 25ms;
      transform: scale(0);
      transform-origin: center; */
      display: none;
    }
  }

  .checkbox__input {
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 1em;
      height: 1em;

      &:focus + .checkbox__control {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
      }

      &:checked + .checkbox__control {
        /* box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor; */
        /* border-color: #3355dd; */
        border: none;
      }

      &:checked + .checkbox__control svg {
        /* transform: scale(1); */
        display: inline;
      }

      /* &:disabled + .checkbox__control {
        color: var(--disabled);
      } */
    }
  }
`;

const CheckboxRender = (props) => {
  return (
    <CheckboxStyleBox>
      <label className="checkbox">
        <span className="checkbox__input">
          <input
            type="checkbox"
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            value={props.value}
          />
          <span className="checkbox__control">
            <svg
              width="26px"
              height="26px"
              viewBox="0 0 26 26"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>ii/status selected</title>
              <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="ii/status-selected" fill="#3355DD">
                  <path
                    d="M24,0 C25.1045695,-2.02906125e-16 26,0.8954305 26,2 L26,24 C26,25.1045695 25.1045695,26 24,26 L2,26 C0.8954305,26 1.3527075e-16,25.1045695 0,24 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L24,0 Z M18,9 L11.9987302,15 L8,11 L6,13 L12,19 L20,11 L18,9 Z"
                    id="check"
                  ></path>
                </g>
              </g>
            </svg>
          </span>
        </span>
        <span className="radio__label">{props.label}</span>
      </label>
    </CheckboxStyleBox>
  );
};

export default function CheckboxFilter(props) {
  return (
    <CheckboxStyle>
      {props.options.map(({ key, label, value }, index) => (
        <CheckboxRender
          name={props.filter?.slug}
          label={label}
          key={key}
          onChange={props.onChange}
          value={value}
          checked={props.filter?.value.includes(value)}
        />
      ))}
    </CheckboxStyle>
  );
}
