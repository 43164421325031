export default function RightArrow(props) {
  return (
    <svg
      width={props.width || "60px"}
      height={props.height || "60px"}
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>ii/system/arrow right</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ii/system/arrow-right" fill="#000000">
          <path
            d="M30,17.4959415 L39.5459415,27.0418831 L37.4246212,29.1632034 L31.5030585,23.2419415 L31.5040585,42.4959415 L28.5040585,42.4959415 L28.5030585,23.2359415 L22.5753788,29.1632034 L20.4540585,27.0418831 L30,17.4959415 Z"
            id="Combined-Shape"
            transform="translate(30.000000, 29.995942) rotate(90.000000) translate(-30.000000, -29.995942) "
          ></path>
        </g>
      </g>
    </svg>
  );
}
