export const unauthorizedCopy = {
  MYTH: {
    header: "Get Access to NewsGuard's Misinformation Fingerprints™",
    body: "Your account license does not authorize you to access NewsGuard's Misinformation Fingerprints™ data about false narratives spreading online. To expand your organization's NewsGuard license to gain access to this content,",
    linkText: "contact us",
    linkUrl: "https://www.newsguardtech.com/feedback/give/",
    linkConnector: "or email",
    email: "partnerships@newsguardtech.com",
    emailUrl:
      "mailto:partnerships@newsguardtech.com?subject=Misinformation Fingerprints Licensing",
    learnMoreLink:
      "https://www.newsguardtech.com/solutions/misinformation-fingerprints/",
    listHeader: "NewsGuard's Misinformation Fingerprints™",
    bullets: [
      "Offer a constantly-updated library of the top misinformation and disinformation narratives spreading on social media and the open web, especially from state-sponsored sources",
      "Are conducted by a team of experienced journalists",
      "Contain multiple categories of data for each narrative, including a description of the falsehood, a description of the facts, search terms, subject tags, keywords, hashtags, variations, links to articles and social media posts advancing the falsehood, and links to reliable sources debunking the falsehood.",
      "Are available in human-readable format (via the NewsGuard dashboard) and machine-readable via a real-time data feed and API",
    ],
    summation:
      "Misinformation Fingerprints™ are used by defense agencies tracking state-sponsored disinformation campaigns, cybersecurity companies, platforms conducting content moderation, researchers, and other clients that need to understand and track mis- and disinformation narratives. ",
  },
  REPORT: {
    header: "Get Access to NewsGuard's Reports",
    body: "Your account license does not authorize you to access NewsGuard's Report data about false narratives spreading online. To expand your organization's NewsGuard license to gain access to this content,",
    linkText: "contact us",
    linkUrl: "https://www.newsguardtech.com/feedback/give/",
    linkConnector: "or email",
    email: "partnerships@newsguardtech.com",
    emailUrl: "mailto:partnerships@newsguardtech.com?subject=OnlineReports",
    learnMoreLink: "",
    listHeader: "NewsGuard's Reports",
    bullets: ["TBA"],
    summation: "Reports ....... ",
  },
  TV: {
    header: "Get Access to NewsGuard's TV Reliability Ratings",
    body: "Your account license does not authorize you to access NewsGuard's reliability ratings for television news programs and networks. To expand your organization's NewsGuard license to gain access to this content,",
    linkText: "contact us",
    linkUrl: "https://www.newsguardtech.com/feedback/give/",
    linkConnector: "or email",
    email: "partnerships@newsguardtech.com",
    emailUrl:
      "mailto:partnerships@newsguardtech.com?subject=TV Reliability Ratings Licensing",
    learnMoreLink: "https://www.newsguardtech.com/solutions/newsguard/",
    listHeader: "NewsGuard's TV Reliability Ratings",
    bullets: [
      "Cover the top news and information networks and programs on television and CTV",
      "Are conducted by a team of experienced journalists",
      "Score each program or network on a set of journalistic credibility criteria",
      "Provide a rating level, numerical score, and metadata fields describing each program or network",
      "Include a detailed written report describing each news source",
    ],
    summation:
      "TV Reliability Ratings are used by brands, agencies, and advertising platforms to direct advertisements to credible, brand-suitable news content and avoid risky or unreliable news content. The ratings can be used for planning for direct and linear ad buys, for contextual CTV and OTT ad targeting, and more.",
  },
  PODCAST: {
    header: "Get Access to NewsGuard's Podcast Reliability Ratings",
    body: "Your account license does not authorize you to access NewsGuard's reliability ratings for news and information podcasts. To expand your organization's NewsGuard license to gain access to this content,",
    linkText: "contact us",
    linkUrl: "https://www.newsguardtech.com/feedback/give/",
    linkConnector: "or email",
    email: "partnerships@newsguardtech.com",
    emailUrl:
      "mailto:partnerships@newsguardtech.com?subject=Podcast Reliability Ratings Licensing",
    learnMoreLink: "https://www.newsguardtech.com/solutions/newsguard/",
    listHeader: "NewsGuard's Podcast Reliability Ratings",
    bullets: [
      "Cover the top news and information podcasts across major podcasting platforms",
      "Are conducted by a team of experienced journalists",
      "Score each podcast or podcast network based on a set of journalistic credibility criteria",
      "Provide a rating level, numerical score, and metadata fields describing each podcast or podcast network",
      "Include a detailed written report describing each podcast or podcast network",
    ],
    summation:
      "Podcast reliability ratings are used by brands, agencies, and podcasting platforms to direct advertisements to credible, brand-suitable news content and avoid risky or unreliable news content. The ratings can be used for planning for direct  ad buys, for contextual programmatic ad targeting, and more.",
  },
  WEB: {
    header: "Get Access to NewsGuard's Reliability Ratings",
    body: "Your account license does not authorize you to access NewsGuard's reliability ratings for news and information websites. To expand your organization's NewsGuard license to gain access to this content,",
    linkText: "contact us",
    linkUrl: "https://www.newsguardtech.com/feedback/give/",
    linkConnector: "or email",
    email: "partnerships@newsguardtech.com",
    emailUrl:
      "mailto:partnerships@newsguardtech.com?subject=Reliability Ratings Licensing",
    learnMoreLink: "https://www.newsguardtech.com/solutions/newsguard/",
    listHeader: "NewsGuard's Reliability Ratings",
    bullets: [
      "Are conducted by a team of experienced journalists",
      "Score each site on nine journalistic criteria related to credibility and transparency",
      "Provide a rating level, 0-100 score, and 40+ metadata fields describing each site",
      "Identify a site’s ownership and financing sources",
      "Include a detailed written report describing each news source",
      "Cover all of the news and information sources accounting for 95%+ of online engagement with news",
      "Are available in human-readable format (via the NewsGuard dashboard) and machine-readable format via a real-time data feed and API",
    ],
    summation:
      "Reliability Ratings are used by platforms for content moderation or policy enforcement, by advertising companies to target ads to trustworthy sources while avoiding ad placements on misinformation, and by other clients to inform and equip internal teams or end users with basic news literacy information to distinguish credible sources from unreliable ones.",
  },
  DEFAULT: {
    header: "Get Access to NewsGuard's Reliability Ratings",
    body: "Your account license does not authorize you to access NewsGuard's data on this page. To learn more or expand your organization's NewsGuard license to gain access to this content,",
    linkText: "contact us",
    linkUrl: "https://www.newsguardtech.com/feedback/give/",
    linkConnector: "or email",
    email: "partnerships@newsguardtech.com",
    emailUrl: "mailto:partnerships@newsguardtech.com",
    learnMoreLink: "https://www.newsguardtech.com/solutions/newsguard/",
    listHeader: null,
    bullets: null,
    summation: null,
  },
};
