import LabelSearchResult from "./LabelSearchResult";

const PublisherSearchResult = ({
  labels,
  index,
  hasMythAccess,
  associatedMyths,
  siteOnline,
  associatedDomains,
}) => {
  const localeLabels = labels.map((label) => {
    return {
      id: label.id,
      country: label.country,
      language: label.language,
      locale: label.locale,
      profileId: label.profileId,
    };
  });

  return (
    <LabelSearchResult
      label={labels[0]}
      index={index}
      hasMythAccess={hasMythAccess}
      associatedMyths={associatedMyths}
      localeLabels={localeLabels}
      siteOnline={siteOnline}
      associatedDomains={associatedDomains}
    />
  );
};

export default PublisherSearchResult;
