import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import AuthService from "../service/AuthService";
import LoadingIndicator from "../common/LoadingIndicator";
import InputModule from "./FormElements/InputModule";
import { Button } from "./FormElements/Button";
import styled from "@emotion/styled/macro";
import { Fieldset, Form, Title } from "./Subscription/SignupStyles";
import ErrorMessage from "./FormElements/ErrorMessage";

const Container = styled.div`
  width: 360px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  @media screen and (max-width: 600px) {
    width: auto;
  }
`;

const Text = styled.p`
  margin-bottom: 30px;
`;

const ForgotPassword = () => {
  const authenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  const principal = useSelector((state) => state.authentication.principal);
  const authService = AuthService.getInstance();
  const intl = useIntl();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resetComplete, setResetComplete] = useState(false);
  const [html5error, setHtml5error] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (authenticated) {
      setEmail(principal.email);
    }
  }, [authenticated, principal]);

  const handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    target.setCustomValidity("");
    setHtml5error((prevState) => ({
      ...prevState,
      [inputName]: undefined,
    }));
    setEmail(inputValue);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    setError(null);
    setHtml5error({});

    event.preventDefault();

    if (validate(event)) {
      authService
        .resetPassword(email)
        .then((response) => {
          authService.saveEmail(email);

          setResetComplete(true);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        })
        .finally((res) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const checkIsValid = (e) => {
    if (e.target.checkValidity() === false) {
      e.target.parentNode.classList.add("was-validated");
    }
  };

  const validate = (event) => {
    setValidated(true);
    return event.target.checkValidity();
  };

  return (
    <Container>
      {resetComplete ? (
        <>
          <Title>
            <FormattedMessage
              id="template.thankYou"
              DefaultMessage="Thank you"
            />
          </Title>
          <Text>
            <FormattedMessage
              id="template.forgot_password_message"
              defaultMessage="If there's a NewsGuard account associated with this email address, we'll email you password reset instructions. \n\nIf you don't receive the email, please check your email's spam folder or <a>try again</a>. Make sure you enter the email address associated with your NewsGuard account."
              values={{
                a: (...chunks) => (
                  <Link
                    to="/forgotPassword"
                    onClick={() => setResetComplete(false)}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Text>
        </>
      ) : (
        <>
          <Title>
            <FormattedMessage
              id="template.forgot_password"
              DefaultMessage="Forgot Password?"
            />
          </Title>

          <Form onSubmit={handleSubmit} noValidate>
            <Text>
              <FormattedMessage
                id="template.forgot_password_instruction"
                defaultMessage="Submit the email associated with your NewsGuard account below and we’ll send you instructions on how to reset your password."
              />
            </Text>

            <Fieldset disabled={loading} aria-busy={loading}>
              <InputModule
                type="email"
                identifier="email"
                autoComplete="email"
                placeholder="Email"
                value={email}
                onChange={handleInputChange}
                onBlur={checkIsValid}
                onInvalid={() => {
                  let email = {
                    id: "template.email_invalid_message",
                    defaultMessage: "Please enter a correct email address",
                    name: "email",
                    html: true,
                  };
                  setHtml5error((prevState) => ({
                    ...prevState,
                    email,
                  }));
                }}
                required
                error={html5error?.email}
              />

              {error && <ErrorMessage error={error} />}

              <Button type="submit">
                {loading ? (
                  <LoadingIndicator button />
                ) : (
                  <FormattedMessage
                    id="template.submit"
                    defaultMessage="Submit"
                  />
                )}
              </Button>
            </Fieldset>
          </Form>
        </>
      )}
      <Text>
        {AuthService.getInstance().isRegisteredUser(principal) ? (
          <FormattedMessage
            id="template.view_your_account"
            values={{
              account: (...chunks) => <Link to="/account">{chunks}</Link>,
            }}
            defaultMessage="View your <account>Account Information</account>"
          />
        ) : (
          <Link to="/signin">
            <FormattedMessage
              id="template.back_to_login"
              defaultMessage="Back to Login"
            />
          </Link>
        )}
      </Text>
    </Container>
  );
};

export default ForgotPassword;
