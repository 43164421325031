import styled from "@emotion/styled/macro";
import SelectedFilterTags from "./Filters/AdvancedSearchFilters/SelectedFilterTags";

const SearchContextWrapper = styled.div`
  max-width: 1160px;
  background-color: #fff;
  margin-bottom: ${(props) => (props.isWeb ? "5px" : 0)};
  /* margin-top: -40px; //to align with the filters button */

  @media only screen and (max-width: 600px) {
    width: calc(100vw - 40px); //20px padding each side
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    height: auto;
  }
`;

const SearchContextInformationStyles = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`;

const ResultsInfoStyles = styled.p`
  font-size: 18px;
  line-height: 22px;
  margin-top: 9px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-left: 2px;
    margin-bottom: 12px;
  }

  span {
    font-weight: 500;
  }
`;
export default function SearchContext({
  filters,
  searchContext,
  clear,
  handleClick,
}) {
  return (
    <>
      <SearchContextInformationStyles>
        <SelectedFilterTags
          filters={filters}
          removeFilter={clear}
          onClick={handleClick}
          searchContext={searchContext}
        />
      </SearchContextInformationStyles>
    </>
  );
}
