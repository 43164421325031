import styled from "@emotion/styled";

const RadioStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media screen and (max-width: 600px) {
    width: 80%;
    gap: 0;
  }
`;

const RadioStyleBox = styled.div`
  margin-right: 40px;
  & :first-of-type {
    margin-left: 1px;
  }

  @media screen and (max-width: 600px) {
    width: 120px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  label {
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .radio__label {
    line-height: 1;
    transition: 180ms all ease-in-out;
    opacity: 0.8;
  }

  .radio__input {
    display: flex;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:focus + .radio__control {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em var(--primary-blue);
      }
    }
  }

  input:checked + .radio__control {
    background: radial-gradient(
      var(--primary-blue) 50%,
      rgba(255, 0, 0, 0) 51%
    );
  }

  .radio__control {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid currentColor;
    transform: translateY(-0.05em);
    margin: -1px 15px 0 0;

    @media screen and (max-width: 600px) {
      margin-right: 5px;
    }
  }
`;

const RadioRender = (props) => {
  return (
    <RadioStyleBox>
      <label className="radio radio-gradient">
        <span className="radio__input">
          <input
            type="radio"
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            value={props.value}
          />
          <span className="radio__control"></span>
        </span>
        <span className="radio__label">{props.label}</span>
      </label>
    </RadioStyleBox>
  );
};

export default function RadioButtonFilter(props) {
  return (
    <RadioStyle>
      {props.options.map(({ key, label, value }, index) => (
        <RadioRender
          name={props.filter?.slug}
          label={label}
          key={key}
          onChange={props.onChange}
          value={value}
          checked={props.filter?.value === value}
        />
      ))}
    </RadioStyle>
  );
}
