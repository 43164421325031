import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import {
  additionalCurrencySuffix,
  getLanguage,
} from "../common/_localeMessages";
import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Button } from "./Partners/Settings/SettingsStyles";
import { SalesTaxDisclaimer } from "./Partners/Helpers/SalesTaxDisclaimer";

const Header2 = styled.h2`
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
`;

const MainContentHeader = styled(Header2)`
  font-weight: 600;
  margin-bottom: 60px;
`;

const MainContent = styled.section`
  border-bottom: 5px solid rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 40px;
  margin-bottom: 80px;
`;

const MainCTAButton = styled(Button)`
  text-transform: none;
  font-weight: 600;
  line-height: 21px;
  margin-top: 40px;
  margin-bottom: 50px;
  font-size: var(--16px);
  padding: 0 30px;
`;

const DisclaimerText = styled.p`
  font-size: var(--14px);
  line-height: 20px;
  margin-bottom: 10px;
  align-self: flex-start;
`;

const UnorderedList = styled.ul`
  column-count: 2;
  column-gap: 50px;
  padding: 0;
  margin-bottom: 15px !important;
  list-style: none;
`;

const ListItem = styled.li`
  color: #000;
  font-size: 16px;
  line-height: 30px;
  font-family: Sora, sans-serif;
  margin-bottom: 30px;

  padding-left: 25px;
  position: relative;

  &::before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #35d;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 9px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const PriceTermPitch = ({
  plan,
  regularPrice,
  price,
  currency,
  isLoading,
  numberFormatOptions,
  isCampaignPlan,
}) => {
  //Free 2-weeks unlimited access to NewsGuard's Ratings on your desktop and mobile devices
  return (
    <MainContent>
      <MainContentHeader>
        {" "}
        {isCampaignPlan ? (
          <FormattedMessage
            id="pitch.main.headline.A1"
            defaultMessage="Unlimited access to NewsGuard on your desktop"
          />
        ) : plan.trial ? (
          <FormattedMessage
            id="pitch.main.headline.trial"
            defaultMessage="Free 2-weeks unlimited access to NewsGuard's Ratings on your desktop and mobile devices"
          />
        ) : (
          <FormattedMessage
            id="pitch.main.headline"
            defaultMessage="Unlimited access to NewsGuard on your desktop and mobile devices"
          />
        )}
      </MainContentHeader>
      <UnorderedList>
        <ListItem>
          <FormattedMessage
            id="dialog.offer_bullet_1"
            defaultMessage="Detailed Nutrition Labels for thousands of news and information sites"
          />
        </ListItem>
        <ListItem>
          <FormattedMessage
            id="dialog.offer_bullet_3"
            defaultMessage="Warnings on websites publishing hoaxes, conspiracies, false medical advice, propaganda, and other misinformation"
          />
        </ListItem>
        <ListItem>
          <FormattedMessage
            id="dialog.offer_bullet_2"
            defaultMessage="Blue rating icons next to links on search and social media platforms"
          />
        </ListItem>

        <ListItem>
          <FormattedMessage
            id="dialog.offer_bullet_4"
            defaultMessage="A 0-100 trust score for each site for easy comparison"
          />
        </ListItem>
        {isCampaignPlan ? (
          ""
        ) : (
          <ListItem>
            <FormattedMessage
              id="dialog.offer_bullet_5"
              defaultMessage="Access to NewsGuard’s mobile apps for iOS and Android"
            />
          </ListItem>
        )}
      </UnorderedList>
      <Link to="/signup">
        <MainCTAButton>
          {plan.trial ? (
            <FormattedMessage
              id="dialog.offer_button_text"
              defaultMessage="Start Free Trial"
            />
          ) : (
            <FormattedMessage
              id="template.startSubscription"
              defaultMessage="Start Subscription"
            />
          )}
        </MainCTAButton>
      </Link>
      <DisclaimerText>
        {isCampaignPlan ? (
          <FormattedMessage
            id="pitch.start.blub.A1"
            defaultMessage="Start your membership today for just €1.10 (€0.92 plus €0.18 VAT)"
          />
        ) : plan.trial ? (
          <FormattedMessage
            id="template.afterTwoWeeks"
            defaultMessage="After two weeks, memberships are just {price} per month."
            values={{
              price: (
                <>
                  <FormattedNumber
                    {...numberFormatOptions}
                    value={price}
                    key={price}
                    currency={currency}
                  />
                  {additionalCurrencySuffix("currency", getLanguage())}
                </>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="template.startYourMembershipForJust"
            defaultMessage="Start your membership today for just "
            values={{
              price: (
                <>
                  <FormattedNumber
                    {...numberFormatOptions}
                    value={price}
                    key={price}
                    currency={currency}
                  />
                  {additionalCurrencySuffix("currency", getLanguage())}
                </>
              ),
            }}
          />
        )}

        {isCampaignPlan ? "" : "*"}
      </DisclaimerText>
      {isCampaignPlan ? "" : <SalesTaxDisclaimer />}
    </MainContent>
  );
};

export default PriceTermPitch;
