import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import useScrollOnParams from "./useScrollOnParams";
import SettingsToggle from "../common/SettingsToggle";
import RadioButton from "../common/ui/RadioButton";

import {
  FieldsetStyle,
  Section,
  SectionDescription,
  SectionTitles,
} from "./SettingsStyles";
import { AccountDetailsHeader } from "../../Account/AccountStyles";

const DevelopingNarrativeSettings = ({
  developingNarrativeAlertOn,
  developingNarrativeFrequency,
  handleChange,
  isLoading,
  handleVisible,
  handleToggle,
}) => {
  const initialTrigger = developingNarrativeAlertOn;
  const [localAlertOn, setLocalAlertOn] = useState(developingNarrativeAlertOn);
  const [localFrequency, setLocalFrequency] = useState(
    developingNarrativeFrequency
  );

  const [formRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "-90px 0px 0px 0px",
  });

  const divRef = useRef(null);
  useScrollOnParams(divRef, "type", "developingNarrativeSettings", 180);

  useEffect(() => {
    if (entry) {
      handleVisible(entry);
    }
  }, [entry, formRef, handleVisible]);

  function handleRadioChange(e) {
    setLocalFrequency(e.target.value);
    handleChange(formRef.current);
  }

  function toggleSettings() {
    handleToggle("developingNarrativeSettings", !localAlertOn, formRef.current);
    setLocalAlertOn((localAlert) => !localAlert);
  }

  return (
    <div ref={divRef}>
      <form name="developingNarrativeSettings" ref={formRef}>
        <input
          hidden={true}
          name="alertOn"
          value={localAlertOn}
          onChange={handleChange} // so that react doesn't complain about uncontrolled inputs
        />

        <SettingsToggle
          callback={toggleSettings}
          initialValue={initialTrigger}
          isLoading={isLoading}
        >
          <Section>
            <AccountDetailsHeader>
              Developing Narratives Email Alerts
            </AccountDetailsHeader>

            <SectionDescription>
              Use the settings below to receive alerts when new developing
              narratives are added to the dashboard.
            </SectionDescription>
            <SettingsToggle.Controls>
              <FieldsetStyle>
                <SectionTitles>
                  Developing Narratives Notification Frequency
                </SectionTitles>
                <RadioButton
                  checked={localFrequency === "WEEKLY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="WEEKLY"
                  label="Notify me with a Weekly Email Digest"
                />
                <RadioButton
                  checked={localFrequency === "DAILY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="DAILY"
                  label="Notify me with a Daily Email Digest"
                />
              </FieldsetStyle>
            </SettingsToggle.Controls>
          </Section>
        </SettingsToggle>
      </form>
    </div>
  );
};

export default DevelopingNarrativeSettings;
