import MultiSelectFilter from "../ui/MultiSelectFilter";
import locales from "../../../../../common/locales/locales.json";

export default function LocaleFilter(props) {
  let allLocaleAuth = props.principal.authorities.find(
    (authority) => authority.authority === `LOCALE_ALL`
  );

  const localeOptions =
    (allLocaleAuth && locales) ||
    locales.filter((locale) => {
      if (props.exclude && props.exclude.includes(locale.value)) {
        return false;
      }

      let foundLocale = props.principal.authorities.find(
        (authority) => authority.authority === `LOCALE_${locale.value}`
      );
      return !!foundLocale;
    });

  return <MultiSelectFilter options={localeOptions} {...props} />;
}
