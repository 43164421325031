import Moment from "react-moment";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/de";
import { FormattedMessage, FormattedNumber } from "react-intl";
import styled from "@emotion/styled/macro";
import {
  additionalCurrencySuffix,
  getLanguage,
  getLocale,
} from "../../common/_localeMessages";

const Heading = styled.h3`
  font-size: var(--12px);
  line-height: var(--15px);
  font-weight: 600;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-column-gap: 37px;
  grid-row-gap: 30px;
  align-items: baseline;
  margin-bottom: 40px;
`;

const AccountInformation = (props) => {
  const { subscription } = props;
  const { currency } = subscription.plan;
  const numberFormatOptions = {
    style: "currency",
    currency: subscription.plan.currency,
  };
  let subtotal = 0;
  let tax = 0;
  let total = 0;

  if (subscription.invoice) {
    subtotal = subscription.invoice.subtotal
      ? subscription.invoice.subtotal
      : 0;

    if (subscription.plan && subscription.coupon) {
      subtotal = subtotal - subscription.coupon.amountOff;
    }

    subtotal = subtotal / 100;

    tax = subscription.invoice.tax ? subscription.invoice.tax / 100 : 0;
    total = subscription.invoice.total ? subscription.invoice.total / 100 : 0;
  } else {
    if (subscription.plan && subscription.coupon) {
      total = (subscription.plan.amount - subscription.coupon.amountOff) / 100;
    } else {
      total = subscription.plan.amount / 100;
    }
  }

  return (
    <Wrapper>
      <Heading>
        <FormattedMessage id="template.name" defaultMessage="Name" />
      </Heading>{" "}
      <p>
        {subscription.accountUser.firstName} {subscription.accountUser.lastName}
      </p>
      <Heading>
        <FormattedMessage id="templateemail" defaultMessage="Email" />
      </Heading>{" "}
      <p>{subscription.accountUser.email}</p>
      {subscription.status === "trialing" ? (
        <>
          <Heading>
            <FormattedMessage
              id="template.trial_start_date"
              defaultMessage="Trial Start Date"
            />
          </Heading>{" "}
          <Moment format="MMM DD, YYYY" locale={getLocale()}>
            {subscription.startDate}
          </Moment>
          <Heading>
            <FormattedMessage
              id="template.trial_end_date"
              defaultMessage="Trial End Date"
            />
          </Heading>{" "}
          <Moment format="MMM DD, YYYY" locale={getLocale()}>
            {subscription.expirationDate}
          </Moment>
          <Heading>
            <FormattedMessage
              id="template.first_billing_date"
              defaultMessage="First Billing Date"
            />
          </Heading>{" "}
          <Moment format="MMM DD, YYYY" locale={getLocale()}>
            {subscription.purchaseDate}
          </Moment>
        </>
      ) : (
        <>
          <Heading>
            <FormattedMessage
              id="template.first_billing_date"
              defaultMessage="First Billing Date"
            />
          </Heading>{" "}
          <Moment format="MMM DD, YYYY">{subscription.purchaseDate}</Moment>
        </>
      )}
      {total && total > 0 ? (
        <>
          <Heading>
            <FormattedMessage id="template.rate" defaultMessage="Rate" />
          </Heading>
          <FormattedNumber {...numberFormatOptions} value={subtotal} />
          {additionalCurrencySuffix(currency, getLanguage())}

          <Heading>
            <FormattedMessage id="template.tax" defaultMessage="Tax" />
          </Heading>
          <FormattedNumber {...numberFormatOptions} value={tax} />
          {additionalCurrencySuffix(currency, getLanguage())}
          <Heading>
            <FormattedMessage id="template.total" defaultMessage="Total" />
          </Heading>
          <FormattedNumber {...numberFormatOptions} value={total} />
          {additionalCurrencySuffix(currency, getLanguage())}
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

export default AccountInformation;
