import styled from "@emotion/styled/macro";

const SearchModule = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => (props.large ? "1440px" : "1160px")};
  background-color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: ${(props) => (props.isHome ? "relative" : "initial")};

  nav {
    margin-left: 20px;
    margin-top: 3px;
    flex-shrink: 0;
    @media screen and (max-width: 600px) {
      margin-left: 12px;
    }
  }

  .logo {
    height: 37px;
    width: 30px;
    @media screen and (max-width: 600px) {
      margin-top: 2px;
      width: 21px;
    }
  }

  form {
    display: flex;
    align-items: center;
  }

  .search-field {
    border-radius: 4px 0 0 4px;
    display: block;
    width: 100%;
    height: 70px;
    background: ${(props) =>
      props.header
        ? "#fff"
        : "#fff url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='88px' height='88px' viewBox='0 0 88 88' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eii/branded/shield%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/branded/shield' fill='%23000000'%3E%3Cpath d='M44,88 L41.7511886,86.87254 C11,71.4309867 11,56.1812178 11,33.0915335 L11,4.26325641e-14 L77,4.26325641e-14 L77,33.0915335 C77,56.1812178 77,71.4484216 46.2430005,86.87254 L44,88 Z M21.0179609,10.0192841 L21.0179609,33.0973451 C21.0179609,54.501651 21.0179609,64.6255448 44,76.7602694 C67.0052826,64.6255448 66.9994717,54.501651 66.9820391,33.1264034 L66.9820391,10.0192841 L21.0179609,10.0192841 Z' id='shield'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E\") no-repeat 20px 21px"};
    background-size: 30px;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    padding-left: ${(props) => (props.header ? "24px" : "74px")};
    border: none;
    position: relative;
    /* flashing cursor shows focus */
    :focus:not(:focus-visible) {
      outline: none;
    }

    /* Focusing the button with a keyboard will show a dashed black line. */
    :focus-visible {
      outline: none;
      border: none;
      border-bottom: none;
    }

    @media screen and (max-width: 600px) {
      background-position: 10px 15px;
      background-size: ${(props) => (props.header ? "50px" : "20px")};
      padding-left: ${(props) => (props.header ? "10px" : "36px")};
      padding-top: ${(props) => (props.header ? "3px" : "3px")};
      font-size: 18px;
      height: 50px;
    }
  }

  /* hack to access react-select indicatorContainer */
  .select__dropdown-indicator {
    padding: 0;
  }

  .divider {
    width: 1px;
    height: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    flex-shrink: 0;
    @media screen and (max-width: 600px) {
      height: 24px;
      margin-left: 10px;
    }
  }

  select {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.08);
    border: none;
    height: 40px;
    width: 85px;
    padding: 0 5px;
  }

  .clearOut {
    background: #fff
      url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eii/system/close%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/close' fill='%23000000'%3E%3Cpath d='M31.5,13 L31.5,28.499 L47,28.5 L47,31.5 L31.5,31.5 L31.5,47 L28.5,47 L28.5,31.5 L13,31.5 L13,28.5 L28.5,28.5 L28.5,13 L31.5,13 Z' id='close' transform='translate(30.000000, 30.000000) rotate(45.000000) translate(-30.000000, -30.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      no-repeat 50% 50%;
    width: 22px;
    background-size: 40px;
    cursor: pointer;
    height: 70px;
    margin: 0 21px;
    z-index: 11;

    @media screen and (max-width: 600px) {
      height: 43px;
      background-size: 30px;
      background-position: 50% 50%;
      width: 17px;
      margin: 0 10px 0 10px;
      flex-shrink: 0;
    }
  }

  .select-wrapper {
    height: 36px;
    @media screen and (max-width: 600px) {
      /* height: 26px; */
      margin-right: ${(props) => (props.header ? 0 : "11px")};
    }
    .hide {
      display: none;
    }
  }
  @media print {
    display: none;
  }
`;

const SearchModuleContainer = styled.div`
  position: ${(props) => (props.header ? "fixed" : "relative")};

  text-align: center;
  margin: 0 auto;
  width: 100%;
  min-width: 560px;
  z-index: 11;
  padding: ${(props) => (props.header || !props.first ? "0 20px" : 0)};
  margin-top: ${(props) => (props.header ? 0 : "30px")};

  @media screen and (max-width: 600px) {
    width: ${(props) =>
      props.header
        ? "calc(100vw - 40px)"
        : props.first
        ? "100%"
        : "calc(100% - 30px)"};
    min-width: 275px;
    top: 0;
    left: ${(p) => (p.header ? "20px" : 0)};
    z-index: 12;
    padding: 0;
  }
`;

const SearchButtonStyle = styled.input`
  height: 36px;
  width: 36px;
  border-color: transparent;
  border-radius: 2px;
  /* background-color: #3355dd; */
  color: #fff;
  font-family: Sora;
  font-size: 16px;
  /* line-height: 40; */
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: ${(props) => (props.home ? "0" : "16px")};
  margin-right: 15px;
  z-index: 13;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const dropDownStyles = {
  control: (styles, state) => ({
    display: "flex",
    position: "relative",
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
    height: "40px",
    boxShadow: "none",
    textTransform: "uppercase",
  }),
  menu: (styles, state) => ({
    ...styles,
    boxShadow: "0 10px 30px 0 rgba(0,0,0,0.1)",
    padding: "0 5px 5px",
    textAlign: "left",
    zIndex: 13,
    width: 180,
    textTransform: "uppercase",
    "@media only screen and (max-width: 600px)": {
      width: "121px",
    },
  }),
  menuList: (styles, state) => ({
    ...styles,
    fontSize: 14,
    padding: "5px",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? "rgba(0,0,0,0.06)" : "#fff",
    fontWeight: state.isSelected ? 500 : 300,
    color: "#000",
    padding: "5px 10px",
    cursor: "pointer",
    "@media screen and (max-width: 600px)": {
      whiteSpace: "normal",
      fontSize: "11px",
      lineHeight: "14px",
      textAlign: "left",
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: "absolute",
    right: "1px",
    top: "1px",

    "@media only screen and (max-width: 600px)": {
      top: "0px",
    },
  }),
  singleValue: (styles) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: "var(--primary-black)",
    whiteSpace: "nowrap",
    "@media screen and (max-width: 600px)": {
      whiteSpace: "normal",
      fontSize: "11px",
      lineHeight: "14px",
      textAlign: "left",
    },
  }),

  valueContainer: () => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0, 0.08)",
    borderRadius: "2px",
    height: "36px",
    padding: "0px 27px 0 14px",
    cursor: "pointer",
    "@media screen and (max-width: 600px)": {
      height: "26px",
      fontSize: "14px",
      padding: "2px 22px 2px 9px",
      position: "relative",
      top: "5px",
    },
  }),
};

const DropdownIndicatorStyles = styled.div`
  background: transparent
    url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='60px' height='60px' viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eii/system/arrow up small%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/arrow-up-small' fill='%23000000'%3E%3Cpolygon id='Rectangle' transform='translate(29.995942, 26.750000) rotate(45.000000) translate(-29.995942, -26.750000) ' points='36.7459415 20 36.7459415 33.5 23.2459415 33.5 23.2459415 30.5 33.7459415 30.4993593 33.7459415 20'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E") -3px
    2px no-repeat;
  background-size: 31px;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  height: 35px;
  width: 25px;
`;

const TopOverflowCover = styled.div`
  height: 20px;
  background: white;
  opacity: 0.9;
  @media screen and (max-width: 600px) {
    height: 10px;
    width: 102%;
  }
  @media print {
    display: none;
  }
`;

const FadeOut = styled.div`
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff);
  width: 100px;
  height: 70px;

  position: absolute;
  right: -10px;
  top: 0;

  @media screen and (max-width: 600px) {
    height: 50px;
    /* right: 123px; */
  }
`;

const SearchFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export {
  DropdownIndicatorStyles,
  dropDownStyles,
  SearchButtonStyle,
  SearchModule,
  SearchModuleContainer,
  TopOverflowCover,
  FadeOut,
  SearchFieldWrapper,
};
