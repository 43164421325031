const Step2 = ({ active }) =>
  active ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <defs>
        <mask
          id="a"
          x="15"
          y="15"
          width="20"
          height="20"
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M32.5 17.5A2.5 2.5 0 0 1 35 20v10a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 15 30V20a2.5 2.5 0 0 1 2.5-2.5Zm.83 6.67H16.67V30a.83.83 0 0 0 .83.83h15a.83.83 0 0 0 .83-.83Zm-.83-5h-15a.83.83 0 0 0-.83.83v2.5h16.66V20a.83.83 0 0 0-.83-.83Z"
            style={{ fill: "#fff", fillRule: "evenodd" }}
          />
        </mask>
      </defs>

      <g data-name="mod/-steps">
        <circle cx="25" cy="25" r="25" style={{ fill: "#35d" }} />
        <path
          data-name="path-3"
          d="M32.5 17.5A2.5 2.5 0 0 1 35 20v10a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 15 30V20a2.5 2.5 0 0 1 2.5-2.5Zm.83 6.67H16.67V30a.83.83 0 0 0 .83.83h15a.83.83 0 0 0 .83-.83Zm-.83-5h-15a.83.83 0 0 0-.83.83v2.5h16.66V20a.83.83 0 0 0-.83-.83Z"
          style={{ fill: "#fff", fillRule: "evenodd" }}
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <defs>
        <mask
          id="a"
          x="15"
          y="15"
          width="20"
          height="20"
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M32.5 17.5A2.5 2.5 0 0 1 35 20v10a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 15 30V20a2.5 2.5 0 0 1 2.5-2.5Zm.83 6.67H16.67V30a.83.83 0 0 0 .83.83h15a.83.83 0 0 0 .83-.83Zm-.83-5h-15a.83.83 0 0 0-.83.83v2.5h16.66V20a.83.83 0 0 0-.83-.83Z"
            style={{ fill: "#fff", fillRule: "evenodd" }}
          />
        </mask>
      </defs>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="insights/-register-account-filled-[new]-password">
            <g data-name="mod/signin/drawer-copy">
              <g data-name="mod/-steps">
                <circle
                  cx="25"
                  cy="25"
                  r="25"
                  style={{ isolation: "isolate", opacity: 0.08 }}
                />
                <path
                  data-name="path-3"
                  d="M32.5 17.5A2.5 2.5 0 0 1 35 20v10a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 15 30V20a2.5 2.5 0 0 1 2.5-2.5Zm.83 6.67H16.67V30a.83.83 0 0 0 .83.83h15a.83.83 0 0 0 .83-.83Zm-.83-5h-15a.83.83 0 0 0-.83.83v2.5h16.66V20a.83.83 0 0 0-.83-.83Z"
                  style={{ fillRule: "evenodd" }}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

export default Step2;
