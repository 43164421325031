import styled from "@emotion/styled/macro";
import { useSortBy, useTable } from "react-table";
import HistoryColumns from "../History/HistoryTableColumns";
import RecentUpdateColumns from "./RecentUpdatesTableColumns";

const TableWrapper = styled.div`
  max-height: ${({ context }) => (context === "history" ? "auto" : "500px")};
  min-height: ${({ context }) => (context === "history" ? "auto" : "462px")};
  overflow-x: ${({ context }) => (context === "history" ? "none" : "auto")};
  @media screen and (max-width: 600px) {
    width: 100%;
    overflow: scroll;
  }
  position: relative;
  z-index: 1;
`;

const TableStylings = styled.table`
  color: #000000;
  font-family: Sora;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 22px;
  padding: 0 5px;

  @media screen and (max-width: 600px) {
    margin-top: 5px;
  }

  tr {
    vertical-align: ${({ context }) =>
      context === "history" ? "top" : "auto"};
  }

  th {
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 15px;
  }

  //hack to target column
  th:nth-of-type(4n) {
    /* padding-left: 20px; */
  }

  .domain {
    /* padding-left: 20px; */
  }

  td {
    padding-bottom: 15px;
    padding-right: 10px;
  }

  .sort {
    position: relative;
    display: inline;
  }

  .score {
    border-radius: 4px;
    padding: 2px 17px 0 18px;
    color: inherit;
    text-align: center;
    width: 63px;
    display: inline-block;

    &--old {
      padding-left: 0;
      color: rgba(0, 0, 0, 0.3);
    }
    &--blue {
      background-color: rgba(51, 85, 221, 0.3);
      color: rgba(0, 0, 0, 1);
    }

    &--red {
      background-color: rgba(255, 68, 68, 0.4);
      color: rgba(0, 0, 0, 1);
    }
    &--green {
      background: rgba(0, 204, 136, 0.4);
      color: rgba(0, 0, 0, 1);
    }

    &--orange {
      background: rgba(255, 187, 0, 0.4);
      color: rgba(0, 0, 0, 1);
    }
    &--decrease {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 60 60' style='enable-background:new 0 0 60 60;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7BfillRule:evenodd;clipRule:evenodd;%7D%0A%3C/style%3E%3Ctitle%3Eii/system/arrow left%3C/title%3E%3Cg id='Symbols'%3E%3Cg id='ii_x2F_system_x2F_arrow-left'%3E%3Cpath id='Combined-Shape' class='st0' d='M30,42.5L20.5,33l2.1-2.1l5.9,5.9l0-19.3l3,0l0,19.3l5.9-5.9l2.1,2.1L30,42.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: -3px -2px;
      background-repeat: no-repeat;
      background-size: 25px;
    }
    &--increase {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 60 60' style='enable-background:new 0 0 60 60;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7BfillRule:evenodd;clipRule:evenodd;%7D%0A%3C/style%3E%3Ctitle%3Eii/system/arrow left%3C/title%3E%3Cg id='Symbols'%3E%3Cg id='ii_x2F_system_x2F_arrow-left'%3E%3Cpath id='Combined-Shape' class='st0' d='M30,17.5l9.5,9.5l-2.1,2.1l-5.9-5.9l0,19.3h-3l0-19.3l-5.9,5.9L20.5,27L30,17.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: -3px -2px;
      background-repeat: no-repeat;
      background-size: 25px;
    }
  }

  .date {
    display: flex;
    width: 175px;

    &--month-day {
      width: 100px;
    }
    &--time {
      width: 75px;
      text-align: left;
    }
  }

  .note {
    white-space: nowrap;
  }

  .region {
    text-transform: uppercase;
    text-align: right;
  }

  .label-link {
    text-decoration: none;
    color: #000000;
  }
`;

const SortIconStyling = styled.svg`
  width: 36px;
  cursor: pointer;
  height: 35px;
  position: absolute;
  top: -7px;
  left: 1px;
  /* top: ${(props) => (props.open ? "18px" : "-5px")}; */

  transform: ${(props) => (props.ascending ? "rotate(180deg)" : "none")};
`;

const SortIcon = (props) => (
  <div className="sort">
    <SortIconStyling
      ascending={props.ascending}
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ii/system/arrow-up-small" fill="#000000">
          <polygon
            id="Rectangle"
            transform="translate(29.995942, 26.750000) rotate(45.000000) translate(-29.995942, -26.750000) "
            points="36.7459415 20 36.7459415 33.5 23.2459415 33.5 23.2459415 30.5 33.7459415 30.4993593 33.7459415 20"
          ></polygon>
        </g>
      </g>
    </SortIconStyling>
  </div>
);

export default function RecentUpdatesTable(props) {
  const { labels, context } = props;

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
        },
        useSortBy
      );

    return (
      <>
        <TableWrapper context={context}>
          <TableStylings {...getTableProps()} context={context}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props

                    <th
                      width={column.width}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortIcon />
                        ) : (
                          <SortIcon ascending />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableStylings>
        </TableWrapper>
      </>
    );
  }

  return (
    <>
      <Table
        columns={
          context === "history"
            ? HistoryColumns(props)
            : RecentUpdateColumns(props)
        }
        data={labels}
      />
    </>
  );
}
