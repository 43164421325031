import { createRoot } from "react-dom/client";

import App from "./App";
import { IntlProvider } from "react-intl";
import { getLocale, getLocaleMessages } from "./common/_localeMessages";

import * as serviceWorker from "./serviceWorker";
import { StrictMode } from "react";

//const locale = "de";
const locale = getLocale();
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <IntlProvider locale={locale} messages={getLocaleMessages(locale)}>
    <App />
  </IntlProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
