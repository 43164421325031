import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthService from "../service/AuthService";
import { isEdge, isLegacyEdge } from "react-device-detect";

const RequireAuth = ({ allowedRoles }) => {
  const isSubscribed = useSelector((state) => state.isSubscribed.subscribed);
  const haveSuspendedSub = useSelector((state) => state.isSubscribed.suspended);
  const authenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  const principal = useSelector((state) => state.authentication.principal);
  const location = useLocation();
  const authService = AuthService.getInstance();

  const ROLES = {
    PARTNER: "ROLE_PARTNER",
    CUSTOMER: "ROLE_CUSTOMER",
  };

  // one-off cases: voucher redeem, reset password

  if (
    authService.isUnregisteredVoucherUser(principal) &&
    location.pathname !== "/redeemed"
  ) {
    return <Navigate to={{ pathname: "/redeemed" }} replace />;
  }

  if (
    location.pathname !== "/resetPassword" &&
    authService.isPasswordChangePrivilege(principal)
  ) {
    return <Navigate to={{ pathname: "/resetPassword" }} />;
  }

  // only non-authenticated users can access these pages
  if (
    allowedRoles.includes("NON_CUSTOMER") &&
    (!authenticated || authService.isUnregisteredVoucherUser(principal))
  ) {
    return <Outlet />;
  }

  if (authenticated) {
    // only non-authenticated users can access these pages
    if (allowedRoles.includes("NON_CUSTOMER")) {
      return <Navigate to={{ pathname: "/" }} replace />;
    }

    // Partner
    if (allowedRoles.includes("PARTNER")) {
      if (
        principal.authorities?.find(
          (auth) => auth.authority === ROLES[allowedRoles]
        )
      ) {
        return <Outlet />;
      } else {
        // Customer
        if (isSubscribed) {
          return <Navigate to={{ pathname: "/account" }} />; // not a partner, go to account
        }
        return <Navigate to={{ pathname: "/subscription/offer" }} />; // not subscribed, go to offer
      }
    }

    // Customer
    if (
      principal.authorities?.find(
        (auth) => auth.authority === ROLES[allowedRoles]
      ) &&
      !authService.isPartner(principal)
    ) {
      //
      // subscribed or suspended ... skip the checkout
      //
      if (isSubscribed || haveSuspendedSub) {
        if (
          location.pathname === "/" ||
          location.pathname === "/subscription/offer"
        ) {
          return (
            <Navigate
              to={{ pathname: "/account" }}
              state={{ from: location }}
            />
          );
        }
        return <Outlet />;
      } else {
        // allow unsububscribed user to go to account page from offer page, and navigate other account pages
        return <Outlet />;
      }
    }
  }

  AuthService.getInstance().saveRoute(location.pathname);
  return <Navigate to="/signin" state={{ from: location }} replace />;
};

export default RequireAuth;
