import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  /* padding-bottom: ${(props) => (props.update ? 0 : "50px")}; */
`;

export const Form = styled.form`
  margin: 0;
  padding: 0;
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  padding-bottom: 30px;
`;

export const Title = styled.h2`
  font-family: "Sora", sans-serif;
  font-size: 20px;
  color: var(--primary-black);
  font-weight: 600;
  text-align: ${(props) => (props.update ? "left" : "center")};
  line-height: 30px;
  margin-bottom: 30px;
`;

export const AccountMessageWrapper = styled.div`
  border-radius: 2px;
  margin-bottom: 20px;
`;

export const AccountMessage = styled.p`
  font-size: var(--14px);
`;

export const AccountMessageSpecial = styled.p`
  font-weight: 600;
  background-color: var(--primary-blue-light);
  margin-bottom: 20px;
  padding: 10px;
`;

export const AccountMessageLink = styled(Link)`
  color: var(--primary-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const AccountCreationCompleteWrapper = styled.div`
  margin-bottom: 20px;
`;

export const SubscriptionText = styled.p`
  font-size: var(--14px);
  margin-bottom: ${({ extra }) => (extra ? "30px" : "20px")};
`;

export const Disclaimer = styled.p`
  font-size: var(--14px);

  & a {
    font-size: inherit;
    color: var(--primary-blue);
    text-decoration: none;
  }
`;

export const LinkButton = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  border-radius: 2px;

  margin-bottom: 10px;
  font-weight: 400;
  padding: 0 15px;
  position: relative;
  height: 40px;
  font-size: var(--16px);

  &:after {
    content: "";
    display: block;
    background-size: 100%;
    background-color: #000;
    mask-size: cover;
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    mask: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg  viewBox='0 0 60 60' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eii/system/arrow right%3C/title%3E%3Cg id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/system/arrow-right' fill='%23000000'%3E%3Cpath d='M30,17.4959415 L39.5459415,27.0418831 L37.4246212,29.1632034 L31.5030585,23.2419415 L31.5040585,42.4959415 L28.5040585,42.4959415 L28.5030585,23.2359415 L22.5753788,29.1632034 L20.4540585,27.0418831 L30,17.4959415 Z' id='Combined-Shape' transform='translate(30.000000, 29.995942) rotate(90.000000) translate(-30.000000, -29.995942) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    height: 40px;
    width: 40px;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const LinkButtonAccount = styled(LinkButton)`
  background-color: var(--primary-gray);
  text-transform: uppercase;
  font-size: var(--14px);
  border: none;
  font-weight: 600;
  margin: 0;
  &:after {
    display: none;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const RadioModule = styled.div`
  display: flex;
  gap: 10px;

  align-items: center;
`;

export const RadioButton = styled.input`
  accent-color: var(--primary-blue);
  height: 25px;
  width: 25px;
`;

export const FinalText = styled.p`
  margin: 5px 0 15px;
`;
