import styled from "@emotion/styled";

const TextStyle = styled.p`
  font-size: 16px;
  line-height: 30px;

  &:first-of-type {
    margin-bottom: 20px;
  }
`;

const AssociatedDomains = ({ maindomain, domains }) => {
  const hasDomains = domains ? domains.length > 0 : false;

  return (
    <>
      {hasDomains ? (
        <div>
          <TextStyle>
            This Nutrition Label covers the following domains and subdomains:
          </TextStyle>
          <TextStyle>{maindomain}</TextStyle>
          {domains.map((domain) => (
            <TextStyle>{domain}</TextStyle>
          ))}
        </div>
      ) : (
        <p>This label has no associated domains.</p>
      )}
    </>
  );
};

export default AssociatedDomains;
