import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";

const PageWrapper = styled.div`
  @page {
    margin: 60px;
  }
`;

const MythContainerWrapper = styled.div`
  border-top: 6px solid rgba(0, 0, 0, 0.07);
  margin-top: 87px;
  padding-top: 60px;
  position: relative;
  /* display: flex;
  flex-direction: column; */
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }

  @media print {
    margin-top: 20px;
  }
`;

const MythContainer = styled.div`
  display: grid;

  grid-template-columns: minmax(min-content, 760px) 1fr;
  grid-template-rows: min-content 3fr;
  grid-column-gap: 50px;

  & .content-description {
    @media screen and (max-width: 600px) {
      padding-top: 10px;
      font-size: 14px;
      padding-right: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 924px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  &.locale {
    text-transform: uppercase;
    white-space: nowrap;
  }

  @media print {
    display: block;
  }
`;

const AsideWrapper = styled.div`
  max-width: 350px;
  min-width: 250px;
  justify-self: self-end;
  width: 100%;
  margin-top: -56px; // to align with locale badge
  @media (max-width: 924px) {
    max-width: 100%;
    justify-self: unset;
    grid-column: 1;
    grid-row: 2;
    margin-top: 0;
  }
`;

const MythHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    align-items: flex-start;
  }

  @media print {
    margin-bottom: 0;
  }
`;

const MythHeader = styled.h1`
  margin-bottom: 0;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media print {
    font-size: 30px;
    line-height: 40px;
  }
`;

const MythSectionTextStyles = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  a {
    text-decoration: none;
    color: #3355dd;
    word-break: break-all;
  }

  &:last-of-type {
    margin-bottom: 35px;
  }
`;

const MythSectionList = styled.ul`
  /* list-style-type: none; */
  /* padding-left: 0; */
`;

const MythSectionItemStyles = styled.div``;
const MythSectionListItemStyles = styled.li`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 60px;
  white-space: pre-wrap;
  position: relative;
  ${(props) =>
    props.firstAppearance
      ? `border: 1px solid #3355DD; list-style-type: none;  padding: 10px 20px 10px;  
      
      `
      : ""};
  ${(props) =>
    props.firstAppearance && !props.hasFirstAppearanceDate
      ? `padding-top: 10px;`
      : ""};

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
  }

  &:last-of-type {
    margin-bottom: 50px;
  }

  a {
    text-decoration: none;
    color: #3355dd;
    word-break: break-all;
  }

  &::marker {
    color: var(--primary-blue);
  }
`;

const MythVariationListItem = styled(MythSectionListItemStyles)`
  list-style-type: disc;

  margin-bottom: 5px;
  padding-left: 10px;

  &::marker {
    color: var(--primary-blue);
    font-size: 20px;
  }

  &:last-of-type {
    margin-bottom: 50px;
  }
`;

const MythSectionHeaderStyles = styled.h3`
  display: flex;
  gap: 3px;

  font-size: 20px;
  font-variation-settings: "wght" 600;
  line-height: 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 5px;
  }
`;

const MythHighlight = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-gray-dark);
`;

const MythHighlightHeaderStyles = styled.h3`
  color: #3355dd;
  font-size: 14px;
  font-variation-settings: "wght" 600;
  text-transform: uppercase;
  line-height: 18px;
`;

const AsideContainerStyles = styled.aside`
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 6px solid #000;
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 50px 20px 20px;
  margin-bottom: 65px;

  section {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 600px) {
    word-break: break-all;
    section {
      margin-bottom: 20px;
    }
  }
`;

const AsideHeaderStyles = styled.h4`
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: 14px;
  font-variation-settings: "wght" 600;
  line-height: 18px;
  margin-bottom: 8px;
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center; // needs to be conditional
`;

const DomainLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  column-gap: 10px;
`;

const DomainLink = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

const LinkWrapper = styled.div`
  line-height: 25px;
`;

const MythExcerptWrapper = styled.div`
  font-family: Sora;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border: 1px solid #3355dd;
  background-color: #eeeffc;
  padding: 10px 10px 12px;
  margin: 10px 0 12px;
  position: relative;
  border-radius: 0 2px 2px 2px;

  & .expand {
    cursor: pointer;
    color: #3355dd;
    font-family: Sora;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: right;
    float: right;
    border: 0;
    background-color: transparent;
  }

  &::before {
    top: -63px;
    left: -44px;
    content: "";
    border-bottom: 112px solid #3355dd;
    border-right: 96px solid transparent;
    position: absolute;
    transform: scale(0.105);
  }

  &::after {
    top: -61px;
    left: -43px;
    content: "";
    border-bottom: 112px solid #eeeffc;
    border-right: 95px solid transparent;
    position: absolute;
    transform: scale(0.09);
  }
`;

const MythExcerpt = styled.blockquote`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: break-word;

  &.clamp-4 {
    -webkit-line-clamp: 4;
  }

  &.expanded {
    overflow: initial;
    -webkit-line-clamp: initial;
  }
`;

const ExampleMetadata = styled.div`
  display: flex;
  font-size: 14px;
  margin-left: -8px;
  align-items: center;
`;

const ArchiveLink = styled.a`
  background-color: rgba(0, 0, 0, 0.08);
  height: 26px;
  padding: 4px 8px;
  color: #000000 !important;
  border: 0.5px solid #cccccc;
  border-radius: 2px;
  font-family: Sora;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  flex-grow: 0;
  word-break: unset;
  flex-shrink: 0;

  margin-left: auto;
`;

const IconWrapper = styled.div`
  align-self: ${(props) => props.alignSelf || "center"};

  height: 20px;
  margin-right: 1px;
`;

const PublishedDate = styled.div`
  font-size: 14px;
  line-height: 20px;

  color: var(--primary-gray-dark);
`;

const SpecialNote = styled.h3`
  font-style: italic;
  margin-bottom: 30px;
`;

export {
  PageWrapper,
  MythContainerWrapper,
  MythContainer,
  AsideWrapper,
  MythHeaderWrapper,
  MythHeader,
  MythSectionTextStyles,
  MythSectionList,
  MythSectionItemStyles,
  MythSectionListItemStyles,
  MythVariationListItem,
  MythSectionHeaderStyles,
  AsideContainerStyles,
  AsideHeaderStyles,
  AdditionalInfo,
  Wrapper,
  Column,
  DomainLinkWrapper,
  DomainLink,
  LinkWrapper,
  MythExcerptWrapper,
  MythExcerpt,
  ExampleMetadata,
  ArchiveLink,
  IconWrapper,
  MythHighlightHeaderStyles,
  MythHighlight,
  PublishedDate,
  SpecialNote,
};
