import { FilterContainer } from "./ui/FilterContainer";
import RatingsFilter from "./AdvancedSearchFilters/RatingsFilter";
import ScoreFilter from "./AdvancedSearchFilters/ScoreFilter";
import CriteriaFilterContainer from "./AdvancedSearchFilters/CriteriaFilterContainer";
import LocaleFilter from "./AdvancedSearchFilters/LocaleFilter";
import MultiSelectFilter from "./ui/MultiSelectFilter";
import metadata from "../../../../../../../../../shared/resources/_metadata";
import stubMetadata from "../../../../../../../../../shared/resources/_stub_metadata.json";

import React from "react";
import AutoSuggestFilter from "./ui/AutoSuggestFilter";
import ContentService from "../../../../service/ContentService";
import OfflineToggle from "./ui/OfflineToggle";

const RATINGS = [
  { value: "A", label: "High Credibility" },
  { value: "B", label: "Generally Credible" },
  { value: "C", label: "Credible with Exceptions" },
  { value: "D", label: "Proceed with Caution" },
  { value: "F", label: "Proceed with Maximum Caution" },
  { value: "P", label: "Platform" },
  { value: "S", label: "Satire" },
  { value: "FL", label: "Flagged" },
  { value: "L", label: "Low News Content" },
];

const TOPICS = [];

metadata.topic.values.map((topic) =>
  TOPICS.push({ value: topic, label: topic })
);

const ORIENTATION = [];

metadata.orientation.values.map((orientation) =>
  ORIENTATION.push({
    type: "orientation",
    value: orientation,
    label: orientation,
  })
);

const MEDIUMS = [];
metadata.medium.values.map((medium) =>
  MEDIUMS.push({ type: "medium", value: medium, label: medium })
);
MEDIUMS.push({ type: "medium", value: "Other", label: "Other" });

const FLAGS = [];
const FLAG_OBJECTS = {};

metadata.flags.values.forEach((flag) => {
  FLAG_OBJECTS[flag.key] = flag.value;
  FLAGS.push({ type: "flags", value: flag.key, label: flag.value });
});

stubMetadata.flags.values.forEach((flag) => {
  if (!FLAG_OBJECTS[flag.key]) {
    FLAGS.push({ type: "flags", value: flag.key, label: flag.value });
  }
});

export const getFlagValue = (flag, stub) => {
  try {
    const result = FLAGS.find((flagVal) => {
      return flagVal.value.toLowerCase().trim() === flag.toLowerCase().trim();
    });
    return result ? result.label : flag;
  } catch (e) {}
  return flag;
};

const LOCATIONS = [];

metadata.dma.values.map((location) =>
  LOCATIONS.push({ type: "location", value: location, label: location })
);

const COVERAGE = [];

metadata.coverage.values.map((coverage) => {
  return COVERAGE.push({ type: "coverage", value: coverage, label: coverage });
});

const COMMUNITIES_SERVED = [];

metadata.communities_served.values.map((community) =>
  COMMUNITIES_SERVED.push({
    type: "community",
    value: community,
    label: community,
  })
);

const BG_SEGMENTS = [
  { type: "", value: "maxexclude", label: "Exclusion - Maximum Safety" },
  { type: "", value: "highexclude", label: "Exclusion - High Safety" },
  { type: "", value: "basicexclude", label: "Exclusion - Basic Safety" },
  { type: "", value: "maxinclude", label: "Inclusion - Maximum Safety" },
  { type: "", value: "highinclude", label: "Inclusion - High Safety" },
  { type: "", value: "basicinclude", label: "Inclusion - Basic Safety" },
];

const WebsiteAdvancedFilters = ({
  principal,
  selectedFilters,
  openFilters,
  handleSelectChange,
  handleUI,
  handleSelectAndRadioChange,
  handleCriteriaChange,
  handleTextChange,
  handleToggleChange,
  clear,
}) => (
  <>
    <FilterContainer
      filter={selectedFilters?.ratings}
      handleClear={clear}
      toggleContainer={handleUI}
      isContainerOpen={openFilters.has("ratings")}
    >
      <RatingsFilter
        RATINGS={RATINGS}
        onChange={handleSelectChange}
        filter={selectedFilters?.ratings}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.score}
      handleClear={clear}
      toggleContainer={handleUI}
      isContainerOpen={openFilters.has("score")}
    >
      <ScoreFilter
        onChange={handleSelectAndRadioChange}
        filter={selectedFilters?.score}
      />
    </FilterContainer>
    <CriteriaFilterContainer
      filters={selectedFilters?.criteria}
      removeCriteriaFilter={clear}
      handleCriteriaChange={handleCriteriaChange}
      toggleContainer={handleUI}
      openFilters={openFilters}
      type={"WEBSITE"}
    />
    <FilterContainer
      filter={selectedFilters?.topics}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={TOPICS}
        onChange={handleSelectChange}
        filter={selectedFilters?.topics}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.flags}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={FLAGS}
        isClearable={false}
        filter={selectedFilters?.flags}
        onChange={handleSelectChange}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.orientation}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={ORIENTATION}
        onChange={handleSelectChange}
        filter={selectedFilters?.orientation}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.coverage}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={COVERAGE}
        onChange={handleSelectChange}
        filter={selectedFilters?.coverage}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.medium}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={MEDIUMS}
        onChange={handleSelectChange}
        filter={selectedFilters?.medium}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.locale}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <LocaleFilter
        onChange={handleSelectChange}
        principal={principal}
        filter={selectedFilters?.locale}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.location}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={LOCATIONS}
        onChange={handleSelectChange}
        filter={selectedFilters?.location}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.owner}
      handleClear={clear}
      smallerContext
      toggleContainer={handleUI}
    >
      <AutoSuggestFilter
        filter={selectedFilters?.owner}
        onChange={handleTextChange}
        getValues={ContentService.getInstance().getOwnerValues}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.show_offline}
      handleClear={clear}
      smallerContext
      toggleContainer={handleUI}
      context="web"
    >
      <OfflineToggle
        filter={selectedFilters?.show_offline}
        onChange={handleToggleChange}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.communities_served}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={COMMUNITIES_SERVED}
        onChange={handleSelectChange}
        filter={selectedFilters?.communities_served}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.bg_segments}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={BG_SEGMENTS}
        onChange={handleSelectChange}
        filter={selectedFilters?.bg_segments}
      />
    </FilterContainer>
  </>
);

export default WebsiteAdvancedFilters;
